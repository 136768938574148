import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { AttachmentTypeFormRule, AttachmentTypeFormSchema } from "@core/form/form.rule";
import { useFormDefaultAttachmentType } from "@core/form/form-default-attachment-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateAttachmentType, 
  useQyGetAttachmentTypeById, 
  useQyDeleteAttachmentType 
} from "@core/query/attachment-type.query";
import { GetResponseAttachmentTypeModel } from "@core/model/query-model-attachment-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditAttachmentType() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultAttachmentType();
  const navigate = useNavigate();
  const { mapUpdateAttachmentType, mapDeleteAttachmentType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { attachmentTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID AttachmentType
  const handleGetApiSuccess = (data: GetResponseAttachmentTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: attachmentTypeResponse,
    isLoading,
    isError: attachmentTypeError,
  } = useQyGetAttachmentTypeById(attachmentTypeId || "", handleGetApiSuccess);
  const attachmentTypeData = attachmentTypeResponse?.data?.[0];

  // API UPDATE AttachmentType
  const handleUpdateApiSuccess = () => {
    showSuccess("AttachmentType updated successfully");
  };
  const { mutate: updateAttachmentType, isLoading: isEditLoading } = useQyUpdateAttachmentType(
    handleUpdateApiSuccess
  );

  // API DELETE AttachmentType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("AttachmentType deleted successfully");
  };
  const { mutate: deleteAttachmentType } = useQyDeleteAttachmentType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<AttachmentTypeFormSchema>({
    defaultValues: getDefault(attachmentTypeData),
    resolver: zodResolver(AttachmentTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: AttachmentTypeFormSchema) => {
    if (!attachmentTypeData) {
      throw new Error("No attachmentType data");
    }

    const formData = mapUpdateAttachmentType(form, attachmentTypeData.code);
    updateAttachmentType(formData);
  };
  const handleValidateError = (err: FieldErrors<AttachmentTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!attachmentTypeData) {
      throw new Error("No attachmentType data found");
    }

    const form = mapDeleteAttachmentType(attachmentTypeData.code);
    deleteAttachmentType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    attachmentTypeId,
    attachmentTypeData,
    isLoading,
    attachmentTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}