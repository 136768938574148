import { 
    MessageResponseDto, 
    GetEmployeePenaltyDto, 
    CreateEmployeePenaltyDto, 
    EditEmployeePenaltyDto,
    EmployeePenaltyControllerGet200Response,
    EmployeePenaltyApiFp,
    DeleteEmployeePenaltyDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeePenaltyModel = EmployeePenaltyControllerGet200Response;
export type GetEmployeePenaltyModel = GetEmployeePenaltyDto;
export type MutateResponseEmployeePenaltyModel = MessageResponseDto;
export type CreateEmployeePenaltyModel = CreateEmployeePenaltyDto;
export type UpdateEmployeePenaltyModel = EditEmployeePenaltyDto;
export type DeleteEmployeePenaltyModel = DeleteEmployeePenaltyDto;

export const apiEmployeePenaltyFp = EmployeePenaltyApiFp(apiConfig);
export const getEmployeePenaltyController = apiEmployeePenaltyFp.employeePenaltyControllerGet;
export const createEmployeePenaltyController = apiEmployeePenaltyFp.employeePenaltyControllerCreate;
export const updateEmployeePenaltyController = apiEmployeePenaltyFp.employeePenaltyControllerEdit;
export const deleteEmployeePenaltyController = apiEmployeePenaltyFp.employeePenaltyControllerDelete;

export const QueryKeyEmployeePenalty = QueryKey.EmployeePenalty;