import { 
    MessageResponseDto, 
    GetEmployeeTimelineDto, 
    CreateEmployeeTimelineDto, 
    EditEmployeeTimelineDto,
    EmployeeTimelineControllerGet200Response,
    EmployeeTimelineApiFp,
    DeleteEmployeeTimelineDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeTimelineModel = EmployeeTimelineControllerGet200Response;
export type GetEmployeeTimelineModel = GetEmployeeTimelineDto;
export type MutateResponseEmployeeTimelineModel = MessageResponseDto;
export type CreateEmployeeTimelineModel = CreateEmployeeTimelineDto;
export type UpdateEmployeeTimelineModel = EditEmployeeTimelineDto;
export type DeleteEmployeeTimelineModel = DeleteEmployeeTimelineDto;

export const apiEmployeeTimelineFp = EmployeeTimelineApiFp(apiConfig);
export const getEmployeeTimelineController = apiEmployeeTimelineFp.employeeTimelineControllerGet;
export const createEmployeeTimelineController = apiEmployeeTimelineFp.employeeTimelineControllerCreate;
export const updateEmployeeTimelineController = apiEmployeeTimelineFp.employeeTimelineControllerEdit;
export const deleteEmployeeTimelineController = apiEmployeeTimelineFp.employeeTimelineControllerDelete;

export const QueryKeyEmployeeTimeline = QueryKey.EmployeeTimeline;