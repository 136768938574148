
import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import {
  CreateStatusModel,
  DeleteStatusModel,
  GetResponseStatusModel,
  MutateResponseStatusModel,
  QueryKeyStatus,
  UpdateStatusModel,
  deleteStatusController,
  createStatusController,
  getStatusController,
  updateStatusController,
} from "@core/model/query-model-status";
import { SETTINGS } from "@core/utility/settings";
import { useSessionContext } from "./session.context";

export function useQyGetStatus(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  dateFilter?: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponseStatusModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    dateFilter: string | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    await checkSession();

    showProgress();
    const operation = await getStatusController(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      dateFilter,
      startDate,
      endDate,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyStatus]);
    return response["data"] as GetResponseStatusModel;
  };

  return useQuery({
    staleTime: SETTINGS.staleTime,
    enabled,
    queryKey: [
      QueryKeyStatus, 
      search,
      limit,
      offset,
      order,
      filter,
      dateFilter,
      startDate,
      endDate
    ],
    queryFn: () => 
      apiFn(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate
      ),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetStatusById(
  id: string,
  onSuccess?:
    | ((data: GetResponseStatusModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (id: string, search = "", limit = 1, offset = 0) => {
    await checkSession();

    showProgress();
    const operation = await getStatusController(
      search,
      limit,
      offset,
      undefined,      
      { filter: JSON.stringify({ code: id }) },
      undefined,
      undefined,
      undefined,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyStatus, id]);
    return response["data"] as GetResponseStatusModel;
  };

  return useQuery({
    queryKey: [QueryKeyStatus, id],
    queryFn: () => apiFn(id),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyCreateStatus(
  onSuccess?:
    | ((data: MutateResponseStatusModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreateStatusModel) => {
    await checkSession();

    showProgress();
    const operation = await createStatusController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseStatusModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyStatus);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdateStatus(
  onSuccess?:
    | ((data: MutateResponseStatusModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdateStatusModel) => {
    await checkSession();

    showProgress();
    const operation = await updateStatusController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseStatusModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyStatus);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyDeleteStatus(
  onSuccess?:
    | ((data: MutateResponseStatusModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DeleteStatusModel) => {
    await checkSession();

    showProgress();
    const operation = await deleteStatusController(payload, authHeaders());
    const response = (await operation()).data;
    return response["message"] as MutateResponseStatusModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyStatus);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
