import { useFilterPersonContext } from "./filter-person.context";

export function FilterPerson() {
  const { roleFilterElement, reportFilterElements, clearFiltersElement } =
    useFilterPersonContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {roleFilterElement}
      {reportFilterElements}
      {clearFiltersElement}
    </div>
  );
}
