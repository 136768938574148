import { ReactNode, createContext, useContext } from "react";
import { useFilterSalaryType } from "./filter-salary-type.hook";

const FilterSalaryTypeContext = createContext(
  {} as ReturnType<typeof useFilterSalaryType>
);
export function useFilterSalaryTypeContext() {
  return useContext(FilterSalaryTypeContext);
}

interface FilterSalaryTypeProviderProps {
  children: ReactNode;
}
export function FilterSalaryTypeProvider({
  children,
}: FilterSalaryTypeProviderProps) {
  return (
    <FilterSalaryTypeContext.Provider value={useFilterSalaryType()}>
      {children}
    </FilterSalaryTypeContext.Provider>
  );
}