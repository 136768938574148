import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { LeaveTypeFormRule, LeaveTypeFormSchema } from "@core/form/form.rule";
import { useFormDefaultLeaveType } from "@core/form/form-default-leave-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateLeaveType, 
  useQyGetLeaveTypeById, 
  useQyDeleteLeaveType 
} from "@core/query/leave-type.query";
import { GetResponseLeaveTypeModel } from "@core/model/query-model-leave-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditLeaveType() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultLeaveType();
  const navigate = useNavigate();
  const { mapUpdateLeaveType, mapDeleteLeaveType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { leaveTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID LeaveType
  const handleGetApiSuccess = (data: GetResponseLeaveTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: leaveTypeResponse,
    isLoading,
    isError: leaveTypeError,
  } = useQyGetLeaveTypeById(leaveTypeId || "", handleGetApiSuccess);
  const leaveTypeData = leaveTypeResponse?.data?.[0];

  // API UPDATE LeaveType
  const handleUpdateApiSuccess = () => {
    showSuccess("LeaveType updated successfully");
  };
  const { mutate: updateLeaveType, isLoading: isEditLoading } = useQyUpdateLeaveType(
    handleUpdateApiSuccess
  );

  // API DELETE LeaveType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("LeaveType deleted successfully");
  };
  const { mutate: deleteLeaveType } = useQyDeleteLeaveType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<LeaveTypeFormSchema>({
    defaultValues: getDefault(leaveTypeData),
    resolver: zodResolver(LeaveTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: LeaveTypeFormSchema) => {
    if (!leaveTypeData) {
      throw new Error("No leaveType data");
    }

    const formData = mapUpdateLeaveType(form, leaveTypeData.code);
    updateLeaveType(formData);
  };
  const handleValidateError = (err: FieldErrors<LeaveTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!leaveTypeData) {
      throw new Error("No leaveType data found");
    }

    const form = mapDeleteLeaveType(leaveTypeData.code);
    deleteLeaveType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    leaveTypeId,
    leaveTypeData,
    isLoading,
    leaveTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}