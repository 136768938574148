import "./employee-diciplinary.scss";
import { Outlet } from "react-router-dom";
import { FilterEmployeeDiciplinaryProvider } from "./filter-employee-diciplinary/filter-employee-diciplinary.context";
import { FormDropdownViolationProvider } from "@domain/violation/form-dropdown-violation/form-dropdown-violation.context";
import { FormDropdownInfractionProvider } from "@domain/infraction/form-dropdown-infraction/form-dropdown-infraction.context";
import { FormDropdownPenaltyScheduleProvider } from "@domain/penalty-schedule/form-dropdown-penalty-schedule/form-dropdown-penalty-schedule.context";

export function EmployeeDiciplinary() {
  return (
    <div id="EmployeeDiciplinary" className="employee-diciplinary">
      <FormDropdownPenaltyScheduleProvider>
        <FormDropdownInfractionProvider>
          <FormDropdownViolationProvider>
            <FilterEmployeeDiciplinaryProvider>
              <Outlet />
            </FilterEmployeeDiciplinaryProvider>
          </FormDropdownViolationProvider>
        </FormDropdownInfractionProvider>
      </FormDropdownPenaltyScheduleProvider>
    </div>
  );
}

export default EmployeeDiciplinary;
