import { ReactNode, createContext, useContext } from "react";
import { useFilterBranch } from "./filter-branch.hook";

const FilterBranchContext = createContext(
  {} as ReturnType<typeof useFilterBranch>
);
export function useFilterBranchContext() {
  return useContext(FilterBranchContext);
}

interface FilterBranchProviderProps {
  children: ReactNode;
}
export function FilterBranchProvider({
  children,
}: FilterBranchProviderProps) {
  return (
    <FilterBranchContext.Provider value={useFilterBranch()}>
      {children}
    </FilterBranchContext.Provider>
  );
}