import { Sidebar } from "primereact/sidebar";
import "./new-shift-shell.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormDropdownShiftProvider } from "@domain/shift/form-dropdown-shift/form-dropdown-shift.context";
import NewEmployeeShift, {
  NewEmployeeShiftDefaultFormProps,
} from "@domain/employee-shift/new-employee-shift/new-employee-shift";
import { useQueryClient } from "react-query";
import { QueryKeyDailyTimeRecordShifts } from "@core/model/query-model-daily-time-record";

/* eslint-disable-next-line */
export interface NewShiftShellProps {}

export function NewShiftShell() {
  const queryClient = useQueryClient();
  let [searchParams] = useSearchParams();
  const title = searchParams.get("title");
  const bucketCode = searchParams.get("bucketCode");
  const userCode = searchParams.get("userCode");
  const day = searchParams.get("day");
  const effectivityDate = searchParams.get("effectivityDate");
  const navigate = useNavigate();

  const handleNewSuccess = () =>
    queryClient.invalidateQueries(QueryKeyDailyTimeRecordShifts);
  const defaultFormProps = {
    bucketCode,
    userCode,
    day,
    title,
    effectivityDate,
    onNewSuccess: handleNewSuccess,
  } as NewEmployeeShiftDefaultFormProps;

  return (
    <div id="NewShiftShell" className="new-shift-shell">
      <Sidebar
        visible={true}
        onHide={() => navigate("/event")}
        className="w-full md:w-[700px]"
      >
        <FormDropdownShiftProvider>
          {!!title &&
          !!bucketCode &&
          !!userCode &&
          !!day &&
          !!effectivityDate ? (
            <NewEmployeeShift
              standalone={true}
              defaultFormProps={defaultFormProps}
            />
          ) : null}
        </FormDropdownShiftProvider>
      </Sidebar>
    </div>
  );
}

export default NewShiftShell;
