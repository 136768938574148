import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { EmploymentTypeFormRule, EmploymentTypeFormSchema } from "@core/form/form.rule";
import { useFormDefaultEmploymentType } from "@core/form/form-default-employment-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateEmploymentType, 
  useQyGetEmploymentTypeById, 
  useQyDeleteEmploymentType 
} from "@core/query/employment-type.query";
import { GetResponseEmploymentTypeModel } from "@core/model/query-model-employment-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmploymentType() {
  // LOCAL DECLARATION
  const { getDefault } = useFormDefaultEmploymentType();
  const navigate = useNavigate();
  const { mapUpdateEmploymentType, mapDeleteEmploymentType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employmentTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmploymentType
  const handleGetApiSuccess = (data: GetResponseEmploymentTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: employmentTypeResponse,
    isLoading,
    isError: employmentTypeError,
  } = useQyGetEmploymentTypeById(employmentTypeId || "", handleGetApiSuccess);
  const employmentTypeData = employmentTypeResponse?.data?.[0];

  // API UPDATE EmploymentType
  const handleUpdateApiSuccess = () => {
    showSuccess("EmploymentType updated successfully");
  };
  const { mutate: updateEmploymentType, isLoading: isEditLoading } = useQyUpdateEmploymentType(
    handleUpdateApiSuccess
  );

  // API DELETE EmploymentType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmploymentType deleted successfully");
  };
  const { mutate: deleteEmploymentType } = useQyDeleteEmploymentType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmploymentTypeFormSchema>({
    defaultValues: getDefault(employmentTypeData),
    resolver: zodResolver(EmploymentTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmploymentTypeFormSchema) => {
    if (!employmentTypeData) {
      throw new Error("No employmentType data");
    }

    const formData = mapUpdateEmploymentType(form, employmentTypeData.code);
    updateEmploymentType(formData);
  };
  const handleValidateError = (err: FieldErrors<EmploymentTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employmentTypeData) {
      throw new Error("No employmentType data found");
    }

    const form = mapDeleteEmploymentType(employmentTypeData.code);
    deleteEmploymentType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    employmentTypeId,
    employmentTypeData,
    isLoading,
    employmentTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}