import { useFormContext } from "react-hook-form";
import "./form-employee-request-overtime.scss";
import { EmployeeRequestFormSchema } from "@core/form/form.rule";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";

type InputFormSchema = EmployeeRequestFormSchema;
interface FormEmployeeRequestOvertimeProps {
  isView?: boolean;
}
export function FormEmployeeRequestOvertime({
  isView,
}: FormEmployeeRequestOvertimeProps) {
  const { control } = useFormContext<InputFormSchema>();
  return (
    <div
      id="FormEmployeeRequestOvertime"
      className="form-employee-request-overtime mt-4"
    >
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
        disabled={isView}
      />
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 bg-white">
        <InputDateControl<InputFormSchema>
          control={control}
          name="effectivityDate"
          label="Effectivity Date"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          disabled={isView}
        />
        <InputDigitControl<InputFormSchema>
          control={control}
          name="otTime"
          label="Overtime (Minutes)"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter otTime"
          disabled={isView}
        />
        <InputTextareaControl<InputFormSchema>
          control={control}
          name="requestReason"
          label="Request Reason"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter overtime reason"
          disabled={isView}
        />
      </section>
    </div>
  );
}

export default FormEmployeeRequestOvertime;
