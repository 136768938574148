import {
  MessageResponseDto,
  GetEmployeeRequestDto,
  CreateEmployeeRequestDto,
  EditEmployeeRequestDto,
  EmployeeRequestControllerGet200Response,
  EmployeeRequestApiFp,
  DeleteEmployeeRequestDto,
  ProcessEmployeeRequestDto,
  OvertimeEmployeeRequestDto,
  LeaveEmployeeRequestDto,
  AmendmentEmployeeRequestDto,
  CsrRequestDto,
  AmendBreakDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeRequestModel =
  EmployeeRequestControllerGet200Response;
export type GetEmployeeRequestModel = GetEmployeeRequestDto;
export type MutateResponseEmployeeRequestModel = MessageResponseDto;
export type CreateEmployeeRequestModel = CreateEmployeeRequestDto;
export type UpdateEmployeeRequestModel = EditEmployeeRequestDto;
export type DeleteEmployeeRequestModel = DeleteEmployeeRequestDto;
export type ProcessEmployeeRequestModel = ProcessEmployeeRequestDto;
export type OvertimeEmployeeRequestModel = OvertimeEmployeeRequestDto &
  Partial<{ file: any }>;
export type LeaveEmployeeRequestModel = LeaveEmployeeRequestDto &
  Partial<{ file: any }>;
export type AmendmentEmployeeRequestModel = AmendmentEmployeeRequestDto &
  Partial<{ file: any }>;
export type ChangeShiftEmployeeRequestModel = CsrRequestDto &
  Partial<{ file: any }>;
export type BreakAmendmentEmployeeRequestModel = AmendBreakDto &
  Partial<{ file: any }>;

export const apiEmployeeRequestFp = EmployeeRequestApiFp(apiConfig);
export const getEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerGet;
export const createEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerCreate;
export const updateEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerEdit;
export const deleteEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerDelete;
export const processEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerProcess;
export const overtimeEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerOvertime;
export const leaveEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerLeave;
export const publicLeaveEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerPublicLeave;
export const amendmentEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerAmandment;
export const breakEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerAmandmentBreak;
export const changeShiftEmployeeRequestController =
  apiEmployeeRequestFp.employeeRequestControllerChangeShift;

export const QueryKeyEmployeeRequest = QueryKey.EmployeeRequest;
