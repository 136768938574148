import { 
    MessageResponseDto, 
    GetLeaveTypeDto, 
    CreateLeaveTypeDto, 
    EditLeaveTypeDto,
    UtilLeaveTypeControllerGet200Response,
    UtilityLeaveTypeApiFp,
    DeleteLeaveTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseLeaveTypeModel = UtilLeaveTypeControllerGet200Response;
export type GetLeaveTypeModel = GetLeaveTypeDto;
export type MutateResponseLeaveTypeModel = MessageResponseDto;
export type CreateLeaveTypeModel = CreateLeaveTypeDto;
export type UpdateLeaveTypeModel = EditLeaveTypeDto;
export type DeleteLeaveTypeModel = DeleteLeaveTypeDto;

export const apiLeaveTypeFp =  UtilityLeaveTypeApiFp(apiConfig);
export const getLeaveTypeController = apiLeaveTypeFp.utilLeaveTypeControllerGet;
export const createLeaveTypeController = apiLeaveTypeFp.utilLeaveTypeControllerCreate;
export const updateLeaveTypeController = apiLeaveTypeFp.utilLeaveTypeControllerEdit;
export const deleteLeaveTypeController = apiLeaveTypeFp.utilLeaveTypeControllerDelete;

export const QueryKeyLeaveType = QueryKey.LeaveType;