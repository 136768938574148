import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmploymentTypeModel } from "@core/model/query-model-employment-type";
import { employmentTypeFormDefault } from "./form.default";
import { EmploymentTypeFormSchema } from "./form.rule";

export const useFormDefaultEmploymentType = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmploymentTypeModel | undefined) => {
    return !cachedValue
      ? employmentTypeFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as EmploymentTypeFormSchema);
  };

  return {
    getDefault,
  };
};
