import { isEmpty } from "lodash-es";

class StorageService {
  static save<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static load<T>(key: string) {
    const rawData = localStorage.getItem(key) || "";
    const data = isEmpty(rawData) ? null : JSON.parse(rawData);
    return data as T;
  }

  static clear(key: string) {
    localStorage.removeItem(key);
  }

  static clearAll() {
    localStorage.clear();
  }
}

export default StorageService;
