import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeIncentive } from "./filter-employee-incentive.hook";

const FilterEmployeeIncentiveContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeIncentive>
);
export function useFilterEmployeeIncentiveContext() {
  return useContext(FilterEmployeeIncentiveContext);
}

interface FilterEmployeeIncentiveProviderProps {
  children: ReactNode;
}
export function FilterEmployeeIncentiveProvider({
  children,
}: FilterEmployeeIncentiveProviderProps) {
  return (
    <FilterEmployeeIncentiveContext.Provider value={useFilterEmployeeIncentive()}>
      {children}
    </FilterEmployeeIncentiveContext.Provider>
  );
}