import './form-status.scss';
import { useFormContext } from "react-hook-form";
import { StatusFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";

type InputFormSchema = StatusFormSchema;
export function Status() {
  const { control } = useFormContext<StatusFormSchema>();
  return (
    <div id="Status" className="status">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
    </div>
  );
}

export default Status;