import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeShiftModel } from "@core/model/query-model-employee-shift";
import { employeeShiftFormDefault } from "./form.default";
import { EmployeeShiftFormSchema } from "./form.rule";

export const useFormDefaultEmployeeShift = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeShiftModel | undefined) => {
    return !cachedValue
      ? employeeShiftFormDefault
     : ({
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        shiftCode: cachedValue.shift_code,
        shiftDescription: '',
        effectivityDate: formatDate(cachedValue.effectivity_date) as any,
        endDate: formatDate(cachedValue.end_date) as any,
        day: cachedValue.day,
        isPermanent: Boolean(cachedValue.is_permanent),
      } as EmployeeShiftFormSchema);
  };

  return {
    getDefault,
  };
};
