import './form-infraction.scss';
import { useFormContext } from "react-hook-form";
import { InfractionFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = InfractionFormSchema;
export function FormInfraction() {
  const { control } = useFormContext<InfractionFormSchema>();
  return (
    <div id="Infraction" className="infraction">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
    </div>
  );
}

export default FormInfraction;