import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownDepartment } from "./form-dropdown-department.hook";

const FormDropdownDepartmentContext = createContext(
  {} as ReturnType<typeof useFormDropdownDepartment>
);
export function useFormDropdownDepartmentContext() {
  return useContext(FormDropdownDepartmentContext);
}

interface FormDropdownDepartmentProviderProps {
  children: ReactNode;
}
export function FormDropdownDepartmentProvider({
  children,
}: FormDropdownDepartmentProviderProps) {
  return (
    <FormDropdownDepartmentContext.Provider value={useFormDropdownDepartment()}>
      {children}
    </FormDropdownDepartmentContext.Provider>
  );
}
