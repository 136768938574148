import "./list-daily-time-record.scss";

import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetOnlyDailyTimeRecord } from "@core/query/daily-time-record.query";
import { DailyTimeRecordPayloadModel } from "@core/model/query-model-daily-time-record";
import { TableSort } from "@core/model/table-sort";
import { useFilterDailyTimeRecordContext } from "../filter-daily-time-record/filter-daily-time-record.context";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { numberFormat } from "@shared/utility/formats/number-format";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { sortBy } from "lodash-es";
import { useCalendarEvent } from "@domain/event-page/calendar-helper.hook";
import DotLabel from "@shared/ui/dot-label/dot-label";

export function DailyTimeRecord() {
  // THIS IS THE LOCAL DECLARATION
  const { calendarGetShiftStyle } = useCalendarEvent();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { formatDateTimeToPh, formatDate, convertToPhDate } =
    useDateTimeContext();
  const navigate = useNavigate();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const { dateElement, calendarDate } = useFilterDailyTimeRecordContext();
  const employeeBucketCode = selectedPersonData?.bucket.code || "";

  const {
    data: dtrDataOnly,
    isLoading,
    isError,
    error,
  } = useQyGetOnlyDailyTimeRecord(
    "",
    rowLimit,
    pageNumber,
    sort,
    {
      bucket_code: employeeBucketCode,
    },
    calendarDate.start,
    calendarDate.end
  );
  const sortedDtrData = sortBy(dtrDataOnly?.data || [], (x) => x.work_date);

  // THIS IS THE LOCAL DECLARATION
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: DailyTimeRecordPayloadModel) => {};
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = <section>{dateElement}</section>;
  const clockColumn = (time: string, status: string) => {
    const timeText = !time ? "-" : formatDateTimeToPh(time);
    const tagElement = !time ? null : (
      <Tag severity="info" value={status}></Tag>
    );
    return (
      <section className="flex flex-col">
        {timeText}
        {tagElement}
      </section>
    );
  };
  const actionCol = (cell: any) => {
    const ot = (
      <Button
        label="File OT"
        severity="secondary"
        outlined
        onClick={() => navigate(`file-ot?total-time=${cell.excess_hours}`)}
        className="block p-2"
      />
    );
    const ammend = (
      <Button
        label="Amend DTR"
        severity="secondary"
        outlined
        className="block p-2"
        onClick={() => {
          const inPhDate = convertToPhDate(cell.clockin);
          const outPhDate = convertToPhDate(cell.clockout);
          navigate(
            `amend-dtr?code=${cell.code}&in=${cell.clockin}&out=${cell.clockout}`
          );
        }}
      />
    );
    const ammendBreak = (
      <Button
        label="Amend Break"
        severity="secondary"
        outlined
        className="block p-2"
        onClick={() =>
          navigate(`amend-break?breakdate=${formatDate(cell.work_date)}`)
        }
      />
    );
    const buttonDisplay = [ammend];
    if (cell.excess_hours > 0) {
      buttonDisplay.push(ot);
    }
    if (cell.break_hours > 0) {
      buttonDisplay.push(ammendBreak);
    }

    return (
      <section className="flex-col gap-1">
        {buttonDisplay.map((action, id) => (
          <span key={id}>{action}</span>
        ))}
      </section>
    );
  };
  const statusCol = (cell: any) => (
    <section>
      <small className="block">
        Absent:<b>{cell.is_absent}</b>
      </small>
      <small className="block">
        OnLeave:<b>{cell.is_onleave}</b>
      </small>
      <small className="block">
        OT:<b>{cell.is_overtime}</b>
      </small>
      <small className="block">
        Amended:<b>{cell.is_amended}</b>
      </small>
    </section>
  );
  const gridVersion2 = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{dtrDataOnly?.count}</b>
      </h5>

      <DataTable
        value={sortedDtrData}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord({} as any)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column
          body={(cell) => (
            <section className="flex-col">
              <h4>{formatDate(cell.work_date)}</h4>
              <DotLabel
                label={cell.util_shift_type_description}
                classBg={
                  calendarGetShiftStyle(cell.util_shift_type_name).classColor
                }
                classLabel="text-gray-500"
              />
              <small>{cell.util_shift_day}</small>
              <p>{cell.util_shift_schedule}</p>
            </section>
          )}
          header="Shift"
        ></Column>
        <Column
          body={(cell) => clockColumn(cell.clockin, cell.clockin_status)}
          header="Clock In"
        ></Column>
        <Column
          body={(cell) => clockColumn(cell.clockout, cell.clockout_status)}
          header="Clock Out"
        ></Column>
        <Column body={statusCol} header="Status"></Column>
        <Column
          body={(cell) => numberFormat(cell.break_hours)}
          header="Break(min)"
        ></Column>
        <Column
          body={(cell) => numberFormat(cell.undertime)}
          header="Undertime(min)"
        ></Column>
        <Column
          body={(cell) => numberFormat(cell.excess_hours)}
          header="Excess(min)"
        ></Column>
        <Column
          body={(cell) => numberFormat(cell.late)}
          header="Late(min)"
        ></Column>
        <Column
          body={(cell) => numberFormat(cell.total_hours)}
          header="Total(min)"
        ></Column>
        <Column body={actionCol} header="Action"></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={dtrDataOnly?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="DailyTimeRecord" className="daily-time-record">
      <HeaderContent title="Daily Time Record"></HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && gridVersion2}

        <Outlet />
      </div>
    </div>
  );
}

export default DailyTimeRecord;
