import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders, deviceTokenAuthHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import { useSessionContext } from "./session.context";
import {
  ClockInOutDailyTimeRecordModel,
  CreateDailyTimeRecordModel,
  DailyTimeRecordApiOverride,
  DailyTimeRecordBranchShiftModel,
  DailyTimeRecordPayloadModel,
  GetResponseBreakModel,
  GetResponseDailyTimeRecordModel,
  MutateResponseDailyTimeRecordModel,
  QRDailyTimeRecordModel,
  QueryKeyDailyTimeRecord,
  QueryKeyDailyTimeRecordBreaks,
  QueryKeyDailyTimeRecordShifts,
  ShiftResponseModel,
  UpdateDailyTimeRecordModel,
  breakClockInDailyTimeRecordController,
  breakClockOutDailyTimeRecordController,
  clockInDailyTimeRecordController,
  clockOutDailyTimeRecordController,
  createDailyTimeRecordController,
  dailyTimeGetBreaksController,
  dailyTimeQrController,
  getDailyTimeRecordController,
  getDailyTimeRecordShiftBranchController,
  getDailyTimeRecordShiftController,
  privateBreakInDailyTimeRecordController,
  privateBreakOutDailyTimeRecordController,
  privateClockInDailyTimeRecordController,
  privateClockOutDailyTimeRecordController,
  updateDailyTimeRecordController,
} from "@core/model/query-model-daily-time-record";

export function useQyGetOnlyDailyTimeRecord(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?: ((data: any) => void | Promise<unknown>) | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    await checkSession();

    showProgress();

    const operation = DailyTimeRecordApiOverride().getDtr(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      startDate,
      endDate
    );
    const response = (await operation).data;
    return response;
  };

  return useQuery({
    enabled,
    queryKey: [
      QueryKeyDailyTimeRecord,
      search,
      limit,
      offset,
      order,
      filter,
      startDate,
      endDate,
    ],
    queryFn: () =>
      apiFn(search, limit, offset, order, filter, startDate, endDate),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetDailyTimeRecord(
  dateForm: string,
  dateTo: string,
  bucketCode: string[],
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponseDailyTimeRecordModel[]) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    dateForm: string,
    dateTo: string,
    bucketCode: string[]
  ) => {
    await checkSession();

    showProgress();
    const payload = {
      date_from: dateForm,
      date_to: dateTo,
      bucket_code: bucketCode,
    } as DailyTimeRecordPayloadModel;
    const operation = await getDailyTimeRecordController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", QueryKeyDailyTimeRecord);
    return response["data"] as GetResponseDailyTimeRecordModel[];
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDailyTimeRecord, dateForm, dateTo, bucketCode],
    queryFn: () => apiFn(dateForm, dateTo, bucketCode),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyPostShift(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DailyTimeRecordPayloadModel) => {
    await checkSession();

    showProgress();
    const operation = await getDailyTimeRecordShiftController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyDailyTimeRecord);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetDailyTimeShifts(
  dateForm: string,
  dateTo: string,
  bucketCode: string[],
  enabled?: boolean,
  onSuccess?:
    | ((data: ShiftResponseModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    dateForm: string,
    dateTo: string,
    bucketCode: string[]
  ) => {
    await checkSession();

    showProgress();
    const payload = {
      date_from: dateForm,
      date_to: dateTo,
      bucket_code: bucketCode,
    } as DailyTimeRecordPayloadModel;
    const operation = await getDailyTimeRecordShiftController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as ShiftResponseModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDailyTimeRecordShifts, dateForm, dateTo, bucketCode],
    queryFn: () => apiFn(dateForm, dateTo, bucketCode),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetDailyTimeShiftByBranch(
  dateForm: string,
  dateTo: string,
  branchCode: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: ShiftResponseModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    dateForm: string,
    dateTo: string,
    branchCode: string
  ) => {
    await checkSession();

    showProgress();
    const payload = {
      date_from: dateForm,
      date_to: dateTo,
      branch_code: branchCode,
    } as DailyTimeRecordBranchShiftModel;
    const operation = await getDailyTimeRecordShiftBranchController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as ShiftResponseModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDailyTimeRecordShifts, dateForm, dateTo, branchCode],
    queryFn: () => apiFn(dateForm, dateTo, branchCode),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyCreateDailyTimeRecord(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreateDailyTimeRecordModel) => {
    await checkSession();

    showProgress();
    const operation = await createDailyTimeRecordController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdateDailyTimeRecord(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdateDailyTimeRecordModel) => {
    await checkSession();

    showProgress();
    const operation = await updateDailyTimeRecordController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGenerateToken(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: QRDailyTimeRecordModel) => {
    await checkSession();

    showProgress();
    const operation = await dailyTimeQrController(payload, authHeaders());
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyClockIn(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await clockInDailyTimeRecordController(payload);
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyPrivateClockIn(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await privateClockInDailyTimeRecordController(
      payload,
      deviceTokenAuthHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyPrivateClockOut(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await privateClockOutDailyTimeRecordController(
      payload,
      deviceTokenAuthHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyPrivateBreakIn(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await privateBreakInDailyTimeRecordController(
      payload,
      deviceTokenAuthHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyPrivateBreakOut(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await privateBreakOutDailyTimeRecordController(
      payload,
      deviceTokenAuthHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyClockOut(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await clockOutDailyTimeRecordController(payload);
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyBreakClockIn(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await breakClockInDailyTimeRecordController(payload);
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyBreakClockOut(
  onSuccess?:
    | ((data: MutateResponseDailyTimeRecordModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ClockInOutDailyTimeRecordModel) => {
    showProgress();
    const operation = await breakClockOutDailyTimeRecordController(payload);
    const response = (await operation()).data;
    return response as MutateResponseDailyTimeRecordModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetBreakDailyTimeRecord(
  workDate: string,
  bucketCode: string,
  enabled: boolean,
  onSuccess?:
    | ((data: GetResponseBreakModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (workDate: string, bucketCode: string) => {
    await checkSession();

    showProgress();
    const operation = await dailyTimeGetBreaksController(
      workDate,
      bucketCode,
      authHeaders()
    );
    const response = await operation();

    console.info("Query", [
      QueryKeyDailyTimeRecordBreaks,
      workDate,
      bucketCode,
    ]);
    return response["data"] as GetResponseBreakModel;
  };

  return useQuery({
    queryKey: [QueryKeyDailyTimeRecordBreaks, workDate, bucketCode],
    queryFn: () => apiFn(workDate, bucketCode),
    enabled,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
