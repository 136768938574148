import { ReactNode, createContext, useContext } from "react";
import { useFilterHolidayType } from "./filter-holiday-type.hook";

const FilterHolidayTypeContext = createContext(
  {} as ReturnType<typeof useFilterHolidayType>
);
export function useFilterHolidayTypeContext() {
  return useContext(FilterHolidayTypeContext);
}

interface FilterHolidayTypeProviderProps {
  children: ReactNode;
}
export function FilterHolidayTypeProvider({
  children,
}: FilterHolidayTypeProviderProps) {
  return (
    <FilterHolidayTypeContext.Provider value={useFilterHolidayType()}>
      {children}
    </FilterHolidayTypeContext.Provider>
  );
}