import { EditHolidayDto } from "@api/api";
import { holidayFormDefault } from "./form.default";
import { HolidayFormSchema } from "./form.rule";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export const useFormDefaultHoliday = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: EditHolidayDto | undefined) => {
    return !cachedValue
      ? holidayFormDefault
      : ({
          name: cachedValue.name,
          description: cachedValue.description,
          date: formatDate(cachedValue.date) as any,
          type: cachedValue.type
        } as HolidayFormSchema);
  };

  return {
    getDefault,
  };
};
