import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { ShiftFormRule, ShiftFormSchema } from "@core/form/form.rule";
import { useFormDefaultShift } from "@core/form/form-default-shift.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateShift,
  useQyGetShiftById,
  useQyDeleteShift,
} from "@core/query/shift.query";
import { GetResponseShiftModel } from "@core/model/query-model-shift";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import {
  calculateDurationInMinutes,
  getDateFromTime,
} from "@core/utility/get-time-difference";
import { addMinutes } from "date-fns";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditShift() {
  // LOCAL DECLARATION
  const { getDefault } = useFormDefaultShift();
  const { formatTime } = useDateTimeContext();
  const navigate = useNavigate();
  const { mapUpdateShift, mapDeleteShift } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();
  const { shiftId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Shift
  const handleGetApiSuccess = (data: GetResponseShiftModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("type", responseData?.type || "");
      setValue("start", responseData?.start);
      setValue("end", responseData?.end);
      setValue("halfDay", responseData?.half_day);
      setValue("gracePeriod", responseData?.grace_period);
      setValue("workHours", responseData?.work_hours);
      setValue("break", responseData?.break);
      setValue("breakStart", responseData?.break_start);
      setValue("title", responseData?.title);
      setValue("isOpen", Boolean(responseData?.is_open));

      if (responseData?.break_start && responseData?.break) {
        const breakStartAsDate = getDateFromTime(responseData?.break_start);
        const breakEnd = addMinutes(breakStartAsDate, responseData?.break);
        const breakEndInput = formatTime(breakEnd as any, "", "HH:mm");

        setValue("breakEnd", breakEndInput);
      }
    }
  };
  const {
    data: shiftResponse,
    isLoading,
    isError: shiftError,
  } = useQyGetShiftById(shiftId || "", handleGetApiSuccess);
  const shiftData = shiftResponse?.data?.[0];

  // API UPDATE Shift
  const handleUpdateApiSuccess = () => {
    showSuccess("Shift updated successfully");
  };
  const { mutate: updateShift, isLoading: isEditLoading } = useQyUpdateShift(
    handleUpdateApiSuccess
  );

  // API DELETE Shift
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Shift deleted successfully");
  };
  const { mutate: deleteShift } = useQyDeleteShift(handleDeleteApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<ShiftFormSchema>({
    defaultValues: getDefault(shiftData),
    resolver: zodResolver(ShiftFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: ShiftFormSchema) => {
    if (!shiftData) {
      throw new Error("No shift data");
    }

    let breakMinutes = form.break || 0;

    if (!form.isOpen) {
      breakMinutes =
        form.breakStart && form.breakEnd
          ? calculateDurationInMinutes(form.breakStart, form.breakEnd)
          : 0;
    }

    if (breakMinutes < 0) {
      showWarning("Invalid break time");
      return;
    }

    const updateForm = {
      ...form,
      break: breakMinutes,
    };

    const formData = mapUpdateShift(updateForm, shiftData.code);
    updateShift(formData);
  };
  const handleValidateError = (err: FieldErrors<ShiftFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!shiftData) {
      throw new Error("No shift data found");
    }

    const form = mapDeleteShift(shiftData.code);
    deleteShift(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    shiftId,
    shiftData,
    isLoading,
    shiftError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
