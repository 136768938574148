import { useFilterLeaveSubcategoryContext } from "./filter-leave-subcategory.context";

export function FilterLeaveSubcategory() {
  const { dateFilterElement, reportFilterElements, clearFiltersElement } =
    useFilterLeaveSubcategoryContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {dateFilterElement}
      {reportFilterElements}
      {clearFiltersElement}
    </div>
  );
}