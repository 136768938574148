import { useFilterFaqContext } from "./filter-faq.context";

export function FilterFaq() {
  const { dateFilterElement, clearFiltersElement } =
    useFilterFaqContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {dateFilterElement}
      {clearFiltersElement}
    </div>
  );
}