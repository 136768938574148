import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownAttachmentType } from "./form-dropdown-attachment-type.hook";

const FormDropdownAttachmentTypeContext = createContext(
  {} as ReturnType<typeof useFormDropdownAttachmentType>
);
export function useFormDropdownAttachmentTypeContext() {
  return useContext(FormDropdownAttachmentTypeContext);
}

interface FormDropdownAttachmentTypeProviderProps {
  children: ReactNode;
}
export function FormDropdownAttachmentTypeProvider({
  children,
}: FormDropdownAttachmentTypeProviderProps) {
  return (
    <FormDropdownAttachmentTypeContext.Provider value={useFormDropdownAttachmentType()}>
      {children}
    </FormDropdownAttachmentTypeContext.Provider>
  );
}
