import { Outlet } from "react-router-dom";

import { FilterLeaveSubcategoryProvider } from "./filter-leave-subcategory/filter-leave-subcategory.context";
import './leave-subcategory.scss';

export function LeaveSubcategory() {
  return (
    <div id="LeaveSubcategory" className="leave-subcategory">
       <FilterLeaveSubcategoryProvider>
        <Outlet />
      </FilterLeaveSubcategoryProvider>
    </div>
  );
}

export default LeaveSubcategory;
