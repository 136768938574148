import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeScoreModel } from "@core/model/query-model-employee-score";
import { employeeScoreFormDefault } from "./form.default";
import { EmployeeScoreFormSchema } from "./form.rule";

export const useFormDefaultEmployeeScore = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeScoreModel | undefined) => {
    return !cachedValue
      ? employeeScoreFormDefault
      : ({
          userCode: cachedValue.user_code,
          bucketCode: cachedValue.bucket_code,
          branchCode: cachedValue.branch_code,
          // questionCode: cachedValue.question_code, TODO: Check
          questionLabel: "",
          // score: cachedValue.score,
          // effectivityDate: formatDate(cachedValue.effectivity_date) as any,
        } as EmployeeScoreFormSchema);
  };

  return {
    getDefault,
  };
};
