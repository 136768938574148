import { EditShiftTypeDto } from "@api/api";
import { shiftTypeFormDefault } from "./form.default";
import { ShiftTypeFormSchema } from "./form.rule";

export const useFormDefaultShiftType = () => {
  const getDefault = (cachedValue: EditShiftTypeDto | undefined) => {
    return !cachedValue
      ? shiftTypeFormDefault
     : ({
				name: cachedValue.name,
				description: cachedValue.description,
      } as ShiftTypeFormSchema);
  };

  return {
    getDefault,
  };
};
