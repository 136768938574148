import "./form-employee-request-dtr.scss";
import { EmployeeRequestFormSchema } from "@core/form/form.rule";
import { useFormContext } from "react-hook-form";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import { useDateFilterDailyTimeRecord } from "@domain/daily-time-record/date-filter-daily-time-record/date-filter-daily-time-record";
import {
  useQyGetDailyTimeRecord,
  useQyGetOnlyDailyTimeRecord,
} from "@core/query/daily-time-record.query";
import {
  DataTable,
  DataTableSelectionSingleChangeEvent,
  DataTableValue,
  DataTableValueArray,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useEffect, useState } from "react";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import { getShouldFetchShifts } from "@core/utility/fetch-shift";

type InputFormSchema = EmployeeRequestFormSchema;
interface FormEmployeeRequestDtrProps {
  isView?: boolean;
  omitDtrSelection?: boolean;
  bucketCode?: string;
}
export function FormEmployeeRequestDtr({
  isView = false,
  omitDtrSelection = false,
  bucketCode,
}: FormEmployeeRequestDtrProps) {
  const {
    formatDateTimeForInputMeridian,
    formatDateTimeToPh,
    convertToPhDate,
    formatDate,
  } = useDateTimeContext();
  const { control, setValue, watch } = useFormContext<InputFormSchema>();
  const { dateElement, calendarDate } = useDateFilterDailyTimeRecord();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const employeeBucketCode =
    selectedPersonData?.bucket.code || bucketCode || "";
  const shouldFetchShifts = getShouldFetchShifts(calendarDate, [
    employeeBucketCode,
  ]);
  const [selectedDtr, setSelectedDtr] = useState<DataTableValue | null>(null);
  const dtrCodeValue = watch("dtrCode");
  const clockOut = watch("amendClockout");
  const clockIn = watch("amendClockin");

  useEffect(() => {
    if (!clockOut) {
      setValue("amendClockout", "00/00/00 00:00 pm");
    }
  }, [clockOut]);

  useEffect(() => {
    if (!clockIn) {
      setValue("amendClockin", "00/00/00 00:00 pm");
    }
  }, [clockIn]);

  // THIS IS THE QUERY DECLARATION
  const { data: dtrData } = useQyGetOnlyDailyTimeRecord(
    "",
    99,
    0,
    undefined,
    {
      bucket_code: employeeBucketCode,
    },
    calendarDate.start,
    calendarDate.end,
    shouldFetchShifts
  );

  const handleSelected = (
    data: DataTableSelectionSingleChangeEvent<DataTableValueArray>
  ) => {
    if (!data?.value) return;

    setSelectedDtr(data.value);
    setValue("dtrCode", data.value.code);

    const dataValue = data.value;
    if (dataValue.clockin) {
      const clockInPh = convertToPhDate(dataValue.clockin)?.toISOString();
      setValue("amendClockin", formatDateTimeForInputMeridian(clockInPh));
      setValue("workDate", formatDate(dataValue.clockin));
    }
    if (dataValue.clockout) {
      const clockOutPh = convertToPhDate(dataValue.clockout)?.toISOString();
      setValue("amendClockout", formatDateTimeForInputMeridian(clockOutPh));
    }
  };
  const showTopFields = () => {
    if (isView && !omitDtrSelection) {
      return (
        <section>
          <ImageControl<InputFormSchema>
            control={control}
            name="file"
            label="File Attachment"
            disabled={true}
          />
          <InputDateControl<InputFormSchema>
            control={control}
            type="datetime-local"
            name="amendCurrentClockin"
            label="Current Clockin"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            disabled={true}
          />
          <InputDateControl<InputFormSchema>
            control={control}
            type="datetime-local"
            name="amendCurrentClockout"
            label="Current Clockout"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            disabled={true}
          />
        </section>
      );
    } else if (!isView && !omitDtrSelection) {
      return (
        <section className="mt-4">
          <ImageControl<InputFormSchema>
            control={control}
            name="file"
            label="File Attachment"
          />
          <span className="my-4 block">{dateElement}</span>

          <h3>Select the DTR that you wanted to amend</h3>
          <DataTable
            value={dtrData?.data}
            selectionMode="single"
            selection={selectedDtr}
            onSelectionChange={(e) => handleSelected(e)}
          >
            <Column
              body={(cell) =>
                !cell.clockin ? "-" : formatDateTimeToPh(cell.clockin)
              }
              header="Clock In"
            ></Column>
            <Column field="clockin_status" header="Clockin Status"></Column>
            <Column
              body={(cell) =>
                !cell.clockout ? "-" : formatDateTimeToPh(cell.clockout)
              }
              header="Clock Out"
            ></Column>
            <Column field="clockout_status" header="Clockout Status"></Column>
            <Column field="excess_hours" header="Excess (Minutes)"></Column>
            <Column field="undertime" header="Undertime (Minutes)"></Column>
          </DataTable>
        </section>
      );
    } else if (omitDtrSelection) {
      return (
        <section>
          <ImageControl<InputFormSchema>
            control={control}
            name="file"
            label="File Attachment"
          />
          <InputDateControl<InputFormSchema>
            control={control}
            type="datetime-local"
            name="amendCurrentClockin"
            label="Current Clockin"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            disabled={true}
          />
          <InputDateControl<InputFormSchema>
            control={control}
            type="datetime-local"
            name="amendCurrentClockout"
            label="Current Clockout"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            disabled={true}
          />
        </section>
      );
    }
  };

  return (
    <div id="FormEmployeeRequestDtr" className="form-employee-request-dtr">
      {showTopFields()}
      {dtrCodeValue || isView ? (
        <section className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 bg-white">
          <InputDateControl<InputFormSchema>
            control={control}
            type="datetime-local"
            name="amendClockin"
            label="New Clockin"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter amendClockin"
            disabled={isView}
          />
          <InputDateControl<InputFormSchema>
            control={control}
            type="datetime-local"
            name="amendClockout"
            label="New Clockout"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter amendClockout"
            disabled={isView}
          />
          <InputDateControl<InputFormSchema>
            control={control}
            name="workDate"
            label="Work Date"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter work date"
            disabled={isView}
          />
          <InputTextareaControl<InputFormSchema>
            control={control}
            name="requestReason"
            label="Request Reason"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter requestReason"
            disabled={isView}
          />
        </section>
      ) : null}
    </div>
  );
}

export default FormEmployeeRequestDtr;
