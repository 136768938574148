import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";

import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { useQyDeletePerson } from "@core/query/person.query";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useReactToPrint } from "react-to-print";
import { useCallback, useRef, useState } from "react";
import { useFormDropdownPersonContext } from "../form-dropdown-person/form-dropdown-person.context";
import { useQyGetEmployeeScoreAverage } from "@core/query/employee-score.query";
import { useUserIdentityContext } from "@core/services/user-identity.context";

export function useEditPerson() {
  // LOCAL DECLARATION;
  const {
    selectedPersonData,
    personId,
    isLoading,
    personError,
    formMethod,
    getValues,
  } = useFormDropdownPersonContext();
  const { userIsAdmin } = useUserIdentityContext();

  const [qrToken, setQrToken] = useState("");
  const [searchParams] = useSearchParams();
  const { branchId } = useParams();
  const returnParam = searchParams.get("return");
  const navigate = useNavigate();
  const { mapDeletePerson } = useFormApiMapper();
  const { showSuccess, showWarning } = useNotificationContext();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [changeBucketvisible, setChangeBucketVisible] = useState(false);
  const [temporaryTransferVisible, setTemporaryTransferVisible] =
    useState(false);

  // QUERY DECLARATION
  // API AVERAGE SCORE
  const { data: scoreResponse } = useQyGetEmployeeScoreAverage(
    personId,
    !!personId
  );
  const averageScore = scoreResponse?.data?.[0];

  // API DELETE Person
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Person deleted successfully");
  };
  const { mutate: deletePerson } = useQyDeletePerson(handleDeleteApiSuccess);

  // LOCAL FUNCTIONS
  const getActions = useCallback(() => {
    const deleteActionItem = {
      label: "Delete",
      type: "more",
      value: ActionEnum.Delete,
    } as ButtonAction;

    const actions: ButtonAction[] = [
      { label: "Print QR", type: "split", value: ActionEnum.NewQr },
      {
        label: "Permanent Transfer",
        type: "more",
        value: ActionEnum.ChangeBucket,
      },
      {
        label: "Temporary Transfer",
        type: "more",
        value: ActionEnum.TemporaryTransfer,
      },
    ];

    if (userIsAdmin() ) {
      actions.splice(0, 0, deleteActionItem);
    }

    return actions;
  }, [userIsAdmin]);
  const handleBack = () => {
    if (returnParam === "event") {
      navigate(`/event`);
      return;
    } else if (returnParam === "employee") {
      navigate(`/personnel`);
      return;
    }

    navigate(`/branch/${branchId}`);
  };
  const accept = () => {
    if (!selectedPersonData) {
      throw new Error("No person data found");
    }

    const form = mapDeletePerson(selectedPersonData.code);
    deletePerson(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;

      case ActionEnum.NewQr:
        const bucketCode = selectedPersonData?.bucket.code;
        if (!bucketCode) {
          showWarning("No code found. Refresh the page and try again.");
          return;
        }

        setQrToken(bucketCode);
        setTimeout(() => {
          handlePrint();
        }, 500);
        break;

      case ActionEnum.ChangeBucket:
        setChangeBucketVisible(true);
        break;

      case ActionEnum.TemporaryTransfer:
        setTemporaryTransferVisible(true);
        break;
    }
  };

  return {
    personId,
    selectedPersonData,
    isLoading,
    personError,
    formMethod,
    componentRef,
    qrToken,
    averageScore,
    changeBucketvisible,
    temporaryTransferVisible,
    setTemporaryTransferVisible,
    setChangeBucketVisible,
    handleBack,
    handleAction,
    getValues,
    getActions,
  };
}
