import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  DailyTimeRecordFormRule,
  DailyTimeRecordFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultDailyTimeRecord } from "@core/form/form-default-daily-time-record.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useQyUpdateDailyTimeRecord } from "@core/query/daily-time-record.query";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditDailyTimeRecord() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultDailyTimeRecord();
  const navigate = useNavigate();
  const { mapUpdateDailyTimeRecord } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { dailyTimeRecordId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION

  // API UPDATE DailyTimeRecord
  const handleUpdateApiSuccess = () => {
    showSuccess("DailyTimeRecord updated successfully");
  };
  const { mutate: updateDailyTimeRecord, isLoading: isEditLoading } =
    useQyUpdateDailyTimeRecord(handleUpdateApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<DailyTimeRecordFormSchema>({
    defaultValues: getDefault(undefined),
    resolver: zodResolver(DailyTimeRecordFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: DailyTimeRecordFormSchema) => {
    const formData = mapUpdateDailyTimeRecord(form);
    updateDailyTimeRecord(formData);
  };
  const handleValidateError = (err: FieldErrors<DailyTimeRecordFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {};
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    dailyTimeRecordId,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
