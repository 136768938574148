import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownPenaltySchedule } from "./form-dropdown-penalty-schedule.hook";

const FormDropdownPenaltyScheduleContext = createContext(
  {} as ReturnType<typeof useFormDropdownPenaltySchedule>
);
export function useFormDropdownPenaltyScheduleContext() {
  return useContext(FormDropdownPenaltyScheduleContext);
}

interface FormDropdownPenaltyScheduleProviderProps {
  children: ReactNode;
}
export function FormDropdownPenaltyScheduleProvider({
  children,
}: FormDropdownPenaltyScheduleProviderProps) {
  return (
    <FormDropdownPenaltyScheduleContext.Provider value={useFormDropdownPenaltySchedule()}>
      {children}
    </FormDropdownPenaltyScheduleContext.Provider>
  );
}
