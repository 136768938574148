import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeShift } from "./filter-employee-shift.hook";

const FilterEmployeeShiftContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeShift>
);
export function useFilterEmployeeShiftContext() {
  return useContext(FilterEmployeeShiftContext);
}

interface FilterEmployeeShiftProviderProps {
  children: ReactNode;
}
export function FilterEmployeeShiftProvider({
  children,
}: FilterEmployeeShiftProviderProps) {
  return (
    <FilterEmployeeShiftContext.Provider value={useFilterEmployeeShift()}>
      {children}
    </FilterEmployeeShiftContext.Provider>
  );
}