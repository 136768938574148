import {
  MessageResponseDto,
  EmployeeLedgerApiFp,
  EmployeeLedgerControllerGetIndividualLeger200Response,
  EmployeeLedgerResponseDto,
  EmployeeLedgerListDto,
  EmployeeLedgerDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeLedgerModel =
  EmployeeLedgerControllerGetIndividualLeger200Response;
export type GetEmployeeLedgerModel = EmployeeLedgerResponseDto;
export type MutateResponseEmployeeLedgerModel = MessageResponseDto;
export type CreateEmployeeLedgerModel = EmployeeLedgerDto;
export type CreateEmployeeLedgerListModel = EmployeeLedgerListDto;

export const apiEmployeeLedgerFp = EmployeeLedgerApiFp(apiConfig);
export const getEmployeeLedgerIndividualController =
  apiEmployeeLedgerFp.employeeLedgerControllerGetIndividualLeger;
export const getEmployeeLedgerEntriesController =
  apiEmployeeLedgerFp.employeeLedgerControllerGetIndividualLeger;
export const createEmployeeLedgerController =
  apiEmployeeLedgerFp.employeeLedgerControllerCreate;

export const QueryKeyEmployeeLedger = QueryKey.EmployeeLedger;
