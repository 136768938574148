import { LocalProfile } from "@core/model/local-profile";
import { PROFILE } from "@core/utility/settings";
import StorageService from "@shared/services/storage.service";
import { useCallback } from "react";
export function useUserIdentity() {
  const getUser = useCallback(
    () => StorageService.load<LocalProfile>(PROFILE),
    []
  );

  const userIsAdmin = useCallback(() => {
    const user = StorageService.load<LocalProfile>(PROFILE);
    if (!user) return false;

    const role = user.roleName || "";
    const adminLevelRoles = ["owner", "admin"];
    const hasAdminLevel = adminLevelRoles.indexOf(role.toLowerCase()) >= 0;
    return hasAdminLevel;
  }, []);

  const userIsOwner = useCallback(() => {
    const user = StorageService.load<LocalProfile>(PROFILE);
    if (!user) return false;

    const role = user.roleName || "";
    const adminLevelRoles = ["owner"];
    const hasAdminLevel = adminLevelRoles.indexOf(role.toLowerCase()) >= 0;
    return hasAdminLevel;
  }, []);

  return {
    getUser,
    userIsAdmin,
    userIsOwner,
  };
}
