import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { useFilterEventPageContext } from "./filter-event-page.context";
import { useCallback } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Divider } from "primereact/divider";
import config from "../../../../config.json";

export function FilterEventPage() {
  const {
    branchElement,
    employeeElement,
    selectAllEmployeeElement,
    groupElement,
    selectedFilter,
    filterOptions,
    mappedEmployee,
    employeeListElement,
    setSelectedFilter,
    userIsAdmin,
    getEmployeeBuckets,
  } = useFilterEventPageContext();

  const handleFilter = (e: SelectButtonChangeEvent) => {
    const filterOption = e.value;
    setSelectedFilter(filterOption);

    if (selectedFilter !== filterOption) {
    }
  };
  const displayedFilter = useCallback(() => {
    if (selectedFilter === filterOptions[0]) {
      return (
        <div>
          {employeeElement}{" "}
          <div className="mt-2">{selectAllEmployeeElement}</div>
        </div>
      );
    }

    return <div>{groupElement}</div>;
  }, [selectedFilter, mappedEmployee]);

  return (
    <section className="">
      <Accordion activeIndex={[0]} multiple>
        <AccordionTab header="Filter">
          {userIsAdmin() && (
            <section>
              <label>Select a Branch</label>
              {branchElement}
            </section>
          )}

          <Divider />
          {config.features.event.item.showGrouping ? (
            <SelectButton
              className="flex justify-center mb-4"
              value={selectedFilter}
              onChange={(e) => handleFilter(e)}
              options={filterOptions}
              allowEmpty={false}
            />
          ) : null}
          {displayedFilter()}
          <small className="block mt-3">
            You have selected {getEmployeeBuckets().length} employees from the
            filter.
          </small>
        </AccordionTab>
        <AccordionTab header="Assignment">
          <small className="mb-4 block">
            Drag employees who don't have a shift into the calendar to create a
            new shift.
          </small>
          {employeeListElement.length > 0
            ? employeeListElement
            : "No Branch selected. Select a branch in the filter"}
        </AccordionTab>
      </Accordion>
    </section>
  );
}
