import { ReactNode, createContext, useContext } from "react";
import { useFilterJobGrade } from "./filter-job-grade.hook";

const FilterJobGradeContext = createContext(
  {} as ReturnType<typeof useFilterJobGrade>
);
export function useFilterJobGradeContext() {
  return useContext(FilterJobGradeContext);
}

interface FilterJobGradeProviderProps {
  children: ReactNode;
}
export function FilterJobGradeProvider({
  children,
}: FilterJobGradeProviderProps) {
  return (
    <FilterJobGradeContext.Provider value={useFilterJobGrade()}>
      {children}
    </FilterJobGradeContext.Provider>
  );
}