import "./list-employee-timeline.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetEmployeeTimeline } from "@core/query/employee-timeline.query";
import { GetEmployeeTimelineModel } from "@core/model/query-model-employee-timeline";
import { TableSort } from "@core/model/table-sort";
import { FilterEmployeeTimeline } from "../filter-employee-timeline/filter-employee-timeline";
import { useFilterEmployeeTimelineContext } from "../filter-employee-timeline/filter-employee-timeline.context";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { IoInformationCircleOutline } from "react-icons/io5";
import classNames from "classnames";
import { BsPencil } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";

export function EmployeeTimeline() {
  // THIS IS THE LOCAL DECLARATION
  const navigate = useNavigate();
  const { formatDateTimeV2 } = useDateTimeContext();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const [filterPanel, setFilterPanel] = useState(false);
  const { filterChipsElement, filterEntity } =
    useFilterEmployeeTimelineContext();

  // THIS IS THE QUERY DECLARATION
  const {
    data: employeeTimelineResponse,
    isLoading,
    isError,
    error,
  } = useQyGetEmployeeTimeline(
    searchTerm,
    rowLimit,
    pageNumber,
    sort,
    filterEntity
  );

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetEmployeeTimelineModel) => {
    navigate(`${item.code}`);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleNew = () => {
    navigate(`new`);
  };
  const getTimelineIcon = (action: string) => {
    const caseAction = action.toLowerCase();
    switch (caseAction) {
      case "add":
        return {
          style: "bg-green-500",
          icon: <IoMdAdd />,
        };
      case "update":
        return {
          style: "bg-amber-500",
          icon: <BsPencil />,
        };
      default:
        return {
          style: "bg-blue-500",
          icon: <IoInformationCircleOutline />,
        };
    }
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <SearchInput
          clearIcon={<MdClear />}
          searchIcon={<CiSearch />}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          placeholder="Search action"
          className="w-full block"
        />
      </div>

      <Sidebar visible={filterPanel} onHide={() => setFilterPanel(false)}>
        <FilterEmployeeTimeline />
      </Sidebar>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{employeeTimelineResponse?.count}</b>
      </h5>

      <DataTable
        value={employeeTimelineResponse?.data}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord(e.value)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column
          body={(cell) => `${cell.person_first_name} ${cell.person_last_name}`}
          header="Employee"
        ></Column>
        <Column field="source" header="Source"></Column>
        <Column field="action" header="Action"></Column>
        <Column field="message" header="Message"></Column>
        <Column
          body={(cell) => formatDateTimeV2(cell.updated_at)}
          header="Date"
        ></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={employeeTimelineResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );
  const customizedMarker = (item: GetEmployeeTimelineModel) => {
    const timelineIcon = getTimelineIcon(item.action || "");
    return (
      <span
        className={classNames(
          "flex w-10 h-10 items-center justify-center rounded-lg text-white border-circle z-1 shadow-1 ",
          timelineIcon.style
        )}
      >
        {timelineIcon.icon}
      </span>
    );
  };
  const customizedContent = (item: GetEmployeeTimelineModel) => {
    return (
      <Card title={item.action} subTitle={formatDateTimeV2(item.updated_at)}>
        <p>{item.message}</p>
        <small>Employee: &nbsp;</small>
        <span className="text-blue-500 text-base font-bold">
          {item.person_first_name} {item.person_last_name}
        </span>
      </Card>
    );
  };
  const timeline = (
    <Timeline
      value={employeeTimelineResponse?.data}
      align="alternate"
      className="customized-timeline block mt-8"
      marker={customizedMarker}
      content={customizedContent}
    />
  );

  return (
    <div id="EmployeeTimeline" className="employee-timeline">
      <HeaderContent title="Employee Timeline"></HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && timeline}
      </div>
    </div>
  );
}

export default EmployeeTimeline;
