import { ReactNode, createContext, useContext } from "react";
import { useDateTime } from "./date-time.hook";

const DateTimeContext = createContext({} as ReturnType<typeof useDateTime>);
export function useDateTimeContext() {
  return useContext(DateTimeContext);
}

interface DateTimeProviderProps {
  children: ReactNode;
  dateFormat?: string;
  dateTimeFormat?: string;
}
export function DateTimeProvider({
  children,
  dateFormat,
  dateTimeFormat,
}: DateTimeProviderProps) {
  return (
    <DateTimeContext.Provider
      value={useDateTime({ dateFormat, dateTimeFormat })}
    >
      {children}
    </DateTimeContext.Provider>
  );
}
