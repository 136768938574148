import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeRequest } from "./filter-employee-request.hook";

const FilterEmployeeRequestContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeRequest>
);
export function useFilterEmployeeRequestContext() {
  return useContext(FilterEmployeeRequestContext);
}

interface FilterEmployeeRequestProviderProps {
  children: ReactNode;
}
export function FilterEmployeeRequestProvider({
  children,
}: FilterEmployeeRequestProviderProps) {
  return (
    <FilterEmployeeRequestContext.Provider value={useFilterEmployeeRequest()}>
      {children}
    </FilterEmployeeRequestContext.Provider>
  );
}