import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownStatus } from "./form-dropdown-status.hook";

const FormDropdownStatusContext = createContext(
  {} as ReturnType<typeof useFormDropdownStatus>
);
export function useFormDropdownStatusContext() {
  return useContext(FormDropdownStatusContext);
}

interface FormDropdownStatusProviderProps {
  children: ReactNode;
}
export function FormDropdownStatusProvider({
  children,
}: FormDropdownStatusProviderProps) {
  return (
    <FormDropdownStatusContext.Provider value={useFormDropdownStatus()}>
      {children}
    </FormDropdownStatusContext.Provider>
  );
}
