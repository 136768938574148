import './form-daily-time-record.scss';
import { useFormContext } from "react-hook-form";
import { DailyTimeRecordFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = DailyTimeRecordFormSchema;
export function DailyTimeRecord() {
  const { control } = useFormContext<DailyTimeRecordFormSchema>();
  return (
    <div id="DailyTimeRecord" className="daily-time-record">
      <InputDateControl<InputFormSchema>
        control={control}
        name="clockIn"
        label="Clock In"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="clockOut"
        label="Clock Out"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default DailyTimeRecord;