import { Outlet } from "react-router-dom";

import { FilterStatusProvider } from "./filter-status/filter-status.context";
import './status.scss';

export function Status() {
  return (
    <div id="Status" className="status">
       <FilterStatusProvider>
        <Outlet />
      </FilterStatusProvider>
    </div>
  );
}

export default Status;
