import {
  MessageResponseDto,
  CreateHolidayDto,
  EditHolidayDto,
  UtilHolidayControllerGet200Response,
  UtilityHolidayApiFp,
  DeleteHolidayDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseHolidayModel = UtilHolidayControllerGet200Response;
export type GetHolidayModel = EditHolidayDto;
export type MutateResponseHolidayModel = MessageResponseDto;
export type CreateHolidayModel = CreateHolidayDto;
export type UpdateHolidayModel = EditHolidayDto;
export type DeleteHolidayModel = DeleteHolidayDto;

export const apiHolidayFp = UtilityHolidayApiFp(apiConfig);
export const getHolidayController = apiHolidayFp.utilHolidayControllerGet;
export const createHolidayController = apiHolidayFp.utilHolidayControllerCreate;
export const updateHolidayController = apiHolidayFp.utilHolidayControllerEdit;
export const deleteHolidayController = apiHolidayFp.utilHolidayControllerDelete;

export const QueryKeyHoliday = QueryKey.Holiday;
