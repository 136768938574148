import { useState } from "react";
import { CreateJobGradeModel } from "@core/model/query-model-job-grade";
import { useQyCreateJobGrade, useQyGetJobGrade } from "@core/query/job-grade.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownJobGrade = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newJobGrade, setNewJobGrade] = useState<CreateJobGradeModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewJobGrade({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newJobGrade.name) {
      showWarning("Please fill in JobGrade details");
      return;
    }
    setIsCreating(true);
    addJobGrade(newJobGrade);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New JobGrade is added. Check and select the JobGrade in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addJobGrade } = useQyCreateJobGrade(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: jobGradeResponse } = useQyGetJobGrade('');
  const mappedJobGrade = (jobGradeResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newJobGrade,
    mappedJobGrade,
    isCreating,
    jobGradeResponse,
    setSidebar,
    setFilter,
    setNewJobGrade,
    handleFilterInput,
    handleAdd,
    addJobGrade,
  };
};