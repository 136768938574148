import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { EmployeeShiftFormRule, EmployeeShiftFormSchema } from "@core/form/form.rule";
import { useFormDefaultEmployeeShift } from "@core/form/form-default-employee-shift.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateEmployeeShift, 
  useQyGetEmployeeShiftById, 
  useQyDeleteEmployeeShift 
} from "@core/query/employee-shift.query";
import { GetResponseEmployeeShiftModel } from "@core/model/query-model-employee-shift";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeeShift() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeShift();
  const navigate = useNavigate();
  const { mapUpdateEmployeeShift, mapDeleteEmployeeShift } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeShiftId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeShift
  const handleGetApiSuccess = (data: GetResponseEmployeeShiftModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("shiftCode", responseData?.shift_code || "");
      setValue("effectivityDate", formatDate(responseData?.effectivity_date, undefined) as any);
      setValue("endDate", formatDate(responseData?.end_date, undefined) as any);
      setValue("day", responseData?.day || "");
      setValue("isPermanent", Boolean(responseData?.is_permanent));
    }
  };
  const {
    data: employeeShiftResponse,
    isLoading,
    isError: employeeShiftError,
  } = useQyGetEmployeeShiftById(employeeShiftId || "", handleGetApiSuccess);
  const employeeShiftData = employeeShiftResponse?.data?.[0];

  // API UPDATE EmployeeShift
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeShift updated successfully");
  };
  const { mutate: updateEmployeeShift, isLoading: isEditLoading } = useQyUpdateEmployeeShift(
    handleUpdateApiSuccess
  );

  // API DELETE EmployeeShift
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeShift deleted successfully");
  };
  const { mutate: deleteEmployeeShift } = useQyDeleteEmployeeShift(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeShiftFormSchema>({
    defaultValues: getDefault(employeeShiftData),
    resolver: zodResolver(EmployeeShiftFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeShiftFormSchema) => {
    if (!employeeShiftData) {
      throw new Error("No employeeShift data");
    }

    const formData = mapUpdateEmployeeShift(form, employeeShiftData.code);
    updateEmployeeShift(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeShiftFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeShiftData) {
      throw new Error("No employeeShift data found");
    }

    const form = mapDeleteEmployeeShift(employeeShiftData.code);
    deleteEmployeeShift(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    employeeShiftId,
    employeeShiftData,
    isLoading,
    employeeShiftError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}