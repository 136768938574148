import "./form-holiday-type.scss";
import { useFormContext } from "react-hook-form";
import { HolidayTypeFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";

type InputFormSchema = HolidayTypeFormSchema;
export function HolidayType() {
  const { control } = useFormContext<HolidayTypeFormSchema>();
  return (
    <div id="HolidayType" className="holiday-type">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="percentage"
        label="Percentage (Salary Reflected)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter rate"
      />
    </div>
  );
}

export default HolidayType;
