import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeDiciplinaryModel } from "@core/model/query-model-employee-diciplinary";
import { employeeDiciplinaryFormDefault } from "./form.default";
import { EmployeeDiciplinaryFormSchema } from "./form.rule";

export const useFormDefaultEmployeeDiciplinary = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeDiciplinaryModel | undefined) => {
    return !cachedValue
      ? employeeDiciplinaryFormDefault
     : ({
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        branchCode: cachedValue.branch_code,
        incidentDate: formatDate(cachedValue.incident_date) as any,
        issuedDate: formatDate(cachedValue.issued_date) as any,
        violationCode: cachedValue.violation_code,
        infractionCode: cachedValue.infraction_code,
        details: cachedValue.details,
        penaltyScheduleCode: cachedValue.penalty_schedule_code,
        actionPlan: cachedValue.action_plan,
        followupDate: cachedValue.followup_date,
        issuer: cachedValue.issuer,
        supervisor: cachedValue.supervisor,
      } as EmployeeDiciplinaryFormSchema);
  };

  return {
    getDefault,
  };
};
