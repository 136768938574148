import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownShiftContext } from "./form-dropdown-shift.context";

export interface FormDropdownShiftProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
  label?: string;
  disabled?: boolean;
}

export function FormDropdownShift<T extends FieldValues>({
  name,
  allowCreate = false,
  label = "Shift",
  disabled
}: FormDropdownShiftProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const { sidebar, mappedShift, setSidebar, setFilter, handleFilterInput } =
    useFormDropdownShiftContext();

  return (
    <div className="shift">
      <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
        <h2>Create new shift</h2>
        <p>
          You are creating a new shift. Please, fill the fields to create a new
          shift and apply to current item creation.
        </p>
      </Sidebar>
      <DropdownControl<T>
        control={control}
        name={name}
        label={label}
        options={mappedShift}
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your shift"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
        onKeyDown={allowCreate ? handleFilterInput : () => {}}
        disabled={disabled}
      />
    </div>
  );
}

export default FormDropdownShift;
