import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetFaqModel } from "@core/model/query-model-faq";
import { faqFormDefault } from "./form.default";
import { FaqFormSchema } from "./form.rule";

export const useFormDefaultFaq = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetFaqModel | undefined) => {
    return !cachedValue
      ? faqFormDefault
     : ({
        title: cachedValue.title,
        description: cachedValue.description,
        link: cachedValue.link,
      } as FaqFormSchema);
  };

  return {
    getDefault,
  };
};
