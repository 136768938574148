import { Outlet } from "react-router-dom";

import { FilterPersonProvider } from "./filter-person/filter-person.context";
import "./person.scss";
import { FormDropdownJobTitleProvider } from "@domain/job-title/form-dropdown-job-title/form-dropdown-job-title.context";
import { FormDropdownJobGradeProvider } from "@domain/job-grade/form-dropdown-job-grade/form-dropdown-job-grade.context";
import { FormDropdownEmploymentTypeProvider } from "@domain/employment-type/form-dropdown-employment-type/form-dropdown-employment-type.context";
import { FormDropdownDepartmentProvider } from "@domain/department/form-dropdown-department/form-dropdown-department.context";
import { FormDropdownPersonProvider } from "./form-dropdown-person/form-dropdown-person.context";
import { FormDropdownRoleProvider } from "@domain/role/form-dropdown-role/form-dropdown-role.context";
import { FormDropdownBranchProvider } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";

export function Person() {
  return (
    <div id="Person" className="person">
      <FormDropdownBranchProvider>
        <FormDropdownPersonProvider>
          <FormDropdownDepartmentProvider>
            <FormDropdownEmploymentTypeProvider>
              <FormDropdownJobGradeProvider>
                <FormDropdownJobTitleProvider>
                  <FormDropdownRoleProvider>
                    <FilterPersonProvider>
                      <Outlet />
                    </FilterPersonProvider>
                  </FormDropdownRoleProvider>
                </FormDropdownJobTitleProvider>
              </FormDropdownJobGradeProvider>
            </FormDropdownEmploymentTypeProvider>
          </FormDropdownDepartmentProvider>
        </FormDropdownPersonProvider>
      </FormDropdownBranchProvider>
    </div>
  );
}

export default Person;
