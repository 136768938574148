import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeDeductionFormRule,
  EmployeeDeductionFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeDeduction } from "@core/form/form-default-employee-deduction.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeDeduction,
  useQyGetEmployeeDeductionById,
  useQyDeleteEmployeeDeduction,
} from "@core/query/employee-deduction.query";
import { GetResponseEmployeeDeductionModel } from "@core/model/query-model-employee-deduction";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeeDeduction() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeDeduction();
  const navigate = useNavigate();
  const { mapUpdateEmployeeDeduction, mapDeleteEmployeeDeduction } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeDeductionId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeDeduction
  const handleGetApiSuccess = (data: GetResponseEmployeeDeductionModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("incident", responseData?.incident_code || "");
      setValue("amount", responseData?.amount);
      setValue(
        "effectivityDate",
        formatDate(responseData?.effectivity_date) as any,
        undefined
      );
      setValue("remarks", responseData?.remarks || "");
    }
  };
  const {
    data: employeeDeductionResponse,
    isLoading,
    isError: employeeDeductionError,
  } = useQyGetEmployeeDeductionById(
    employeeDeductionId || "",
    handleGetApiSuccess
  );
  const employeeDeductionData = employeeDeductionResponse?.data?.[0];

  // API UPDATE EmployeeDeduction
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeDeduction updated successfully");
  };
  const { mutate: updateEmployeeDeduction, isLoading: isEditLoading } =
    useQyUpdateEmployeeDeduction(handleUpdateApiSuccess);

  // API DELETE EmployeeDeduction
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeDeduction deleted successfully");
  };
  const { mutate: deleteEmployeeDeduction } = useQyDeleteEmployeeDeduction(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeDeductionFormSchema>({
    defaultValues: getDefault(employeeDeductionData),
    resolver: zodResolver(EmployeeDeductionFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeDeductionFormSchema) => {
    if (!employeeDeductionData) {
      throw new Error("No employeeDeduction data");
    }

    const formData = mapUpdateEmployeeDeduction(
      form,
      employeeDeductionData.code
    );
    updateEmployeeDeduction(formData);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeDeductionFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeDeductionData) {
      throw new Error("No employeeDeduction data found");
    }

    const form = mapDeleteEmployeeDeduction(employeeDeductionData.code);
    deleteEmployeeDeduction(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeDeductionId,
    employeeDeductionData,
    isLoading,
    employeeDeductionError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
