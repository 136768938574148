import { Outlet } from "react-router-dom";

import { FilterViolationProvider } from "./filter-violation/filter-violation.context";
import './violation.scss';

export function Violation() {
  return (
    <div id="Violation" className="violation">
       <FilterViolationProvider>
        <Outlet />
      </FilterViolationProvider>
    </div>
  );
}

export default Violation;
