import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { FavoriteFormRule, FavoriteFormSchema } from "@core/form/form.rule";
import { useFormDefaultFavorite } from "@core/form/form-default-favorite.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateFavorite, 
  useQyGetFavoriteById, 
  useQyDeleteFavorite 
} from "@core/query/favorite.query";
import { GetResponseFavoriteModel } from "@core/model/query-model-favorite";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditFavorite() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultFavorite();
  const navigate = useNavigate();
  const { mapUpdateFavorite, mapDeleteFavorite } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { favoriteId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Favorite
  const handleGetApiSuccess = (data: GetResponseFavoriteModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: favoriteResponse,
    isLoading,
    isError: favoriteError,
  } = useQyGetFavoriteById(favoriteId || "", handleGetApiSuccess);
  const favoriteData = favoriteResponse?.data?.[0];

  // API UPDATE Favorite
  const handleUpdateApiSuccess = () => {
    showSuccess("Favorite updated successfully");
  };
  const { mutate: updateFavorite, isLoading: isEditLoading } = useQyUpdateFavorite(
    handleUpdateApiSuccess
  );

  // API DELETE Favorite
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Favorite deleted successfully");
  };
  const { mutate: deleteFavorite } = useQyDeleteFavorite(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<FavoriteFormSchema>({
    defaultValues: getDefault(favoriteData),
    resolver: zodResolver(FavoriteFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: FavoriteFormSchema) => {
    if (!favoriteData) {
      throw new Error("No favorite data");
    }

    const formData = mapUpdateFavorite(form, favoriteData.code);
    updateFavorite(formData);
  };
  const handleValidateError = (err: FieldErrors<FavoriteFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!favoriteData) {
      throw new Error("No favorite data found");
    }

    const form = mapDeleteFavorite(favoriteData.code);
    deleteFavorite(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    favoriteId,
    favoriteData,
    isLoading,
    favoriteError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}