import { FieldValues, Path, useFormContext } from "react-hook-form";
import { useFormAutocompletePerson } from "./form-autocomplete-person.hook";
import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useState } from "react";
import { LabelValue } from "@shared/models/label-value.interface";

export interface FormAutocompletePersonProps<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  hint?: string;
  defaultAutocompleteValue?: LabelValue;
}

export function FormAutocompletePerson<T extends FieldValues>({
  name,
  label,
  hint,
  defaultAutocompleteValue,
}: FormAutocompletePersonProps<T>) {
  const { setValue } = useFormContext<T>();
  const { suggestions, onSearch } = useFormAutocompletePerson();
  const [autocompleteValue, setAutocompleteValue] = useState(
    defaultAutocompleteValue
  );

  useEffect(() => {
    if (!defaultAutocompleteValue) return;
    setAutocompleteValue(defaultAutocompleteValue);
  }, [defaultAutocompleteValue]);

  const handleBlur = () => {
    if (typeof autocompleteValue === "string") {
      setAutocompleteValue({ label: "", value: "" });
      setValue(name, "" as any);
    }
  };

  return (
    <div className="person field mt-5">
      <span className="p-float-label">
        <AutoComplete
          id={name}
          field="label"
          inputClassName="w-full"
          className="w-full md:w-3/4"
          placeholder="Type to find a person"
          value={autocompleteValue}
          suggestions={suggestions}
          onChange={(e) => {
            setValue(name, e.value.value);
            setAutocompleteValue(e.value);
          }}
          completeMethod={onSearch}
          onBlur={handleBlur}
        />
        <label className="text-xs text-gray-500" htmlFor={name}>
          {label}
        </label>
      </span>
      <small className="text-gray-400 mb-1">{hint}</small>
    </div>
  );
}

export default FormAutocompletePerson;
