import { 
    MessageResponseDto, 
    CreateJobGradeDto, 
    EditJobGradeDto,
    UtilJobGradeControllerGet200Response,
    UtilityJobGradeApiFp,
    DeleteJobGradeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseJobGradeModel = UtilJobGradeControllerGet200Response;
export type GetJobGradeModel = EditJobGradeDto;
export type MutateResponseJobGradeModel = MessageResponseDto;
export type CreateJobGradeModel = CreateJobGradeDto;
export type UpdateJobGradeModel = EditJobGradeDto;
export type DeleteJobGradeModel = DeleteJobGradeDto;

export const apiJobGradeFp =  UtilityJobGradeApiFp(apiConfig);
export const getJobGradeController = apiJobGradeFp.utilJobGradeControllerGet;
export const createJobGradeController = apiJobGradeFp.utilJobGradeControllerCreate;
export const updateJobGradeController = apiJobGradeFp.utilJobGradeControllerEdit;
export const deleteJobGradeController = apiJobGradeFp.utilJobGradeControllerDelete;

export const QueryKeyJobGrade = QueryKey.JobGrade;