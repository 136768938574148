import { useState } from "react";
import { CreateIncidentTypeModel } from "@core/model/query-model-incident-type";
import { useQyCreateIncidentType, useQyGetIncidentType } from "@core/query/incident-type.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownIncidentType = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newIncidentType, setNewIncidentType] = useState<CreateIncidentTypeModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewIncidentType({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newIncidentType.name) {
      showWarning("Please fill in IncidentType details");
      return;
    }
    setIsCreating(true);
    addIncidentType(newIncidentType);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New IncidentType is added. Check and select the IncidentType in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addIncidentType } = useQyCreateIncidentType(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: incidentTypeResponse } = useQyGetIncidentType('');
  const mappedIncidentType = (incidentTypeResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newIncidentType,
    mappedIncidentType,
    isCreating,
    incidentTypeResponse,
    setSidebar,
    setFilter,
    setNewIncidentType,
    handleFilterInput,
    handleAdd,
    addIncidentType,
  };
};