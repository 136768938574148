import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { QuestionnaireFormRule, QuestionnaireFormSchema } from "@core/form/form.rule";
import { useFormDefaultQuestionnaire } from "@core/form/form-default-questionnaire.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateQuestionnaire, 
  useQyGetQuestionnaireById, 
  useQyDeleteQuestionnaire 
} from "@core/query/questionnaire.query";
import { GetResponseQuestionnaireModel } from "@core/model/query-model-questionnaire";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditQuestionnaire() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultQuestionnaire();
  const navigate = useNavigate();
  const { mapUpdateQuestionnaire, mapDeleteQuestionnaire } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { questionnaireId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Questionnaire
  const handleGetApiSuccess = (data: GetResponseQuestionnaireModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("jobTitle", responseData?.job_title || "");
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: questionnaireResponse,
    isLoading,
    isError: questionnaireError,
  } = useQyGetQuestionnaireById(questionnaireId || "", handleGetApiSuccess);
  const questionnaireData = questionnaireResponse?.data?.[0];

  // API UPDATE Questionnaire
  const handleUpdateApiSuccess = () => {
    showSuccess("Questionnaire updated successfully");
  };
  const { mutate: updateQuestionnaire, isLoading: isEditLoading } = useQyUpdateQuestionnaire(
    handleUpdateApiSuccess
  );

  // API DELETE Questionnaire
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Questionnaire deleted successfully");
  };
  const { mutate: deleteQuestionnaire } = useQyDeleteQuestionnaire(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<QuestionnaireFormSchema>({
    defaultValues: getDefault(questionnaireData),
    resolver: zodResolver(QuestionnaireFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: QuestionnaireFormSchema) => {
    if (!questionnaireData) {
      throw new Error("No questionnaire data");
    }

    const formData = mapUpdateQuestionnaire(form, questionnaireData.code);
    updateQuestionnaire(formData);
  };
  const handleValidateError = (err: FieldErrors<QuestionnaireFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!questionnaireData) {
      throw new Error("No questionnaire data found");
    }

    const form = mapDeleteQuestionnaire(questionnaireData.code);
    deleteQuestionnaire(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    questionnaireId,
    questionnaireData,
    isLoading,
    questionnaireError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}