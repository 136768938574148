import "./form-employee-loan.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeLoanFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";

type InputFormSchema = EmployeeLoanFormSchema;
export function FormEmployeeLoan() {
  const { control } = useFormContext<EmployeeLoanFormSchema>();
  return (
    <div id="EmployeeLoan" className="employee-loan">
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="subject"
        label="Subject"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter subject"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="purpose"
        label="Purpose"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter purpose"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="amount"
        label="Amount"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="intervals"
        label="Intervals in months"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount needed for employee to pay"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="paymentStart"
        label="Start of payment"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default FormEmployeeLoan;
