import { SeverityTag } from "@shared/models/severity-tag.enum";

export const getSeverity = (status: string) => {
  switch (status) {
    case "APPROVED":
      return SeverityTag.Success;
    case "CANCELLED":
      return SeverityTag.Warning;
    case "REJECTED":
      return SeverityTag.Danger;

    default:
      return SeverityTag.Info;
  }
};
