import { FieldValues, Path, useFormContext } from "react-hook-form";
import { useFormDropdownPersonContext } from "./form-dropdown-person.context";
import { AutoComplete } from "primereact/autocomplete";

export interface FormDropdownPersonProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
  label?: string;
  hint?: string;
}

export function FormDropdownPerson<T extends FieldValues>({
  name,
  label,
  hint,
}: FormDropdownPersonProps<T>) {
  const { setValue } = useFormContext<T>();
  const { suggestions, selectedReportingTo, onSearch, setSelectedReportingTo } =
    useFormDropdownPersonContext();

  return (
    <div className="person field mt-5">
      <span className="p-float-label">
        <AutoComplete
          id={name}
          field="label"
          inputClassName="w-full"
          className="w-full md:w-3/4"
          placeholder="Type to find a person"
          value={selectedReportingTo}
          suggestions={suggestions}
          onChange={(e) => {
            setValue(name, e.value.value);
            setSelectedReportingTo(e.value);
          }}
          completeMethod={onSearch}
        />
        <label className="text-xs text-gray-500" htmlFor={name}>
          {label}
        </label>
      </span>
      <small className="text-gray-400 mb-1">{hint}</small>
    </div>
  );
}

export default FormDropdownPerson;
