import "./form-person.scss";
import { FieldErrors, useFormContext } from "react-hook-form";
import { PersonFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormPersonEmployment from "../form-person-employment/form-person-employment";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { LabelValue } from "@shared/models/label-value.interface";
import FormPersonAddress from "../form-person-address/form-person-address";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import { useFormDropdownPersonContext } from "../form-dropdown-person/form-dropdown-person.context";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useQyUpdatePerson, useQyUploadFile } from "@core/query/person.query";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import HeaderButton from "@shared/ui/header-button/header-button";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { ActionEnum } from "@core/model/actions.enum";
import { TabPanel, TabView } from "primereact/tabview";

type InputFormSchema = PersonFormSchema;
export interface PersonProps {
  isEdit: boolean;
}
export function Person({ isEdit = false }: PersonProps) {
  const { control, handleSubmit, setValue } =
    useFormContext<PersonFormSchema>();
  const { showError, showSuccess } = useNotificationContext();
  const { mapUpdatePerson, mapUploadAttachment } = useFormApiMapper();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const genderOptions = [
    {
      label: "Male",
      value: "MALE",
    },
    {
      label: "Female",
      value: "FEMALE",
    },
  ] as LabelValue[];
  const branchActions: ButtonAction[] = [
    { label: "Update", type: "primary", value: ActionEnum.Update },
  ];

  // QUERIES
  // API UPDATE Person
  const handleUpdateApiSuccess = () => {
    showSuccess("Person updated successfully");
  };
  const { mutate: updatePerson } = useQyUpdatePerson(handleUpdateApiSuccess);

  // API UPLOAD ATTACHMENT
  const handleUploadApiSuccess = () => {
    showSuccess("Attachment updated successfully");
    setValue("attachmentType", "");
    setValue("attachment", null);
  };
  const { mutate: upload } = useQyUploadFile(handleUploadApiSuccess);

  // LOCAL FUNCTION
  const handleValidate = (form: PersonFormSchema) => {
    if (!selectedPersonData) {
      throw new Error("No person data");
    }

    const formData = mapUpdatePerson(form, selectedPersonData.code);
    updatePerson(formData);
  };
  const handleValidateError = (err: FieldErrors<PersonFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleUploadValidate = (form: PersonFormSchema) => {
    if (!selectedPersonData) {
      throw new Error("No person data");
    }

    const formData = mapUploadAttachment(form, selectedPersonData.code);
    upload(formData);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      case ActionEnum.Upload:
        handleSubmit(handleUploadValidate, handleValidateError)();
        break;
    }
  };

  return (
    <div id="Person" className="person">
      {isEdit && (
        <span className="flex justify-end">
          <HeaderButton actions={branchActions} onAction={handleAction} />
        </span>
      )}

      <TabView>
        <TabPanel header="Personal">
          <ImageControl<InputFormSchema>
            control={control}
            name="picture"
            label="Profile Picture"
            imagePreviewClass="w-32 h-32"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="firstName"
            label="FirstName"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter firstName"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="middleName"
            label="MiddleName"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter middleName"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="lastName"
            label="LastName"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter lastName"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="email"
            label="Email"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter email"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="username"
            label="Username"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter Username"
          />
          <InputControl<InputFormSchema>
            control={control}
            type="password"
            name="password"
            label="Password"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter password"
            hint="Optional. Only fill for HR / Admin / Owner"
          />
          <DropdownControl<InputFormSchema>
            control={control}
            name="gender"
            label="Sex"
            options={genderOptions}
            containerClassName="mb-9"
            className="w-full md:w-3/4"
            placeholder="Enter your gender"
          />
          <InputDateControl<InputFormSchema>
            control={control}
            name="birthday"
            label="Birthday"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
        </TabPanel>
        <TabPanel header="Employment">
          <FormPersonEmployment />
        </TabPanel>
        <TabPanel header="Address">
          <FormPersonAddress />
        </TabPanel>
      </TabView>
    </div>
  );
}

export default Person;
