import { StatusEnum } from "@core/model/status.enum";

export function getColor(status: string) {
  const statusFilter = status?.toLowerCase() || "";
  switch (statusFilter) {
    case StatusEnum.Approved:
      return {
        bgClass: "bg-green-500",
      };
    case StatusEnum.Rejected:
      return {
        bgClass: "bg-red-500",
      };
    case StatusEnum.Pending:
      return {
        bgClass: "bg-blue-500",
      };

    default:
      return {
        bgClass: "bg-gray-500",
      };
  }
}
