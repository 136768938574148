import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { ShiftTypeFormRule, ShiftTypeFormSchema } from "@core/form/form.rule";
import { useFormDefaultShiftType } from "@core/form/form-default-shift-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateShiftType, 
  useQyGetShiftTypeById, 
  useQyDeleteShiftType 
} from "@core/query/shift-type.query";
import { GetResponseShiftTypeModel } from "@core/model/query-model-shift-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";

export function useEditShiftType() {
  // LOCAL DECLARATION
  const { getDefault } = useFormDefaultShiftType();
  const navigate = useNavigate();
  const { mapUpdateShiftType, mapDeleteShiftType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { shiftTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID ShiftType
  const handleGetApiSuccess = (data: GetResponseShiftTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: shiftTypeResponse,
    isLoading,
    isError: shiftTypeError,
  } = useQyGetShiftTypeById(shiftTypeId || "", handleGetApiSuccess);
  const shiftTypeData = shiftTypeResponse?.data?.[0];

  // API UPDATE ShiftType
  const handleUpdateApiSuccess = () => {
    showSuccess("ShiftType updated successfully");
  };
  const { mutate: updateShiftType, isLoading: isEditLoading } = useQyUpdateShiftType(
    handleUpdateApiSuccess
  );

  // API DELETE ShiftType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("ShiftType deleted successfully");
  };
  const { mutate: deleteShiftType } = useQyDeleteShiftType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<ShiftTypeFormSchema>({
    defaultValues: getDefault(shiftTypeData),
    resolver: zodResolver(ShiftTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: ShiftTypeFormSchema) => {
    if (!shiftTypeData) {
      throw new Error("No shiftType data");
    }

    const formData = mapUpdateShiftType(form, shiftTypeData.code);
    updateShiftType(formData);
  };
  const handleValidateError = (err: FieldErrors<ShiftTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!shiftTypeData) {
      throw new Error("No shiftType data found");
    }

    const form = mapDeleteShiftType(shiftTypeData.code);
    deleteShiftType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    shiftTypeId,
    shiftTypeData,
    isLoading,
    shiftTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}