import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeePaymentModel } from "@core/model/query-model-employee-payment";
import { employeePaymentFormDefault } from "./form.default";
import { EmployeePaymentFormSchema } from "./form.rule";

export const useFormDefaultEmployeePayment = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeePaymentModel | undefined) => {
    return !cachedValue
      ? employeePaymentFormDefault
      : ({
          userCode: cachedValue.user_code,
          bucketCode: cachedValue.bucket_code,
          // loan: cachedValue.loan,
          amount: cachedValue.amount,
          effectivityDate: formatDate(cachedValue.effectivity_date) as any,
          remarks: cachedValue.remarks,
        } as EmployeePaymentFormSchema);
  };

  return {
    getDefault,
  };
};
