import { ReactNode, createContext, useContext } from "react";
import { useFilterShiftType } from "./filter-shift-type.hook";

const FilterShiftTypeContext = createContext(
  {} as ReturnType<typeof useFilterShiftType>
);
export function useFilterShiftTypeContext() {
  return useContext(FilterShiftTypeContext);
}

interface FilterShiftTypeProviderProps {
  children: ReactNode;
}
export function FilterShiftTypeProvider({
  children,
}: FilterShiftTypeProviderProps) {
  return (
    <FilterShiftTypeContext.Provider value={useFilterShiftType()}>
      {children}
    </FilterShiftTypeContext.Provider>
  );
}