import { Outlet } from "react-router-dom";

import { FilterEmployeeBranchProvider } from "./filter-employee-branch/filter-employee-branch.context";
import './employee-branch.scss';

export function EmployeeBranch() {
  return (
    <div id="EmployeeBranch" className="employee-branch">
       <FilterEmployeeBranchProvider>
        <Outlet />
      </FilterEmployeeBranchProvider>
    </div>
  );
}

export default EmployeeBranch;
