import { format, isValid, addHours } from "date-fns";

interface useDateTimeProps {
  dateFormat?: string;
  dateTimeFormat?: string;
}
export function useDateTime({
  dateFormat = "yyyy-MM-dd",
  dateTimeFormat = "yyyy-MM-dd p",
}: useDateTimeProps) {
  const format12HourWithUTC = (date: string) => {
    if (!date || date === "") return "";

    const withUTC = `${date} UTC`;
    const newDateFormat = new Date(withUTC);

    const newDate = format(newDateFormat, "yyyy/MM/dd h:mm a");
    return newDate;
  };

  const formatDate = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), dateFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, dateFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const formatDateTimeLocale = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid ? new Date(date).toLocaleString() : defaultDisplay;
    } else {
      displayDate = date ? format(date, dateTimeFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const formatDateTime = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), dateTimeFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, dateTimeFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const getTime = (time: string) => {
    const dateOnly = new Date().toISOString().replace(/T.*$/, "");
    return `${dateOnly}T${time}:00`;
  };

  const formatTime = (
    date: Date | string | null | undefined,
    defaultDisplay: string = "",
    timeFormat = "h:mm a"
  ) => {
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), timeFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, timeFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const formatDateTimeForInput = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    const inputFormat = "yyyy-MM-dd hh:mm";
    let displayDate = formatTime(date, defaultDisplay, inputFormat);

    return displayDate;
  };

  const formatDateTimeForInputMeridian = (
    date: string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    const dateIsValid = isValid(new Date(date as string));

    if (dateIsValid) {
      const dated = new Date(date as string);
      displayDate = dated.toISOString().slice(0, 16);
    }

    return displayDate;
  };

  const formatDateTimeV2 = (
    date: string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    const dateIsValid = isValid(new Date(date as string));

    if (dateIsValid) {
      const inputDate = formatDateTimeForInputMeridian(date);
      displayDate = format(inputDate, dateTimeFormat);
    }

    return displayDate;
  };

  const formatDateTimeToPh = (
    date: string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    const inputDate = formatDateTimeForInputMeridian(date);
    const phDate = convertToPhDate(inputDate);

    if (!phDate) {
      return displayDate;
    }

    displayDate = format(phDate, dateTimeFormat);

    return displayDate;
  };

  const convertToPhDate = (date: string | null | undefined) => {
    const dateIsValid = isValid(new Date(date as string));
    if (!dateIsValid) {
      return undefined;
    }

    const convertToDate = new Date(date as string);
    const updateDateTime = addHours(convertToDate, 8);
    return updateDateTime;
  };

  return {
    dateFormat,
    dateTimeFormat,
    format12HourWithUTC,
    formatDate,
    formatDateTime,
    formatDateTimeLocale,
    formatDateTimeForInput,
    formatDateTimeForInputMeridian,
    formatTime,
    getTime,
    formatDateTimeV2,
    formatDateTimeToPh,
    convertToPhDate,
  };
}
