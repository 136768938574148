import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeSalaryFormRule,
  EmployeeSalaryFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeSalary } from "@core/form/form-default-employee-salary.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeSalary,
  useQyGetEmployeeSalaryById,
  useQyDeleteEmployeeSalary,
} from "@core/query/employee-salary.query";
import { GetResponseEmployeeSalaryModel } from "@core/model/query-model-employee-salary";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeeSalary() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeSalary();
  const navigate = useNavigate();
  const { mapUpdateEmployeeSalary, mapDeleteEmployeeSalary } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeSalaryId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeSalary
  const handleGetApiSuccess = (data: GetResponseEmployeeSalaryModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("salaryType", responseData?.salary_type || "");
      setValue("amount", responseData?.amount);
      setValue(
        "effectivityDate",
        formatDate(responseData?.effectivity_date) as any,
        undefined
      );
    }
  };
  const {
    data: employeeSalaryResponse,
    isLoading,
    isError: employeeSalaryError,
  } = useQyGetEmployeeSalaryById(employeeSalaryId || "", handleGetApiSuccess);
  const employeeSalaryData = employeeSalaryResponse?.data?.[0];

  // API UPDATE EmployeeSalary
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeSalary updated successfully");
  };
  const { mutate: updateEmployeeSalary, isLoading: isEditLoading } =
    useQyUpdateEmployeeSalary(handleUpdateApiSuccess);

  // API DELETE EmployeeSalary
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeSalary deleted successfully");
  };
  const { mutate: deleteEmployeeSalary } = useQyDeleteEmployeeSalary(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeSalaryFormSchema>({
    defaultValues: getDefault(employeeSalaryData),
    resolver: zodResolver(EmployeeSalaryFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeSalaryFormSchema) => {
    if (!employeeSalaryData) {
      throw new Error("No employeeSalary data");
    }

    const formData = mapUpdateEmployeeSalary(form, employeeSalaryData.code);
    updateEmployeeSalary(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeSalaryFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeSalaryData) {
      throw new Error("No employeeSalary data found");
    }

    const form = mapDeleteEmployeeSalary(employeeSalaryData.code);
    deleteEmployeeSalary(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeSalaryId,
    employeeSalaryData,
    isLoading,
    employeeSalaryError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
