import './new-job-title.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateJobTitle } from "@core/query/job-title.query";
import { JobTitleFormRule, JobTitleFormSchema } from "@core/form/form.rule";
import { jobTitleFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormJobTitle from "../form-job-title/form-job-title";

export function JobTitle() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateJobTitle } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New position is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addJobTitle, isLoading: isCreating } =
    useQyCreateJobTitle(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<JobTitleFormSchema>({
    defaultValues: jobTitleFormDefault,
    resolver: zodResolver(JobTitleFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: JobTitleFormSchema) => {
    const formData = mapCreateJobTitle(form);
    addJobTitle(formData);
  };
  const handleValidateError = (err: FieldErrors<JobTitleFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="JobTitle" className="job-title">
      <HeaderContent
        title="New Position"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormJobTitle />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default JobTitle;