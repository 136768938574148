import { useCallback, useState } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useFormDropdownBranchContext } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import { useQyGetEmployeeBucketWithTemp } from "@core/query/employee-bucket.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { MultiSelect } from "primereact/multiselect";
import { useUserIdentity } from "@core/services/user-identity.hook";
import { CiCircleInfo } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { QuickViewEventDataProps } from "../quick-view-event/quick-view-event";
import { FaRegEye } from "react-icons/fa6";
import { Checkbox } from "primereact/checkbox";
import { CalendarDate } from "@core/model/calendar-event.interface";
import { useFormDropdownFavoriteContext } from "@domain/employee-favorite/form-dropdown-favorite/form-dropdown-favorite.context";
import { SelectButtonChangeEvent } from "primereact/selectbutton";

const getDefaultBranchId = (isAdmin: boolean, branchId: string) => {
  return isAdmin ? "" : branchId;
};

export function useFilterEventPage() {
  const navigate = useNavigate();
  const { getUser, userIsAdmin } = useUserIdentity();
  const [selectedBranch, setSelectedBranch] = useState(
    getDefaultBranchId(userIsAdmin(), getUser()?.branchId)
  );
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedGroupEmployee, setSelectedGroupEmployee] = useState<string[]>(
    []
  );
  const [selectedEmployee, setSelectedEmployee] = useState<string[]>([]);
  const [calendarDate, setCalendarDate] = useState<CalendarDate>({
    start: "",
    end: "",
  });
  const { employeeGroup, groupRecord } = useFormDropdownFavoriteContext();
  const { mappedBranch } = useFormDropdownBranchContext();
  const [selectedEvent, setSelectedEvent] =
    useState<QuickViewEventDataProps | null>(null);
  const [detailSidebar, setDetailSidebar] = useState(false);
  const [isSelectAllEmployee, setIsSelectAllEmployee] = useState(false);
  const filterOptions = ["Employee", "Group"];
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);

  // QUERIES
  const { data: employeeResponse } = useQyGetEmployeeBucketWithTemp(
    calendarDate.start,
    calendarDate.end,
    selectedBranch,
    !!selectedBranch
  );

  // LOCAL FUNCTION
  const mappedEmployee = (employeeResponse?.data || []).map(
    (item) =>
      ({
        label: `${item.person_first_name} ${item.person_last_name}`,
        value: item.bucket_code,
      } as LabelValue)
  );
  const mappedEmployeeWithPayload = (employeeResponse?.data || []).map(
    (item) =>
      ({
        label: `${item.person_first_name} ${item.person_last_name}`,
        value: item.bucket_code,
        payload: {
          userCode: item.user_code,
          branchCode: item.branch,
        },
      } as LabelValue)
  );
  const handleViewEmployee = (branchId: string, userId: string) => {
    navigate(`/employee/${branchId}/${userId}?return=event`);
  };
  const handleGroupSelect = (e: DropdownChangeEvent) => {
    const groupId = e.value;
    if (!groupId) {
      setSelectedGroup("");
      setSelectedGroupEmployee([]);
    }

    const selectedGroupBuckets = groupRecord?.[groupId].map(
      (item) => item.bucket_code
    ) || [""];

    setSelectedGroup(groupId);
    setSelectedGroupEmployee(selectedGroupBuckets);
  };
  const getEmployeeBuckets = useCallback(() => {
    if (selectedFilter === filterOptions[0] && selectedEmployee.length <= 0) {
      const defaultSelected = mappedEmployee.map((item) => item.value);
      const showEmployees = isSelectAllEmployee
        ? defaultSelected
        : defaultSelected.slice(0, 4);

      return showEmployees;
    } else if (
      selectedFilter === filterOptions[0] &&
      selectedEmployee.length > 0
    ) {
      return selectedEmployee;
    }

    return selectedGroupEmployee;
  }, [mappedEmployee, selectedEmployee, isSelectAllEmployee, selectedFilter]);

  // HTML
  const branchElement = (
    <Dropdown
      value={selectedBranch}
      onChange={(e) => setSelectedBranch(e.value)}
      options={mappedBranch}
      optionLabel="label"
      placeholder="Select a branch"
      className="w-full md:w-14rem"
      filter
    />
  );
  const selectAllEmployeeElement = (
    <div className="flex align-items-center">
      <Checkbox
        onChange={(e) => setIsSelectAllEmployee(e.checked || false)}
        checked={isSelectAllEmployee}
        inputId="allEmployee"
      ></Checkbox>
      <label htmlFor="allEmployee" className="ml-2">
        Select All Employee
      </label>
    </div>
  );
  const employeeElement = (
    <MultiSelect
      value={selectedEmployee}
      onChange={(e) => {
        console.log(e.value);
        setSelectedEmployee(e.value);
      }}
      options={mappedEmployee}
      optionLabel="label"
      placeholder="Select one or more employees"
      className="w-full md:w-20rem"
      filter
    />
  );
  const employeeListElement = mappedEmployeeWithPayload.map((item, id) => (
    <div
      className="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2 cursor-pointer"
      title={item.label}
      data-bucket-id={item.value}
      data-user-id={item.payload.userCode}
      data-color="#0097a7"
      key={id}
    >
      <div className="fc-event-main flex justify-between items-center">
        <strong>{item.label}</strong>
        <section className="flex gap-2">
          <span
            className="cursor-pointer hover:bg-blue-200 rounded-lg"
            onClick={() => {
              setSelectedEvent({
                name: item.label,
                branchId: item.payload.branchCode,
                bucketId: item.value,
                userId: item.payload.userCode,
                eventType: "",
                startShift: "",
                endShift: "",
                shiftCode: "",
              });
              setDetailSidebar(true);
            }}
          >
            <FaRegEye />
          </span>
          <span
            className="cursor-pointer hover:bg-blue-200 rounded-lg"
            onClick={() =>
              handleViewEmployee(item.payload.branchCode, item.payload.userCode)
            }
          >
            <CiCircleInfo />
          </span>
        </section>
      </div>
    </div>
  ));
  const groupElement = (
    <Dropdown
      value={selectedGroup}
      onChange={(e) => handleGroupSelect(e)}
      options={employeeGroup}
      optionLabel="label"
      placeholder="Select a group"
      className="w-full md:w-14rem"
      filter
    />
  );

  return {
    calendarDate,
    selectedBranch,
    selectedEmployee,
    mappedEmployee,
    branchElement,
    employeeElement,
    employeeListElement,
    selectedEvent,
    detailSidebar,
    selectAllEmployeeElement,
    isSelectAllEmployee,
    groupElement,
    filterOptions,
    selectedFilter,
    setSelectedFilter,
    setSelectedGroup,
    setDetailSidebar,
    setSelectedEvent,
    setCalendarDate,
    userIsAdmin,
    getUser,
    getEmployeeBuckets,
  };
}
