import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownInfractionContext } from "./form-dropdown-infraction.context";

export interface FormDropdownInfractionProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownInfraction<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownInfractionProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const {
    sidebar,
    newInfraction,
    mappedInfraction,
    isCreating,
    setSidebar,
    setFilter,
    setNewInfraction,
    handleFilterInput,
    handleAdd,
  } = useFormDropdownInfractionContext();

  return (
    <div className="infraction">
      {newInfraction && (
        <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
          <h2>Create new infraction</h2>
          <p>
            You are creating a new infraction. Please, fill the fields to create
            a new infraction and apply to current item creation.
          </p>
          <div className="flex flex-col gap-2 mt-4">
            <InputText
              placeholder="Infraction Name"
              value={newInfraction.name}
              onChange={(e: any) =>
                setNewInfraction({ ...newInfraction, name: e.target.value })
              }
            />
            <InputText
              placeholder="Infraction Description"
              value={newInfraction.description}
              onChange={(e: any) =>
                setNewInfraction({
                  ...newInfraction,
                  description: e.target.value,
                })
              }
            />

            <Button
              label="Create"
              onClick={handleAdd}
              className="block"
              disabled={isCreating}
            />
          </div>
        </Sidebar>
      )}
      <DropdownControl<T>
        control={control}
        name={name}
        label="Infraction"
        options={mappedInfraction}
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your infraction"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
        onKeyDown={allowCreate ? handleFilterInput : () => {}}
      />
    </div>
  );
}

export default FormDropdownInfraction;
