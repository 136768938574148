import "./new-employee-branch.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeBranch } from "@core/query/employee-branch.query";
import {
  EmployeeBranchFormRule,
  EmployeeBranchFormSchema,
} from "@core/form/form.rule";
import { employeeBranchFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeBranch from "../form-employee-branch/form-employee-branch";
import { CreateEmployeeBranchArrayModel } from "@core/model/query-model-employee-branch";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { FormDropdownBranchProvider } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";

export interface NewEmployeeBranchDefaultProps {
  userCode?: string;
  bucketCode?: string;
}
export interface NewEmployeeBranchProps {
  standalone?: boolean;
  defaultFormProps?: NewEmployeeBranchDefaultProps;
}
export function NewEmployeeBranch({
  standalone,
  defaultFormProps,
}: NewEmployeeBranchProps) {
  // LOCAL DECLARATION
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { mapCreateEmployeeBranch } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New EmployeeBranch is created`);
  };
  const { mutate: addEmployeeBranch, isLoading: isCreating } =
    useQyCreateEmployeeBranch(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeBranchFormSchema>({
    defaultValues: employeeBranchFormDefault,
    resolver: zodResolver(EmployeeBranchFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeBranchFormSchema) => {
    const defaultFormData = {
      userCode: defaultFormProps?.userCode,
      bucketCode: defaultFormProps?.bucketCode,
    };

    if (!standalone) {
      defaultFormData.userCode = selectedPersonData?.code;
      defaultFormData.bucketCode = selectedPersonData?.bucket.code;
    }

    const updatedForm = {
      ...form,
      userCode: defaultFormData.userCode,
      bucketCode: defaultFormData.bucketCode,
    } as EmployeeBranchFormSchema;

    const formData = mapCreateEmployeeBranch(updatedForm);
    const formDataArray = {
      branch: [formData],
    } as CreateEmployeeBranchArrayModel;
    addEmployeeBranch(formDataArray);
  };
  const handleValidateError = (err: FieldErrors<EmployeeBranchFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeBranch" className="employee-branch">
      <HeaderContent title="Make Employee Transfer" headingType="h2">
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormDropdownBranchProvider>
          <FormProvider {...formMethod}>
            <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
              <FormEmployeeBranch />
            </section>
          </FormProvider>
        </FormDropdownBranchProvider>
      </div>
    </div>
  );
}

export default NewEmployeeBranch;
