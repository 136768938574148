import { InputText } from "primereact/inputtext";
import styles from "./search-input.module.scss";
import { ChangeEvent, ReactNode, SyntheticEvent, useState } from "react";
import classNames from "classnames";

export interface SearchInputProps {
  searchIcon?: ReactNode;
  clearIcon?: ReactNode;
  searchTerm?: string;
  className?: string;
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
}

export function SearchInput({
  searchIcon,
  clearIcon,
  searchTerm = "",
  className,
  placeholder,
  onSearch,
}: SearchInputProps) {
  const [searchPhrase, setsearchPhrase] = useState(searchTerm);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setsearchPhrase(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(searchPhrase);
    }
  };

  const clearSearchPhrase = (_: SyntheticEvent) => {
    setsearchPhrase("");
    onSearch("");
  };

  return (
    <div className={`w-full`}>
      <span className="p-input-icon-left w-full">
        {!!searchIcon && searchIcon}
        <InputText
          value={searchPhrase}
          placeholder={placeholder}
          onChange={handleSearch}
          onKeyUp={handleKeyPress}
          className={className}
        />
        {searchPhrase && (
          <span
            className={classNames(styles["right-icon"], "cursor-pointer")}
            onClick={clearSearchPhrase}
          >
            {clearIcon}
          </span>
        )}
      </span>
    </div>
  );
}

export default SearchInput;
