import { Outlet } from "react-router-dom";

import { FilterLeaveTypeProvider } from "./filter-leave-type/filter-leave-type.context";
import './leave-type.scss';

export function LeaveType() {
  return (
    <div id="LeaveType" className="leave-type">
       <FilterLeaveTypeProvider>
        <Outlet />
      </FilterLeaveTypeProvider>
    </div>
  );
}

export default LeaveType;
