import { branchFormDefault } from "./form.default";
import { BranchFormSchema } from "./form.rule";

export const useFormDefaultBranch = () => {
  const getDefault = (cachedValue: BranchFormSchema | undefined) => {
    return !cachedValue
      ? branchFormDefault
      : ({
          code: cachedValue.code,
          description: cachedValue.description,
          head: cachedValue.head,
          name: cachedValue.name,
        } as BranchFormSchema);
  };

  return {
    getDefault,
  };
};
