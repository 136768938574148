import "./edit-employee-diciplinary.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormEmployeeDiciplinary from "../form-employee-diciplinary/form-employee-diciplinary";
import { useEditEmployeeDiciplinary } from "./edit-employee-diciplinary.hook";

export function EditEmployeeDiciplinary() {
  const {
    employeeDiciplinaryData,
    isLoading,
    employeeDiciplinaryError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditEmployeeDiciplinary();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormEmployeeDiciplinary />
      </section>
    </div>
  );

  return (
    <div id="EmployeeDiciplinary" className="employee-diciplinary">
      <HeaderContent
        title={"Edit Employee Disciplinary"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {employeeDiciplinaryError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditEmployeeDiciplinary;
