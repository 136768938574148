import './new-favorite.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateFavorite } from "@core/query/favorite.query";
import { FavoriteFormRule, FavoriteFormSchema } from "@core/form/form.rule";
import { favoriteFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormFavorite from "../form-favorite/form-favorite";

export function NewFavorite() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateFavorite } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New Favorite is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addFavorite, isLoading: isCreating } =
    useQyCreateFavorite(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<FavoriteFormSchema>({
    defaultValues: favoriteFormDefault,
    resolver: zodResolver(FavoriteFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: FavoriteFormSchema) => {
    const formData = mapCreateFavorite(form);
    addFavorite(formData);
  };
  const handleValidateError = (err: FieldErrors<FavoriteFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="Favorite" className="favorite">
      <HeaderContent
        title="New Grouping"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormFavorite />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewFavorite;