import { Outlet } from "react-router-dom";

import { FilterDailyTimeRecordProvider } from "./filter-daily-time-record/filter-daily-time-record.context";
import './daily-time-record.scss';

export function DailyTimeRecord() {
  return (
    <div id="DailyTimeRecord" className="daily-time-record">
       <FilterDailyTimeRecordProvider>
        <Outlet />
      </FilterDailyTimeRecordProvider>
    </div>
  );
}

export default DailyTimeRecord;
