import { ReactNode, createContext, useContext } from "react";
import { useFilterInfraction } from "./filter-infraction.hook";

const FilterInfractionContext = createContext(
  {} as ReturnType<typeof useFilterInfraction>
);
export function useFilterInfractionContext() {
  return useContext(FilterInfractionContext);
}

interface FilterInfractionProviderProps {
  children: ReactNode;
}
export function FilterInfractionProvider({
  children,
}: FilterInfractionProviderProps) {
  return (
    <FilterInfractionContext.Provider value={useFilterInfraction()}>
      {children}
    </FilterInfractionContext.Provider>
  );
}