import { Outlet } from "react-router-dom";

import { FilterEmployeeOverageProvider } from "./filter-employee-overage/filter-employee-overage.context";
import './employee-overage.scss';

export function EmployeeOverage() {
  return (
    <div id="EmployeeOverage" className="employee-overage">
       <FilterEmployeeOverageProvider>
        <Outlet />
      </FilterEmployeeOverageProvider>
    </div>
  );
}

export default EmployeeOverage;
