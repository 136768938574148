import { 
    MessageResponseDto, 
    GetLeaveCategoryDto, 
    CreateLeaveCategoryDto, 
    EditLeaveCategoryDto,
    UtilLeaveCategoryControllerGet200Response,
    UtilityLeaveCategoryApiFp,
    DeleteLeaveCategoryDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseLeaveCategoryModel = UtilLeaveCategoryControllerGet200Response;
export type GetLeaveCategoryModel = GetLeaveCategoryDto;
export type MutateResponseLeaveCategoryModel = MessageResponseDto;
export type CreateLeaveCategoryModel = CreateLeaveCategoryDto;
export type UpdateLeaveCategoryModel = EditLeaveCategoryDto;
export type DeleteLeaveCategoryModel = DeleteLeaveCategoryDto;

export const apiLeaveCategoryFp =  UtilityLeaveCategoryApiFp(apiConfig);
export const getLeaveCategoryController = apiLeaveCategoryFp.utilLeaveCategoryControllerGet;
export const createLeaveCategoryController = apiLeaveCategoryFp.utilLeaveCategoryControllerCreate;
export const updateLeaveCategoryController = apiLeaveCategoryFp.utilLeaveCategoryControllerEdit;
export const deleteLeaveCategoryController = apiLeaveCategoryFp.utilLeaveCategoryControllerDelete;

export const QueryKeyLeaveCategory = QueryKey.LeaveCategory;