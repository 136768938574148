import { Outlet } from "react-router-dom";
import "./main-shell.scss";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { useInterceptor } from "@core/services/interceptor.hook";

export function MainShell() {
  const { progress, toastRef } = useNotificationContext();
  useInterceptor();

  return (
    <div id="MainShell" className="main-shell">
      <Toast ref={toastRef} position="bottom-left" />
      {progress?.show && (
        <ProgressBar
          mode="indeterminate"
          style={{
            height: "6px",
            zIndex: 999999,
            position: "fixed",
            width: "100%",
          }}
        ></ProgressBar>
      )}

      <Outlet />
    </div>
  );
}

export default MainShell;
