import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownIncidentTypeContext } from "./form-dropdown-incident-type.context";

export interface FormDropdownIncidentTypeProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownIncidentType<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownIncidentTypeProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const {
    sidebar,
    newIncidentType,
    mappedIncidentType,
    isCreating,
    setSidebar,
    setFilter,
    setNewIncidentType,
    handleFilterInput,
    handleAdd,
  } = useFormDropdownIncidentTypeContext();
  
  return (
    <div className="incident-type">
      {newIncidentType && (
        <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
          <h2>Create new incidentType</h2>
          <p>
            You are creating a new incidentType. Please, fill the fields to create a
            new incidentType and apply to current item creation.
          </p>
          <div className="flex flex-col gap-2 mt-4">
            <InputText
              placeholder="IncidentType Name"
              value={newIncidentType.name}
              onChange={(e: any) =>
                setNewIncidentType({ ...newIncidentType, name: e.target.value })
              }
            />
            <InputText
              placeholder="IncidentType Description"
              value={newIncidentType.description}
              onChange={(e: any) =>
                setNewIncidentType({ ...newIncidentType, description: e.target.value })
              }
            />

            <Button
              label="Create"
              onClick={handleAdd}
              className="block"
              disabled={isCreating}
            />
          </div>
        </Sidebar>
      )}
      <DropdownControl<T>
        control={control}
        name={name}
        label="IncidentType"
        options={ mappedIncidentType }
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your incidentType"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
        onKeyDown={allowCreate ? handleFilterInput : () => {}}
      />
    </div>
  );
}

export default FormDropdownIncidentType;