import { useQyUpdateEmployeeBucket } from "@core/query/employee-bucket.query";
import "./change-bucket.scss";
import { useFormDropdownBranchContext } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { Button } from "primereact/button";
import { GetEmployeeBucketDto } from "@api/api";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";

export interface ChangeBucketProps {
  bucketInfo: GetEmployeeBucketDto;
}

export function ChangeBucket({ bucketInfo }: ChangeBucketProps) {
  const { showError, showSuccess, showWarning } = useNotificationContext();
  const { mappedBranch } = useFormDropdownBranchContext();
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const { mapUpdateEmployeeBucket } = useFormApiMapper();

  // QUERIES
  const handleApiSuccess = () => {
    showSuccess("Employee branch information updated");
  };
  const { mutate: changeInfo } = useQyUpdateEmployeeBucket(handleApiSuccess);

  // LOCAL FUNCTION
  const handleUpdate = () => {
    if (!selectedBranch) {
      showWarning("Please select a branch");
      return;
    }

    const payload = mapUpdateEmployeeBucket(
      {
        branch: selectedBranch,
        department: bucketInfo.department,
        employeeNo: bucketInfo.employee_no,
        employmentType: bucketInfo.employment_type,
        endDate: bucketInfo.end_date,
        jobGrade: bucketInfo.job_grade,
        jobTitle: bucketInfo.job_title,
        offboardReason: bucketInfo.offboard_reason,
        reportingTo: "n/a",
        resignationDate: bucketInfo.resignation_date,
        role: bucketInfo.role,
        signedDate: bucketInfo.signed_date,
        startDate: bucketInfo.start_date,
        userCode: bucketInfo.user_code,
      },
      bucketInfo.code
    );
    changeInfo(payload);
  };

  // LOCAL RENDER
  const branchElement = (
    <div className="flex flex-col">
      <label className="text-xs text-gray-500" htmlFor="start">
        Select Employee Main Branch
      </label>
      <Dropdown
        value={selectedBranch}
        onChange={(e) => setSelectedBranch(e.value)}
        options={mappedBranch}
        optionLabel="label"
        placeholder="Select a branch"
        className="w-full md:w-14rem"
        filter
      />
    </div>
  );
  return (
    <div id="ChangeBucket" className="change-bucket">
      {branchElement}

      <Button label="Update" onClick={handleUpdate} />
    </div>
  );
}

export default ChangeBucket;
