import "./form-employee-payment.scss";
import { useFormContext } from "react-hook-form";
import { EmployeePaymentFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownEmployeeLoan from "@domain/employee-loan/form-dropdown-employee-loan/form-dropdown-employee-loan";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";

type InputFormSchema = EmployeePaymentFormSchema;
export function FormEmployeePayment() {
  const { control } = useFormContext<EmployeePaymentFormSchema>();
  return (
    <div id="EmployeePayment" className="employee-payment">
      <FormDropdownEmployeeLoan<InputFormSchema> name="loan" />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="amount"
        label="Amount"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="remarks"
        label="Remarks"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter remarks"
      />
    </div>
  );
}

export default FormEmployeePayment;
