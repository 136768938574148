import { Outlet } from "react-router-dom";

import { FilterDeviceAuthorizationProvider } from "./filter-device-authorization/filter-device-authorization.context";
import './device-authorization.scss';

export function DeviceAuthorization() {
  return (
    <div id="DeviceAuthorization" className="device-authorization">
       <FilterDeviceAuthorizationProvider>
        <Outlet />
      </FilterDeviceAuthorizationProvider>
    </div>
  );
}

export default DeviceAuthorization;
