import { 
    MessageResponseDto, 
    GetEmployeeDiciplinaryDto, 
    CreateEmployeeDiciplinaryDto, 
    EditEmployeeDiciplinaryDto,
    EmployeeDiciplinaryControllerGet200Response,
    EmployeeDiciplinaryApiFp,
    DeleteEmployeeDiciplinaryDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeDiciplinaryModel = EmployeeDiciplinaryControllerGet200Response;
export type GetEmployeeDiciplinaryModel = GetEmployeeDiciplinaryDto;
export type MutateResponseEmployeeDiciplinaryModel = MessageResponseDto;
export type CreateEmployeeDiciplinaryModel = CreateEmployeeDiciplinaryDto;
export type UpdateEmployeeDiciplinaryModel = EditEmployeeDiciplinaryDto;
export type DeleteEmployeeDiciplinaryModel = DeleteEmployeeDiciplinaryDto;

export const apiEmployeeDiciplinaryFp = EmployeeDiciplinaryApiFp(apiConfig);
export const getEmployeeDiciplinaryController = apiEmployeeDiciplinaryFp.employeeDiciplinaryControllerGet;
export const createEmployeeDiciplinaryController = apiEmployeeDiciplinaryFp.employeeDiciplinaryControllerCreate;
export const updateEmployeeDiciplinaryController = apiEmployeeDiciplinaryFp.employeeDiciplinaryControllerEdit;
export const deleteEmployeeDiciplinaryController = apiEmployeeDiciplinaryFp.employeeDiciplinaryControllerDelete;

export const QueryKeyEmployeeDiciplinary = QueryKey.EmployeeDiciplinary;