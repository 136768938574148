import "./form-employee-salary.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeSalaryFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownSalaryType from "@domain/salary-type/form-dropdown-salary-type/form-dropdown-salary-type";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";

type InputFormSchema = EmployeeSalaryFormSchema;
export function EmployeeSalary() {
  const { control } = useFormContext<EmployeeSalaryFormSchema>();
  return (
    <div id="EmployeeSalary" className="employee-salary">
      <FormDropdownSalaryType<InputFormSchema> name="salaryType" />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="amount"
        label="Amount"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="remarks"
        label="Remarks"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter remarks"
      />
    </div>
  );
}

export default EmployeeSalary;
