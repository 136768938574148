import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeSalary } from "./filter-employee-salary.hook";

const FilterEmployeeSalaryContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeSalary>
);
export function useFilterEmployeeSalaryContext() {
  return useContext(FilterEmployeeSalaryContext);
}

interface FilterEmployeeSalaryProviderProps {
  children: ReactNode;
}
export function FilterEmployeeSalaryProvider({
  children,
}: FilterEmployeeSalaryProviderProps) {
  return (
    <FilterEmployeeSalaryContext.Provider value={useFilterEmployeeSalary()}>
      {children}
    </FilterEmployeeSalaryContext.Provider>
  );
}