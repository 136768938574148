import HeaderContent from "@shared/ui/header-content/header-content";
import "./edit-branch.scss";
import { useEditBranch } from "./edit-branch.hook";
import { TabView, TabPanel } from "primereact/tabview";
import { FaChevronLeft } from "react-icons/fa6";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import FormBranch from "../form-branch/form-branch";
import { FormProvider } from "react-hook-form";
import HeaderButton from "@shared/ui/header-button/header-button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Outlet } from "react-router-dom";

export function EditBranch() {
  const {
    branchData,
    isLoading,
    branchError,
    formMethod,
    branchActions,
    userIsAdmin,
    handleBack,
    handleAction,
  } = useEditBranch();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormBranch />
      </section>
    </div>
  );

  return (
    <div id="EditBranch" className="edit-branch">
      {userIsAdmin() ? (
        <HeaderContent
          title={branchData?.name || "Edit Branch"}
          backIcon={<FaChevronLeft />}
          onBack={() => handleBack()}
        ></HeaderContent>
      ) : (
        <HeaderContent
          title={branchData?.name || "Edit Branch"}
        ></HeaderContent>
      )}

      <section className="p-7">
        <TabView>
          <TabPanel header="Employees">
            <Outlet />
          </TabPanel>
          <TabPanel header="Branch Information">
            <section className="p-7">
              <span className="flex justify-end mb-4">
                <HeaderButton actions={branchActions} onAction={handleAction} />
              </span>
              <FormProvider {...formMethod}>
                <ConfirmDialog />
                {isLoading && displayLoading}
                {branchError && !isLoading && displayError}
                {!isLoading ? mainContent : null}
              </FormProvider>
            </section>
          </TabPanel>
        </TabView>
      </section>
    </div>
  );
}

export default EditBranch;
