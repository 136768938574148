import React from "react";
import { AppRoute } from "./app.route";
import { DateTimeProvider } from "@shared/utility/date-time-service/date-time.context";
import { SETTINGS } from "@core/utility/settings";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationProvider } from "@shared/ui/notification/notification.context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <DateTimeProvider
        dateFormat={SETTINGS.dateFormat}
        dateTimeFormat={SETTINGS.dateTimeFormat}
      >
        <NotificationProvider>
          <AppRoute />
        </NotificationProvider>
      </DateTimeProvider>
    </QueryClientProvider>
  );
}

export default App;
