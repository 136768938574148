import "./list-salary-type.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetSalaryType } from "@core/query/salary-type.query";
import { GetSalaryTypeModel } from "@core/model/query-model-salary-type";
import { TableSort } from "@core/model/table-sort";
import { FilterSalaryType } from "../filter-salary-type/filter-salary-type";
import { useFilterSalaryTypeContext } from "../filter-salary-type/filter-salary-type.context";

export function SalaryType() {
  // THIS IS THE LOCAL DECLARATION
  const navigate = useNavigate();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const [filterPanel, setFilterPanel] = useState(false);
  const { filterChipsElement, filterEntity } = useFilterSalaryTypeContext();

  // THIS IS THE QUERY DECLARATION
  const {
    data: salaryTypeResponse,
    isLoading,
    isError,
    error,
  } = useQyGetSalaryType(searchTerm, rowLimit, pageNumber, sort, filterEntity);

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetSalaryTypeModel) => {
    navigate(`${item.code}`);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleNew = () => {
    navigate(`new`);
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <SearchInput
          clearIcon={<MdClear />}
          searchIcon={<CiSearch />}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          placeholder="Search items"
          className="w-full block"
        />
        <Button
          label="Filter"
          severity="secondary"
          badgeClassName="p-badge-danger"
          onClick={() => setFilterPanel(true)}
          outlined
        />
      </div>

      {filterChipsElement}
      <Sidebar visible={filterPanel} onHide={() => setFilterPanel(false)}>
        <FilterSalaryType />
      </Sidebar>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{salaryTypeResponse?.count}</b>
      </h5>

      <DataTable
        value={salaryTypeResponse?.data}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord(e.value)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column field="name" header="Name" sortable></Column>
        <Column field="description" header="Description" sortable></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={salaryTypeResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="SalaryType" className="salary-type">
      <HeaderContent title="Salary Type" headingType="h2">
        <span className="flex gap-2">
          <Button label="New" onClick={handleNew} />
        </span>
      </HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default SalaryType;
