import { Outlet } from "react-router-dom";

import { FilterRoleProvider } from "./filter-role/filter-role.context";
import './role.scss';

export function Role() {
  return (
    <div id="Role" className="role">
       <FilterRoleProvider>
        <Outlet />
      </FilterRoleProvider>
    </div>
  );
}

export default Role;
