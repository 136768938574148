import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownJobGrade } from "./form-dropdown-job-grade.hook";

const FormDropdownJobGradeContext = createContext(
  {} as ReturnType<typeof useFormDropdownJobGrade>
);
export function useFormDropdownJobGradeContext() {
  return useContext(FormDropdownJobGradeContext);
}

interface FormDropdownJobGradeProviderProps {
  children: ReactNode;
}
export function FormDropdownJobGradeProvider({
  children,
}: FormDropdownJobGradeProviderProps) {
  return (
    <FormDropdownJobGradeContext.Provider value={useFormDropdownJobGrade()}>
      {children}
    </FormDropdownJobGradeContext.Provider>
  );
}
