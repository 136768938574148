import { Outlet } from "react-router-dom";

import { FilterEmployeeAttachmentProvider } from "./filter-employee-attachment/filter-employee-attachment.context";
import "./employee-attachment.scss";
import { FormDropdownAttachmentTypeProvider } from "@domain/attachment-type/form-dropdown-attachment-type/form-dropdown-attachment-type.context";

export function EmployeeAttachment() {
  return (
    <div id="EmployeeAttachment" className="employee-attachment">
      <FormDropdownAttachmentTypeProvider>
        <FilterEmployeeAttachmentProvider>
          <Outlet />
        </FilterEmployeeAttachmentProvider>
      </FormDropdownAttachmentTypeProvider>
    </div>
  );
}

export default EmployeeAttachment;
