import "./edit-person.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";
import { GrScorecard } from "react-icons/gr";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import { useEditPerson } from "./edit-person.hook";
import { Outlet } from "react-router-dom";
import PrintQr from "../print-qr/print-qr";
import StatCard from "@shared/ui/stat-card/stat-card";
import { Avatar } from "primereact/avatar";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useEditPersonMenu } from "./edit-person-menu";
import { Menubar } from "primereact/menubar";
import TextBlock from "@shared/ui/text-block/text-block";
import { Tag } from "primereact/tag";
import { Sidebar } from "primereact/sidebar";
import ChangeBucket from "../change-bucket/change-bucket";
import NewEmployeeBranch from "@domain/employee-branch/new-employee-branch/new-employee-branch";

export function Person() {
  const { formatDate } = useDateTimeContext();
  const {
    selectedPersonData,
    isLoading,
    personError,
    formMethod,
    componentRef,
    qrToken,
    averageScore,
    changeBucketvisible,
    temporaryTransferVisible,
    setTemporaryTransferVisible,
    setChangeBucketVisible,
    handleBack,
    handleAction,
    getValues,
    getActions,
  } = useEditPerson();
  const { menu } = useEditPersonMenu();
  const name = `${selectedPersonData?.first_name || ""} ${
    selectedPersonData?.last_name || ""
  }`;
  const displayName = !!name.trim() ? name : "Edit Person";

  const profilePic = getValues("picture");
  const avatarElement = !!profilePic ? (
    <Avatar image={profilePic} style={{ width: "100px", height: "100px" }} />
  ) : (
    <Avatar
      label={selectedPersonData?.first_name[0] || "-"}
      size="xlarge"
      style={{
        backgroundColor: "#2196F3",
        color: "#ffffff",
        width: "100px",
        height: "100px",
      }}
    />
  );

  const printSection = () => (
    <div style={{ display: "none" }}>
      <div ref={componentRef}>
        <PrintQr
          name={`${selectedPersonData?.first_name} ${selectedPersonData?.last_name}`}
          code={qrToken}
          department={selectedPersonData?.bucket.department_name || ""}
          position={selectedPersonData?.bucket.job_title_name || ""}
        />
      </div>
    </div>
  );
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
        <Menubar model={menu} />
        <Outlet />
      </section>
    </div>
  );
  const profileHeader = (
    <section className="flex justify-center gap-6 mb-10">
      <div className="flex gap-4 items-center">
        {avatarElement}
        <section className="flex flex-col">
          <small className="font-bold">{selectedPersonData?.email}</small>
          {selectedPersonData?.bucket.job_title_name && (
            <TextBlock
              label="Position"
              value={selectedPersonData?.bucket.job_title_name}
              mode="horizontal"
            />
          )}
          {selectedPersonData?.bucket.branch_name && (
            <TextBlock
              label="Branch"
              value={selectedPersonData?.bucket.branch_name}
              mode="horizontal"
            />
          )}
          {selectedPersonData?.bucket.department_name && (
            <TextBlock
              label="Department"
              value={selectedPersonData?.bucket.department_name}
              mode="horizontal"
            />
          )}
          {selectedPersonData?.bucket.start_date && (
            <TextBlock
              label="Hire Date"
              value={formatDate(selectedPersonData?.bucket.start_date)}
              mode="horizontal"
            />
          )}
          {selectedPersonData?.bucket.end_date && (
            <TextBlock
              label="Terminated Date"
              value={formatDate(selectedPersonData?.bucket.end_date)}
              mode="horizontal"
            />
          )}
          {selectedPersonData?.bucket.termination_type_name && (
            <Tag value={selectedPersonData?.bucket.termination_type_name}></Tag>
          )}
        </section>
      </div>
      <div className="flex">
        <StatCard
          value={averageScore?.tally || 0}
          label="Average Score"
          icon={
            <div className="bg-lime-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
        />
      </div>
    </section>
  );
  const changeBucketSection = (
    <Sidebar
      visible={changeBucketvisible}
      onHide={() => setChangeBucketVisible(false)}
    >
      {selectedPersonData?.bucket ? (
        <ChangeBucket bucketInfo={selectedPersonData?.bucket} />
      ) : (
        <small>No Employee information detected</small>
      )}
    </Sidebar>
  );
  const temporaryTransferSection = (
    <Sidebar
      visible={temporaryTransferVisible}
      onHide={() => setTemporaryTransferVisible(false)}
      className="w-full md:w-[700px]"
    >
      {selectedPersonData ? (
        <NewEmployeeBranch
          standalone={true}
          defaultFormProps={{
            userCode: selectedPersonData?.code,
            bucketCode: selectedPersonData?.bucket.code,
          }}
        />
      ) : (
        <small>No Employee information detected</small>
      )}
    </Sidebar>
  );

  return (
    <div id="Person" className="person">
      <HeaderContent
        title={displayName}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={getActions()} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        {changeBucketSection}
        {temporaryTransferSection}
        {profileHeader}
        <ConfirmDialog />
        {printSection()}
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {personError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default Person;
