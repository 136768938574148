import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetPaymentMethodModel } from "@core/model/query-model-payment-method";
import { paymentMethodFormDefault } from "./form.default";
import { PaymentMethodFormSchema } from "./form.rule";

export const useFormDefaultPaymentMethod = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetPaymentMethodModel | undefined) => {
    return !cachedValue
      ? paymentMethodFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as PaymentMethodFormSchema);
  };

  return {
    getDefault,
  };
};
