import { useNotificationContext } from "@shared/ui/notification/notification.context";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function useInterceptor() {
  const { hideProgress } = useNotificationContext();
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      hideProgress();

      if (error.response.status === 401 || error.response.status === 403) {
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );
}
