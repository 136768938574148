import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeFavoriteArrayFormRule,
  EmployeeFavoriteArrayFormSchema,
  EmployeeFavoriteFormRule,
  EmployeeFavoriteFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeFavorite } from "@core/form/form-default-employee-favorite.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyGetEmployeeFavoriteById,
  useQyDeleteEmployeeFavorite,
} from "@core/query/employee-favorite.query";
import { GetResponseEmployeeFavoriteModel } from "@core/model/query-model-employee-favorite";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { employeeFavoriteArrayFormDefault } from "@core/form/form.default";

export function useEditEmployeeFavorite() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeFavorite();
  const navigate = useNavigate();
  const { mapDeleteEmployeeFavorite } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeFavoriteId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeFavorite
  const handleGetApiSuccess = (data: GetResponseEmployeeFavoriteModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("favoriteId", responseData?.favorite_code || "");
    }
  };
  const {
    data: employeeFavoriteResponse,
    isLoading,
    isError: employeeFavoriteError,
  } = useQyGetEmployeeFavoriteById(
    employeeFavoriteId || "",
    handleGetApiSuccess
  );
  const employeeFavoriteData = employeeFavoriteResponse?.data?.[0];

  // API DELETE EmployeeFavorite
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeFavorite deleted successfully");
  };
  const { mutate: deleteEmployeeFavorite } = useQyDeleteEmployeeFavorite(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeFavoriteArrayFormSchema>({
    defaultValues: employeeFavoriteArrayFormDefault,
    resolver: zodResolver(EmployeeFavoriteArrayFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeFavoriteArrayFormSchema) => {
    if (!employeeFavoriteData) {
      throw new Error("No employeeFavorite data");
    }
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeFavoriteArrayFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeFavoriteData) {
      throw new Error("No employeeFavorite data found");
    }

    const form = mapDeleteEmployeeFavorite(employeeFavoriteData.code);
    deleteEmployeeFavorite(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeFavoriteId,
    employeeFavoriteData,
    isLoading,
    employeeFavoriteError,
    formMethod,
    actions,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
