import { EmployeeRequestFormSchema } from "@core/form/form.rule";
import "./form-employee-request-csr.scss";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import { useFormContext } from "react-hook-form";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import FormDropdownShift from "@domain/shift/form-dropdown-shift/form-dropdown-shift";

type InputFormSchema = EmployeeRequestFormSchema;
interface FormEmployeeRequestCsrProps {
  isView?: boolean;
}
export function FormEmployeeRequestCsr({
  isView,
}: FormEmployeeRequestCsrProps) {
  const { control } = useFormContext<EmployeeRequestFormSchema>();
  return (
    <div id="FormEmployeeRequestCsr" className="form-employee-request-csr mt-4">
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
        disabled={isView}
      />
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 bg-white">
        <InputDateControl<InputFormSchema>
          control={control}
          name="effectivityDate"
          label="Shift date to change"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          disabled={isView}
        />
        {/* <InputDateControl<InputFormSchema>
          control={control}
          name="effectivityDateEnd"
          label="Effectivity End Date"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
        /> */}
        <FormDropdownShift<InputFormSchema>
          name="shiftSchedule"
          label="Select New Shift"
          disabled={isView}
        />
        {/* <DropdownControl<InputFormSchema>
          control={control}
          name="shiftDay"
          label="Day"
          options={mappedDays}
          containerClassName="mb-9"
          className="w-full md:w-3/4"
          placeholder="Enter the day"
        /> */}
        <InputTextareaControl<InputFormSchema>
          control={control}
          name="requestReason"
          label="Request Reason"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter overtime reason"
          disabled={isView}
        />

        <CheckboxControl<InputFormSchema>
          control={control}
          name="isPermanent"
          label="Is Permanent"
          containerClassName="pb-2"
          disabled={isView}
        />
      </section>
    </div>
  );
}

export default FormEmployeeRequestCsr;
