import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownRole } from "./form-dropdown-role.hook";

const FormDropdownRoleContext = createContext(
  {} as ReturnType<typeof useFormDropdownRole>
);
export function useFormDropdownRoleContext() {
  return useContext(FormDropdownRoleContext);
}

interface FormDropdownRoleProviderProps {
  children: ReactNode;
}
export function FormDropdownRoleProvider({
  children,
}: FormDropdownRoleProviderProps) {
  return (
    <FormDropdownRoleContext.Provider value={useFormDropdownRole()}>
      {children}
    </FormDropdownRoleContext.Provider>
  );
}
