import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeRequestModel } from "@core/model/query-model-employee-request";
import { employeeRequestFormDefault } from "./form.default";
import { EmployeeRequestFormSchema } from "./form.rule";

export const useFormDefaultEmployeeRequest = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeRequestModel | undefined) => {
    return !cachedValue
      ? employeeRequestFormDefault
      : ({
          userCode: cachedValue.user_code,
          bucketCode: cachedValue.bucket_code,
          requestType: cachedValue.request_type,
          requestReason: cachedValue.request_reason,
          effectivityDate: formatDate(cachedValue.effectivity_date) as any,
          effectivityDateEnd: "",
          leaveType: cachedValue.leave_type,
          leaveCategory: cachedValue.leave_category,
          leaveSubcategory: cachedValue.leave_subcategory,
          amendCurrentClockin: cachedValue.amend_current_clockin,
          amendCurrentClockout: cachedValue.amend_current_clockout,
          amendClockin: cachedValue.amend_clockin,
          amendClockout: cachedValue.amend_clockout,
          shiftCurrentSchedule: cachedValue.shift_current_schedule,
          shiftSchedule: cachedValue.shift_schedule,
          isPermanent: Boolean(cachedValue.is_permanent),
          shiftDay: cachedValue.shift_day,
          endDate: formatDate(cachedValue.end_date) as any,
          otTime: cachedValue.ot_time,
          status: cachedValue.status,
          workDate: "",
          dtrCode: "",
        } as EmployeeRequestFormSchema);
  };

  return {
    getDefault,
  };
};
