import { ReactNode, createContext, useContext } from "react";
import { useUserIdentity } from "./user-identity.hook";

const UserIdentityContext = createContext(
  {} as ReturnType<typeof useUserIdentity>
);
export function useUserIdentityContext() {
  return useContext(UserIdentityContext);
}

interface UserIdentityProviderProps {
  children: ReactNode;
}
export function UserIdentityProvider({ children }: UserIdentityProviderProps) {
  return (
    <UserIdentityContext.Provider value={useUserIdentity()}>
      {children}
    </UserIdentityContext.Provider>
  );
}
