import { useState } from "react";
import { useQyCreateShift, useQyGetShift } from "@core/query/shift.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export const useFormDropdownShift = () => {
  const { showSuccess } = useNotificationContext();
  const { formatTime, getTime } = useDateTimeContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    setIsCreating(true);
  };

  const handleAddApiSuccess = () => {
    showSuccess("New Shift is added. Check and select the Shift in the form.");
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addShift } = useQyCreateShift(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: shiftResponse } = useQyGetShift("");
  const mappedShift = (shiftResponse?.data || []).map((item) => {
    const preText = item.title
      ? `${item.title}-[${item.shift_type_name}]`
      : `[${item.shift_type_name}]`;
    return {
      label: `${preText} ${formatTime(getTime(item.start))} - ${formatTime(
        getTime(item.end)
      )}`,
      value: item.code,
    } as LabelValue;
  });

  return {
    sidebar,
    filter,
    mappedShift,
    isCreating,
    shiftResponse,
    setSidebar,
    setFilter,
    handleFilterInput,
    handleAdd,
    addShift,
  };
};
