import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { InfractionFormRule, InfractionFormSchema } from "@core/form/form.rule";
import { useFormDefaultInfraction } from "@core/form/form-default-infraction.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateInfraction, 
  useQyGetInfractionById, 
  useQyDeleteInfraction 
} from "@core/query/infraction.query";
import { GetResponseInfractionModel } from "@core/model/query-model-infraction";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditInfraction() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultInfraction();
  const navigate = useNavigate();
  const { mapUpdateInfraction, mapDeleteInfraction } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { infractionId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Infraction
  const handleGetApiSuccess = (data: GetResponseInfractionModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: infractionResponse,
    isLoading,
    isError: infractionError,
  } = useQyGetInfractionById(infractionId || "", handleGetApiSuccess);
  const infractionData = infractionResponse?.data?.[0];

  // API UPDATE Infraction
  const handleUpdateApiSuccess = () => {
    showSuccess("Infraction updated successfully");
  };
  const { mutate: updateInfraction, isLoading: isEditLoading } = useQyUpdateInfraction(
    handleUpdateApiSuccess
  );

  // API DELETE Infraction
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Infraction deleted successfully");
  };
  const { mutate: deleteInfraction } = useQyDeleteInfraction(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<InfractionFormSchema>({
    defaultValues: getDefault(infractionData),
    resolver: zodResolver(InfractionFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: InfractionFormSchema) => {
    if (!infractionData) {
      throw new Error("No infraction data");
    }

    const formData = mapUpdateInfraction(form, infractionData.code);
    updateInfraction(formData);
  };
  const handleValidateError = (err: FieldErrors<InfractionFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!infractionData) {
      throw new Error("No infraction data found");
    }

    const form = mapDeleteInfraction(infractionData.code);
    deleteInfraction(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    infractionId,
    infractionData,
    isLoading,
    infractionError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}