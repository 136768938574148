import { ReactNode, createContext, useContext } from "react";
import { useSession } from "./session.hook";

const SessionContext = createContext({} as ReturnType<typeof useSession>);
export function useSessionContext() {
  return useContext(SessionContext);
}

interface SessionProviderProps {
  children: ReactNode;
}
export function SessionProvider({ children }: SessionProviderProps) {
  return (
    <SessionContext.Provider value={useSession()}>
      {children}
    </SessionContext.Provider>
  );
}
