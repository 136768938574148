import {
  MessageResponseDto,
  GetViolationDto,
  CreateViolationDto,
  EditViolationDto,
  UtilViolationControllerGet200Response,
  UtilityViolationApiFp,
  DeleteViolationDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseViolationModel = UtilViolationControllerGet200Response;
export type GetViolationModel = GetViolationDto;
export type MutateResponseViolationModel = MessageResponseDto;
export type CreateViolationModel = CreateViolationDto;
export type UpdateViolationModel = EditViolationDto;
export type DeleteViolationModel = DeleteViolationDto;

export const apiViolationFp = UtilityViolationApiFp(apiConfig);
export const getViolationController = apiViolationFp.utilViolationControllerGet;
export const createViolationController =
  apiViolationFp.utilViolationControllerCreate;
export const updateViolationController =
  apiViolationFp.utilViolationControllerEdit;
export const deleteViolationController =
  apiViolationFp.utilViolationControllerDelete;

export const QueryKeyViolation = QueryKey.Violation;
