import "./edit-shift.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormShift from "../form-shift/form-shift";
import { useEditShift } from "./edit-shift.hook";
import { useUserIdentityContext } from "@core/services/user-identity.context";

export function Shift() {
  const { userIsOwner } = useUserIdentityContext();
  const {
    shiftData,
    isLoading,
    shiftError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditShift();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormShift />
      </section>
    </div>
  );

  return (
    <div id="Shift" className="shift">
      <HeaderContent
        title={"Edit Shift"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        {userIsOwner() ? (
          <HeaderButton actions={actions} onAction={handleAction} />
        ) : null}
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {shiftError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default Shift;
