import {
  MessageResponseDto,
  GetPersonDto,
  PersonControllerGet200Response,
  PersonApiFp,
  DeletePersonDto,
  CreatePersonExtendedDto,
  EditPersonExtendedDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponsePersonModel = PersonControllerGet200Response;
export type GetPersonModel = GetPersonDto;
export type MutateResponsePersonModel = MessageResponseDto;
export type CreatePersonModel = CreatePersonExtendedDto &
  Partial<{ file: any }>;
export type UpdatePersonModel = EditPersonExtendedDto & Partial<{ file: any }>;
export type DeletePersonModel = DeletePersonDto;
export interface UploadPersonModel {
  code: string;
  attachment_type: UploadPersonModelAttachmentType;
  file: any;
}
export enum UploadPersonModelAttachmentType {
  Curriculum = "curriculum_vitae",
  Supporting = "supporting_doc",
  Intent = "intent_letter",
  Application = "application_letter",
}

export const apiPersonFp = PersonApiFp(apiConfig);
export const getPersonController = apiPersonFp.personControllerGet;
export const createPersonController = apiPersonFp.personControllerCreate;
export const updatePersonController = apiPersonFp.personControllerEdit;
export const deletePersonController = apiPersonFp.personControllerDelete;
export const uploadFilePersonController =
  apiPersonFp.personControllerAttachFile;

export const QueryKeyPerson = QueryKey.Person;
