import {
  GetBranchDailyDemographicsDto,
  GetBranchDailyDemographicsV2Dto,
  ReportApiFp,
  ReportControllerBranchDailyDemographics200Response,
  ReportControllerBranchDailyDemographicsV2200Response,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetBranchDailyResponseModel =
  ReportControllerBranchDailyDemographics200Response;
export type GetBranchDailyResponseModelV2 =
  ReportControllerBranchDailyDemographicsV2200Response;

export type GetBranchDailyModel = GetBranchDailyDemographicsDto;
export type GetBranchDailyModelV2 = GetBranchDailyDemographicsV2Dto;

export const apiReportFp = ReportApiFp(apiConfig);
export const getBranchDaily =
  apiReportFp.reportControllerBranchDailyDemographics;
export const getBranchDailyV2 =
  apiReportFp.reportControllerBranchDailyDemographicsV2;

export const QueryKeyReportBranchDaily = QueryKey.ReportBranchDaily;
