import { useCallback, useState } from "react";
import { CreateStatusModel } from "@core/model/query-model-status";
import { useQyCreateStatus, useQyGetStatus } from "@core/query/status.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { DesignatedStatus } from "./designated-status.enum";

export const useFormDropdownStatus = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newStatus, setNewStatus] = useState<CreateStatusModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewStatus({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newStatus.name) {
      showWarning("Please fill in Status details");
      return;
    }
    setIsCreating(true);
    addStatus(newStatus);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New Status is added. Check and select the Status in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addStatus } = useQyCreateStatus(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: statusResponse } = useQyGetStatus("");
  const mappedStatus = (statusResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );
  const getPending = useCallback(() => {
    const pending =
      mappedStatus.filter((item) =>
        item.label.toLowerCase().indexOf(DesignatedStatus.Pending)
      )[0] || null;

    console.log({ mappedStatus, pending });
    return pending;
  }, [mappedStatus]);

  return {
    sidebar,
    filter,
    newStatus,
    mappedStatus,
    isCreating,
    statusResponse,
    setSidebar,
    setFilter,
    setNewStatus,
    handleFilterInput,
    handleAdd,
    addStatus,
    getPending,
  };
};
