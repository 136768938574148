import { 
    MessageResponseDto, 
    CreateHolidayTypeDto, 
    EditHolidayTypeDto,
    UtilHolidayTypeControllerGet200Response,
    UtilityHolidayTypeApiFp,
    DeleteHolidayTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseHolidayTypeModel = UtilHolidayTypeControllerGet200Response;
export type GetHolidayTypeModel = EditHolidayTypeDto;
export type MutateResponseHolidayTypeModel = MessageResponseDto;
export type CreateHolidayTypeModel = CreateHolidayTypeDto;
export type UpdateHolidayTypeModel = EditHolidayTypeDto;
export type DeleteHolidayTypeModel = DeleteHolidayTypeDto;

export const apiHolidayTypeFp =  UtilityHolidayTypeApiFp(apiConfig);
export const getHolidayTypeController = apiHolidayTypeFp.utilHolidayTypeControllerGet;
export const createHolidayTypeController = apiHolidayTypeFp.utilHolidayTypeControllerCreate;
export const updateHolidayTypeController = apiHolidayTypeFp.utilHolidayTypeControllerEdit;
export const deleteHolidayTypeController = apiHolidayTypeFp.utilHolidayTypeControllerDelete;

export const QueryKeyHolidayType = QueryKey.HolidayType;