import "./new-employee-ledger.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeLedger } from "@core/query/employee-ledger.query";
import {
  EmployeeLedgerFormRule,
  EmployeeLedgerFormSchema,
} from "@core/form/form.rule";
import { employeeLedgerFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeLedger from "../form-employee-ledger/form-employee-ledger";
import { CreateEmployeeLedgerListModel } from "@core/model/query-model-employee-ledger";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";

export function EmployeeLedger() {
  // LOCAL DECLARATION
  const { selectedPersonData } = useFormDropdownPersonContext();
  const navigate = useNavigate();
  const { mapCreateEmployeeLedger } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = () => {
    showSuccess(`New Employee Ledger is created`);
    navigate(`../`);
  };
  const { mutate: addEmployeeLedger, isLoading: isCreating } =
    useQyCreateEmployeeLedger(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeLedgerFormSchema>({
    defaultValues: employeeLedgerFormDefault,
    resolver: zodResolver(EmployeeLedgerFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeLedgerFormSchema) => {
    const updatedForm = {
      ...form,
      userId: selectedPersonData?.code,
      bucketId: selectedPersonData?.bucket.code,
    } as EmployeeLedgerFormSchema;
    const ledgerModel = mapCreateEmployeeLedger(updatedForm);
    
    const formData = {
      ledger: [ledgerModel],
    } as CreateEmployeeLedgerListModel;
    addEmployeeLedger(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeLedgerFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeLedger" className="employee-ledger">
      <HeaderContent
        title="New Employee Ledger"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeeLedger />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default EmployeeLedger;
