import { Outlet, useNavigate } from "react-router-dom";
import "./auth-shell.scss";
import Sidebar from "@shared/ui/navigation/sidebar/sidebar";
import SidebarItem from "@shared/ui/navigation/sidebar/sidebar-item/sidebar-item";
import { AiOutlineLogout } from "react-icons/ai";
import { CiCircleChevRight } from "react-icons/ci";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { useShellMenu } from "../shell-menu.hook";
import { useQyGetPerson } from "@core/query/person.query";
import StorageService from "@shared/services/storage.service";
import { AUTH, PROFILE } from "@core/utility/settings";
import { LocalAuth } from "@core/model/local-auth";
import { ApiToFormService } from "@core/services/api-to-form.service";
import { LocalProfile } from "@core/model/local-profile";
import HamburgerMenu from "../hamburger-menu/hamburger-menu";
import config from "../../../../config.json";

const getLocalProfileFromStorage = () => {
  const profile = StorageService.load<LocalProfile>(PROFILE);
  return profile || null;
};

export function AuthShell() {
  const { getDesktopNavigation } = useShellMenu();
  const navigate = useNavigate();
  const profile = getLocalProfileFromStorage();

  const navigation = getDesktopNavigation();

  // PERSON API
  const handlePersonSuccess = (response: any) => {
    const mappedData = ApiToFormService.MapProfile(response.data[0]);
    StorageService.save<LocalProfile>(PROFILE, mappedData);
  };
  const auth = StorageService.load<LocalAuth>(AUTH);
  const userCode = auth.userCode;
  useQyGetPerson(
    "",
    1,
    0,
    undefined,
    {
      code: userCode || "",
    },
    undefined,
    undefined,
    undefined,
    !profile,
    handlePersonSuccess,
    undefined,
    true
  );

  return (
    <div id="AuthShell" className="auth-shell">
      <HamburgerMenu
        logoutElement={<AiOutlineLogout className="h-5 w-5 m-3" />}
        menu={navigation}
        username={profile?.name || "-"}
        userRole={profile?.roleName || "-"}
        userDepartment={profile?.departmentName || "-"}
        onLogout={() => navigate("/login")}
      />

      <section className="flex">
        <Sidebar
          title={config.appName}
          logo="/icon-192x192.png"
          logoWidth="w-12"
          username={profile?.name || "-"}
          userRole={profile?.roleName || "-"}
          userDepartment={profile?.departmentName || "-"}
          minifiedWidthClass="w-[80px]"
          onLogout={() => navigate("/login")}
          navClass="bg-gray-100"
          logoutElement={<AiOutlineLogout className="h-5 w-5 m-3" />}
          userInfoElement={<CiCircleChevRight className="h-7 w-7" />}
          headerLeftElement={<FaChevronLeft />}
          headerRightElement={<FaChevronRight />}
        >
          {navigation.map((item, id) => (
            <SidebarItem key={id} {...item} onAction={() => {}} />
          ))}
        </Sidebar>

        <div className="h-screen flex-1 px-4">
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default AuthShell;
