import "./new-employee-attachment.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeAttachment } from "@core/query/employee-attachment.query";
import {
  EmployeeAttachmentFormRule,
  EmployeeAttachmentFormSchema,
} from "@core/form/form.rule";
import { employeeAttachmentFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeAttachment from "../form-employee-attachment/form-employee-attachment";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { AttachmentSource } from "@core/model/query-model-employee-attachment";

export interface NewEmployeeAttachmentProps {
  defaultSource?: AttachmentSource;
}
export function NewEmployeeAttachment({
  defaultSource = AttachmentSource.Employee,
}: NewEmployeeAttachmentProps) {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateEmployeeAttachment } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { selectedPersonData } = useFormDropdownPersonContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New EmployeeAttachment is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addEmployeeAttachment, isLoading: isCreating } =
    useQyCreateEmployeeAttachment(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeAttachmentFormSchema>({
    defaultValues: employeeAttachmentFormDefault,
    resolver: zodResolver(EmployeeAttachmentFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeAttachmentFormSchema) => {
    const mutatedForm = {
      ...form,
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
      isActive: true,
      source: defaultSource
    } as EmployeeAttachmentFormSchema;
    
    const formData = mapCreateEmployeeAttachment(mutatedForm);
    addEmployeeAttachment(formData);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeAttachmentFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeAttachment" className="employee-attachment">
      <HeaderContent
        title="New Employee Attachment"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeeAttachment />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeAttachment;
