import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownBranch } from "./form-dropdown-branch.hook";

const FormDropdownBranchContext = createContext(
  {} as ReturnType<typeof useFormDropdownBranch>
);
export function useFormDropdownBranchContext() {
  return useContext(FormDropdownBranchContext);
}

interface FormDropdownBranchProviderProps {
  children: ReactNode;
}
export function FormDropdownBranchProvider({
  children,
}: FormDropdownBranchProviderProps) {
  return (
    <FormDropdownBranchContext.Provider value={useFormDropdownBranch()}>
      {children}
    </FormDropdownBranchContext.Provider>
  );
}
