import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import {
  CreateHolidayModel,
  DeleteHolidayModel,
  GetResponseHolidayModel,
  MutateResponseHolidayModel,
  QueryKeyHoliday,
  UpdateHolidayModel,
  createHolidayController,
  deleteHolidayController,
  getHolidayController,
  updateHolidayController,
} from "@core/model/query-model-holiday";
import { SETTINGS } from "@core/utility/settings";
import { useSessionContext } from "./session.context";

export function useQyGetHoliday(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  dateFilter?: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponseHolidayModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    dateFilter: string | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    await checkSession();

    showProgress();
    const operation = await getHolidayController(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      dateFilter,
      startDate,
      endDate,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyHoliday]);
    return response["data"] as GetResponseHolidayModel;
  };

  return useQuery({
    staleTime: SETTINGS.staleTime,
    enabled,
    queryKey: [
      QueryKeyHoliday,
      search,
      limit,
      offset,
      order,
      filter,
      dateFilter,
      startDate,
      endDate,
    ],
    queryFn: () =>
      apiFn(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate
      ),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetHolidayById(
  id: string,
  onSuccess?:
    | ((data: GetResponseHolidayModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (id: string, search = "", limit = 1, offset = 0) => {
    await checkSession();

    showProgress();
    const operation = await getHolidayController(
      search,
      limit,
      offset,
      undefined,
      { filter: JSON.stringify({ code: id }) },
      undefined,
      undefined,
      undefined,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyHoliday, id]);
    return response["data"] as GetResponseHolidayModel;
  };

  return useQuery({
    queryKey: [QueryKeyHoliday, id],
    queryFn: () => apiFn(id),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyCreateHoliday(
  onSuccess?:
    | ((data: MutateResponseHolidayModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreateHolidayModel) => {
    await checkSession();

    showProgress();
    const operation = await createHolidayController(payload, authHeaders());
    const response = (await operation()).data;
    return response as MutateResponseHolidayModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyHoliday);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdateHoliday(
  onSuccess?:
    | ((data: MutateResponseHolidayModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdateHolidayModel) => {
    await checkSession();

    showProgress();
    const operation = await updateHolidayController(payload, authHeaders());
    const response = (await operation()).data;
    return response["message"] as MutateResponseHolidayModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyHoliday);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyDeleteHoliday(
  onSuccess?:
    | ((data: MutateResponseHolidayModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DeleteHolidayModel) => {
    await checkSession();

    showProgress();
    const operation = await deleteHolidayController(payload, authHeaders());
    const response = (await operation()).data;
    return response["message"] as MutateResponseHolidayModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyHoliday);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
