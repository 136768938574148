import "./edit-employee-request.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import { useEditEmployeeRequest } from "./edit-employee-request.hook";
import { DesignatedRequestType } from "@domain/request-type/form-dropdown-request-type/designated-request-type.enum";
import FormEmployeeRequestOvertime from "../form-employee-request-overtime/form-employee-request-overtime";
import FormEmployeeRequestLeave from "../form-employee-request-leave/form-employee-request-leave";
import FormEmployeeRequestDtr from "../form-employee-request-dtr/form-employee-request-dtr";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import FormEmployeeRequestCsr from "../form-employee-request-csr/form-employee-request-csr";
import FormEmployeeRequestBreak from "../form-employee-request-break/form-employee-request-break";
import { Divider } from "primereact/divider";
import { displayTag } from "@shared/utility/table-helper/display-column";
import { SeverityTag } from "@shared/models/severity-tag.enum";
import { getSeverity } from "@core/utility/get-severity";
import TextBlock from "@shared/ui/text-block/text-block";
import { useCallback } from "react";

export function EmployeeRequest() {
  const {
    isLoading,
    employeeRequestError,
    formMethod,
    actions,
    selectedRequestTypeName,
    showDecision,
    mappedStatus,
    selectedStatus,
    decisionReason,
    employeeRequestData,
    setDecisionReason,
    handleSubmitDecision,
    setSelectedStatus,
    setShowDecision,
    handleBack,
    handleAction,
    userIsAdmin,
    formatDateTimeV2,
  } = useEditEmployeeRequest();

  // LOCAL RENDER
  const displayForm = () => {
    switch (selectedRequestTypeName) {
      case DesignatedRequestType.Overtime:
        return <FormEmployeeRequestOvertime isView={true} />;
      case DesignatedRequestType.Leave:
        return <FormEmployeeRequestLeave isView={true} />;
      case DesignatedRequestType.DtrAmendment:
        return <FormEmployeeRequestDtr isView={true} />;
      case DesignatedRequestType.ChangeShift:
        return <FormEmployeeRequestCsr isView={true} />;
      case DesignatedRequestType.BreakAmendment:
        return <FormEmployeeRequestBreak isView={true} />;

      default:
        return null;
    }
  };
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const approvedByData = (
    <section>
      <TextBlock
        label="Approved By"
        value={`${employeeRequestData?.approved_first_name} ${employeeRequestData?.approved_last_name}`}
      ></TextBlock>
      <TextBlock
        label="Approved At"
        value={formatDateTimeV2(employeeRequestData?.approved_at)}
      ></TextBlock>
    </section>
  );
  const rejectedByData = (
    <section>
      <TextBlock
        label="Rejected by"
        value={`${employeeRequestData?.rejected_first_name} ${employeeRequestData?.rejected_last_name}`}
      ></TextBlock>
      <TextBlock
        label="Rejected At"
        value={formatDateTimeV2(employeeRequestData?.rejected_at)}
      ></TextBlock>
    </section>
  );
  const cancelledByData = (
    <section>
      <TextBlock
        label="Cancelled by"
        value={`${employeeRequestData?.cancelled_first_name} ${employeeRequestData?.cancelled_last_name}`}
      ></TextBlock>
      <TextBlock
        label="Cancelled At"
        value={formatDateTimeV2(employeeRequestData?.cancelled_at)}
      ></TextBlock>
    </section>
  );
  const mainContent = useCallback(() => {
    let displayProcessor = null;
    switch (employeeRequestData?.status_name) {
      case "APPROVED":
        displayProcessor = approvedByData;
        break;
      case "REJECTED":
        displayProcessor = rejectedByData;
        break;
      case "CANCELLED":
        displayProcessor = cancelledByData;
        break;
    }

    return (
      <div>
        {displayForm()}
        <Divider />
        <h4>Status</h4>
        {displayTag(
          employeeRequestData?.status_name || "PENDING",
          getSeverity(employeeRequestData?.status_name || "PENDING")
        )}

        <TextBlock
          label="Created At"
          value={formatDateTimeV2(employeeRequestData?.created_at)}
        ></TextBlock>
        <TextBlock
          label="Created By"
          value={`${employeeRequestData?.created_first_name} ${employeeRequestData?.created_last_name}`}
        ></TextBlock>
        <TextBlock
          label="Created For"
          value={`${(employeeRequestData as any)?.person_first_name || ""} ${
            (employeeRequestData as any)?.person_last_name || ""
          }`}
        ></TextBlock>
        <TextBlock
          label="Issued Branch"
          value={employeeRequestData?.branch_name || "-"}
        ></TextBlock>

        {displayProcessor}
      </div>
    );
  }, [employeeRequestData]);

  return (
    <div id="EmployeeRequest" className="employee-request">
      <HeaderContent
        title={"View Employee Request"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        {userIsAdmin() ? (
          <HeaderButton actions={actions} onAction={handleAction} />
        ) : null}
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <Sidebar visible={showDecision} onHide={() => setShowDecision(false)}>
          <h2 className="mb-6">Decision</h2>

          <section className="flex flex-col gap-2">
            <Dropdown
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.value)}
              options={mappedStatus}
              optionLabel="label"
              placeholder="Select decision status"
              className="w-full md:w-14rem"
            />
            <span>
              <label className="text-xs text-gray-500">Decision</label>
              <InputTextarea
                value={decisionReason}
                onChange={(e) => setDecisionReason(e.target.value)}
                rows={5}
                cols={30}
              />
            </span>
          </section>
          <Button label="Submit" onClick={(e) => handleSubmitDecision()} />
        </Sidebar>
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {employeeRequestError && !isLoading && displayError}
          {!isLoading ? mainContent() : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EmployeeRequest;
