import { GetPayrollDto, PayrollBreakDto } from "@api/api";
import { GetPayrollModel } from "@core/model/query-model-payroll";
import DotLabel from "@shared/ui/dot-label/dot-label";
import TextBlock from "@shared/ui/text-block/text-block";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { currencyFormat } from "@shared/utility/formats/currency-format";
import { numberFormat } from "@shared/utility/formats/number-format";
import { Tag } from "primereact/tag";
import { CellSummation } from "./cell-summation";
import { useFormDropdownPayrollContext } from "./form-dropdown-payroll.context";
import { useCalendarEvent } from "@domain/event-page/calendar-helper.hook";
import { TableExportField } from "@core/model/table-export-field.interface";
import { useCallback } from "react";

export function useListPayrollTable(payrollData: GetPayrollDto[]) {
  const { calendarGetShiftStyle } = useCalendarEvent();
  const {
    payrollExport,
    handleDeduction,
    setSelectedPayslip,
    handlePrintPayslip,
  } = useFormDropdownPayrollContext();
  const { formatDate, formatDateTime, convertToPhDate, formatTime } =
    useDateTimeContext();

  const handlePayslip = (slip: TableExportField) => {
    const forPayslip = payrollExport.find((x) => x.userId === slip.userId);
    if (!forPayslip) {
      return;
    }

    setSelectedPayslip(forPayslip);
    setTimeout(() => {
      handlePrintPayslip();
    }, 200);
  };
  const shiftColumn = (cell: GetPayrollModel) => (
    <section className="flex-col">
      <h5>{cell.util_shift_day}</h5>
      <span className="font-bold text-base mb-2 block">
        {formatDate(cell.work_date)}
      </span>
      {cell.util_shift_type_description && (
        <DotLabel
          label={cell.util_shift_type_description}
          classBg={calendarGetShiftStyle(cell.util_shift_type_name).classColor}
          classLabel="text-gray-500"
        />
      )}
      <p>{cell.util_shift_schedule}</p>
      <small>{(cell as any)?.util_shift_title}</small>
      {Boolean(cell.is_absent) && <Tag severity="danger" value="Absent"></Tag>}
      {Boolean(cell.is_onleave) && <Tag severity="info" value="On Leave"></Tag>}
    </section>
  );
  const clockColumn = (clockIn: string, status: string) => (
    <section className="flex flex-col">
      {formatDateTime(clockIn)}
      {!!status ? (
        <div>
          <Tag severity="info" value={status}></Tag>
        </div>
      ) : (
        "-"
      )}
    </section>
  );
  const breakColumn = (breaks: PayrollBreakDto[]) => (
    <section className="flex flex-col">
      {(breaks || []).length > 0 ? <h5>Breaks</h5> : <span>No Breaks</span>}
      {(breaks || []).map((breakItem, id) => {
        const startBreakTime = convertToPhDate(breakItem.break_start);
        const endBreakTime = convertToPhDate(breakItem.break_end);
        return (
          <div key={id}>
            <div className="flex gap-2">
              <label>Start</label>
              <span className="font-bold">{formatTime(startBreakTime)}</span>
            </div>
            <div className="flex gap-2">
              <label>End</label>
              <span className="font-bold">{formatTime(endBreakTime)}</span>
            </div>
          </div>
        );
      })}
    </section>
  );
  const headerTemplate = useCallback(
    (cell: GetPayrollModel) => {
      return (
        <CellSummation
          cell={cell}
          onDeduction={handleDeduction}
          onPayslip={handlePayslip}
        />
      );
    },
    [payrollExport]
  );
  const footerTemplate = (data: GetPayrollModel) => {
    return (
      <>
        <td colSpan={7}>
          <div className="flex justify-content-end font-bold w-full">
            Total Records: {numberFormat(calculate(data.user_code).count)}
          </div>
        </td>
      </>
    );
  };
  const calculate = (userCode: string) => {
    let count = 0;

    if (payrollData) {
      for (let item of payrollData) {
        if (item.user_code === userCode) {
          count++;
        }
      }
    }

    return {
      count,
    };
  };
  const deductionColumn = (cell: GetPayrollModel) => (
    <section>
      <span className="text-gray-400 font-bold text-sm">Deductions</span>
      <TextBlock
        label="Break"
        value={currencyFormat(cell.deduction_break, "PHP")} //cell.deduction_incident
        mode="horizontal"
      />
      <TextBlock
        label="Overage"
        value={currencyFormat(cell.deduction_overage, "PHP")} //cell.deduction_incident
        mode="horizontal"
      />
      <TextBlock
        label="Late"
        value={currencyFormat(cell.deduction_late, "PHP")}
        mode="horizontal"
      />
      <TextBlock
        label="Penalty"
        value={currencyFormat(cell.deduction_penalty, "PHP")}
        mode="horizontal"
      />
      <TextBlock
        label="Undertime"
        value={currencyFormat(cell.deduction_undertime, "PHP")}
        mode="horizontal"
      />
    </section>
  );
  const govColumn = (cell: GetPayrollModel) => (
    <section>
      <small className="block">
        Philhealth:<b>{cell.philhealth}</b>
      </small>
      <small className="block">
        SSS:<b>{cell.sss}</b>
      </small>
      <small className="block">
        TIN:<b>{cell.tin}</b>
      </small>
    </section>
  );
  const timeColumn = (cell: GetPayrollModel) => (
    <section>
      <span className="text-gray-400 font-bold text-sm">Time</span>
      <TextBlock
        label="Break"
        value={cell.break_hours?.toString() || "-"}
        mode="horizontal"
      />
      <TextBlock
        label="Undertime"
        value={cell.undertime?.toString() || "-"}
        mode="horizontal"
      />
      <TextBlock
        label="Late"
        value={cell.late?.toString() || "-"}
        mode="horizontal"
      />
      <TextBlock
        label="Excess Mins"
        value={cell.excess_hours?.toString() || "-"}
        mode="horizontal"
      />
      <TextBlock
        label="Total Mins"
        value={cell.total_hours?.toString() || "-"}
        mode="horizontal"
      />
    </section>
  );

  return {
    headerTemplate,
    footerTemplate,
    shiftColumn,
    clockColumn,
    deductionColumn,
    govColumn,
    timeColumn,
    breakColumn,
  };
}
