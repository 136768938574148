import "./new-employee-overage.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeOverage } from "@core/query/employee-overage.query";
import {
  EmployeeAttachmentFormSchema,
  EmployeeOverageFormRule,
  EmployeeOverageFormSchema,
} from "@core/form/form.rule";
import { employeeOverageFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeOverage from "../form-employee-overage/form-employee-overage";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useQyCreateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { AttachmentSource } from "@core/model/query-model-employee-attachment";

export function NewEmployeeOverage() {
  // LOCAL DECLARATION
  const { selectedPersonData } = useFormDropdownPersonContext();
  const navigate = useNavigate();
  const { mapCreateEmployeeOverage, mapCreateEmployeeAttachment } =
    useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  // API ATTACHMENT
  const { mutate: addEmployeeAttachment } = useQyCreateEmployeeAttachment();

  // API GET
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    if (typeof fileInput === "object") {
      const mutatedForm = {
        userCode: selectedPersonData?.code,
        bucketCode: selectedPersonData?.bucket.code,
        isActive: true,
        source: AttachmentSource.Overage,
        file: fileInput,
        requestCode: data.code,
      } as EmployeeAttachmentFormSchema;

      const formData = mapCreateEmployeeAttachment(mutatedForm);
      addEmployeeAttachment(formData);
    }

    showSuccess(`New EmployeeOverage is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addEmployeeOverage, isLoading: isCreating } =
    useQyCreateEmployeeOverage(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeOverageFormSchema>({
    defaultValues: employeeOverageFormDefault,
    resolver: zodResolver(EmployeeOverageFormRule),
  });
  const { handleSubmit, watch } = formMethod;
  const fileInput = watch("file");

  const handleValidate = (form: EmployeeOverageFormSchema) => {
    const mutatedForm = {
      ...form,
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
    } as EmployeeOverageFormSchema;
    const formData = mapCreateEmployeeOverage(mutatedForm);
    addEmployeeOverage(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeOverageFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeOverage" className="employee-overage">
      <HeaderContent
        title="New Employee Overage"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeeOverage />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeOverage;
