import "./edit-employee-shift.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormEmployeeShift from "../form-employee-shift/form-employee-shift";
import { useEditEmployeeShift } from "./edit-employee-shift.hook";

export function EmployeeShift() {
  const {
    employeeShiftData,
    isLoading,
    employeeShiftError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditEmployeeShift();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormEmployeeShift />
      </section>
    </div>
  );

  return (
    <div id="EmployeeShift" className="employee-shift">
      <HeaderContent
        title={"Edit EmployeeShift"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {employeeShiftError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EmployeeShift;
