import { ReactNode, createContext, useContext } from "react";
import { useFilterJobTitle } from "./filter-job-title.hook";

const FilterJobTitleContext = createContext(
  {} as ReturnType<typeof useFilterJobTitle>
);
export function useFilterJobTitleContext() {
  return useContext(FilterJobTitleContext);
}

interface FilterJobTitleProviderProps {
  children: ReactNode;
}
export function FilterJobTitleProvider({
  children,
}: FilterJobTitleProviderProps) {
  return (
    <FilterJobTitleContext.Provider value={useFilterJobTitle()}>
      {children}
    </FilterJobTitleContext.Provider>
  );
}