import { useFilterViolationContext } from "./filter-violation.context";

export function FilterViolation() {
  const { dateFilterElement, clearFiltersElement } =
    useFilterViolationContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {dateFilterElement}
      {clearFiltersElement}
    </div>
  );
}