import { Outlet } from "react-router-dom";

import { FilterFavoriteProvider } from "./filter-favorite/filter-favorite.context";
import './favorite.scss';

export function Favorite() {
  return (
    <div id="Favorite" className="favorite">
       <FilterFavoriteProvider>
        <Outlet />
      </FilterFavoriteProvider>
    </div>
  );
}

export default Favorite;
