import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeIncentiveModel } from "@core/model/query-model-employee-incentive";
import { employeeIncentiveFormDefault } from "./form.default";
import { EmployeeIncentiveFormSchema } from "./form.rule";

export const useFormDefaultEmployeeIncentive = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeIncentiveModel | undefined) => {
    return !cachedValue
      ? employeeIncentiveFormDefault
     : ({
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        amount: cachedValue.amount,
        effectivityDate: formatDate(cachedValue.effectivity_date) as any,
        remarks: cachedValue.remarks,
      } as EmployeeIncentiveFormSchema);
  };

  return {
    getDefault,
  };
};
