import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeDeduction } from "./filter-employee-deduction.hook";

const FilterEmployeeDeductionContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeDeduction>
);
export function useFilterEmployeeDeductionContext() {
  return useContext(FilterEmployeeDeductionContext);
}

interface FilterEmployeeDeductionProviderProps {
  children: ReactNode;
}
export function FilterEmployeeDeductionProvider({
  children,
}: FilterEmployeeDeductionProviderProps) {
  return (
    <FilterEmployeeDeductionContext.Provider value={useFilterEmployeeDeduction()}>
      {children}
    </FilterEmployeeDeductionContext.Provider>
  );
}