import './form-employee-timeline.scss';
import { useFormContext } from "react-hook-form";
import { EmployeeTimelineFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = EmployeeTimelineFormSchema;
export function EmployeeTimeline() {
  const { control } = useFormContext<EmployeeTimelineFormSchema>();
  return (
    <div id="EmployeeTimeline" className="employee-timeline">
      <InputControl<InputFormSchema>
        control={control}
        name="userCode"
        label="User Code"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter userCode"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="bucketCode"
        label="Bucket Code"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter bucketCode"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="source"
        label="Source"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter source"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="action"
        label="Action"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter action"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="message"
        label="Message"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter message"
      />
    </div>
  );
}

export default EmployeeTimeline;