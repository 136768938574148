import { 
    MessageResponseDto, 
    GetEmployeeIncentiveDto, 
    CreateEmployeeIncentiveDto, 
    EditEmployeeIncentiveDto,
    EmployeeIncentiveControllerGet200Response,
    EmployeeIncentiveApiFp,
    DeleteEmployeeIncentiveDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeIncentiveModel = EmployeeIncentiveControllerGet200Response;
export type GetEmployeeIncentiveModel = GetEmployeeIncentiveDto;
export type MutateResponseEmployeeIncentiveModel = MessageResponseDto;
export type CreateEmployeeIncentiveModel = CreateEmployeeIncentiveDto;
export type UpdateEmployeeIncentiveModel = EditEmployeeIncentiveDto;
export type DeleteEmployeeIncentiveModel = DeleteEmployeeIncentiveDto;

export const apiEmployeeIncentiveFp = EmployeeIncentiveApiFp(apiConfig);
export const getEmployeeIncentiveController = apiEmployeeIncentiveFp.employeeIncentiveControllerGet;
export const createEmployeeIncentiveController = apiEmployeeIncentiveFp.employeeIncentiveControllerCreate;
export const updateEmployeeIncentiveController = apiEmployeeIncentiveFp.employeeIncentiveControllerEdit;
export const deleteEmployeeIncentiveController = apiEmployeeIncentiveFp.employeeIncentiveControllerDelete;

export const QueryKeyEmployeeIncentive = QueryKey.EmployeeIncentive;