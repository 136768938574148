import { MenuItem } from "primereact/menuitem";

export function getVisibleMenu(panelMenuItems: MenuItem[]) {
  const visiblePanels = panelMenuItems.map((menuItem: MenuItem) => {
    const childrenMenuItems = (menuItem.items || []) as MenuItem[];
    const visibleChildrenItems = childrenMenuItems.filter(
      (childItem: MenuItem) => !childItem.data?.isHidden
    ) as MenuItem[];
    const filteredMenuItem = {
      ...menuItem,
      items: visibleChildrenItems,
    } as MenuItem;

    return filteredMenuItem;
  });

  const filteredPanels = visiblePanels.filter((item) => !item.data?.isHidden);

  return filteredPanels;
}
