import "./form-employee-incident.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeIncidentFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownIncidentType from "@domain/incident-type/form-dropdown-incident-type/form-dropdown-incident-type";
import FormDropdownPaymentMethod from "@domain/payment-method/form-dropdown-payment-method/form-dropdown-payment-method";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";

type InputFormSchema = EmployeeIncidentFormSchema;
export function EmployeeIncident() {
  const { control } = useFormContext<EmployeeIncidentFormSchema>();
  return (
    <div id="EmployeeIncident" className="employee-incident">
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
        controlClassName="block mb-4"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <FormDropdownIncidentType<InputFormSchema> name="incidentType" />
      <FormDropdownPaymentMethod<InputFormSchema> name="paymentMethod" />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="amount"
        label="Amount"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount needed for employee to pay"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="intervals"
        label="Intervals in months"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount needed for employee to pay"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="paymentStart"
        label="Start of payment"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="remarks"
        label="Remarks"
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter remarks"
      />
    </div>
  );
}

export default EmployeeIncident;
