import "./print-payslip.scss";
import TextBlock from "@shared/ui/text-block/text-block";
import { CalendarDate } from "@core/model/calendar-event.interface";
import { TableExportField } from "@core/model/table-export-field.interface";
import classNames from "classnames";
import { numberFormat } from "@shared/utility/formats/number-format";
import config from "../../../../config.json";

export interface PrintPayslipProps {
  summary: TableExportField;
  period: CalendarDate;
}

export function PrintPayslip({ summary, period }: PrintPayslipProps) {
  const logo = "/icon-512x512.png";
  const days =
    summary.workDays > 1
      ? `${summary.workDays} days`
      : `${summary.workDays} day`;
  return (
    <div id="PrintPayslip" className="print-payslip mt-48">
      <style type="text/css" media="print">
        {
          "\
@page { size: A4; }\
"
        }
      </style>
      <section className="flex justify-center">
        <div className="flex flex-col items-center">
          <img src={logo} className="w-28" alt={config.company} />
          <h1>7'S Traders Corporation</h1>
          <h4>Salary Slip</h4>
          <h4>{summary.branch}</h4>
        </div>
      </section>

      <section className="flex justify-center my-6">
        <div>
          <TextBlock label="Employee" value={summary.name || "-"} />
          <TextBlock label="Designation" value={summary.position || "-"} />
          <TextBlock
            label="Salary Period"
            value={`${period.start} to ${period.end}`}
          />
          <TextBlock label="Days Period" value={days} />
        </div>
      </section>

      <div className="flex justify-center  mx-6 w-full">
        <table className="text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
          <tbody className="border-t border-b border-l-0 border-r-0">
            <tr>
              <th
                className="print-normal px-2 py-1 border w-44 bg-green-200"
                colSpan={2}
              >
                EARNINGS
              </th>
              <th
                className="print-normal px-2 py-1 border w-44 bg-pink-200"
                colSpan={2}
              >
                DEDUCTIONS
              </th>
              <th
                className="print-normal px-2 py-1 border w-44 bg-blue-200"
                colSpan={2}
              >
                OTHERS
              </th>
            </tr>
            <tr>
              <td className="w-20 align-top">Rate</td>
              <td className="font-bold align-top">{summary.dailySalary}</td>

              <td className="w-24 align-top border-l">
                <div>
                  <label>Absents:</label>
                </div>
                <div>
                  <label>Breaks:</label>
                </div>
                <div>
                  <label>Incidents:</label>
                </div>
                <div>
                  <label>Late:</label>
                </div>
                <div>
                  <label>Overage:</label>
                </div>
                <div>
                  <label>Loan Payment:</label>
                </div>
                <div>
                  <label>Penalty:</label>
                </div>
                <div>
                  <label>Undertime:</label>
                </div>

                {summary.govermentDeductionParticulars
                  ? summary.govermentDeductionParticulars.map((item, id) => (
                      <div key={`govfeelabel-${id}`}>
                        <label>{item.label}:</label>
                      </div>
                    ))
                  : null}
                {summary.otherPaymentDeductionParticulars
                  ? summary.otherPaymentDeductionParticulars.map((item, id) => (
                      <div key={`extrafeelabel-${id}`}>
                        <label>{item.label}:</label>
                      </div>
                    ))
                  : null}
              </td>
              <td className="align-top">
                <p className="font-bold">
                  {numberFormat(summary.absentPenalty || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.breakPenalty || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.incidentPenalty || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.latePenalty || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.overagePenalty || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.loanPayment || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.penaltyPenalty || 0, 2)}
                </p>
                <p className="font-bold">
                  {numberFormat(summary.undertimePenalty || 0, 2)}
                </p>

                {summary.govermentDeductionParticulars
                  ? summary.govermentDeductionParticulars.map((item, id) => (
                      <p key={`govfee-${id}`} className="font-bold">
                        {numberFormat(parseFloat(item.value), 2)}
                      </p>
                    ))
                  : null}
                {summary.otherPaymentDeductionParticulars
                  ? summary.otherPaymentDeductionParticulars.map((item, id) => (
                      <p key={`extrafee-${id}`} className="font-bold">
                        {numberFormat(parseFloat(item.value), 2)}
                      </p>
                    ))
                  : null}
              </td>

              <td className="align-top border-l w-20">
                <div className="flex flex-col">
                  <label>Holiday:</label>
                  <label>Incentives:</label>
                  <label>OT Pay:</label>
                </div>
              </td>
              <td className="align-top">
                <div className="flex flex-col">
                  <label>{numberFormat(summary.bonusHoliday, 2)}</label>
                  <label>{numberFormat(summary.incentives, 2)}</label>
                  <label>{numberFormat(summary.otPay, 2)}</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="border-t">
                <section className="flex gap-2">
                  <label className="break-keep ">Total Basic: </label>
                  <p className="font-bold">
                    {numberFormat(summary.grossSalary || 0, 2)}
                  </p>
                </section>
              </td>
              <td colSpan={2} className="border-t border-l">
                <section className="flex gap-2">
                  <label className="break-keep">Total Deduction</label>
                  <p className="font-bold">
                    {numberFormat(summary.netDeduction || 0, 2)}
                  </p>
                </section>
              </td>
              <td colSpan={2} className="border-t border-l">
                <section className="flex gap-2">
                  <label className="break-keep">Net Salary</label>
                  <p className="font-bold">
                    {numberFormat(summary.netSalary || 0, 2)}
                  </p>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <section className="flex justify-center gap-6 mt-11">
        <small
          className={classNames(
            "mb-6 block relative top-[1px] border-t border-black w-1/3 text-center"
          )}
        >
          Name / Signature of Employee
        </small>
        <small
          className={classNames(
            "mb-6 block relative top-[1px] border-t border-black w-1/6 text-center"
          )}
        >
          Date
        </small>
      </section>
    </div>
  );
}

export default PrintPayslip;
