import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeOverage } from "./filter-employee-overage.hook";

const FilterEmployeeOverageContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeOverage>
);
export function useFilterEmployeeOverageContext() {
  return useContext(FilterEmployeeOverageContext);
}

interface FilterEmployeeOverageProviderProps {
  children: ReactNode;
}
export function FilterEmployeeOverageProvider({
  children,
}: FilterEmployeeOverageProviderProps) {
  return (
    <FilterEmployeeOverageContext.Provider value={useFilterEmployeeOverage()}>
      {children}
    </FilterEmployeeOverageContext.Provider>
  );
}