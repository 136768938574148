import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeFavorite } from "./filter-employee-favorite.hook";

const FilterEmployeeFavoriteContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeFavorite>
);
export function useFilterEmployeeFavoriteContext() {
  return useContext(FilterEmployeeFavoriteContext);
}

interface FilterEmployeeFavoriteProviderProps {
  children: ReactNode;
}
export function FilterEmployeeFavoriteProvider({
  children,
}: FilterEmployeeFavoriteProviderProps) {
  return (
    <FilterEmployeeFavoriteContext.Provider value={useFilterEmployeeFavorite()}>
      {children}
    </FilterEmployeeFavoriteContext.Provider>
  );
}