import { 
    MessageResponseDto, 
    GetEmployeeDeductionDto, 
    CreateEmployeeDeductionDto, 
    EditEmployeeDeductionDto,
    EmployeeDeductionControllerGet200Response,
    EmployeeDeductionApiFp,
    DeleteEmployeeDeductionDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeDeductionModel = EmployeeDeductionControllerGet200Response;
export type GetEmployeeDeductionModel = GetEmployeeDeductionDto;
export type MutateResponseEmployeeDeductionModel = MessageResponseDto;
export type CreateEmployeeDeductionModel = CreateEmployeeDeductionDto;
export type UpdateEmployeeDeductionModel = EditEmployeeDeductionDto;
export type DeleteEmployeeDeductionModel = DeleteEmployeeDeductionDto;

export const apiEmployeeDeductionFp = EmployeeDeductionApiFp(apiConfig);
export const getEmployeeDeductionController = apiEmployeeDeductionFp.employeeDeductionControllerGet;
export const createEmployeeDeductionController = apiEmployeeDeductionFp.employeeDeductionControllerCreate;
export const updateEmployeeDeductionController = apiEmployeeDeductionFp.employeeDeductionControllerEdit;
export const deleteEmployeeDeductionController = apiEmployeeDeductionFp.employeeDeductionControllerDelete;

export const QueryKeyEmployeeDeduction = QueryKey.EmployeeDeduction;