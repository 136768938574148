import {
  CreateBranchDto,
  EditBranchDto,
  SigninUserRequestDto,
  CreateHolidayDto,
  EditHolidayDto,
  CreatePersonExtendedDto,
  DeleteHolidayDto,
  CreateHolidayTypeDto,
  EditHolidayTypeDto,
  DeleteHolidayTypeDto,
  CreateJobTitleDto,
  EditJobTitleDto,
  DeleteJobTitleDto,
  CreateJobGradeDto,
  EditJobGradeDto,
  DeleteJobGradeDto,
  CreateShiftTypeDto,
  EditShiftTypeDto,
  DeleteShiftTypeDto,
  CreateStatusDto,
  EditStatusDto,
  DeleteStatusDto,
  CreateShiftDto,
  EditShiftDto,
  DeleteShiftDto,
  CreateEmploymentTypeDto,
  EditEmploymentTypeDto,
  DeleteEmploymentTypeDto,
  CreateDepartmentDto,
  EditDepartmentDto,
  DeleteDepartmentDto,
  CreateRoleDto,
  EditRoleDto,
  DeleteRoleDto,
  DeleteBranchDto,
  CreateEmployeeBucketDto,
  EditEmployeeBucketDto,
  DeleteEmployeeBucketDto,
  DeletePersonDto,
  CreatePersonAddressDto,
  EditPersonAddressDto,
  DeletePersonAddressDto,
  CreateEmployeeShiftDto,
  EditEmployeeShiftDto,
  DeleteEmployeeShiftDto,
  CreateQuestionnaireDto,
  EditQuestionnaireDto,
  DeleteQuestionnaireDto,
  CreateEmployeeScoreDto,
  EditEmployeeScoreDto,
  DeleteEmployeeScoreDto,
  ClockinDto,
  ClockoutDto,
  CreateLeaveSubcategoryDto,
  EditLeaveSubcategoryDto,
  DeleteLeaveSubcategoryDto,
  CreateLeaveCategoryDto,
  EditLeaveCategoryDto,
  DeleteLeaveCategoryDto,
  CreateLeaveTypeDto,
  EditLeaveTypeDto,
  DeleteLeaveTypeDto,
  CreateRequestTypeDto,
  EditRequestTypeDto,
  DeleteRequestTypeDto,
  CreateEmployeeRequestDto,
  EditEmployeeRequestDto,
  DeleteEmployeeRequestDto,
  CreatePaymentMethodDto,
  EditPaymentMethodDto,
  DeletePaymentMethodDto,
  CreateEmployeeIncidentDto,
  EditEmployeeIncidentDto,
  DeleteEmployeeIncidentDto,
  CreateIncidentTypeDto,
  EditIncidentTypeDto,
  DeleteIncidentTypeDto,
  CreateSalaryTypeDto,
  EditSalaryTypeDto,
  DeleteSalaryTypeDto,
  CreateEmployeeSalaryDto,
  EditEmployeeSalaryDto,
  DeleteEmployeeSalaryDto,
  CreateEmployeeDeductionDto,
  EditEmployeeDeductionDto,
  DeleteEmployeeDeductionDto,
  CreateEmployeePenaltyDto,
  EditEmployeePenaltyDto,
  DeleteEmployeePenaltyDto,
  CreateEmployeeTimelineDto,
  EditEmployeeTimelineDto,
  DeleteEmployeeTimelineDto,
  DeleteEmployeeAttachmentDto,
  CreateEmployeeIncentiveDto,
  EditEmployeeIncentiveDto,
  DeleteEmployeeIncentiveDto,
  CreateEmployeeDiciplinaryDto,
  EditEmployeeDiciplinaryDto,
  DeleteEmployeeDiciplinaryDto,
  CreateViolationDto,
  EditViolationDto,
  DeleteViolationDto,
  CreateInfractionDto,
  EditInfractionDto,
  DeleteInfractionDto,
  CreatePenaltyScheduleDto,
  EditPenaltyScheduleDto,
  DeletePenaltyScheduleDto,
  CreateAttachmentTypeDto,
  EditAttachmentTypeDto,
  DeleteAttachmentTypeDto,
  CreateEmployeeLoanDto,
  EditEmployeeLoanDto,
  DeleteEmployeeLoanDto,
  CreateEmployeePaymentDto,
  EditEmployeePaymentDto,
  DeleteEmployeePaymentDto,
  CreateEmployeeBranchDto,
  EditEmployeeBranchDto,
  DeleteEmployeeBranchDto,
  CreateEmployeeOverageDto,
  EditEmployeeOverageDto,
  DeleteEmployeeOverageDto,
  CreateFaqDto,
  EditFaqDto,
  DeleteFaqDto,
  CreateFavoriteDto,
  EditFavoriteDto,
  DeleteFavoriteDto,
  CreateEmployeeFavoriteDto,
  DeleteEmployeeFavoriteDto,
  // MAPPER API IMPORT
} from "@api/api";
import {
  BranchFormSchema,
  EmployeeFormSchema,
  LoginFormSchema,
  HolidayFormSchema,
  HolidayTypeFormSchema,
  JobTitleFormSchema,
  JobGradeFormSchema,
  ShiftTypeFormSchema,
  StatusFormSchema,
  ShiftFormSchema,
  EmploymentTypeFormSchema,
  DepartmentFormSchema,
  RoleFormSchema,
  EmployeeBucketFormSchema,
  PersonFormSchema,
  PersonAddressFormSchema,
  EmployeeShiftFormSchema,
  QuestionnaireFormSchema,
  EmployeeScoreFormSchema,
  DailyTimeRecordFormSchema,
  LeaveSubcategoryFormSchema,
  LeaveCategoryFormSchema,
  LeaveTypeFormSchema,
  RequestTypeFormSchema,
  EmployeeRequestFormSchema,
  PaymentMethodFormSchema,
  EmployeeIncidentFormSchema,
  IncidentTypeFormSchema,
  SalaryTypeFormSchema,
  EmployeeSalaryFormSchema,
  EmployeeDeductionFormSchema,
  EmployeePenaltyFormSchema,
  EmployeeTimelineFormSchema,
  EmployeeLedgerFormSchema,
  EmployeeAttachmentFormSchema,
  EmployeeIncentiveFormSchema,
  EmployeeDiciplinaryFormSchema,
  ViolationFormSchema,
  InfractionFormSchema,
  PenaltyScheduleFormSchema,
  AttachmentTypeFormSchema,
  EmployeeLoanFormSchema,
  EmployeePaymentFormSchema,
  EmployeeBranchFormSchema,
  EmployeeOverageFormSchema,
  FaqFormSchema,
  DeviceAuthorizationFormSchema,
  FavoriteFormSchema,
  EmployeeFavoriteFormSchema,
  // MAPPER IMPORT
} from "@core/form/form.rule";
import { QRDailyTimeRecordModel } from "@core/model/query-model-daily-time-record";
import { CreateDeviceAuthorizationModel } from "@core/model/query-model-device-authorization";
import {
  CreateEmployeeAttachmentModel,
  UpdateEmployeeAttachmentModel,
} from "@core/model/query-model-employee-attachment";
import { CreateEmployeeLedgerModel } from "@core/model/query-model-employee-ledger";
import {
  AmendmentEmployeeRequestModel,
  BreakAmendmentEmployeeRequestModel,
  ChangeShiftEmployeeRequestModel,
  LeaveEmployeeRequestModel,
  OvertimeEmployeeRequestModel,
} from "@core/model/query-model-employee-request";
import {
  CreatePersonModel,
  UpdatePersonModel,
  UploadPersonModel,
} from "@core/model/query-model-person";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { format } from "date-fns";

export function useFormApiMapper() {
  const { formatDate } = useDateTimeContext();

  const mapLogin = (form: LoginFormSchema) => {
    const payload = {
      username: form.username,
      password: form.password,
    } as SigninUserRequestDto;

    return payload;
  };

  const mapNewBranch = (form: BranchFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
      head: form.head,
    } as CreateBranchDto;

    return payload;
  };
  const mapEditBranch = (form: BranchFormSchema, code: string) => {
    const payload = {
      code,
      description: form.description,
      head: form.head,
      name: form.name,
    } as EditBranchDto;

    return payload;
  };
  const mapDeleteBranch = (code: string) => {
    const payload = {
      code,
    } as DeleteBranchDto;

    return payload;
  };

  const mapNewEmployee = (form: EmployeeFormSchema) => {
    const payload = {
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      username: form.username,
      email: form.email,
      password: form.password,
      avatar: form.avatar,
      gender: form.gender,
      birthday: formatDate(form.birthday, undefined),
    } as CreatePersonExtendedDto;

    return payload;
  };

  const mapCreateHoliday = (form: HolidayFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
      date: formatDate(form.date, undefined),
      type: form.type,
    } as CreateHolidayDto;

    return payload;
  };
  const mapUpdateHoliday = (form: HolidayFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
      date: formatDate(form.date, undefined),
      type: form.type,
    } as EditHolidayDto;

    return payload;
  };
  const mapDeleteHoliday = (code: string) => {
    const payload = {
      code,
    } as DeleteHolidayDto;

    return payload;
  };
  const mapCreateHolidayType = (form: HolidayTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
      percentage: form.percentage,
    } as CreateHolidayTypeDto;

    return payload;
  };
  const mapUpdateHolidayType = (form: HolidayTypeFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
      percentage: form.percentage,
    } as EditHolidayTypeDto;

    return payload;
  };
  const mapDeleteHolidayType = (code: string) => {
    const payload = {
      code,
    } as DeleteHolidayTypeDto;

    return payload;
  };
  const mapCreateJobTitle = (form: JobTitleFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateJobTitleDto;

    return payload;
  };
  const mapUpdateJobTitle = (form: JobTitleFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditJobTitleDto;

    return payload;
  };
  const mapDeleteJobTitle = (code: string) => {
    const payload = {
      code,
    } as DeleteJobTitleDto;

    return payload;
  };
  const mapCreateJobGrade = (form: JobGradeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateJobGradeDto;

    return payload;
  };
  const mapUpdateJobGrade = (form: JobGradeFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditJobGradeDto;

    return payload;
  };
  const mapDeleteJobGrade = (code: string) => {
    const payload = {
      code,
    } as DeleteJobGradeDto;

    return payload;
  };
  const mapCreateShiftType = (form: ShiftTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateShiftTypeDto;

    return payload;
  };
  const mapUpdateShiftType = (form: ShiftTypeFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditShiftTypeDto;

    return payload;
  };
  const mapDeleteShiftType = (code: string) => {
    const payload = {
      code,
    } as DeleteShiftTypeDto;

    return payload;
  };
  const mapCreateStatus = (form: StatusFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateStatusDto;

    return payload;
  };
  const mapUpdateStatus = (form: StatusFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditStatusDto;

    return payload;
  };
  const mapDeleteStatus = (code: string) => {
    const payload = {
      code,
    } as DeleteStatusDto;

    return payload;
  };
  const mapCreateShift = (form: ShiftFormSchema) => {
    const payload = {
      type: form.type,
      start: form.start,
      end: form.end,
      half_day: form.halfDay,
      grace_period: form.gracePeriod,
      work_hours: form.workHours,
      break_start: form.breakStart,
      break: form.break,
      title: form.title,
      is_open: form.isOpen,
    } as CreateShiftDto;

    return payload;
  };
  const mapUpdateShift = (form: ShiftFormSchema, code: string) => {
    const payload = {
      code,
      type: form.type,
      start: form.start,
      end: form.end,
      half_day: form.halfDay,
      grace_period: form.gracePeriod,
      work_hours: form.workHours,
      break_start: form.breakStart,
      break: form.break,
      title: form.title,
      is_open: form.isOpen,
    } as EditShiftDto;

    return payload;
  };
  const mapDeleteShift = (code: string) => {
    const payload = {
      code,
    } as DeleteShiftDto;

    return payload;
  };
  const mapCreateEmploymentType = (form: EmploymentTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateEmploymentTypeDto;

    return payload;
  };
  const mapUpdateEmploymentType = (
    form: EmploymentTypeFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditEmploymentTypeDto;

    return payload;
  };
  const mapDeleteEmploymentType = (code: string) => {
    const payload = {
      code,
    } as DeleteEmploymentTypeDto;

    return payload;
  };
  const mapCreateDepartment = (form: DepartmentFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
      head: form.head,
    } as CreateDepartmentDto;

    return payload;
  };
  const mapUpdateDepartment = (form: DepartmentFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
      head: form.head,
    } as EditDepartmentDto;

    return payload;
  };
  const mapDeleteDepartment = (code: string) => {
    const payload = {
      code,
    } as DeleteDepartmentDto;

    return payload;
  };
  const mapCreateRole = (form: RoleFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateRoleDto;

    return payload;
  };
  const mapUpdateRole = (form: RoleFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditRoleDto;

    return payload;
  };
  const mapDeleteRole = (code: string) => {
    const payload = {
      code,
    } as DeleteRoleDto;

    return payload;
  };
  const mapCreateEmployeeBucket = (form: EmployeeBucketFormSchema) => {
    const payload = {
      user_code: form.userCode,
      employee_no: form.employeeNo,
      signed_date: formatDate(form.signedDate as Date, undefined),
      start_date: formatDate(form.startDate as Date, undefined),
      end_date: formatDate(form.endDate as Date, undefined),
      job_title: form.jobTitle,
      job_grade: form.jobGrade,
      employment_type: form.employmentType,
      department: form.department,
      branch: form.branch,
      reporting_to: form.reportingTo,
      offboard_reason: form.offboardReason,
      resignation_date: formatDate(form.resignationDate as Date, undefined),
    } as CreateEmployeeBucketDto;

    if (form.role) {
      payload.role = form.role;
    }

    return payload;
  };
  const mapUpdateEmployeeBucket = (
    form: EmployeeBucketFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      employee_no: form.employeeNo,
      signed_date: formatDate(form.signedDate as Date, undefined),
      start_date: formatDate(form.startDate as Date, undefined),
      end_date: formatDate(form.endDate as Date, undefined),
      job_title: form.jobTitle,
      job_grade: form.jobGrade,
      employment_type: form.employmentType,
      department: form.department,
      branch: form.branch,
      reporting_to: form.reportingTo,
      offboard_reason: form.offboardReason,
      resignation_date: formatDate(form.resignationDate as Date, undefined),
    } as EditEmployeeBucketDto;

    if (form.role) {
      payload.role = form.role;
    }

    return payload;
  };
  const mapDeleteEmployeeBucket = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeBucketDto;

    return payload;
  };
  const mapCreatePerson = (form: PersonFormSchema) => {
    const bucket = form.bucket
      ? mapCreateEmployeeBucket(form.bucket)
      : undefined;
    const address = form.address
      ? mapCreatePersonAddress(form.address)
      : undefined;
    const payload = {
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      username: form.username,
      email: form.email,
      password: form.password,
      gender: form.gender,
      birthday: formatDate(form.birthday as Date, undefined),
      bucket,
      address,
      file: form.picture,
    } as CreatePersonModel;

    return payload;
  };
  const mapUpdatePerson = (form: PersonFormSchema, code: string) => {
    const bucket = form.bucket
      ? mapUpdateEmployeeBucket(form.bucket, form.bucket.code || "")
      : undefined;
    const address = form.address
      ? mapUpdatePersonAddress(form.address, form.address.code || "")
      : undefined;
    const payload = {
      code,
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      username: form.username,
      email: form.email,
      gender: form.gender,
      birthday: formatDate(form.birthday as Date, undefined),
      bucket,
      address,
    } as UpdatePersonModel;

    if (form.password) {
      payload.password = form.password;
    }

    if (typeof form.picture === "object") {
      payload.file = form.picture;
    }

    const password = form.password;
    if (!!password) {
      payload.password = password;
    }

    return payload;
  };
  const mapUploadAttachment = (form: PersonFormSchema, code: string) => {
    const payload = {
      code,
      attachment_type: form.attachmentType,
      file: form.attachment,
    } as UploadPersonModel;

    return payload;
  };
  const mapDeletePerson = (code: string) => {
    const payload = {
      code,
    } as DeletePersonDto;

    return payload;
  };
  const mapCreatePersonAddress = (form: PersonAddressFormSchema) => {
    const payload = {
      user_code: form.userCode,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      zip: form.zip,
      longitude: form.longitude,
      latitude: form.latitude,
    } as CreatePersonAddressDto;

    return payload;
  };
  const mapUpdatePersonAddress = (
    form: PersonAddressFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      zip: form.zip,
      longitude: form.longitude,
      latitude: form.latitude,
    } as EditPersonAddressDto;

    return payload;
  };
  const mapDeletePersonAddress = (code: string) => {
    const payload = {
      code,
    } as DeletePersonAddressDto;

    return payload;
  };
  const mapCreateEmployeeShift = (form: EmployeeShiftFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      shift_code: form.shiftCode,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      end_date: formatDate(form.endDate as Date, undefined),
      day: form.day,
      is_permanent: form.isPermanent,
    } as CreateEmployeeShiftDto;

    return payload;
  };
  const mapUpdateEmployeeShift = (
    form: EmployeeShiftFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      shift_code: form.shiftCode,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      end_date: formatDate(form.endDate as Date, undefined),
      day: form.day,
      is_permanent: form.isPermanent,
    } as EditEmployeeShiftDto;

    return payload;
  };
  const mapDeleteEmployeeShift = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeShiftDto;

    return payload;
  };
  const mapCreateQuestionnaire = (form: QuestionnaireFormSchema) => {
    const payload = {
      job_title: form.jobTitle,
      name: form.name,
      description: form.description,
    } as CreateQuestionnaireDto;

    return payload;
  };
  const mapUpdateQuestionnaire = (
    form: QuestionnaireFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      job_title: form.jobTitle,
      name: form.name,
      description: form.description,
    } as EditQuestionnaireDto;

    return payload;
  };
  const mapDeleteQuestionnaire = (code: string) => {
    const payload = {
      code,
    } as DeleteQuestionnaireDto;

    return payload;
  };
  const mapCreateEmployeeScore = (form: EmployeeScoreFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      question_code: form.questionCode,
      score: form.score,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
    } as CreateEmployeeScoreDto;

    return payload;
  };
  const mapUpdateEmployeeScore = (
    form: EmployeeScoreFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      question_code: form.questionCode,
      score: form.score,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
    } as EditEmployeeScoreDto;

    return payload;
  };
  const mapDeleteEmployeeScore = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeScoreDto;

    return payload;
  };
  const mapCreateDailyTimeRecordQr = (bucketCode: string) => {
    const payload = {
      bucket_code: bucketCode,
    } as QRDailyTimeRecordModel;

    return payload;
  };
  const mapCreateDailyTimeRecord = (form: DailyTimeRecordFormSchema) => {
    const payload = {
      clock_in: formatDate(form.clockIn as Date, undefined),
    } as ClockinDto;

    return payload;
  };
  const mapUpdateDailyTimeRecord = (form: DailyTimeRecordFormSchema) => {
    const payload = {
      clock_out: formatDate(form.clockOut as Date, undefined),
    } as ClockoutDto;

    return payload;
  };
  const mapCreateLeaveSubcategory = (form: LeaveSubcategoryFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateLeaveSubcategoryDto;

    return payload;
  };
  const mapUpdateLeaveSubcategory = (
    form: LeaveSubcategoryFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditLeaveSubcategoryDto;

    return payload;
  };
  const mapDeleteLeaveSubcategory = (code: string) => {
    const payload = {
      code,
    } as DeleteLeaveSubcategoryDto;

    return payload;
  };
  const mapCreateLeaveCategory = (form: LeaveCategoryFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateLeaveCategoryDto;

    return payload;
  };
  const mapUpdateLeaveCategory = (
    form: LeaveCategoryFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditLeaveCategoryDto;

    return payload;
  };
  const mapDeleteLeaveCategory = (code: string) => {
    const payload = {
      code,
    } as DeleteLeaveCategoryDto;

    return payload;
  };
  const mapCreateLeaveType = (form: LeaveTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateLeaveTypeDto;

    return payload;
  };
  const mapUpdateLeaveType = (form: LeaveTypeFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditLeaveTypeDto;

    return payload;
  };
  const mapDeleteLeaveType = (code: string) => {
    const payload = {
      code,
    } as DeleteLeaveTypeDto;

    return payload;
  };
  const mapCreateRequestType = (form: RequestTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateRequestTypeDto;

    return payload;
  };
  const mapUpdateRequestType = (form: RequestTypeFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditRequestTypeDto;

    return payload;
  };
  const mapDeleteRequestType = (code: string) => {
    const payload = {
      code,
    } as DeleteRequestTypeDto;

    return payload;
  };
  const mapCreateEmployeeRequest = (form: EmployeeRequestFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      request_type: form.requestType,
      request_reason: form.requestReason,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      leave_type: form.leaveType,
      leave_category: form.leaveCategory,
      leave_subcategory: form.leaveSubcategory,
      amend_current_clockin: form.amendCurrentClockin,
      amend_current_clockout: form.amendCurrentClockout,
      amend_clockin: form.amendClockin,
      amend_clockout: form.amendClockout,
      shift_current_schedule: form.shiftCurrentSchedule,
      shift_schedule: form.shiftSchedule,
      is_permanent: form.isPermanent,
      shift_day: form.shiftDay,
      end_date: formatDate(form.endDate as Date, undefined),
      ot_time: form.otTime,
      status: form.status,
    } as CreateEmployeeRequestDto;

    return payload;
  };
  const mapUpdateEmployeeRequest = (
    form: EmployeeRequestFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      request_type: form.requestType,
      request_reason: form.requestReason,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      leave_type: form.leaveType,
      leave_category: form.leaveCategory,
      leave_subcategory: form.leaveSubcategory,
      amend_current_clockin: form.amendCurrentClockin,
      amend_current_clockout: form.amendCurrentClockout,
      amend_clockin: form.amendClockin,
      amend_clockout: form.amendClockout,
      shift_current_schedule: form.shiftCurrentSchedule,
      shift_schedule: form.shiftSchedule,
      is_permanent: form.isPermanent,
      shift_day: form.shiftDay,
      end_date: formatDate(form.endDate as Date, undefined),
      ot_time: form.otTime,
      status: form.status,
    } as EditEmployeeRequestDto;

    return payload;
  };
  const mapDeleteEmployeeRequest = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeRequestDto;

    return payload;
  };
  const mapOvertimeEmployeeRequest = (form: EmployeeRequestFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      request_type: form.requestType,
      request_reason: form.requestReason,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      ot_time: form.otTime,
      file: form.file,
    } as OvertimeEmployeeRequestModel;

    return payload;
  };
  const mapLeaveEmployeeRequest = (form: EmployeeRequestFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      request_type: form.requestType,
      request_reason: form.requestReason,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      leave_type: form.leaveType,
      leave_category: form.leaveCategory,
      leave_subcategory: form.leaveSubcategory,
      file: form.file,
    } as LeaveEmployeeRequestModel;

    return payload;
  };
  const mapDtrEmployeeRequest = (form: EmployeeRequestFormSchema) => {
    const payload = {
      bucket_code: form.bucketCode,
      dtr_code: form.dtrCode,
      request_reason: form.requestReason,
      new_clockin: form.amendClockin,
      new_clockout: form.amendClockout,
      work_date: formatDate(form.workDate, ""),
      file: form.file,
    } as AmendmentEmployeeRequestModel;

    return payload;
  };
  const mapBreakEmployeeRequest = (form: EmployeeRequestFormSchema) => {
    const payload = {
      break_code: form.breakCode,
      request_type: form.requestType,
      request_reason: form.requestReason,
      amend_break_start: form.amendBreakStart,
      amend_break_end: form.amendBreakEnd,
      is_break_permision: form.isBreakPermission,
      file: form.file,
    } as BreakAmendmentEmployeeRequestModel;

    return payload;
  };
  const mapChangeShiftEmployeeRequest = (form: EmployeeRequestFormSchema) => {
    const dateStr = formatDate(form.effectivityDate as Date, undefined);
    const payload = {
      bucket_code: form.bucketCode,
      effectivity_date: dateStr,
      effectivity_date_end: dateStr,
      is_permanent: form.isPermanent,
      request_reason: form.requestReason,
      shift_day: format(dateStr, "eeee"),
      shift_schedule_code: form.shiftSchedule,
      file: form.file,
    } as ChangeShiftEmployeeRequestModel;

    return payload;
  };
  const mapCreatePaymentMethod = (form: PaymentMethodFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreatePaymentMethodDto;

    return payload;
  };
  const mapUpdatePaymentMethod = (
    form: PaymentMethodFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditPaymentMethodDto;

    return payload;
  };
  const mapDeletePaymentMethod = (code: string) => {
    const payload = {
      code,
    } as DeletePaymentMethodDto;

    return payload;
  };
  const mapCreateEmployeeIncident = (form: EmployeeIncidentFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      incident_type: form.incidentType,
      payment_method: form.paymentMethod,
      amount: form.amount,
      balance: form.balance,
      is_paid: form.isPaid,
      intervals: form.intervals,
      payment_start: formatDate(form.paymentStart as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeeIncidentDto;

    return payload;
  };
  const mapUpdateEmployeeIncident = (
    form: EmployeeIncidentFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      incident_type: form.incidentType,
      payment_method: form.paymentMethod,
      amount: form.amount,
      balance: form.balance,
      is_paid: form.isPaid,
      intervals: form.intervals,
      payment_start: formatDate(form.paymentStart as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeeIncidentDto;

    return payload;
  };
  const mapDeleteEmployeeIncident = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeIncidentDto;

    return payload;
  };
  const mapCreateIncidentType = (form: IncidentTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateIncidentTypeDto;

    return payload;
  };
  const mapUpdateIncidentType = (
    form: IncidentTypeFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditIncidentTypeDto;

    return payload;
  };
  const mapDeleteIncidentType = (code: string) => {
    const payload = {
      code,
    } as DeleteIncidentTypeDto;

    return payload;
  };
  const mapCreateSalaryType = (form: SalaryTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateSalaryTypeDto;

    return payload;
  };
  const mapUpdateSalaryType = (form: SalaryTypeFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditSalaryTypeDto;

    return payload;
  };
  const mapDeleteSalaryType = (code: string) => {
    const payload = {
      code,
    } as DeleteSalaryTypeDto;

    return payload;
  };
  const mapCreateEmployeeSalary = (form: EmployeeSalaryFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      salary_type: form.salaryType,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeeSalaryDto;

    return payload;
  };
  const mapUpdateEmployeeSalary = (
    form: EmployeeSalaryFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      salary_type: form.salaryType,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeeSalaryDto;

    return payload;
  };
  const mapDeleteEmployeeSalary = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeSalaryDto;

    return payload;
  };
  const mapCreateEmployeeDeduction = (form: EmployeeDeductionFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      incident: form.incident,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeeDeductionDto;

    return payload;
  };
  const mapUpdateEmployeeDeduction = (
    form: EmployeeDeductionFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      incident: form.incident,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeeDeductionDto;

    return payload;
  };
  const mapDeleteEmployeeDeduction = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeDeductionDto;

    return payload;
  };
  const mapCreateEmployeePenalty = (form: EmployeePenaltyFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeePenaltyDto;

    return payload;
  };
  const mapUpdateEmployeePenalty = (
    form: EmployeePenaltyFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeePenaltyDto;

    return payload;
  };
  const mapDeleteEmployeePenalty = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeePenaltyDto;

    return payload;
  };
  const mapCreateEmployeeTimeline = (form: EmployeeTimelineFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      source: form.source,
      action: form.action,
      message: form.message,
    } as CreateEmployeeTimelineDto;

    return payload;
  };
  const mapUpdateEmployeeTimeline = (
    form: EmployeeTimelineFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      source: form.source,
      action: form.action,
      message: form.message,
    } as EditEmployeeTimelineDto;

    return payload;
  };
  const mapDeleteEmployeeTimeline = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeTimelineDto;

    return payload;
  };
  const mapCreateEmployeeLedger = (form: EmployeeLedgerFormSchema) => {
    const payload = {
      user_id: form.userId,
      bucket_id: form.bucketId,
      ledger_value: form.ledgerValue,
      ledger_action: form.ledgerAction,
      ledger_status: form.ledgerStatus,
      transaction_code: form.transactionCode,
      ledger_remarks: form.ledgerRemarks,
      effectivity_date: form.effectivityDate,
    } as CreateEmployeeLedgerModel;

    return payload;
  };
  const mapCreateEmployeeAttachment = (form: EmployeeAttachmentFormSchema) => {
    const payload = {
      is_active: form.isActive,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      source: form.source,
      attachment_type: form.attachmentType,
      request_code: form.requestCode,
      file: form.file,
      mime_type: form.mimeType,
    } as CreateEmployeeAttachmentModel;

    return payload;
  };
  const mapUpdateEmployeeAttachment = (
    form: EmployeeAttachmentFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      isActive: form.isActive,
      userCode: form.userCode,
      bucketCode: form.bucketCode,
      source: form.source,
      attachment_type: form.attachmentType,
      requestCode: form.requestCode,
      file: form.file,
      mimeType: form.mimeType,
    } as UpdateEmployeeAttachmentModel;

    return payload;
  };
  const mapDeleteEmployeeAttachment = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeAttachmentDto;

    return payload;
  };
  const mapCreateEmployeeIncentive = (form: EmployeeIncentiveFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeeIncentiveDto;

    return payload;
  };
  const mapUpdateEmployeeIncentive = (
    form: EmployeeIncentiveFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeeIncentiveDto;

    return payload;
  };
  const mapDeleteEmployeeIncentive = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeIncentiveDto;

    return payload;
  };
  const mapCreateEmployeeDiciplinary = (
    form: EmployeeDiciplinaryFormSchema
  ) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      incident_date: formatDate(form.incidentDate as Date, undefined),
      issued_date: formatDate(form.issuedDate as Date, undefined),
      violation_code: form.violationCode,
      infraction_code: form.infractionCode,
      details: form.details,
      penalty_schedule_code: form.penaltyScheduleCode,
      action_plan: form.actionPlan,
      followup_date: form.followupDate,
      issuer: form.issuer,
      supervisor: form.supervisor,
    } as CreateEmployeeDiciplinaryDto;

    return payload;
  };
  const mapUpdateEmployeeDiciplinary = (
    form: EmployeeDiciplinaryFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      incident_date: formatDate(form.incidentDate as Date, undefined),
      issued_date: formatDate(form.issuedDate as Date, undefined),
      violation_code: form.violationCode,
      infraction_code: form.infractionCode,
      details: form.details,
      penalty_schedule_code: form.penaltyScheduleCode,
      action_plan: form.actionPlan,
      followup_date: form.followupDate,
      issuer: form.issuer,
      supervisor: form.supervisor,
    } as EditEmployeeDiciplinaryDto;

    return payload;
  };
  const mapDeleteEmployeeDiciplinary = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeDiciplinaryDto;

    return payload;
  };
  const mapCreateViolation = (form: ViolationFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateViolationDto;

    return payload;
  };
  const mapUpdateViolation = (form: ViolationFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditViolationDto;

    return payload;
  };
  const mapDeleteViolation = (code: string) => {
    const payload = {
      code,
    } as DeleteViolationDto;

    return payload;
  };
  const mapCreateInfraction = (form: InfractionFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateInfractionDto;

    return payload;
  };
  const mapUpdateInfraction = (form: InfractionFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditInfractionDto;

    return payload;
  };
  const mapDeleteInfraction = (code: string) => {
    const payload = {
      code,
    } as DeleteInfractionDto;

    return payload;
  };
  const mapCreatePenaltySchedule = (form: PenaltyScheduleFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreatePenaltyScheduleDto;

    return payload;
  };
  const mapUpdatePenaltySchedule = (
    form: PenaltyScheduleFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditPenaltyScheduleDto;

    return payload;
  };
  const mapDeletePenaltySchedule = (code: string) => {
    const payload = {
      code,
    } as DeletePenaltyScheduleDto;

    return payload;
  };
  const mapCreateAttachmentType = (form: AttachmentTypeFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateAttachmentTypeDto;

    return payload;
  };
  const mapUpdateAttachmentType = (
    form: AttachmentTypeFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditAttachmentTypeDto;

    return payload;
  };
  const mapDeleteAttachmentType = (code: string) => {
    const payload = {
      code,
    } as DeleteAttachmentTypeDto;

    return payload;
  };
  const mapCreateEmployeeLoan = (form: EmployeeLoanFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      subject: form.subject,
      purpose: form.purpose,
      intervals: form.intervals,
      payment_start: formatDate(form.paymentStart as Date, undefined),
    } as CreateEmployeeLoanDto;

    return payload;
  };
  const mapUpdateEmployeeLoan = (
    form: EmployeeLoanFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      subject: form.subject,
      purpose: form.purpose,
      intervals: form.intervals,
      payment_start: formatDate(form.paymentStart as Date, undefined),
    } as EditEmployeeLoanDto;

    return payload;
  };
  const mapDeleteEmployeeLoan = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeLoanDto;

    return payload;
  };
  const mapCreateEmployeePayment = (form: EmployeePaymentFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      loan: form.loan,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeePaymentDto;

    return payload;
  };
  const mapUpdateEmployeePayment = (
    form: EmployeePaymentFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      loan: form.loan,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeePaymentDto;

    return payload;
  };
  const mapDeleteEmployeePayment = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeePaymentDto;

    return payload;
  };
  const mapCreateEmployeeBranch = (form: EmployeeBranchFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
    } as CreateEmployeeBranchDto;

    return payload;
  };
  const mapUpdateEmployeeBranch = (
    form: EmployeeBranchFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      branch_code: form.branchCode,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
    } as EditEmployeeBranchDto;

    return payload;
  };
  const mapDeleteEmployeeBranch = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeBranchDto;

    return payload;
  };
  const mapCreateEmployeeOverage = (form: EmployeeOverageFormSchema) => {
    const payload = {
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as CreateEmployeeOverageDto;

    return payload;
  };
  const mapUpdateEmployeeOverage = (
    form: EmployeeOverageFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      bucket_code: form.bucketCode,
      amount: form.amount,
      effectivity_date: formatDate(form.effectivityDate as Date, undefined),
      remarks: form.remarks,
    } as EditEmployeeOverageDto;

    return payload;
  };
  const mapDeleteEmployeeOverage = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeOverageDto;

    return payload;
  };
  const mapCreateFaq = (form: FaqFormSchema) => {
    const payload = {
      title: form.title,
      description: form.description,
      link: form.link,
    } as CreateFaqDto;

    return payload;
  };
  const mapUpdateFaq = (form: FaqFormSchema, code: string) => {
    const payload = {
      code,
      title: form.title,
      description: form.description,
      link: form.link,
    } as EditFaqDto;

    return payload;
  };
  const mapDeleteFaq = (code: string) => {
    const payload = {
      code,
    } as DeleteFaqDto;

    return payload;
  };
  const mapCreateDeviceAuthorization = (
    form: DeviceAuthorizationFormSchema
  ) => {
    const payload = {
      authorize: form.authorize,
    } as CreateDeviceAuthorizationModel;

    return payload;
  };
  const mapCreateFavorite = (form: FavoriteFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateFavoriteDto;

    return payload;
  };
  const mapUpdateFavorite = (form: FavoriteFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as EditFavoriteDto;

    return payload;
  };
  const mapDeleteFavorite = (code: string) => {
    const payload = {
      code,
    } as DeleteFavoriteDto;

    return payload;
  };
  const mapCreateEmployeeFavorite = (form: EmployeeFavoriteFormSchema) => {
    const payload = {
      user_code: form.userCode,
      favorite_code: form.favoriteCode,
    } as CreateEmployeeFavoriteDto;

    return payload;
  };
  const mapDeleteEmployeeFavorite = (code: string) => {
    const payload = {
      code,
    } as DeleteEmployeeFavoriteDto;

    return payload;
  };
  // MAPPER FUNCTION

  return {
    mapLogin,
    mapNewBranch,
    mapEditBranch,
    mapDeleteBranch,
    mapNewEmployee,
    mapCreateHoliday,
    mapUpdateHoliday,
    mapDeleteHoliday,
    mapCreateHolidayType,
    mapUpdateHolidayType,
    mapDeleteHolidayType,
    mapCreateJobTitle,
    mapUpdateJobTitle,
    mapDeleteJobTitle,
    mapCreateJobGrade,
    mapUpdateJobGrade,
    mapDeleteJobGrade,
    mapCreateShiftType,
    mapUpdateShiftType,
    mapDeleteShiftType,
    mapCreateStatus,
    mapUpdateStatus,
    mapDeleteStatus,
    mapCreateShift,
    mapUpdateShift,
    mapDeleteShift,
    mapCreateEmploymentType,
    mapUpdateEmploymentType,
    mapDeleteEmploymentType,
    mapCreateDepartment,
    mapUpdateDepartment,
    mapDeleteDepartment,
    mapCreateRole,
    mapUpdateRole,
    mapDeleteRole,
    mapCreateEmployeeBucket,
    mapUpdateEmployeeBucket,
    mapDeleteEmployeeBucket,
    mapCreatePerson,
    mapUpdatePerson,
    mapUploadAttachment,
    mapDeletePerson,
    mapCreatePersonAddress,
    mapUpdatePersonAddress,
    mapDeletePersonAddress,
    mapCreateEmployeeShift,
    mapUpdateEmployeeShift,
    mapDeleteEmployeeShift,
    mapCreateQuestionnaire,
    mapUpdateQuestionnaire,
    mapDeleteQuestionnaire,
    mapCreateEmployeeScore,
    mapUpdateEmployeeScore,
    mapDeleteEmployeeScore,
    mapCreateDailyTimeRecord,
    mapUpdateDailyTimeRecord,
    mapCreateDailyTimeRecordQr,
    mapCreateLeaveSubcategory,
    mapUpdateLeaveSubcategory,
    mapDeleteLeaveSubcategory,
    mapCreateLeaveCategory,
    mapUpdateLeaveCategory,
    mapDeleteLeaveCategory,
    mapCreateLeaveType,
    mapUpdateLeaveType,
    mapDeleteLeaveType,
    mapCreateRequestType,
    mapUpdateRequestType,
    mapDeleteRequestType,
    mapCreateEmployeeRequest,
    mapUpdateEmployeeRequest,
    mapDeleteEmployeeRequest,
    mapOvertimeEmployeeRequest,
    mapLeaveEmployeeRequest,
    mapDtrEmployeeRequest,
    mapBreakEmployeeRequest,
    mapChangeShiftEmployeeRequest,
    mapCreatePaymentMethod,
    mapUpdatePaymentMethod,
    mapDeletePaymentMethod,
    mapCreateEmployeeIncident,
    mapUpdateEmployeeIncident,
    mapDeleteEmployeeIncident,
    mapCreateIncidentType,
    mapUpdateIncidentType,
    mapDeleteIncidentType,
    mapCreateSalaryType,
    mapUpdateSalaryType,
    mapDeleteSalaryType,
    mapCreateEmployeeSalary,
    mapUpdateEmployeeSalary,
    mapDeleteEmployeeSalary,
    mapCreateEmployeeDeduction,
    mapUpdateEmployeeDeduction,
    mapDeleteEmployeeDeduction,
    mapCreateEmployeePenalty,
    mapUpdateEmployeePenalty,
    mapDeleteEmployeePenalty,
    mapCreateEmployeeTimeline,
    mapUpdateEmployeeTimeline,
    mapDeleteEmployeeTimeline,
    mapCreateEmployeeLedger,
    mapCreateEmployeeAttachment,
    mapUpdateEmployeeAttachment,
    mapDeleteEmployeeAttachment,
    mapCreateEmployeeIncentive,
    mapUpdateEmployeeIncentive,
    mapDeleteEmployeeIncentive,
    mapCreateEmployeeDiciplinary,
    mapUpdateEmployeeDiciplinary,
    mapDeleteEmployeeDiciplinary,
    mapCreateViolation,
    mapUpdateViolation,
    mapDeleteViolation,
    mapCreateInfraction,
    mapUpdateInfraction,
    mapDeleteInfraction,
    mapCreatePenaltySchedule,
    mapUpdatePenaltySchedule,
    mapDeletePenaltySchedule,
    mapCreateAttachmentType,
    mapUpdateAttachmentType,
    mapDeleteAttachmentType,
    mapCreateEmployeeLoan,
    mapUpdateEmployeeLoan,
    mapDeleteEmployeeLoan,
    mapCreateEmployeePayment,
    mapUpdateEmployeePayment,
    mapDeleteEmployeePayment,
    mapCreateEmployeeBranch,
    mapUpdateEmployeeBranch,
    mapDeleteEmployeeBranch,
    mapCreateEmployeeOverage,
    mapUpdateEmployeeOverage,
    mapDeleteEmployeeOverage,
    mapCreateFaq,
    mapUpdateFaq,
    mapDeleteFaq,
    mapCreateDeviceAuthorization,
    mapCreateFavorite,
    mapUpdateFavorite,
    mapDeleteFavorite,
    mapCreateEmployeeFavorite,
    mapDeleteEmployeeFavorite,
    // MAPPER RETURN
  };
}
