import { useState } from "react";
import { CreateJobTitleModel } from "@core/model/query-model-job-title";
import { useQyCreateJobTitle, useQyGetJobTitle } from "@core/query/job-title.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownJobTitle = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newJobTitle, setNewJobTitle] = useState<CreateJobTitleModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewJobTitle({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newJobTitle.name) {
      showWarning("Please fill in JobTitle details");
      return;
    }
    setIsCreating(true);
    addJobTitle(newJobTitle);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New JobTitle is added. Check and select the JobTitle in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addJobTitle } = useQyCreateJobTitle(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: jobTitleResponse } = useQyGetJobTitle('');
  const mappedJobTitle = (jobTitleResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newJobTitle,
    mappedJobTitle,
    isCreating,
    jobTitleResponse,
    setSidebar,
    setFilter,
    setNewJobTitle,
    handleFilterInput,
    handleAdd,
    addJobTitle,
  };
};