import { useNavigate } from "react-router-dom";
import "./new-employee.scss";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { MessageResponseDto } from "@api/api";
import { useQyAddEmployee } from "@core/query/employee.query";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { EmployeeFormRule, EmployeeFormSchema } from "@core/form/form.rule";
import { employeeFormDefault } from "@core/form/form.default";
import { zodResolver } from "@hookform/resolvers/zod";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import HeaderContent from "@shared/ui/header-content/header-content";
import { Button } from "primereact/button";
import FormEmployee from "../form-employee/form-employee";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";

export function NewEmployee() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapNewEmployee } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New employee item is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addEmployee, isLoading: isCreating } =
    useQyAddEmployee(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeFormSchema>({
    defaultValues: employeeFormDefault,
    resolver: zodResolver(EmployeeFormRule),
  });
  const { handleSubmit } = formMethod;
  const handleValidate = (form: EmployeeFormSchema) => {
    const formData = mapNewEmployee(form);
    addEmployee(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="NewEmployee" className="new-employee">
      <HeaderContent title="New Employee" onBack={() => navigate("../")}>
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployee />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployee;
