import { useState } from "react";
import { useQyGetPerson, useQyGetPersonById } from "@core/query/person.query";
import { zodResolver } from "@hookform/resolvers/zod";
import { LabelValue } from "@shared/models/label-value.interface";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { GetResponsePersonModel } from "@core/model/query-model-person";
import { useForm } from "react-hook-form";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useParams } from "react-router-dom";
import { PersonFormRule, PersonFormSchema } from "@core/form/form.rule";
import { useFormDefaultPerson } from "@core/form/form-default-person.hook";
import { useQyGetGoogleCloudAssetPreview } from "@core/query/google-cloud-storage.query";

export const useFormDropdownPerson = () => {
  const { personId } = useParams();
  const { getDefault } = useFormDefaultPerson();
  const { formatDate } = useDateTimeContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [enabledGet, setEnabledGet] = useState(false);
  const [suggestions, setSuggestions] = useState<LabelValue[]>([]);
  const [selectedReportingTo, setSelectedReportingTo] =
    useState<LabelValue | null>(null);

  const [curriculum, setCurriculum] = useState<string>("");
  const [supporting, setSupporting] = useState<string>("");
  const [intent, setIntent] = useState<string>("");
  const [application, setApplication] = useState<string>("");

  const handleSuggestions = (response: GetResponsePersonModel) => {
    const mappedSuggestions = (response?.data || [])
      .map(
        (item) =>
          ({
            label: `${item.last_name} ${item.first_name}`,
            value: item.code,
          } as LabelValue)
      )
      .filter((x) => !!x.label);

    setSuggestions(mappedSuggestions);
  };
  const { data: personListResponse } = useQyGetPerson(
    searchTerm,
    10,
    0,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    enabledGet,
    handleSuggestions
  );

  const { mutate: viewProfilePic } = useQyGetGoogleCloudAssetPreview();
  const { mutate: viewCurriculum } = useQyGetGoogleCloudAssetPreview();
  const { mutate: viewSupporting } = useQyGetGoogleCloudAssetPreview();
  const { mutate: viewIntent } = useQyGetGoogleCloudAssetPreview();
  const { mutate: viewApplication } = useQyGetGoogleCloudAssetPreview();

  // API GETBYID Person
  const handleGetApiSuccess = (data: GetResponsePersonModel) => {
    if (!data || !data.count || data.count <= 0) return;
    const responseData = data.data?.[0];

    if (responseData?.avatar) {
      viewProfilePic(responseData?.avatar, {
        onSuccess(data) {
          const profilePic = URL.createObjectURL(data);
          setValue("picture", profilePic);
        },
      });
    }

    const _curriculum = (responseData as any)?.curriculum_vitae;
    if (_curriculum) {
      viewCurriculum(_curriculum, {
        onSuccess(data) {
          const curriculumUrl = URL.createObjectURL(data);
          console.log({ curriculumUrl });
          setCurriculum(curriculumUrl);
        },
      });
    }

    const _supporting = (responseData as any)?.supporting_doc;
    if (_supporting) {
      viewSupporting(_supporting, {
        onSuccess(data) {
          const supportingUrl = URL.createObjectURL(data);
          setSupporting(supportingUrl);
        },
      });
    }

    const _intent = (responseData as any)?.intent_letter;
    if (_intent) {
      viewIntent(_intent, {
        onSuccess(data) {
          const imageObjectURL = URL.createObjectURL(data);
          setIntent(imageObjectURL);
        },
      });
    }

    const _application = (responseData as any)?.application_letter;
    if (_application) {
      viewApplication(_application, {
        onSuccess(data) {
          const imageObjectURL = URL.createObjectURL(data);
          setApplication(imageObjectURL);
        },
      });
    }

    setValue("avatar", responseData?.avatar || "");
    setValue("firstName", responseData?.first_name || "");
    setValue("middleName", responseData?.middle_name || "");
    setValue("lastName", responseData?.last_name || "");
    setValue("username", responseData?.username || "");
    setValue("email", responseData?.email || "");
    setValue("gender", responseData?.gender || "");
    setValue("birthday", formatDate(responseData?.birthday, undefined) as any);

    setValue("bucket.code", responseData?.bucket?.code || "");
    setValue("bucket.role", responseData?.bucket?.role || "");
    setValue("bucket.userCode", responseData?.bucket?.user_code || "");
    setValue("bucket.employeeNo", responseData?.bucket?.employee_no || "");
    setValue(
      "bucket.signedDate",
      formatDate(responseData?.bucket?.signed_date, undefined) as any
    );
    setValue(
      "bucket.startDate",
      formatDate(responseData?.bucket?.start_date, undefined) as any
    );
    setValue(
      "bucket.endDate",
      formatDate(responseData?.bucket?.end_date, undefined) as any
    );
    setValue("bucket.jobTitle", responseData?.bucket?.job_title || "");
    setValue("bucket.jobGrade", responseData?.bucket?.job_grade || "");
    setValue(
      "bucket.employmentType",
      responseData?.bucket?.employment_type || ""
    );
    setValue("bucket.department", responseData?.bucket?.department || "");
    setValue("bucket.branch", responseData?.bucket?.branch || "");
    setValue("bucket.reportingTo", responseData?.bucket?.reporting_to || "");

    const reportingTo = !!responseData?.bucket?.reporting_to;
    if (!!reportingTo) {
      setSelectedReportingTo({
        label: `${responseData?.bucket.reporting_last_name} ${responseData?.bucket.reporting_first_name}`,
        value: responseData?.bucket?.reporting_to,
      } as LabelValue);
    } else {
      setSelectedReportingTo(null);
    }

    setValue(
      "bucket.offboardReason",
      responseData?.bucket?.offboard_reason || ""
    );
    setValue(
      "bucket.resignationDate",
      formatDate(responseData?.bucket?.resignation_date, undefined) as any
    );

    setValue("address.code", responseData?.address?.code || "");
    setValue("address.userCode", responseData?.address?.user_code || "");
    setValue("address.streetName", responseData?.address?.street_name || "");
    setValue("address.subdivision", responseData?.address?.subdivision || "");
    setValue("address.zone", responseData?.address?.zone || "");
    setValue("address.sitio", responseData?.address?.sitio || "");
    setValue("address.purok", responseData?.address?.purok || "");
    setValue("address.barangay", responseData?.address?.barangay || "");
    setValue("address.municipality", responseData?.address?.municipality || "");
    setValue("address.province", responseData?.address?.province || "");
    setValue("address.region", responseData?.address?.region || "");
    setValue("address.country", responseData?.address?.country || "");
    setValue("address.zip", responseData?.address?.zip || "");
    setValue("address.longitude", responseData?.address?.longitude);
    setValue("address.latitude", responseData?.address?.latitude);
  };
  const {
    data: selectedPersonResponse,
    isLoading,
    isError: personError,
  } = useQyGetPersonById(personId || "", handleGetApiSuccess);
  const selectedPersonData = selectedPersonResponse?.data?.[0];

  const formMethod = useForm<PersonFormSchema>({
    defaultValues: getDefault(selectedPersonData),
    resolver: zodResolver(PersonFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const onSearch = (term: AutoCompleteCompleteEvent) => {
    setSearchTerm(term.query);
    setEnabledGet(true);
  };

  return {
    personListResponse,
    selectedPersonData,
    selectedPersonResponse,
    suggestions,
    selectedReportingTo,
    personId,
    isLoading,
    personError,
    formMethod,
    curriculum,
    supporting,
    intent,
    application,
    onSearch,
    setSelectedReportingTo,
    handleSubmit,
    watch,
    getValues,
  };
};
