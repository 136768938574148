import './form-leave-category.scss';
import { useFormContext } from "react-hook-form";
import { LeaveCategoryFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = LeaveCategoryFormSchema;
export function LeaveCategory() {
  const { control } = useFormContext<LeaveCategoryFormSchema>();
  return (
    <div id="LeaveCategory" className="leave-category">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
    </div>
  );
}

export default LeaveCategory;