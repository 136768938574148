import { 
    MessageResponseDto, 
    GetSalaryTypeDto, 
    CreateSalaryTypeDto, 
    EditSalaryTypeDto,
    UtilSalaryTypeControllerGet200Response,
    UtilitySalaryTypeApiFp,
    DeleteSalaryTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseSalaryTypeModel = UtilSalaryTypeControllerGet200Response;
export type GetSalaryTypeModel = GetSalaryTypeDto;
export type MutateResponseSalaryTypeModel = MessageResponseDto;
export type CreateSalaryTypeModel = CreateSalaryTypeDto;
export type UpdateSalaryTypeModel = EditSalaryTypeDto;
export type DeleteSalaryTypeModel = DeleteSalaryTypeDto;

export const apiSalaryTypeFp =  UtilitySalaryTypeApiFp(apiConfig);
export const getSalaryTypeController = apiSalaryTypeFp.utilSalaryTypeControllerGet;
export const createSalaryTypeController = apiSalaryTypeFp.utilSalaryTypeControllerCreate;
export const updateSalaryTypeController = apiSalaryTypeFp.utilSalaryTypeControllerEdit;
export const deleteSalaryTypeController = apiSalaryTypeFp.utilSalaryTypeControllerDelete;

export const QueryKeySalaryType = QueryKey.SalaryType;