import { Outlet } from "react-router-dom";

import { FilterLeaveCategoryProvider } from "./filter-leave-category/filter-leave-category.context";
import './leave-category.scss';

export function LeaveCategory() {
  return (
    <div id="LeaveCategory" className="leave-category">
       <FilterLeaveCategoryProvider>
        <Outlet />
      </FilterLeaveCategoryProvider>
    </div>
  );
}

export default LeaveCategory;
