import {
  MessageResponseDto,
  DailyTimeRecordApiFp,
  ClockinDto,
  ClockoutDto,
  ShiftScheduleListDto,
  EmployeeDtrControllerEmployeeShift200Response,
  EmployeeShiftScheduleStoredProcEntity,
  DailyTimeRecordDto,
  DtrPublicDto,
  DailyTimeRecordEntity,
  PerBranchShiftScheduleDto,
  GetViewDtrDto,
  EmployeeDtrControllerGetBreak200Response,
} from "@api/api";
import { BASE_PATH } from "@api/base";
import { DUMMY_BASE_URL, setSearchParams, toPathString } from "@api/common";
import { authHeaders } from "@core/query/auth-header";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";
import axios from "axios";

export type GetResponseDailyTimeRecordModel = DailyTimeRecordEntity;
export type GetResponseDailyTimeRecordShiftModel =
  EmployeeDtrControllerEmployeeShift200Response;

export type GetViewDtrModel = GetViewDtrDto;
export type DailyTimeRecordPayloadModel = ShiftScheduleListDto;
export type ShiftResponseModel = EmployeeDtrControllerEmployeeShift200Response;
export type GetResponseShiftDataModel = EmployeeShiftScheduleStoredProcEntity;
export type GetResponseBreakModel = EmployeeDtrControllerGetBreak200Response;

export type MutateResponseDailyTimeRecordModel = MessageResponseDto;
export type CreateDailyTimeRecordModel = ClockinDto;
export type UpdateDailyTimeRecordModel = ClockoutDto;
export type ClockInOutDailyTimeRecordModel = DtrPublicDto;
export type QRDailyTimeRecordModel = DailyTimeRecordDto;
export type DailyTimeRecordBranchShiftModel = PerBranchShiftScheduleDto;

export const apiDailyTimeRecordFp = DailyTimeRecordApiFp(apiConfig);

export const getOnlyDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerGet;
export const getDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerGetEmployeeDailyTimeRecord;
export const getDailyTimeRecordShiftController =
  apiDailyTimeRecordFp.employeeDtrControllerEmployeeShift;
export const getDailyTimeRecordShiftBranchController =
  apiDailyTimeRecordFp.employeeDtrControllerEmployeePerBranchShift;

export const breakClockInDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerClockinBreak;
export const breakClockOutDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerClockoutBreak;
export const clockInDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerProcessPublicClockin;
export const clockOutDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerProcessPublicClockout;

export const privateClockInDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerProcessPrivateClockin;
export const privateClockOutDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerProcessPrivateClockout;
export const privateBreakInDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerPrivateClockinBreak;
export const privateBreakOutDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerPrivateClockoutBreak;

export const createDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerProcessClockin;
export const updateDailyTimeRecordController =
  apiDailyTimeRecordFp.employeeDtrControllerProcessClockout;
export const dailyTimeQrController =
  apiDailyTimeRecordFp.employeeDtrControllerGenerateDtrQR;
export const dailyTimeGetBreaksController =
  apiDailyTimeRecordFp.employeeDtrControllerGetBreak;

export const QueryKeyDailyTimeRecord = QueryKey.DailyTimeRecord;
export const QueryKeyDailyTimeRecordShifts = QueryKey.DailyTimeRecordShifts;
export const QueryKeyDailyTimeRecordBreaks = QueryKey.DailyTimeRecordBreaks;

export const DailyTimeRecordApiOverride = function () {
  const getDtr = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    const localVarPath = `/api/v1/dtr/get`;
    const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
    const localVarQueryParameter = {} as any;
    if (search !== undefined) {
      localVarQueryParameter["search"] = search;
    }

    if (limit !== undefined) {
      localVarQueryParameter["limit"] = limit;
    }

    if (offset !== undefined) {
      localVarQueryParameter["offset"] = offset;
    }

    if (order !== undefined) {
      for (const [key, value] of Object.entries(order)) {
        localVarQueryParameter[key] = value;
      }
    }

    if (filter !== undefined) {
      for (const [key, value] of Object.entries(filter)) {
        localVarQueryParameter[key] = value;
      }
    }

    if (startDate !== undefined) {
      localVarQueryParameter["start_date"] = startDate;
    }

    if (endDate !== undefined) {
      localVarQueryParameter["end_date"] = endDate;
    }

    setSearchParams(localVarUrlObj, localVarQueryParameter);
    const url = toPathString(localVarUrlObj);
    const operation = await axios.get(
      `${apiConfig.basePath}/${url}`,
      authHeaders()
    );
    return operation.data;
  };

  return {
    getDtr,
  };
};
