import { ReactNode, createContext, useContext } from "react";
import { useFilterLeaveSubcategory } from "./filter-leave-subcategory.hook";

const FilterLeaveSubcategoryContext = createContext(
  {} as ReturnType<typeof useFilterLeaveSubcategory>
);
export function useFilterLeaveSubcategoryContext() {
  return useContext(FilterLeaveSubcategoryContext);
}

interface FilterLeaveSubcategoryProviderProps {
  children: ReactNode;
}
export function FilterLeaveSubcategoryProvider({
  children,
}: FilterLeaveSubcategoryProviderProps) {
  return (
    <FilterLeaveSubcategoryContext.Provider value={useFilterLeaveSubcategory()}>
      {children}
    </FilterLeaveSubcategoryContext.Provider>
  );
}