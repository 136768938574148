import { Outlet } from "react-router-dom";

import { FilterEmployeeBucketProvider } from "./filter-employee-bucket/filter-employee-bucket.context";
import "./employee-bucket.scss";
import { FormDropdownRoleProvider } from "@domain/role/form-dropdown-role/form-dropdown-role.context";

export function EmployeeBucket() {
  return (
    <div id="EmployeeBucket" className="employee-bucket">
      <FormDropdownRoleProvider>
        <FilterEmployeeBucketProvider>
          <Outlet />
        </FilterEmployeeBucketProvider>
      </FormDropdownRoleProvider>
    </div>
  );
}

export default EmployeeBucket;
