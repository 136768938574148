import { GetResponseShiftDataModel } from "@core/model/query-model-daily-time-record";
import { ShiftEvent } from "@core/model/shift-event.enum";
import { CalendarEvent } from "../../core/model/calendar-event.interface";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useCalendarEvent() {
  const { convertToPhDate } = useDateTimeContext();
  const calendarIsFullDay = (eventType: string): boolean => {
    return eventType !== ShiftEvent.Workday;
  };

  const calendarMap = (api: GetResponseShiftDataModel) => {
    const allDay = calendarIsFullDay(api.name as string);

    const startPhDate = convertToPhDate(api.utc_shift_start_date);
    const endPhDate = convertToPhDate((api as any)?.utc_shift_end_date);

    const mapped = {
      id: api.code,
      title: `${api.first_name} ${api.last_name}`,
      start: startPhDate?.toISOString(),
      end: endPhDate?.toISOString(),
      backgroundColor: calendarGetShiftStyle(api.name).color,
      borderColor: calendarGetShiftStyle(api.name).color,
      extendedProps: {
        shiftName: api.util_shift_title,
        eventType: api.name,
        branchId: api.branch,
        userId: api.user_code,
        bucketCode: api.bucket_code,
        originalBranchId: (api as any).origin_branch_code,
        originalBranchName: api.origin_branch_name,
      },
      allDay,
    } as CalendarEvent;

    return mapped;
  };

  const calendarGetShiftStyle = (event?: string) => {
    switch (event) {
      case ShiftEvent.Workday:
        return {
          color: "green",
          classColor: "bg-green-600",
          textClass: "text-green-600",
        };
      case ShiftEvent.Restday:
        return {
          color: "purple",
          classColor: "bg-purple-600",
          textClass: "text-purple-600",
        };
      case ShiftEvent.TemporaryWorkday:
        return {
          color: "teal",
          classColor: "bg-teal-600",
          textClass: "text-teal-600",
        };

      default:
        return {
          color: "gray",
          classColor: "bg-gray-600",
          textClass: "text-gray-600",
        };
    }
  };

  return {
    calendarIsFullDay,
    calendarMap,
    calendarGetShiftStyle,
  };
}
