import { GetPersonDto } from "@api/api";
import { LocalAuth } from "@core/model/local-auth";
import { LocalProfile } from "@core/model/local-profile";

export class ApiToFormService {
  static MapLocalAuth(data: any): LocalAuth {
    const mappedData = {
      accessExpiry: data.access_exp,
      accessToken: data.access_token,
      refreshExpiry: data.refresh_exp,
      refreshToken: data.refresh_token,
      userCode: data.user_code,
    } as LocalAuth;

    return mappedData;
  }

  static MapProfile(data: GetPersonDto): LocalProfile {
    const mappedData = {
      code: data.code,
      bucketId: data?.bucket?.code,
      branchId: data?.bucket?.branch,
      name: `${data.last_name} ${data.first_name}`,
      departmentId: data?.bucket?.department,
      departmentName: data?.bucket?.department_name,
      roleId: data?.bucket?.role,
      roleName: data?.bucket?.role_name,
    };

    return mappedData;
  }
}
