import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetLeaveTypeModel } from "@core/model/query-model-leave-type";
import { leaveTypeFormDefault } from "./form.default";
import { LeaveTypeFormSchema } from "./form.rule";

export const useFormDefaultLeaveType = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetLeaveTypeModel | undefined) => {
    return !cachedValue
      ? leaveTypeFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as LeaveTypeFormSchema);
  };

  return {
    getDefault,
  };
};
