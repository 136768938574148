import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetFavoriteModel } from "@core/model/query-model-favorite";
import { favoriteFormDefault } from "./form.default";
import { FavoriteFormSchema } from "./form.rule";

export const useFormDefaultFavorite = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetFavoriteModel | undefined) => {
    return !cachedValue
      ? favoriteFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as FavoriteFormSchema);
  };

  return {
    getDefault,
  };
};
