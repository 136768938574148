import {
  MessageResponseDto,
  GetEmployeeBucketDto,
  CreateEmployeeBucketDto,
  EditEmployeeBucketDto,
  EmployeeBucketControllerGet200Response,
  EmployeeBucketApiFp,
  DeleteEmployeeBucketDto,
  EmployeeBucketControllerBucketWithTemporaryBranch200Response,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeBucketModel =
  | EmployeeBucketControllerGet200Response & Partial<{ score: number }>;
export type GetEmployeeBucketTempResponse =
  EmployeeBucketControllerBucketWithTemporaryBranch200Response;
export type GetEmployeeBucketModel = GetEmployeeBucketDto;
export type MutateResponseEmployeeBucketModel = MessageResponseDto;
export type CreateEmployeeBucketModel = CreateEmployeeBucketDto;
export type UpdateEmployeeBucketModel = EditEmployeeBucketDto;
export type DeleteEmployeeBucketModel = DeleteEmployeeBucketDto;

export const apiEmployeeBucketFp = EmployeeBucketApiFp(apiConfig);
export const getEmployeeBucketController =
  apiEmployeeBucketFp.employeeBucketControllerGet;
export const getEmployeeBucketWithTempController =
  apiEmployeeBucketFp.employeeBucketControllerBucketWithTemporaryBranch;
export const createEmployeeBucketController =
  apiEmployeeBucketFp.employeeBucketControllerCreate;
export const updateEmployeeBucketController =
  apiEmployeeBucketFp.employeeBucketControllerEdit;
export const deleteEmployeeBucketController =
  apiEmployeeBucketFp.employeeBucketControllerDelete;

export const QueryKeyEmployeeBucket = QueryKey.EmployeeBucket;
