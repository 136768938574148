import { 
    MessageResponseDto, 
    GetAttachmentTypeDto, 
    CreateAttachmentTypeDto, 
    EditAttachmentTypeDto,
    UtilAttachmentTypeControllerGet200Response,
    UtilityAttachmentTypeApiFp,
    DeleteAttachmentTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseAttachmentTypeModel = UtilAttachmentTypeControllerGet200Response;
export type GetAttachmentTypeModel = GetAttachmentTypeDto;
export type MutateResponseAttachmentTypeModel = MessageResponseDto;
export type CreateAttachmentTypeModel = CreateAttachmentTypeDto;
export type UpdateAttachmentTypeModel = EditAttachmentTypeDto;
export type DeleteAttachmentTypeModel = DeleteAttachmentTypeDto;

export const apiAttachmentTypeFp =  UtilityAttachmentTypeApiFp(apiConfig);
export const getAttachmentTypeController = apiAttachmentTypeFp.utilAttachmentTypeControllerGet;
export const createAttachmentTypeController = apiAttachmentTypeFp.utilAttachmentTypeControllerCreate;
export const updateAttachmentTypeController = apiAttachmentTypeFp.utilAttachmentTypeControllerEdit;
export const deleteAttachmentTypeController = apiAttachmentTypeFp.utilAttachmentTypeControllerDelete;

export const QueryKeyAttachmentType = QueryKey.AttachmentType;