import "./form-employee-request-leave.scss";
import { EmployeeRequestFormSchema } from "@core/form/form.rule";
import { useFormContext } from "react-hook-form";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import { useQyGetLeaveType } from "@core/query/leave-type.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetLeaveCategory } from "@core/query/leave-category.query";
import { useQyGetLeaveSubcategory } from "@core/query/leave-subcategory.query";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import classNames from "classnames";

type InputFormSchema = EmployeeRequestFormSchema;
export interface FormEmployeeRequestLeaveProps {
  standalone?: boolean;
  isView?: boolean;
}
export function FormEmployeeRequestLeave({
  standalone,
  isView,
}: FormEmployeeRequestLeaveProps) {
  const { control } = useFormContext<EmployeeRequestFormSchema>();

  const { data: leaveTypeResponse } = useQyGetLeaveType("");
  const mappedLeaveType = LabelValue.MapArray(
    leaveTypeResponse?.data || [],
    (x) => x.name,
    (x) => x.code
  );

  const { data: leaveCategoryResponse } = useQyGetLeaveCategory("");
  const mappedLeaveCategory = LabelValue.MapArray(
    leaveCategoryResponse?.data || [],
    (x) => x.name,
    (x) => x.code
  );

  const { data: leaveSubcategoryResponse } = useQyGetLeaveSubcategory("");
  const mappedLeaveSubcategory = LabelValue.MapArray(
    leaveSubcategoryResponse?.data || [],
    (x) => x.name,
    (x) => x.code
  );
  return (
    <div
      id="FormEmployeeRequestLeave"
      className="form-employee-request-leave mt-4"
    >
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
        disabled={isView}
      />
      <section className="grid grid-cols-1 my-4 bg-white">
        <InputDateControl<InputFormSchema>
          control={control}
          name="effectivityDate"
          label="Effectivity Date"
          className={classNames("w-full mb-4", !standalone ? "md:w-1/4" : "")}
          containerClassName="pb-2"
          disabled={isView}
        />
        <DropdownControl<InputFormSchema>
          control={control}
          name="leaveType"
          label="Leave Type"
          options={mappedLeaveType}
          containerClassName="mb-9"
          className={classNames("w-full", !standalone ? "md:w-1/4" : "")}
          placeholder="Enter your leave type"
          filter
          disabled={isView}
        />
        <DropdownControl<InputFormSchema>
          control={control}
          name="leaveCategory"
          label="Leave Category"
          options={mappedLeaveCategory}
          containerClassName="mb-9"
          className={classNames("w-full", !standalone ? "md:w-1/4" : "")}
          placeholder="Enter your leave category"
          filter
          disabled={isView}
        />
        <DropdownControl<InputFormSchema>
          control={control}
          name="leaveSubcategory"
          label="Leave Subcategory"
          options={mappedLeaveSubcategory}
          containerClassName="mb-9"
          className={classNames("w-full", !standalone ? "md:w-1/4" : "")}
          placeholder="Enter your leave sub category"
          filter
          disabled={isView}
        />
        <InputTextareaControl<InputFormSchema>
          control={control}
          name="requestReason"
          label="Request Reason"
          className={classNames("w-full", !standalone ? "md:w-1/4" : "")}
          containerClassName="pb-2"
          placeholder="Enter leave reason"
          disabled={isView}
        />
      </section>
    </div>
  );
}

export default FormEmployeeRequestLeave;
