import { 
    MessageResponseDto, 
    GetInfractionDto, 
    CreateInfractionDto, 
    EditInfractionDto,
    UtilInfractionControllerGet200Response,
    UtilityInfractionApiFp,
    DeleteInfractionDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseInfractionModel = UtilInfractionControllerGet200Response;
export type GetInfractionModel = GetInfractionDto;
export type MutateResponseInfractionModel = MessageResponseDto;
export type CreateInfractionModel = CreateInfractionDto;
export type UpdateInfractionModel = EditInfractionDto;
export type DeleteInfractionModel = DeleteInfractionDto;

export const apiInfractionFp =  UtilityInfractionApiFp(apiConfig);
export const getInfractionController = apiInfractionFp.utilInfractionControllerGet;
export const createInfractionController = apiInfractionFp.utilInfractionControllerCreate;
export const updateInfractionController = apiInfractionFp.utilInfractionControllerEdit;
export const deleteInfractionController = apiInfractionFp.utilInfractionControllerDelete;

export const QueryKeyInfraction = QueryKey.Infraction;