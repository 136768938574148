import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeLoan } from "./filter-employee-loan.hook";

const FilterEmployeeLoanContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeLoan>
);
export function useFilterEmployeeLoanContext() {
  return useContext(FilterEmployeeLoanContext);
}

interface FilterEmployeeLoanProviderProps {
  children: ReactNode;
}
export function FilterEmployeeLoanProvider({
  children,
}: FilterEmployeeLoanProviderProps) {
  return (
    <FilterEmployeeLoanContext.Provider value={useFilterEmployeeLoan()}>
      {children}
    </FilterEmployeeLoanContext.Provider>
  );
}