import {
  MessageResponseDto,
  GetFaqDto,
  CreateFaqDto,
  EditFaqDto,
  UtilFaqControllerGet200Response,
  DeleteFaqDto,
  UtilityFAQApiFp,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseFaqModel = UtilFaqControllerGet200Response;
export type GetFaqModel = GetFaqDto;
export type MutateResponseFaqModel = MessageResponseDto;
export type CreateFaqModel = CreateFaqDto;
export type UpdateFaqModel = EditFaqDto;
export type DeleteFaqModel = DeleteFaqDto;

export const apiFaqFp = UtilityFAQApiFp(apiConfig);
export const getFaqController = apiFaqFp.utilFaqControllerGet;
export const createFaqController = apiFaqFp.utilFaqControllerCreate;
export const updateFaqController = apiFaqFp.utilFaqControllerEdit;
export const deleteFaqController = apiFaqFp.utilFaqControllerDelete;

export const QueryKeyFaq = QueryKey.Faq;
