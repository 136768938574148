import {
  EmployeeBucketFormRule,
  EmployeeBucketFormSchema,
} from "@core/form/form.rule";
import "./government-fee.scss";
import {
  FieldErrors,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import HeaderButton from "@shared/ui/header-button/header-button";
import { ActionEnum } from "@core/model/actions.enum";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useQyGetEmployeeBucketById,
  useQyUpdateEmployeeBucket,
} from "@core/query/employee-bucket.query";
import { useFormDropdownPersonContext } from "../form-dropdown-person/form-dropdown-person.context";
import { GetResponseEmployeeBucketModel } from "@core/model/query-model-employee-bucket";
import { useFormDefaultEmployeeBucket } from "@core/form/form-default-employee-bucket.hook";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

/* eslint-disable-next-line */
export interface GovernmentFeeProps {}

type InputFormSchema = EmployeeBucketFormSchema;
export function GovernmentFee() {
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeBucket();
  const { showError, showSuccess } = useNotificationContext();
  const { mapUpdateEmployeeBucket } = useFormApiMapper();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const branchActions: ButtonAction[] = [
    { label: "Update", type: "primary", value: ActionEnum.Update },
  ];

  // API UPDATE EmployeeShift
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeShift updated successfully");
  };
  const { mutate: updateEmployeeBucket } = useQyUpdateEmployeeBucket(
    handleUpdateApiSuccess
  );

  // QUERY DECLARATION
  // API GETBYID Shift
  const handleGetApiSuccess = (data: GetResponseEmployeeBucketModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("sss", responseData?.sss || 0);
      setValue("pagibig", responseData?.pagibig || 0);
      setValue("philhealth", responseData?.philhealth || 0);
      setValue("philhealth", responseData?.philhealth || 0);
      setValue("code", responseData?.code || "");
      setValue("role", responseData?.role || "");
      setValue("userCode", responseData?.user_code || "");
      setValue("employeeNo", responseData?.employee_no || "");
      setValue(
        "signedDate",
        formatDate(responseData?.signed_date, undefined) as any
      );
      setValue(
        "startDate",
        formatDate(responseData?.start_date, undefined) as any
      );
      setValue("endDate", formatDate(responseData?.end_date, undefined) as any);
      setValue("jobTitle", responseData?.job_title || "");
      setValue("jobGrade", responseData?.job_grade || "");
      setValue("employmentType", responseData?.employment_type || "");
      setValue("department", responseData?.department || "");
      setValue("branch", responseData?.branch || "");
      setValue("reportingTo", responseData?.reporting_to || "");
    }
  };
  const {
    data: bucketResponse,
    isLoading,
    isError: shiftError,
  } = useQyGetEmployeeBucketById(
    selectedPersonData?.bucket.code || "",
    handleGetApiSuccess
  );
  const bucketData = bucketResponse?.data?.[0];

  const formMethod = useForm<InputFormSchema>({
    defaultValues: getDefault(bucketData),
    resolver: zodResolver(EmployeeBucketFormRule),
  });
  const { handleSubmit, setValue, watch, getValues, control } = formMethod;
  const handleValidate = (form: InputFormSchema) => {
    if (!bucketData) {
      throw new Error("No shift data");
    }

    const formData = mapUpdateEmployeeBucket(form, bucketData.code);
    updateEmployeeBucket({
      ...formData,
      sss: form.sss,
      philhealth: form.philhealth,
      pagibig: form.pagibig,
    });
  };
  const handleValidateError = (err: FieldErrors<InputFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
    }
  };

  return (
    <div id="GovernmentFee" className="government-fee">
      <span className="flex justify-end">
        <HeaderButton actions={branchActions} onAction={handleAction} />
      </span>

      <h4>Government Fees Contribution</h4>
      <InputDigitControl<InputFormSchema>
        control={control}
        name="sss"
        label="SSS Contribution"
        className="w-full md:w-1/4"
        containerClassName="pb-2"
        placeholder="Enter employee sss contribution"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="philhealth"
        label="Philhealth Contribution"
        className="w-full md:w-1/4"
        containerClassName="pb-2"
        placeholder="Enter employee philhealth contribution"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="pagibig"
        label="Pagibig Contribution"
        className="w-full md:w-1/4"
        containerClassName="pb-2"
        placeholder="Enter employee Pagibig contribution"
      />
    </div>
  );
}

export default GovernmentFee;
