export enum TableElement {
    Date = 'date',
    Text = 'text',
    Tag = 'tag',
    DateTime = 'datetime',
    Number = 'number',
    Name = 'name',
    ShiftDetail = 'shiftdetail',
    YesNo = 'yesno',
    Log = 'log'
}