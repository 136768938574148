import { 
    MessageResponseDto, 
    GetEmployeePaymentDto, 
    CreateEmployeePaymentDto, 
    EditEmployeePaymentDto,
    EmployeePaymentControllerGet200Response,
    EmployeePaymentApiFp,
    DeleteEmployeePaymentDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeePaymentModel = EmployeePaymentControllerGet200Response;
export type GetEmployeePaymentModel = GetEmployeePaymentDto;
export type MutateResponseEmployeePaymentModel = MessageResponseDto;
export type CreateEmployeePaymentModel = CreateEmployeePaymentDto;
export type UpdateEmployeePaymentModel = EditEmployeePaymentDto;
export type DeleteEmployeePaymentModel = DeleteEmployeePaymentDto;

export const apiEmployeePaymentFp = EmployeePaymentApiFp(apiConfig);
export const getEmployeePaymentController = apiEmployeePaymentFp.employeePaymentControllerGet;
export const createEmployeePaymentController = apiEmployeePaymentFp.employeePaymentControllerCreate;
export const updateEmployeePaymentController = apiEmployeePaymentFp.employeePaymentControllerEdit;
export const deleteEmployeePaymentController = apiEmployeePaymentFp.employeePaymentControllerDelete;

export const QueryKeyEmployeePayment = QueryKey.EmployeePayment;