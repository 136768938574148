import { useState } from "react";
import { CreateBranchModel } from "@core/model/query-model-branch";
import { useQyCreateBranch, useQyGetBranch } from "@core/query/branch.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownBranch = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newBranch, setNewBranch] = useState<CreateBranchModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewBranch({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newBranch.name) {
      showWarning("Please fill in Branch details");
      return;
    }
    setIsCreating(true);
    addBranch(newBranch);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New Branch is added. Check and select the Branch in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addBranch } = useQyCreateBranch(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: branchResponse } = useQyGetBranch("");
  const mappedBranch = (branchResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newBranch,
    mappedBranch,
    isCreating,
    branchResponse,
    setSidebar,
    setFilter,
    setNewBranch,
    handleFilterInput,
    handleAdd,
    addBranch,
  };
};
