import { useState } from "react";
import { CreateHolidayTypeModel } from "@core/model/query-model-holiday-type";
import { useQyCreateHolidayType, useQyGetHolidayType } from "@core/query/holiday-type.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownHolidayType = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newHolidayType, setNewHolidayType] = useState<CreateHolidayTypeModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewHolidayType({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newHolidayType.name) {
      showWarning("Please fill in HolidayType details");
      return;
    }
    setIsCreating(true);
    addHolidayType(newHolidayType);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New HolidayType is added. Check and select the HolidayType in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addHolidayType } = useQyCreateHolidayType(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: holidayTypeResponse } = useQyGetHolidayType('');
  const mappedHolidayType = (holidayTypeResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newHolidayType,
    mappedHolidayType,
    isCreating,
    holidayTypeResponse,
    setSidebar,
    setFilter,
    setNewHolidayType,
    handleFilterInput,
    handleAdd,
    addHolidayType,
  };
};