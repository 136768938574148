import { Outlet } from "react-router-dom";

import { FilterFaqProvider } from "./filter-faq/filter-faq.context";
import './faq.scss';

export function Faq() {
  return (
    <div id="Faq" className="faq">
       <FilterFaqProvider>
        <Outlet />
      </FilterFaqProvider>
    </div>
  );
}

export default Faq;
