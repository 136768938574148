import { 
    MessageResponseDto, 
    GetQuestionnaireDto, 
    CreateQuestionnaireDto, 
    EditQuestionnaireDto,
    UtilQuestionnaireControllerGet200Response,
    UtilityQuestionnaireApiFp,
    DeleteQuestionnaireDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseQuestionnaireModel = UtilQuestionnaireControllerGet200Response;
export type GetQuestionnaireModel = GetQuestionnaireDto;
export type MutateResponseQuestionnaireModel = MessageResponseDto;
export type CreateQuestionnaireModel = CreateQuestionnaireDto;
export type UpdateQuestionnaireModel = EditQuestionnaireDto;
export type DeleteQuestionnaireModel = DeleteQuestionnaireDto;

export const apiQuestionnaireFp =  UtilityQuestionnaireApiFp(apiConfig);
export const getQuestionnaireController = apiQuestionnaireFp.utilQuestionnaireControllerGet;
export const createQuestionnaireController = apiQuestionnaireFp.utilQuestionnaireControllerCreate;
export const updateQuestionnaireController = apiQuestionnaireFp.utilQuestionnaireControllerEdit;
export const deleteQuestionnaireController = apiQuestionnaireFp.utilQuestionnaireControllerDelete;

export const QueryKeyQuestionnaire = QueryKey.Questionnaire;