import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeAttachmentFormSchema,
  EmployeePenaltyFormRule,
  EmployeePenaltyFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeePenalty } from "@core/form/form-default-employee-penalty.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeePenalty,
  useQyGetEmployeePenaltyById,
  useQyDeleteEmployeePenalty,
} from "@core/query/employee-penalty.query";
import { GetResponseEmployeePenaltyModel } from "@core/model/query-model-employee-penalty";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useQyUpdateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { useQyGetGoogleCloudAssetPreview } from "@core/query/google-cloud-storage.query";
import { useState } from "react";

export function useEditEmployeePenalty() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeePenalty();
  const navigate = useNavigate();
  const {
    mapUpdateEmployeePenalty,
    mapDeleteEmployeePenalty,
    mapUpdateEmployeeAttachment,
  } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeePenaltyId } = useParams();
  const [attachmentInfo, setAttachmentInfo] =
    useState<EmployeeAttachmentFormSchema | null>(null);
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION

  // API UPDATE ATTACHMENT
  const { mutate: updateEmployeeAttachment } = useQyUpdateEmployeeAttachment();

  // API VIEW ASSET
  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API GETBYID EmployeePenalty
  const handleGetApiSuccess = (data: GetResponseEmployeePenaltyModel) => {
    if (!data || data.count === 0) {
      return;
    }

    const responseData = data.data?.[0];
    setValue("userCode", responseData?.user_code || "");
    setValue("bucketCode", responseData?.bucket_code || "");
    setValue("amount", responseData?.amount);
    setValue(
      "effectivityDate",
      formatDate(responseData?.effectivity_date) as any,
      undefined
    );
    setValue("remarks", responseData?.remarks || "");

    const attachment = (responseData as any)?.attachments as string;
    const attachmentJson = JSON.parse(attachment);
    if (attachmentJson?.length > 0 && attachmentJson?.[0]?.file) {
      const attachmentFirst = attachmentJson[0];
      const attachmentInfoForm = {
        attachmentType: attachmentFirst.attachment_type,
        bucketCode: attachmentFirst.bucket_code,
        userCode: attachmentFirst.user_code,
        requestCode: attachmentFirst.request_code,
        source: attachmentFirst.source,
        code: attachmentFirst.code,
      } as EmployeeAttachmentFormSchema;
      setAttachmentInfo(attachmentInfoForm);

      viewAsset(attachmentFirst.file, {
        onSuccess(data) {
          const asset = URL.createObjectURL(data);
          setValue("file", asset);
        },
      });
    }
  };
  const {
    data: employeePenaltyResponse,
    isLoading,
    isError: employeePenaltyError,
  } = useQyGetEmployeePenaltyById(employeePenaltyId || "", handleGetApiSuccess);
  const employeePenaltyData = employeePenaltyResponse?.data?.[0];

  // API UPDATE EmployeePenalty
  const handleUpdateApiSuccess = () => {
    if (typeof fileInput !== "string") {
      const formData = mapUpdateEmployeeAttachment(
        { ...attachmentInfo, file: fileInput } as EmployeeAttachmentFormSchema,
        attachmentInfo?.code || ""
      );
      updateEmployeeAttachment(formData);
    }

    showSuccess("Employee Penalty updated successfully");
  };
  const { mutate: updateEmployeePenalty, isLoading: isEditLoading } =
    useQyUpdateEmployeePenalty(handleUpdateApiSuccess);

  // API DELETE EmployeePenalty
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Employee Penalty deleted successfully");
  };
  const { mutate: deleteEmployeePenalty } = useQyDeleteEmployeePenalty(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeePenaltyFormSchema>({
    defaultValues: getDefault(employeePenaltyData),
    resolver: zodResolver(EmployeePenaltyFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const fileInput = watch("file");
  const handleValidate = (form: EmployeePenaltyFormSchema) => {
    if (!employeePenaltyData) {
      throw new Error("No employeePenalty data");
    }

    const formData = mapUpdateEmployeePenalty(form, employeePenaltyData.code);
    updateEmployeePenalty(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeePenaltyFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeePenaltyData) {
      throw new Error("No employeePenalty data found");
    }

    const form = mapDeleteEmployeePenalty(employeePenaltyData.code);
    deleteEmployeePenalty(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeePenaltyId,
    employeePenaltyData,
    isLoading,
    employeePenaltyError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
