import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeePaymentFormRule,
  EmployeePaymentFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeePayment } from "@core/form/form-default-employee-payment.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeePayment,
  useQyGetEmployeePaymentById,
  useQyDeleteEmployeePayment,
} from "@core/query/employee-payment.query";
import { GetResponseEmployeePaymentModel } from "@core/model/query-model-employee-payment";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeePayment() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeePayment();
  const navigate = useNavigate();
  const { mapUpdateEmployeePayment, mapDeleteEmployeePayment } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeePaymentId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeePayment
  const handleGetApiSuccess = (data: GetResponseEmployeePaymentModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("loan", responseData?.loan_code || "");
      setValue("amount", responseData?.amount);
      setValue(
        "effectivityDate",
        formatDate(responseData?.effectivity_date) as any,
        undefined
      );
      setValue("remarks", responseData?.remarks || "");
    }
  };
  const {
    data: employeePaymentResponse,
    isLoading,
    isError: employeePaymentError,
  } = useQyGetEmployeePaymentById(employeePaymentId || "", handleGetApiSuccess);
  const employeePaymentData = employeePaymentResponse?.data?.[0];

  // API UPDATE EmployeePayment
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeePayment updated successfully");
  };
  const { mutate: updateEmployeePayment, isLoading: isEditLoading } =
    useQyUpdateEmployeePayment(handleUpdateApiSuccess);

  // API DELETE EmployeePayment
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeePayment deleted successfully");
  };
  const { mutate: deleteEmployeePayment } = useQyDeleteEmployeePayment(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeePaymentFormSchema>({
    defaultValues: getDefault(employeePaymentData),
    resolver: zodResolver(EmployeePaymentFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeePaymentFormSchema) => {
    if (!employeePaymentData) {
      throw new Error("No employeePayment data");
    }

    const formData = mapUpdateEmployeePayment(form, employeePaymentData.code);
    updateEmployeePayment(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeePaymentFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeePaymentData) {
      throw new Error("No employeePayment data found");
    }

    const form = mapDeleteEmployeePayment(employeePaymentData.code);
    deleteEmployeePayment(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeePaymentId,
    employeePaymentData,
    isLoading,
    employeePaymentError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
