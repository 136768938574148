import { useState } from "react";
import { CreateEmploymentTypeModel } from "@core/model/query-model-employment-type";
import { useQyCreateEmploymentType, useQyGetEmploymentType } from "@core/query/employment-type.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownEmploymentType = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newEmploymentType, setNewEmploymentType] = useState<CreateEmploymentTypeModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewEmploymentType({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newEmploymentType.name) {
      showWarning("Please fill in EmploymentType details");
      return;
    }
    setIsCreating(true);
    addEmploymentType(newEmploymentType);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New EmploymentType is added. Check and select the EmploymentType in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addEmploymentType } = useQyCreateEmploymentType(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: employmentTypeResponse } = useQyGetEmploymentType('');
  const mappedEmploymentType = (employmentTypeResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newEmploymentType,
    mappedEmploymentType,
    isCreating,
    employmentTypeResponse,
    setSidebar,
    setFilter,
    setNewEmploymentType,
    handleFilterInput,
    handleAdd,
    addEmploymentType,
  };
};