import "./edit-employee-payment.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormEmployeePayment from "../form-employee-payment/form-employee-payment";
import { useEditEmployeePayment } from "./edit-employee-payment.hook";

export function EditEmployeePayment() {
  const {
    employeePaymentData,
    isLoading,
    employeePaymentError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditEmployeePayment();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormEmployeePayment />
      </section>
    </div>
  );

  return (
    <div id="EmployeePayment" className="employee-payment">
      <HeaderContent
        title={"Edit Employee Payment"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {employeePaymentError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditEmployeePayment;
