import "./form-questionnaire.scss";
import { useFormContext } from "react-hook-form";
import { QuestionnaireFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import FormDropdownJobTitle from "@domain/job-title/form-dropdown-job-title/form-dropdown-job-title";

type InputFormSchema = QuestionnaireFormSchema;
export function Questionnaire() {
  const { control } = useFormContext<QuestionnaireFormSchema>();
  return (
    <div id="Questionnaire" className="questionnaire">
      <FormDropdownJobTitle<InputFormSchema> name="jobTitle" />
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
    </div>
  );
}

export default Questionnaire;
