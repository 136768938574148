import { Outlet } from "react-router-dom";

import { FilterPersonAddressProvider } from "./filter-person-address/filter-person-address.context";
import './person-address.scss';

export function PersonAddress() {
  return (
    <div id="PersonAddress" className="person-address">
       <FilterPersonAddressProvider>
        <Outlet />
      </FilterPersonAddressProvider>
    </div>
  );
}

export default PersonAddress;
