import { Outlet } from "react-router-dom";

import { FilterPenaltyScheduleProvider } from "./filter-penalty-schedule/filter-penalty-schedule.context";
import './penalty-schedule.scss';

export function PenaltySchedule() {
  return (
    <div id="PenaltySchedule" className="penalty-schedule">
       <FilterPenaltyScheduleProvider>
        <Outlet />
      </FilterPenaltyScheduleProvider>
    </div>
  );
}

export default PenaltySchedule;
