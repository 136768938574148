import { Outlet } from "react-router-dom";

import { FilterInfractionProvider } from "./filter-infraction/filter-infraction.context";
import './infraction.scss';

export function Infraction() {
  return (
    <div id="Infraction" className="infraction">
       <FilterInfractionProvider>
        <Outlet />
      </FilterInfractionProvider>
    </div>
  );
}

export default Infraction;
