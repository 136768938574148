import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import { useSessionContext } from "./session.context";
import {
  CreateEmployeeBranchModel,
  DeleteEmployeeBranchModel,
  GetResponseEmployeeBranchModel,
  MutateResponseEmployeeBranchModel,
  QueryKeyEmployeeBranch,
  UpdateEmployeeBranchModel,
  deleteEmployeeBranchController,
  createEmployeeBranchController,
  getEmployeeBranchController,
  updateEmployeeBranchController,
  CreateEmployeeBranchArrayModel,
} from "@core/model/query-model-employee-branch";

export function useQyGetEmployeeBranch(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  dateFilter?: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponseEmployeeBranchModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    dateFilter: string | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    await checkSession();

    showProgress();
    const operation = await getEmployeeBranchController(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      dateFilter,
      startDate,
      endDate,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["data"] as GetResponseEmployeeBranchModel;
  };

  return useQuery({
    enabled,
    queryKey: [
      QueryKeyEmployeeBranch,
      search,
      limit,
      offset,
      order,
      filter,
      dateFilter,
      startDate,
      endDate,
    ],
    queryFn: () =>
      apiFn(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate
      ),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetEmployeeBranchById(
  id: string,
  onSuccess?:
    | ((data: GetResponseEmployeeBranchModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (id: string, search = "", limit = 1, offset = 0) => {
    await checkSession();

    showProgress();
    const operation = await getEmployeeBranchController(
      search,
      limit,
      offset,
      undefined,
      { filter: JSON.stringify({ code: id }) },
      undefined,
      undefined,
      undefined,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["data"] as GetResponseEmployeeBranchModel;
  };

  return useQuery({
    queryKey: [QueryKeyEmployeeBranch, id],
    queryFn: () => apiFn(id),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyCreateEmployeeBranch(
  onSuccess?:
    | ((data: MutateResponseEmployeeBranchModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreateEmployeeBranchArrayModel) => {
    await checkSession();

    showProgress();
    const operation = await createEmployeeBranchController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseEmployeeBranchModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeBranch);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdateEmployeeBranch(
  onSuccess?:
    | ((data: MutateResponseEmployeeBranchModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdateEmployeeBranchModel) => {
    await checkSession();

    showProgress();
    const operation = await updateEmployeeBranchController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseEmployeeBranchModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeBranch);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyDeleteEmployeeBranch(
  onSuccess?:
    | ((data: MutateResponseEmployeeBranchModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DeleteEmployeeBranchModel) => {
    await checkSession();

    showProgress();
    const operation = await deleteEmployeeBranchController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseEmployeeBranchModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeBranch);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
