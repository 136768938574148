import { useQyGetEmployeeLoan } from "@core/query/employee-loan.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { currencyFormat } from "@shared/utility/formats/currency-format";

export interface FormDropdownEmployeeLoanProps {
  userCode?: string;
}
export const useFormDropdownEmployeeLoan = ({
  userCode,
}: FormDropdownEmployeeLoanProps) => {
  const { formatDate } = useDateTimeContext();

  const filter = userCode
    ? {
        user_code: userCode,
      }
    : undefined;
  const { data: employeeLoanResponse } = useQyGetEmployeeLoan(
    "",
    99999,
    0,
    undefined,
    filter
  );
  const mappedEmployeeLoan = (employeeLoanResponse?.data || []).map(
    (item) =>
      ({
        label: `${formatDate(item.effectivity_date)}: ${currencyFormat(
          item.amount,
          "PHP"
        )}`,
        value: item.code,
      } as LabelValue)
  );

  return { mappedEmployeeLoan, employeeLoanResponse };
};
