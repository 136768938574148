import { 
    MessageResponseDto, 
    GetRequestTypeDto, 
    CreateRequestTypeDto, 
    EditRequestTypeDto,
    UtilRequestTypeControllerGet200Response,
    UtilityRequestTypeApiFp,
    DeleteRequestTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseRequestTypeModel = UtilRequestTypeControllerGet200Response;
export type GetRequestTypeModel = GetRequestTypeDto;
export type MutateResponseRequestTypeModel = MessageResponseDto;
export type CreateRequestTypeModel = CreateRequestTypeDto;
export type UpdateRequestTypeModel = EditRequestTypeDto;
export type DeleteRequestTypeModel = DeleteRequestTypeDto;

export const apiRequestTypeFp =  UtilityRequestTypeApiFp(apiConfig);
export const getRequestTypeController = apiRequestTypeFp.utilRequestTypeControllerGet;
export const createRequestTypeController = apiRequestTypeFp.utilRequestTypeControllerCreate;
export const updateRequestTypeController = apiRequestTypeFp.utilRequestTypeControllerEdit;
export const deleteRequestTypeController = apiRequestTypeFp.utilRequestTypeControllerDelete;

export const QueryKeyRequestType = QueryKey.RequestType;