import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeBucket } from "./filter-employee-bucket.hook";

const FilterEmployeeBucketContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeBucket>
);
export function useFilterEmployeeBucketContext() {
  return useContext(FilterEmployeeBucketContext);
}

interface FilterEmployeeBucketProviderProps {
  children: ReactNode;
}
export function FilterEmployeeBucketProvider({
  children,
}: FilterEmployeeBucketProviderProps) {
  return (
    <FilterEmployeeBucketContext.Provider value={useFilterEmployeeBucket()}>
      {children}
    </FilterEmployeeBucketContext.Provider>
  );
}