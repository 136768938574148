import "./list-payroll.scss";

import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGeneratePayroll } from "@core/query/payroll.query";
import {
  GetPayrollModel,
  GetResponsePayrollModel,
} from "@core/model/query-model-payroll";
import { TableSort } from "@core/model/table-sort";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { Dropdown } from "primereact/dropdown";
import { useFormDropdownBranchContext } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import { InputText } from "primereact/inputtext";
import { useListPayrollTable } from "../form-dropdown-payroll/list-payroll-table";
import { useUserIdentityContext } from "@core/services/user-identity.context";
import { PayrollControllerGet200Response } from "@api/api";
import { useFormDropdownPayrollContext } from "../form-dropdown-payroll/form-dropdown-payroll.context";
import { downloadCsv } from "@shared/utility/download-csv";
import { CalendarDate } from "@core/model/calendar-event.interface";
import { groupBy } from "lodash-es";
import { useFormDropdownEmploymentType } from "@domain/employment-type/form-dropdown-employment-type/form-dropdown-employment-type.hook";
import PrintPayslip from "../print-payslip/print-payslip";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import config from "../../../../config.json";

export function Payroll() {
  // THIS IS THE LOCAL DECLARATION
  const {
    payrollExport,
    selectedPayslip,
    payslipRef,
    calculateSummation,
    setPayrollExport,
  } = useFormDropdownPayrollContext();
  const { formatDate, formatDateTime } = useDateTimeContext();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const { mappedBranch } = useFormDropdownBranchContext();
  const { mappedEmploymentType } = useFormDropdownEmploymentType();
  const [calendarDate, setCalendarDate] = useState<CalendarDate>({
    start: formatDate(new Date()),
    end: formatDate(new Date()),
  });
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("");
  const [expandedRowsGrid, setExpandedRowsGrid] = useState<GetPayrollModel[]>(
    []
  );
  const { showWarning } = useNotificationContext();
  const { userIsAdmin, getUser } = useUserIdentityContext();

  const checkDefaultBranch = useCallback(() => {
    if (!userIsAdmin()) {
      return getUser().branchId;
    }

    return "";
  }, [userIsAdmin, getUser]);
  const [selectedBranch, setSelectedBranch] = useState(checkDefaultBranch());
  const [payrollResponse, setPayrollResponse] =
    useState<PayrollControllerGet200Response | null>(null);

  // THIS IS THE QUERY DECLARATION
  const apiSuccess = (payrollResponse: GetResponsePayrollModel) => {
    const payroll = payrollResponse?.data;
    setPayrollResponse(payrollResponse);

    const groupedPayroll = groupBy(payroll, (item) => item.user_code);
    const summary = Object.keys(groupedPayroll).map((key) => {
      const payrollField = groupedPayroll[key];
      const payrollSummary = calculateSummation(payrollField);

      return payrollSummary;
    });

    setPayrollExport(summary);
  };
  const {
    mutate: generate,
    isLoading,
    isError,
    error,
  } = useQyGeneratePayroll(apiSuccess);

  const {
    headerTemplate,
    footerTemplate,
    shiftColumn,
    clockColumn,
    deductionColumn,
    timeColumn,
    breakColumn,
  } = useListPayrollTable(payrollResponse?.data || []);

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetPayrollModel) => {};
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleGenerate = () => {
    if (!calendarDate.start || !calendarDate.end || !selectedBranch) {
      showWarning("Please fill Start, End and branch");
      return;
    }

    generate({
      dateFrom: calendarDate.start,
      dateTo: calendarDate.end,
      branchCode: selectedBranch,
      employmentType: selectedEmploymentType,
    });
  };
  const handleExport = () => {
    if (payrollExport?.length === 0) {
      showWarning(
        "No payroll generated for exporting. Please generate a payroll report first."
      );
      return;
    }

    const headers = [
      "Position,Name,Basic Rate,No. of Days,Total,OT (Hours),OT (Minutes),OT Rate,OT Pay,Holiday,Incentives,Total,Deductions,Government Fees,Net Deduction,Net Salary,Signature",
    ];
    const rows = payrollExport.map((item) => {
      const otHrs = item.otMinutes / 60;
      const total = item.dailySalary * item.workDays;
      const otRate = item.dailySalary / 8;
      const grossDeduction =
        item.grossDeduction + (item.totalOtherPaymentDeduction || 0);
      const initialNetSalary =
        item.grossSalary + item.bonusHoliday + item.incentives + item.otPay;

      return [
        `"${item.position}"`,
        `"${item.name}"`,
        `"${item.dailySalary}"`,
        `"${item.workDays}"`,
        `"${total}"`,
        `"${otHrs}"`,
        `"${item.otMinutes}"`,
        `"${otRate}"`,
        `"${item.otPay}"`,
        `"${item.bonusHoliday}"`,
        `"${item.incentives}"`,
        `"${initialNetSalary}"`,

        `"${grossDeduction}"`,
        `"${item.totalGovernmentDeduction}"`,
        `"${item.netDeduction}"`,
        `"${item.netSalary}"`,
        "",
      ];
    });
    downloadCsv(
      headers,
      rows,
      `${config.company}-payroll-${formatDateTime(new Date())}`
    );
  };

  // THIS IS THE LOCAL RENDERS
  const branchElement = (
    <div className="flex flex-col">
      <label className="text-xs text-gray-500" htmlFor="start">
        Branch
      </label>
      <Dropdown
        value={selectedBranch}
        onChange={(e) => setSelectedBranch(e.value)}
        options={mappedBranch}
        optionLabel="label"
        placeholder="Select a branch"
        className="w-full md:w-14rem"
        filter
      />
    </div>
  );
  const dateElement = (
    <div className="flex gap-4 items-center">
      <div className="flex flex-col">
        <label className="text-xs text-gray-500" htmlFor="start">
          Start
        </label>
        <InputText
          type="date"
          id="start"
          value={calendarDate.start}
          onChange={(e) => {
            setCalendarDate({
              ...calendarDate,
              start: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-col">
        <label className="text-xs text-gray-500" htmlFor="end">
          End
        </label>
        <InputText
          type="date"
          id="end"
          value={calendarDate.end}
          onChange={(e) => {
            setCalendarDate({
              ...calendarDate,
              end: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const employmentElement = (
    <div className="flex flex-col">
      <label className="text-xs text-gray-500" htmlFor="start">
        Employment Type
      </label>
      <Dropdown
        value={selectedEmploymentType}
        onChange={(e) => setSelectedEmploymentType(e.value)}
        options={mappedEmploymentType}
        optionLabel="label"
        placeholder="Select employment type"
        className="w-full md:w-14rem"
        filter
      />
    </div>
  );
  const filterElement = (
    <section className="flex gap-4 items-center justify-between mb-2">
      <section className="flex gap-4 items-center">
        {userIsAdmin() ? <span>{branchElement}</span> : null}
        <span>{dateElement}</span>
        <span>{employmentElement}</span>
      </section>

      <div className="flex gap-2">
        <Button
          label="Generate"
          onClick={handleGenerate}
          disabled={isLoading}
        />
        <Button
          label="Export"
          severity="secondary"
          onClick={handleExport}
          disabled={isLoading}
          outlined
        />
      </div>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{payrollResponse?.count}</b>
      </h5>

      <DataTable
        value={payrollResponse?.data}
        rowGroupMode="subheader"
        groupRowsBy="user_code"
        sortMode="single"
        sortField="user_code"
        sortOrder={1}
        expandableRowGroups
        expandedRows={expandedRowsGrid}
        onRowToggle={(e) => setExpandedRowsGrid(e.data as any)}
        rowGroupHeaderTemplate={headerTemplate}
        rowGroupFooterTemplate={footerTemplate}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column body={shiftColumn} header="Shift"></Column>
        <Column
          body={(cell) => clockColumn(cell.clockin, cell.clockin_status)}
          header="Clock In"
        ></Column>
        <Column
          body={(cell) => clockColumn(cell.clockout, cell.clockout_status)}
          header="Clock Out"
        ></Column>
        <Column
          body={(cell) => breakColumn(cell.break)}
          header="Breaks"
        ></Column>
        <Column body={timeColumn} header="Time (Minutes)"></Column>
        <Column body={deductionColumn} header="Deductions"></Column>
      </DataTable>

      {/* <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={payrollResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      /> */}
    </section>
  );
  const printPayslip = (
    <div style={{ display: "none" }}>
      <div ref={payslipRef}>
        {selectedPayslip && (
          <PrintPayslip summary={selectedPayslip} period={calendarDate} />
        )}
      </div>
    </div>
  );

  return (
    <div id="Payroll" className="payroll">
      <HeaderContent title="Payroll"></HeaderContent>

      <div className="p-7">
        {printPayslip}
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default Payroll;
