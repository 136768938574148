import { ReactNode, createContext, useContext } from "react";
import { useFilterLeaveCategory } from "./filter-leave-category.hook";

const FilterLeaveCategoryContext = createContext(
  {} as ReturnType<typeof useFilterLeaveCategory>
);
export function useFilterLeaveCategoryContext() {
  return useContext(FilterLeaveCategoryContext);
}

interface FilterLeaveCategoryProviderProps {
  children: ReactNode;
}
export function FilterLeaveCategoryProvider({
  children,
}: FilterLeaveCategoryProviderProps) {
  return (
    <FilterLeaveCategoryContext.Provider value={useFilterLeaveCategory()}>
      {children}
    </FilterLeaveCategoryContext.Provider>
  );
}