import {
  MessageResponseDto,
  GetEmployeeAttachmentDto,
  EmployeeAttachmentControllerGet200Response,
  EmployeeAttachmentApiFp,
  DeleteEmployeeAttachmentDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeAttachmentModel =
  EmployeeAttachmentControllerGet200Response;
export type GetEmployeeAttachmentModel = GetEmployeeAttachmentDto &
  Partial<{ fileBlob: any }>;
export type MutateResponseEmployeeAttachmentModel = MessageResponseDto;
export type DeleteEmployeeAttachmentModel = DeleteEmployeeAttachmentDto;
export interface CreateEmployeeAttachmentModel {
  user_code: string;
  bucket_code: string;
  is_active?: boolean;
  source?: string;
  attachment_type?: string;
  request_code?: string;
  file?: File;
  mime_type?: string;
}
export interface UpdateEmployeeAttachmentModel {
  userCode: string;
  bucketCode: string;
  isActive?: boolean;
  source?: string;
  attachmentType?: string;
  requestCode?: string;
  file?: File;
  mimeType?: string;
  code?: string;
}
export enum AttachmentSource {
  Employee = "EMPLOYEE",
  Incentive = "INCENTIVE",
  Incident = "INCIDENT",
  Penalty = "PENALTY",
  Overage = "OVERAGE",
  Loan = "LOAN",
}

export const apiEmployeeAttachmentFp = EmployeeAttachmentApiFp(apiConfig);
export const getEmployeeAttachmentController =
  apiEmployeeAttachmentFp.employeeAttachmentControllerGet;
export const createEmployeeAttachmentController =
  apiEmployeeAttachmentFp.employeeAttachmentControllerCreate;
export const updateEmployeeAttachmentController =
  apiEmployeeAttachmentFp.employeeAttachmentControllerEdit;
export const deleteEmployeeAttachmentController =
  apiEmployeeAttachmentFp.employeeAttachmentControllerDelete;

export const QueryKeyEmployeeAttachment = QueryKey.EmployeeAttachment;
