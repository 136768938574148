import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetLeaveSubcategoryModel } from "@core/model/query-model-leave-subcategory";
import { leaveSubcategoryFormDefault } from "./form.default";
import { LeaveSubcategoryFormSchema } from "./form.rule";

export const useFormDefaultLeaveSubcategory = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetLeaveSubcategoryModel | undefined) => {
    return !cachedValue
      ? leaveSubcategoryFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as LeaveSubcategoryFormSchema);
  };

  return {
    getDefault,
  };
};
