import { 
    MessageResponseDto, 
    GetPenaltyScheduleDto, 
    CreatePenaltyScheduleDto, 
    EditPenaltyScheduleDto,
    UtilPenaltyScheduleControllerGet200Response,
    UtilityPenaltyScheduleApiFp,
    DeletePenaltyScheduleDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponsePenaltyScheduleModel = UtilPenaltyScheduleControllerGet200Response;
export type GetPenaltyScheduleModel = GetPenaltyScheduleDto;
export type MutateResponsePenaltyScheduleModel = MessageResponseDto;
export type CreatePenaltyScheduleModel = CreatePenaltyScheduleDto;
export type UpdatePenaltyScheduleModel = EditPenaltyScheduleDto;
export type DeletePenaltyScheduleModel = DeletePenaltyScheduleDto;

export const apiPenaltyScheduleFp =  UtilityPenaltyScheduleApiFp(apiConfig);
export const getPenaltyScheduleController = apiPenaltyScheduleFp.utilPenaltyScheduleControllerGet;
export const createPenaltyScheduleController = apiPenaltyScheduleFp.utilPenaltyScheduleControllerCreate;
export const updatePenaltyScheduleController = apiPenaltyScheduleFp.utilPenaltyScheduleControllerEdit;
export const deletePenaltyScheduleController = apiPenaltyScheduleFp.utilPenaltyScheduleControllerDelete;

export const QueryKeyPenaltySchedule = QueryKey.PenaltySchedule;