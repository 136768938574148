import { Outlet } from "react-router-dom";

import { FilterSalaryTypeProvider } from "./filter-salary-type/filter-salary-type.context";
import './salary-type.scss';

export function SalaryType() {
  return (
    <div id="SalaryType" className="salary-type">
       <FilterSalaryTypeProvider>
        <Outlet />
      </FilterSalaryTypeProvider>
    </div>
  );
}

export default SalaryType;
