import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownViolation } from "./form-dropdown-violation.hook";

const FormDropdownViolationContext = createContext(
  {} as ReturnType<typeof useFormDropdownViolation>
);
export function useFormDropdownViolationContext() {
  return useContext(FormDropdownViolationContext);
}

interface FormDropdownViolationProviderProps {
  children: ReactNode;
}
export function FormDropdownViolationProvider({
  children,
}: FormDropdownViolationProviderProps) {
  return (
    <FormDropdownViolationContext.Provider value={useFormDropdownViolation()}>
      {children}
    </FormDropdownViolationContext.Provider>
  );
}
