import {
  MessageResponseDto,
  GetEmployeeLoanDto,
  CreateEmployeeLoanDto,
  EditEmployeeLoanDto,
  EmployeeLoanControllerGet200Response,
  EmployeeLoanApiFp,
  DeleteEmployeeLoanDto,
  EmployeeLoanControllerProcess200Response,
  ProcessLoanDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeLoanModel = EmployeeLoanControllerGet200Response;
export type ProcessEmployeeLoanResponseModel =
  EmployeeLoanControllerProcess200Response;

export type GetEmployeeLoanModel = GetEmployeeLoanDto;
export type MutateResponseEmployeeLoanModel = MessageResponseDto;
export type CreateEmployeeLoanModel = CreateEmployeeLoanDto;
export type UpdateEmployeeLoanModel = EditEmployeeLoanDto;
export type DeleteEmployeeLoanModel = DeleteEmployeeLoanDto;
export type ProcessEmployeeLoanModel = ProcessLoanDto;

export const apiEmployeeLoanFp = EmployeeLoanApiFp(apiConfig);
export const getEmployeeLoanController =
  apiEmployeeLoanFp.employeeLoanControllerGet;
export const createEmployeeLoanController =
  apiEmployeeLoanFp.employeeLoanControllerCreate;
export const updateEmployeeLoanController =
  apiEmployeeLoanFp.employeeLoanControllerEdit;
export const deleteEmployeeLoanController =
  apiEmployeeLoanFp.employeeLoanControllerDelete;
export const processEmployeeLoanController =
  apiEmployeeLoanFp.employeeLoanControllerProcess;

export const QueryKeyEmployeeLoan = QueryKey.EmployeeLoan;
