import "./new-employee-favorite.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeFavorite } from "@core/query/employee-favorite.query";
import {
  EmployeeFavoriteArrayFormRule,
  EmployeeFavoriteArrayFormSchema,
  EmployeeFavoriteFormRule,
  EmployeeFavoriteFormSchema,
} from "@core/form/form.rule";
import {
  employeeFavoriteArrayFormDefault,
  employeeFavoriteFormDefault,
} from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeFavorite from "../form-employee-favorite/form-employee-favorite";

export function NewEmployeeFavorite() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateEmployeeFavorite } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New EmployeeFavorite is created`);
    navigate(`../`);
  };
  const { mutate: addEmployeeFavorite, isLoading: isCreating } =
    useQyCreateEmployeeFavorite(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeFavoriteArrayFormSchema>({
    defaultValues: employeeFavoriteArrayFormDefault,
    resolver: zodResolver(EmployeeFavoriteArrayFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeFavoriteArrayFormSchema) => {
    const mappedArray = form.groups.map((item) => {
      return mapCreateEmployeeFavorite({
        userCode: item.userCode,
        favoriteCode: form.favoriteId,
      });
    });

    addEmployeeFavorite(mappedArray);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeFavoriteArrayFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeFavorite" className="employee-favorite">
      <HeaderContent
        title="New Employee Group"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeeFavorite />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeFavorite;
