import { Outlet } from "react-router-dom";

import { FilterPaymentMethodProvider } from "./filter-payment-method/filter-payment-method.context";
import './payment-method.scss';

export function PaymentMethod() {
  return (
    <div id="PaymentMethod" className="payment-method">
       <FilterPaymentMethodProvider>
        <Outlet />
      </FilterPaymentMethodProvider>
    </div>
  );
}

export default PaymentMethod;
