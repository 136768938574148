import "./new-shift.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateShift } from "@core/query/shift.query";
import { ShiftFormRule, ShiftFormSchema } from "@core/form/form.rule";
import { shiftFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormShift from "../form-shift/form-shift";
import { calculateDurationInMinutes } from "@core/utility/get-time-difference";

export function Shift() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateShift } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New Shift is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addShift, isLoading: isCreating } =
    useQyCreateShift(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<ShiftFormSchema>({
    defaultValues: shiftFormDefault,
    resolver: zodResolver(ShiftFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: ShiftFormSchema) => {
    let breakMinutes = form.break || 0;

    if (!form.isOpen) {
      breakMinutes =
        form.breakStart && form.breakEnd
          ? calculateDurationInMinutes(form.breakStart, form.breakEnd)
          : 0;
    }

    if (breakMinutes < 0) {
      showWarning("Invalid break time");
      return;
    }

    const updateForm = {
      ...form,
      break: breakMinutes,
    };
    const formData = mapCreateShift(updateForm);
    addShift(formData);
  };
  const handleValidateError = (err: FieldErrors<ShiftFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="Shift" className="shift">
      <HeaderContent
        title="New Shift"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormShift />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default Shift;
