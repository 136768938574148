import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeAttachmentFormSchema,
  EmployeeIncidentFormRule,
  EmployeeIncidentFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeIncident } from "@core/form/form-default-employee-incident.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeIncident,
  useQyGetEmployeeIncidentById,
  useQyDeleteEmployeeIncident,
} from "@core/query/employee-incident.query";
import { GetResponseEmployeeIncidentModel } from "@core/model/query-model-employee-incident";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useQyGetGoogleCloudAssetPreview } from "@core/query/google-cloud-storage.query";
import { useQyUpdateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { useState } from "react";

export function useEditEmployeeIncident() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { branchId } = useParams();
  const { getDefault } = useFormDefaultEmployeeIncident();
  const navigate = useNavigate();
  const {
    mapUpdateEmployeeIncident,
    mapDeleteEmployeeIncident,
    mapUpdateEmployeeAttachment,
  } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const [attachmentInfo, setAttachmentInfo] =
    useState<EmployeeAttachmentFormSchema | null>(null);
  const { employeeIncidentId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION

  // API UPDATE ATTACHMENT
  const { mutate: updateEmployeeAttachment } = useQyUpdateEmployeeAttachment();

  // API VIEW ASSET
  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API GETBYID EmployeeIncident
  const handleGetApiSuccess = (data: GetResponseEmployeeIncidentModel) => {
    if (!data || data.count === 0) {
      return;
    }

    const responseData = data.data?.[0];
    setValue("userCode", responseData?.user_code || "");
    setValue("bucketCode", responseData?.bucket_code || "");
    setValue("branchCode", responseData?.branch || "");
    setValue(
      "effectivityDate",
      formatDate(responseData?.effectivity_date) as any,
      undefined
    );
    setValue("incidentType", responseData?.incident_type || "");
    setValue("paymentMethod", responseData?.payment_method || "");
    setValue("amount", responseData?.amount);
    setValue("balance", responseData?.balance);
    setValue("isPaid", Boolean(responseData?.is_paid));
    setValue("intervals", (responseData as any)?.intervals);
    setValue(
      "paymentStart",
      formatDate((responseData as any)?.payment_start) as any
    );
    setValue("remarks", responseData?.remarks);

    const attachment = (responseData as any)?.attachments as string;
    const attachmentJson = JSON.parse(attachment);
    if (attachmentJson?.length > 0 && attachmentJson?.[0]?.file) {
      const attachmentFirst = attachmentJson[0];
      const attachmentInfoForm = {
        attachmentType: attachmentFirst.attachment_type,
        bucketCode: attachmentFirst.bucket_code,
        userCode: attachmentFirst.user_code,
        requestCode: attachmentFirst.request_code,
        source: attachmentFirst.source,
        code: attachmentFirst.code,
      } as EmployeeAttachmentFormSchema;
      setAttachmentInfo(attachmentInfoForm);

      viewAsset(attachmentFirst.file, {
        onSuccess(data) {
          const asset = URL.createObjectURL(data);
          setValue("file", asset);
        },
      });
    }
  };
  const {
    data: employeeIncidentResponse,
    isLoading,
    isError: employeeIncidentError,
  } = useQyGetEmployeeIncidentById(
    employeeIncidentId || "",
    handleGetApiSuccess
  );
  const employeeIncidentData = employeeIncidentResponse?.data?.[0];

  // API UPDATE EmployeeIncident
  const handleUpdateApiSuccess = () => {
    if (typeof fileInput !== "string") {
      const formData = mapUpdateEmployeeAttachment(
        { ...attachmentInfo, file: fileInput } as EmployeeAttachmentFormSchema,
        attachmentInfo?.code || ""
      );
      updateEmployeeAttachment(formData);
    }

    showSuccess("EmployeeIncident updated successfully");
  };
  const { mutate: updateEmployeeIncident, isLoading: isEditLoading } =
    useQyUpdateEmployeeIncident(handleUpdateApiSuccess);

  // API DELETE EmployeeIncident
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeIncident deleted successfully");
  };
  const { mutate: deleteEmployeeIncident } = useQyDeleteEmployeeIncident(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeIncidentFormSchema>({
    defaultValues: getDefault(employeeIncidentData),
    resolver: zodResolver(EmployeeIncidentFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const fileInput = watch("file");
  const handleValidate = (form: EmployeeIncidentFormSchema) => {
    if (!employeeIncidentData) {
      throw new Error("No employeeIncident data");
    }

    const mutatedForm = {
      ...form,
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
      branchCode: branchId,
    } as EmployeeIncidentFormSchema;

    const formData = mapUpdateEmployeeIncident(
      mutatedForm,
      employeeIncidentData.code
    );
    updateEmployeeIncident(formData);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeIncidentFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeIncidentData) {
      throw new Error("No employeeIncident data found");
    }

    const form = mapDeleteEmployeeIncident(employeeIncidentData.code);
    deleteEmployeeIncident(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeIncidentId,
    employeeIncidentData,
    isLoading,
    employeeIncidentError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
