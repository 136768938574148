import { GetShiftModel } from "@core/model/query-model-shift";
import { shiftFormDefault } from "./form.default";
import { ShiftFormSchema } from "./form.rule";

export const useFormDefaultShift = () => {
  const getDefault = (cachedValue: GetShiftModel | undefined) => {
    return !cachedValue
      ? shiftFormDefault
      : ({
          type: cachedValue.type,
          start: cachedValue.start,
          end: cachedValue.end,
          halfDay: cachedValue.half_day,
          gracePeriod: cachedValue.grace_period,
          workHours: cachedValue.work_hours,
          break: cachedValue.break,
          breakStart: cachedValue.break_start,
          breakEnd: "",
          title: cachedValue.title,
          isOpen: Boolean(cachedValue.is_open),
        } as ShiftFormSchema);
  };

  return {
    getDefault,
  };
};
