import { ReactNode, createContext, useContext } from "react";
import { useFilterEventPage } from "./filter-event-page.hook";

const FilterEventPageContext = createContext(
  {} as ReturnType<typeof useFilterEventPage>
);
export function useFilterEventPageContext() {
  return useContext(FilterEventPageContext);
}

interface FilterEventPageProviderProps {
  children: ReactNode;
}
export function FilterEventPageProvider({
  children,
}: FilterEventPageProviderProps) {
  return (
    <FilterEventPageContext.Provider value={useFilterEventPage()}>
      {children}
    </FilterEventPageContext.Provider>
  );
}
