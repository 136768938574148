import "./form-employee-shift.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeShiftFormSchema } from "@core/form/form.rule";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import FormDropdownShift from "@domain/shift/form-dropdown-shift/form-dropdown-shift";
import { useEffect } from "react";
import { format } from "date-fns";

type InputFormSchema = EmployeeShiftFormSchema;
export function EmployeeShift() {
  const { control, watch, setValue } =
    useFormContext<EmployeeShiftFormSchema>();
  const shiftDate = watch("effectivityDate");

  useEffect(() => {
    if (shiftDate) {
      const day = format(shiftDate, "EEEE");
      setValue("day", day);
    }
  }, [shiftDate]);

  return (
    <div id="EmployeeShift" className="employee-shift">
      <FormDropdownShift<InputFormSchema> name="shiftCode" />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      {/* <InputDateControl<InputFormSchema>
        control={control}
        name="endDate"
        label="End Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      /> */}
      <CheckboxControl<InputFormSchema>
        control={control}
        name="isPermanent"
        label="Is Permanent"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default EmployeeShift;
