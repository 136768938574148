import "./form-holiday.scss";
import { useFormContext } from "react-hook-form";
import { HolidayFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownHolidayType from "@domain/holiday-type/form-dropdown-holiday-type/form-dropdown-holiday-type";

type InputFormSchema = HolidayFormSchema;
export function Holiday() {
  const { control } = useFormContext<HolidayFormSchema>();
  return (
    <div id="Holiday" className="holiday">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="date"
        label="Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <FormDropdownHolidayType<InputFormSchema> name="type" />
    </div>
  );
}

export default Holiday;
