import { Outlet, useParams } from "react-router-dom";

import { FilterEmployeePaymentProvider } from "./filter-employee-payment/filter-employee-payment.context";
import "./employee-payment.scss";
import { FormDropdownEmployeeLoanProvider } from "@domain/employee-loan/form-dropdown-employee-loan/form-dropdown-employee-loan.context";

export function EmployeePayment() {
  const { personId } = useParams();
  return (
    <div id="EmployeePayment" className="employee-payment">
      <FormDropdownEmployeeLoanProvider userCode={personId}>
        <FilterEmployeePaymentProvider>
          <Outlet />
        </FilterEmployeePaymentProvider>
      </FormDropdownEmployeeLoanProvider>
    </div>
  );
}

export default EmployeePayment;
