import { useFilterEmployeeLoanContext } from "./filter-employee-loan.context";

export function FilterEmployeeLoan() {
  const { dateFilterElement, clearFiltersElement } =
    useFilterEmployeeLoanContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {dateFilterElement}
      {clearFiltersElement}
    </div>
  );
}