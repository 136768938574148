import { 
    MessageResponseDto, 
    GetEmployeeScoreDto, 
    CreateEmployeeScoreDto, 
    EditEmployeeScoreDto,
    EmployeeScoreControllerGet200Response,
    EmployeeScoreApiFp,
    DeleteEmployeeScoreDto,
    AddEmployeeScoreDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeScoreModel = EmployeeScoreControllerGet200Response;
export type GetEmployeeScoreModel = GetEmployeeScoreDto;
export type MutateResponseEmployeeScoreModel = MessageResponseDto;
export type CreateEmployeeScoreModel = CreateEmployeeScoreDto;
export type AddEmployeeScoreModel = AddEmployeeScoreDto;
export type UpdateEmployeeScoreModel = EditEmployeeScoreDto;
export type DeleteEmployeeScoreModel = DeleteEmployeeScoreDto;

export const apiEmployeeScoreFp = EmployeeScoreApiFp(apiConfig);
export const getEmployeeScoreController = apiEmployeeScoreFp.employeeScoreControllerGet;
export const createEmployeeScoreController = apiEmployeeScoreFp.employeeScoreControllerCreate;
export const updateEmployeeScoreController = apiEmployeeScoreFp.employeeScoreControllerEdit;
export const deleteEmployeeScoreController = apiEmployeeScoreFp.employeeScoreControllerDelete;
export const getEmployeeScoreAverageController = apiEmployeeScoreFp.employeeScoreControllerGetAvg;

export const QueryKeyEmployeeScore = QueryKey.EmployeeScore;
export const QueryKeyEmployeeScoreAverage = QueryKey.EmployeeScore;