import { 
    MessageResponseDto, 
    CreateJobTitleDto, 
    EditJobTitleDto,
    UtilJobTitleControllerGet200Response,
    UtilityJobTitleApiFp,
    DeleteJobTitleDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseJobTitleModel = UtilJobTitleControllerGet200Response;
export type GetJobTitleModel = EditJobTitleDto;
export type MutateResponseJobTitleModel = MessageResponseDto;
export type CreateJobTitleModel = CreateJobTitleDto;
export type UpdateJobTitleModel = EditJobTitleDto;
export type DeleteJobTitleModel = DeleteJobTitleDto;

export const apiJobTitleFp =  UtilityJobTitleApiFp(apiConfig);
export const getJobTitleController = apiJobTitleFp.utilJobTitleControllerGet;
export const createJobTitleController = apiJobTitleFp.utilJobTitleControllerCreate;
export const updateJobTitleController = apiJobTitleFp.utilJobTitleControllerEdit;
export const deleteJobTitleController = apiJobTitleFp.utilJobTitleControllerDelete;

export const QueryKeyJobTitle = QueryKey.JobTitle;