import "./new-person.scss";

import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreatePerson } from "@core/query/person.query";
import { PersonFormRule, PersonFormSchema } from "@core/form/form.rule";
import { personFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormPerson from "../form-person/form-person";

export function Person() {
  // LOCAL DECLARATION
  const { branchId } = useParams();
  const navigate = useNavigate();
  const { mapCreatePerson } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New Person is created`);
    navigate(`../${data.code}?return=branch`);
  };
  const { mutate: addPerson, isLoading: isCreating } =
    useQyCreatePerson(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<PersonFormSchema>({
    defaultValues: personFormDefault,
    resolver: zodResolver(PersonFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: PersonFormSchema) => {
    const updatedForm = {
      ...form,
      bucket: {
        ...form.bucket,
        branch: branchId,
      },
    } as PersonFormSchema;
    const formData = mapCreatePerson(updatedForm);
    addPerson(formData);
  };
  const handleValidateError = (err: FieldErrors<PersonFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="Person" className="person">
      <HeaderContent
        title="New Employee"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate(-1)}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormPerson isEdit={false} />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default Person;
