import { CalendarDate } from "@core/model/calendar-event.interface";

export const getShouldFetchShifts = (
  calendar: CalendarDate,
  selectedEmployeeBuckets: string[]
) => {
  return !!(
    calendar.start &&
    calendar.end &&
    selectedEmployeeBuckets.length > 0
  );
};
