import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownShift } from "./form-dropdown-shift.hook";

const FormDropdownShiftContext = createContext(
  {} as ReturnType<typeof useFormDropdownShift>
);
export function useFormDropdownShiftContext() {
  return useContext(FormDropdownShiftContext);
}

interface FormDropdownShiftProviderProps {
  children: ReactNode;
}
export function FormDropdownShiftProvider({
  children,
}: FormDropdownShiftProviderProps) {
  return (
    <FormDropdownShiftContext.Provider value={useFormDropdownShift()}>
      {children}
    </FormDropdownShiftContext.Provider>
  );
}
