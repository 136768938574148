import { LabelValue } from "@shared/models/label-value.interface";
import { SeverityTag } from "@shared/models/severity-tag.enum";
import { Tag } from "primereact/tag";

export const displayColumnAs = (field: string, mapper: LabelValue[]) => {
  const selected = mapper.filter((x) => x.value === field);
  return <b>{selected[0]?.label || "-"}</b>;
};

export const displayTag = (value: string, severity: SeverityTag) => {
  return <Tag value={value} severity={severity} />;
};
