export enum QueryKey {
  Authentication = "Authentication",
  Branch = "Branch",
  Person = "Person",
  Holiday = "Holiday",
  HolidayType = "HolidayType",
  JobTitle = "JobTitle",
  JobGrade = "JobGrade",
  ShiftType = "ShiftType",
  Status = "Status",
  Shift = "Shift",
  EmploymentType = "EmploymentType",
  Department = "Department",
  Role = "Role",
  EmployeeBucket = "EmployeeBucket",
  PersonAddress = "PersonAddress",
  EmployeeShift = "EmployeeShift",
  Questionnaire = "Questionnaire",
  EmployeeScore = "EmployeeScore",
  DailyTimeRecord = "DailyTimeRecord",
  DailyTimeRecordShifts = "DailyTimeRecordShifts",
  DailyTimeRecordBreaks = "DailyTimeRecordBreaks",
  LeaveSubcategory = "LeaveSubcategory",
  LeaveCategory = "LeaveCategory",
  LeaveType = "LeaveType",
  RequestType = "RequestType",
  EmployeeRequest = "EmployeeRequest",
  PaymentMethod = "PaymentMethod",
  EmployeeIncident = "EmployeeIncident",
  IncidentType = "IncidentType",
  SalaryType = "SalaryType",
  EmployeeSalary = "EmployeeSalary",
  GoogleCloudStorage = "GoogleCloudStorage",
  EmployeeDeduction = "EmployeeDeduction",
  ReportBranchDaily = "ReportBranchDaily",
  EmployeePenalty = "EmployeePenalty",
  EmployeeTimeline = "EmployeeTimeline",
  Payroll = "Payroll",
  PayrollSummary = "PayrollSummary",
  EmployeeLedger = "EmployeeLedger",
  EmployeeAttachment = "EmployeeAttachment",
  EmployeeIncentive = "EmployeeIncentive",
  EmployeeDiciplinary = "EmployeeDiciplinary",
  Violation = "Violation",
  Infraction = "Infraction",
  PenaltySchedule = "PenaltySchedule",
  AttachmentType = "AttachmentType",
  EmployeeLoan = "EmployeeLoan",
EmployeePayment = "EmployeePayment",
EmployeeBranch = "EmployeeBranch",
EmployeeOverage = "EmployeeOverage",
Faq = "Faq",
DeviceAuthorization = "DeviceAuthorization",
Favorite = "Favorite",
EmployeeFavorite = "EmployeeFavorite",
// KEY ADD
}
