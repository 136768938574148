import { 
    MessageResponseDto, 
    GetShiftDto, 
    CreateShiftDto, 
    EditShiftDto,
    UtilShiftControllerGet200Response,
    UtilityShiftApiFp,
    DeleteShiftDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseShiftModel = UtilShiftControllerGet200Response;
export type GetShiftModel = GetShiftDto;
export type MutateResponseShiftModel = MessageResponseDto;
export type CreateShiftModel = CreateShiftDto;
export type UpdateShiftModel = EditShiftDto;
export type DeleteShiftModel = DeleteShiftDto;

export const apiShiftFp =  UtilityShiftApiFp(apiConfig);
export const getShiftController = apiShiftFp.utilShiftControllerGet;
export const createShiftController = apiShiftFp.utilShiftControllerCreate;
export const updateShiftController = apiShiftFp.utilShiftControllerEdit;
export const deleteShiftController = apiShiftFp.utilShiftControllerDelete;

export const QueryKeyShift = QueryKey.Shift;