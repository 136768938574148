import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeAttachmentModel } from "@core/model/query-model-employee-attachment";
import { employeeAttachmentFormDefault } from "./form.default";
import { EmployeeAttachmentFormSchema } from "./form.rule";

export const useFormDefaultEmployeeAttachment = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeAttachmentModel | undefined) => {
    return !cachedValue
      ? employeeAttachmentFormDefault
     : ({
        isActive: Boolean(cachedValue.is_active),
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        source: cachedValue.source,
        attachmentType: cachedValue.attachment_type,
        requestCode: cachedValue.request_code,
        file: cachedValue.file,
        mimeType: cachedValue.mime_type,
      } as EmployeeAttachmentFormSchema);
  };

  return {
    getDefault,
  };
};
