import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetSalaryTypeModel } from "@core/model/query-model-salary-type";
import { salaryTypeFormDefault } from "./form.default";
import { SalaryTypeFormSchema } from "./form.rule";

export const useFormDefaultSalaryType = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetSalaryTypeModel | undefined) => {
    return !cachedValue
      ? salaryTypeFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as SalaryTypeFormSchema);
  };

  return {
    getDefault,
  };
};
