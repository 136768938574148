import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { LeaveSubcategoryFormRule, LeaveSubcategoryFormSchema } from "@core/form/form.rule";
import { useFormDefaultLeaveSubcategory } from "@core/form/form-default-leave-subcategory.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateLeaveSubcategory, 
  useQyGetLeaveSubcategoryById, 
  useQyDeleteLeaveSubcategory 
} from "@core/query/leave-subcategory.query";
import { GetResponseLeaveSubcategoryModel } from "@core/model/query-model-leave-subcategory";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditLeaveSubcategory() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultLeaveSubcategory();
  const navigate = useNavigate();
  const { mapUpdateLeaveSubcategory, mapDeleteLeaveSubcategory } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { leaveSubcategoryId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID LeaveSubcategory
  const handleGetApiSuccess = (data: GetResponseLeaveSubcategoryModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: leaveSubcategoryResponse,
    isLoading,
    isError: leaveSubcategoryError,
  } = useQyGetLeaveSubcategoryById(leaveSubcategoryId || "", handleGetApiSuccess);
  const leaveSubcategoryData = leaveSubcategoryResponse?.data?.[0];

  // API UPDATE LeaveSubcategory
  const handleUpdateApiSuccess = () => {
    showSuccess("LeaveSubcategory updated successfully");
  };
  const { mutate: updateLeaveSubcategory, isLoading: isEditLoading } = useQyUpdateLeaveSubcategory(
    handleUpdateApiSuccess
  );

  // API DELETE LeaveSubcategory
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("LeaveSubcategory deleted successfully");
  };
  const { mutate: deleteLeaveSubcategory } = useQyDeleteLeaveSubcategory(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<LeaveSubcategoryFormSchema>({
    defaultValues: getDefault(leaveSubcategoryData),
    resolver: zodResolver(LeaveSubcategoryFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: LeaveSubcategoryFormSchema) => {
    if (!leaveSubcategoryData) {
      throw new Error("No leaveSubcategory data");
    }

    const formData = mapUpdateLeaveSubcategory(form, leaveSubcategoryData.code);
    updateLeaveSubcategory(formData);
  };
  const handleValidateError = (err: FieldErrors<LeaveSubcategoryFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!leaveSubcategoryData) {
      throw new Error("No leaveSubcategory data found");
    }

    const form = mapDeleteLeaveSubcategory(leaveSubcategoryData.code);
    deleteLeaveSubcategory(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    leaveSubcategoryId,
    leaveSubcategoryData,
    isLoading,
    leaveSubcategoryError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}