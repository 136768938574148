import { Outlet } from "react-router-dom";

import { FilterEmployeeLoanProvider } from "./filter-employee-loan/filter-employee-loan.context";
import "./employee-loan.scss";
import { FormDropdownStatusProvider } from "@domain/status/form-dropdown-status/form-dropdown-status.context";

export function EmployeeLoan() {
  return (
    <div id="EmployeeLoan" className="employee-loan">
      <FormDropdownStatusProvider>
        <FilterEmployeeLoanProvider>
          <Outlet />
        </FilterEmployeeLoanProvider>
      </FormDropdownStatusProvider>
    </div>
  );
}

export default EmployeeLoan;
