import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetAttachmentTypeModel } from "@core/model/query-model-attachment-type";
import { attachmentTypeFormDefault } from "./form.default";
import { AttachmentTypeFormSchema } from "./form.rule";

export const useFormDefaultAttachmentType = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetAttachmentTypeModel | undefined) => {
    return !cachedValue
      ? attachmentTypeFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as AttachmentTypeFormSchema);
  };

  return {
    getDefault,
  };
};
