import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { EmployeeBranchFormRule, EmployeeBranchFormSchema } from "@core/form/form.rule";
import { useFormDefaultEmployeeBranch } from "@core/form/form-default-employee-branch.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateEmployeeBranch, 
  useQyGetEmployeeBranchById, 
  useQyDeleteEmployeeBranch 
} from "@core/query/employee-branch.query";
import { GetResponseEmployeeBranchModel } from "@core/model/query-model-employee-branch";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeeBranch() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeBranch();
  const navigate = useNavigate();
  const { mapUpdateEmployeeBranch, mapDeleteEmployeeBranch } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeBranchId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeBranch
  const handleGetApiSuccess = (data: GetResponseEmployeeBranchModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("branchCode", responseData?.branch_code || "");
      setValue("effectivityDate", formatDate(responseData?.effectivity_date) as any, undefined);
    }
  };
  const {
    data: employeeBranchResponse,
    isLoading,
    isError: employeeBranchError,
  } = useQyGetEmployeeBranchById(employeeBranchId || "", handleGetApiSuccess);
  const employeeBranchData = employeeBranchResponse?.data?.[0];

  // API UPDATE EmployeeBranch
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeBranch updated successfully");
  };
  const { mutate: updateEmployeeBranch, isLoading: isEditLoading } = useQyUpdateEmployeeBranch(
    handleUpdateApiSuccess
  );

  // API DELETE EmployeeBranch
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeBranch deleted successfully");
  };
  const { mutate: deleteEmployeeBranch } = useQyDeleteEmployeeBranch(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeBranchFormSchema>({
    defaultValues: getDefault(employeeBranchData),
    resolver: zodResolver(EmployeeBranchFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeBranchFormSchema) => {
    if (!employeeBranchData) {
      throw new Error("No employeeBranch data");
    }

    const formData = mapUpdateEmployeeBranch(form, employeeBranchData.code);
    updateEmployeeBranch(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeBranchFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeBranchData) {
      throw new Error("No employeeBranch data found");
    }

    const form = mapDeleteEmployeeBranch(employeeBranchData.code);
    deleteEmployeeBranch(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    employeeBranchId,
    employeeBranchData,
    isLoading,
    employeeBranchError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}