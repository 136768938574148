import { Button } from "primereact/button";
import "./clock-shell.scss";
import { useNavigate } from "react-router-dom";
import config from "../../../../config.json";

export function ClockShell() {
  const navigate = useNavigate();
  const logo = "/icon-512x512.png";

  return (
    <div id="ClockShell" className="clock-shell">
      <section className="flex flex-col justify-center items-center h-screen">
        <div className="flex flex-col items-center">
          <img
            src={logo}
            className="w-[400px]"
            alt={`${config.company} logo`}
          />
        </div>
        <h4 className="text-center mb-4">
          Please choose your action and scan your QR Code
        </h4>
        <div className="w-1/4 flex flex-col gap-6">
          <section className="flex flex-col w-full gap-2">
            <Button label="Clock In" onClick={() => navigate("in")} />
            <Button
              label="Start Break"
              onClick={() => navigate("breakin")}
              outlined
            />
            <Button
              label="Finish Break"
              severity="secondary"
              outlined
              onClick={() => navigate("breakout")}
            />
            <Button
              label="Clock Out"
              severity="secondary"
              onClick={() => navigate("out")}
            />
            <Button
              label="Request Leave"
              severity="info"
              onClick={() => navigate("leave")}
            />
          </section>
          <section className="flex flex-col w-full gap-2">
            <Button
              className="mt-4"
              severity="secondary"
              label="Return"
              onClick={() => navigate("/login")}
              outlined
            />
          </section>
        </div>
      </section>
    </div>
  );
}

export default ClockShell;
