import { Controller, FieldValues } from "react-hook-form";
import "./input-rating-control.scss";
import { FormControllerProp } from "../form-controller-prop.interface";
import { Rating } from "primereact/rating";
import classNames from "classnames";
import FormError from "../form-error/form-error";

export interface InputRatingControlProps<FieldsType extends FieldValues>
  extends FormControllerProp<FieldsType> {
  label?: string;
  className?: string;
  containerClassName?: string;
  hint?: string;
  disabled?: boolean;
}

export function InputRatingControl<FieldsType extends FieldValues>({
  rules,
  label,
  control,
  name,
  className,
  containerClassName,
  hint,
  disabled,
}: InputRatingControlProps<FieldsType>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={classNames("field mt-5", containerClassName)}>
          <span>
            <label htmlFor={field.name}>{label}</label>
            <Rating
              id={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(e) => field.onChange(e)}
              className={classNames(
                { "p-invalid": fieldState.error },
                className
              )}
              disabled={disabled}
            />
          </span>
          {hint && <small className="text-gray-400 mb-1">{hint}</small>}
          {fieldState.error && (
            <FormError error={fieldState.error} className="mt-1" />
          )}
        </div>
      )}
    />
  );
}

export default InputRatingControl;
