import { BranchFormSchema } from "@core/form/form.rule";
import "./form-branch.scss";
import { useFormContext } from "react-hook-form";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import FormDropdownPerson from "@domain/person/form-dropdown-person/form-dropdown-person";

export function FormBranch() {
  const { control } = useFormContext<BranchFormSchema>();
  return (
    <div id="FormBranch" className="form-branch">
      <InputControl<BranchFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter branch name"
      />
      <InputTextareaControl<BranchFormSchema>
        control={control}
        name="description"
        label="Description"
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter the branch description"
      />
      <FormDropdownPerson<BranchFormSchema>
        name="head"
        label="Branch Head"
        hint="Type and wait for the suggestion dropdown to appear and then select the person"
      />
    </div>
  );
}

export default FormBranch;
