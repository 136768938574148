import "./form-employee-overage.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeOverageFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";

type InputFormSchema = EmployeeOverageFormSchema;
export function FormEmployeeOverage() {
  const { control } = useFormContext<EmployeeOverageFormSchema>();
  return (
    <div id="EmployeeOverage" className="employee-overage">
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
        controlClassName="block mb-4"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="amount"
        label="Amount"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="remarks"
        label="Remarks"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter remarks"
      />
    </div>
  );
}

export default FormEmployeeOverage;
