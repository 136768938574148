import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainShell from "./domain/shell/main-shell/main-shell";
import EventPage from "./domain/event-page/event-page";
import Login from "./domain/login/login";
import Home from "./domain/home/home";
import ListEvent from "./domain/event-page/list-event/list-event";
import AuthShell from "@domain/shell/auth-shell/auth-shell";
import Branch from "@domain/branch/branch";
import ListBranch from "@domain/branch/list-branch/list-branch";
import EditBranch from "@domain/branch/edit-branch/edit-branch";
import EditEmployee from "@domain/employee/edit-employee/edit-employee";
import { ProtectedRoute } from "@core/ui/protected-route/protected-route";
import NewBranch from "@domain/branch/new-branch/new-branch";
import NewEmployee from "@domain/employee/new-employee/new-employee";
import Setting from "@domain/setting/setting";
import Holiday from "@domain/holiday/holiday";
import ListHoliday from "@domain/holiday/list-holiday/list-holiday";
import NewHoliday from "@domain/holiday/new-holiday/new-holiday";
import EditHoliday from "@domain/holiday/edit-holiday/edit-holiday";
import HolidayType from "@domain/holiday-type/holiday-type";
import ListHolidayType from "@domain/holiday-type/list-holiday-type/list-holiday-type";
import NewHolidayType from "@domain/holiday-type/new-holiday-type/new-holiday-type";
import EditHolidayType from "@domain/holiday-type/edit-holiday-type/edit-holiday-type";
import JobTitle from "@domain/job-title/job-title";
import ListJobTitle from "@domain/job-title/list-job-title/list-job-title";
import NewJobTitle from "@domain/job-title/new-job-title/new-job-title";
import EditJobTitle from "@domain/job-title/edit-job-title/edit-job-title";
import JobGrade from "@domain/job-grade/job-grade";
import ListJobGrade from "@domain/job-grade/list-job-grade/list-job-grade";
import NewJobGrade from "@domain/job-grade/new-job-grade/new-job-grade";
import EditJobGrade from "@domain/job-grade/edit-job-grade/edit-job-grade";
import ShiftType from "@domain/shift-type/shift-type";
import ListShiftType from "@domain/shift-type/list-shift-type/list-shift-type";
import NewShiftType from "@domain/shift-type/new-shift-type/new-shift-type";
import EditShiftType from "@domain/shift-type/edit-shift-type/edit-shift-type";
import Status from "@domain/status/status";
import ListStatus from "@domain/status/list-status/list-status";
import NewStatus from "@domain/status/new-status/new-status";
import EditStatus from "@domain/status/edit-status/edit-status";
import Shift from "@domain/shift/shift";
import ListShift from "@domain/shift/list-shift/list-shift";
import NewShift from "@domain/shift/new-shift/new-shift";
import EditShift from "@domain/shift/edit-shift/edit-shift";
import EmploymentType from "@domain/employment-type/employment-type";
import ListEmploymentType from "@domain/employment-type/list-employment-type/list-employment-type";
import NewEmploymentType from "@domain/employment-type/new-employment-type/new-employment-type";
import EditEmploymentType from "@domain/employment-type/edit-employment-type/edit-employment-type";
import Department from "@domain/department/department";
import ListDepartment from "@domain/department/list-department/list-department";
import NewDepartment from "@domain/department/new-department/new-department";
import EditDepartment from "@domain/department/edit-department/edit-department";
import Role from "@domain/role/role";
import ListRole from "@domain/role/list-role/list-role";
import NewRole from "@domain/role/new-role/new-role";
import EditRole from "@domain/role/edit-role/edit-role";
import EmployeeBucket from "@domain/employee-bucket/employee-bucket";
import ListEmployeeBucket from "@domain/employee-bucket/list-employee-bucket/list-employee-bucket";
import NewPerson from "@domain/person/new-person/new-person";
import Person from "@domain/person/person";
import EditPerson from "@domain/person/edit-person/edit-person";
import ListPerson from "@domain/person/list-person/list-person";
import PersonAddress from "@domain/person-address/person-address";
import ListPersonAddress from "@domain/person-address/list-person-address/list-person-address";
import NewPersonAddress from "@domain/person-address/new-person-address/new-person-address";
import EditPersonAddress from "@domain/person-address/edit-person-address/edit-person-address";
import EmployeeShift from "@domain/employee-shift/employee-shift";
import ListEmployeeShift from "@domain/employee-shift/list-employee-shift/list-employee-shift";
import NewEmployeeShift from "@domain/employee-shift/new-employee-shift/new-employee-shift";
import EditEmployeeShift from "@domain/employee-shift/edit-employee-shift/edit-employee-shift";
import FormPerson from "@domain/person/form-person/form-person";
import Questionnaire from "@domain/questionnaire/questionnaire";
import ListQuestionnaire from "@domain/questionnaire/list-questionnaire/list-questionnaire";
import NewQuestionnaire from "@domain/questionnaire/new-questionnaire/new-questionnaire";
import EditQuestionnaire from "@domain/questionnaire/edit-questionnaire/edit-questionnaire";
import EmployeeScore from "@domain/employee-score/employee-score";
import ListEmployeeScore from "@domain/employee-score/list-employee-score/list-employee-score";
import NewEmployeeScore from "@domain/employee-score/new-employee-score/new-employee-score";
import EditEmployeeScore from "@domain/employee-score/edit-employee-score/edit-employee-score";
import DailyTimeRecord from "@domain/daily-time-record/daily-time-record";
import ListDailyTimeRecord from "@domain/daily-time-record/list-daily-time-record/list-daily-time-record";
import NewDailyTimeRecord from "@domain/daily-time-record/new-daily-time-record/new-daily-time-record";
import EditDailyTimeRecord from "@domain/daily-time-record/edit-daily-time-record/edit-daily-time-record";
import QuickViewDate from "@domain/event-page/quick-view-date/quick-view-date";
import LeaveSubcategory from "@domain/leave-subcategory/leave-subcategory";
import ListLeaveSubcategory from "@domain/leave-subcategory/list-leave-subcategory/list-leave-subcategory";
import NewLeaveSubcategory from "@domain/leave-subcategory/new-leave-subcategory/new-leave-subcategory";
import EditLeaveSubcategory from "@domain/leave-subcategory/edit-leave-subcategory/edit-leave-subcategory";
import LeaveCategory from "@domain/leave-category/leave-category";
import ListLeaveCategory from "@domain/leave-category/list-leave-category/list-leave-category";
import NewLeaveCategory from "@domain/leave-category/new-leave-category/new-leave-category";
import EditLeaveCategory from "@domain/leave-category/edit-leave-category/edit-leave-category";
import ClockShell from "@domain/daily-time-record/clock-shell/clock-shell";
import QrScanner from "@core/ui/qr-scanner/qr-scanner";
import LeaveType from "@domain/leave-type/leave-type";
import ListLeaveType from "@domain/leave-type/list-leave-type/list-leave-type";
import NewLeaveType from "@domain/leave-type/new-leave-type/new-leave-type";
import EditLeaveType from "@domain/leave-type/edit-leave-type/edit-leave-type";
import RequestType from "@domain/request-type/request-type";
import ListRequestType from "@domain/request-type/list-request-type/list-request-type";
import NewRequestType from "@domain/request-type/new-request-type/new-request-type";
import EditRequestType from "@domain/request-type/edit-request-type/edit-request-type";
import EmployeeRequest from "@domain/employee-request/employee-request";
import ListEmployeeRequest from "@domain/employee-request/list-employee-request/list-employee-request";
import NewEmployeeRequest from "@domain/employee-request/new-employee-request/new-employee-request";
import EditEmployeeRequest from "@domain/employee-request/edit-employee-request/edit-employee-request";
import PaymentMethod from "@domain/payment-method/payment-method";
import ListPaymentMethod from "@domain/payment-method/list-payment-method/list-payment-method";
import NewPaymentMethod from "@domain/payment-method/new-payment-method/new-payment-method";
import EditPaymentMethod from "@domain/payment-method/edit-payment-method/edit-payment-method";
import EmployeeIncident from "@domain/employee-incident/employee-incident";
import ListEmployeeIncident from "@domain/employee-incident/list-employee-incident/list-employee-incident";
import NewEmployeeIncident from "@domain/employee-incident/new-employee-incident/new-employee-incident";
import EditEmployeeIncident from "@domain/employee-incident/edit-employee-incident/edit-employee-incident";
import IncidentType from "@domain/incident-type/incident-type";
import ListIncidentType from "@domain/incident-type/list-incident-type/list-incident-type";
import NewIncidentType from "@domain/incident-type/new-incident-type/new-incident-type";
import EditIncidentType from "@domain/incident-type/edit-incident-type/edit-incident-type";
import { UserIdentityProvider } from "@core/services/user-identity.context";
import { SessionProvider } from "@core/query/session.context";
import SalaryType from "@domain/salary-type/salary-type";
import ListSalaryType from "@domain/salary-type/list-salary-type/list-salary-type";
import NewSalaryType from "@domain/salary-type/new-salary-type/new-salary-type";
import EditSalaryType from "@domain/salary-type/edit-salary-type/edit-salary-type";
import EmployeeSalary from "@domain/employee-salary/employee-salary";
import ListEmployeeSalary from "@domain/employee-salary/list-employee-salary/list-employee-salary";
import NewEmployeeSalary from "@domain/employee-salary/new-employee-salary/new-employee-salary";
import EditEmployeeSalary from "@domain/employee-salary/edit-employee-salary/edit-employee-salary";
import EmployeeDeduction from "@domain/employee-deduction/employee-deduction";
import ListEmployeeDeduction from "@domain/employee-deduction/list-employee-deduction/list-employee-deduction";
import NewEmployeeDeduction from "@domain/employee-deduction/new-employee-deduction/new-employee-deduction";
import EditEmployeeDeduction from "@domain/employee-deduction/edit-employee-deduction/edit-employee-deduction";
import { FormDropdownBranchProvider } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import EmployeePenalty from "@domain/employee-penalty/employee-penalty";
import ListEmployeePenalty from "@domain/employee-penalty/list-employee-penalty/list-employee-penalty";
import NewEmployeePenalty from "@domain/employee-penalty/new-employee-penalty/new-employee-penalty";
import EditEmployeePenalty from "@domain/employee-penalty/edit-employee-penalty/edit-employee-penalty";
import EmployeeTimeline from "@domain/employee-timeline/employee-timeline";
import ListEmployeeTimeline from "@domain/employee-timeline/list-employee-timeline/list-employee-timeline";
import NewEmployeeTimeline from "@domain/employee-timeline/new-employee-timeline/new-employee-timeline";
import EditEmployeeTimeline from "@domain/employee-timeline/edit-employee-timeline/edit-employee-timeline";
import NewShiftShell from "@domain/event-page/new-shift-shell/new-shift-shell";
import Payroll from "@domain/payroll/payroll";
import ListPayroll from "@domain/payroll/list-payroll/list-payroll";
import NewPayroll from "@domain/payroll/new-payroll/new-payroll";
import EditPayroll from "@domain/payroll/edit-payroll/edit-payroll";
import EmployeeLedger from "@domain/employee-ledger/employee-ledger";
import ListEmployeeLedger from "@domain/employee-ledger/list-employee-ledger/list-employee-ledger";
import NewEmployeeLedger from "@domain/employee-ledger/new-employee-ledger/new-employee-ledger";
import EditEmployeeLedger from "@domain/employee-ledger/edit-employee-ledger/edit-employee-ledger";
import EmployeeAttachment from "@domain/employee-attachment/employee-attachment";
import ListEmployeeAttachment from "@domain/employee-attachment/list-employee-attachment/list-employee-attachment";
import NewEmployeeAttachment from "@domain/employee-attachment/new-employee-attachment/new-employee-attachment";
import EditEmployeeAttachment from "@domain/employee-attachment/edit-employee-attachment/edit-employee-attachment";
import EmployeeIncentive from "@domain/employee-incentive/employee-incentive";
import ListEmployeeIncentive from "@domain/employee-incentive/list-employee-incentive/list-employee-incentive";
import NewEmployeeIncentive from "@domain/employee-incentive/new-employee-incentive/new-employee-incentive";
import EditEmployeeIncentive from "@domain/employee-incentive/edit-employee-incentive/edit-employee-incentive";
import EmployeeDiciplinary from "@domain/employee-diciplinary/employee-diciplinary";
import ListEmployeeDiciplinary from "@domain/employee-diciplinary/list-employee-diciplinary/list-employee-diciplinary";
import NewEmployeeDiciplinary from "@domain/employee-diciplinary/new-employee-diciplinary/new-employee-diciplinary";
import EditEmployeeDiciplinary from "@domain/employee-diciplinary/edit-employee-diciplinary/edit-employee-diciplinary";
import Violation from "@domain/violation/violation";
import ListViolation from "@domain/violation/list-violation/list-violation";
import NewViolation from "@domain/violation/new-violation/new-violation";
import EditViolation from "@domain/violation/edit-violation/edit-violation";
import Infraction from "@domain/infraction/infraction";
import ListInfraction from "@domain/infraction/list-infraction/list-infraction";
import NewInfraction from "@domain/infraction/new-infraction/new-infraction";
import EditInfraction from "@domain/infraction/edit-infraction/edit-infraction";
import PenaltySchedule from "@domain/penalty-schedule/penalty-schedule";
import ListPenaltySchedule from "@domain/penalty-schedule/list-penalty-schedule/list-penalty-schedule";
import NewPenaltySchedule from "@domain/penalty-schedule/new-penalty-schedule/new-penalty-schedule";
import EditPenaltySchedule from "@domain/penalty-schedule/edit-penalty-schedule/edit-penalty-schedule";
import AttachmentType from "@domain/attachment-type/attachment-type";
import ListAttachmentType from "@domain/attachment-type/list-attachment-type/list-attachment-type";
import NewAttachmentType from "@domain/attachment-type/new-attachment-type/new-attachment-type";
import EditAttachmentType from "@domain/attachment-type/edit-attachment-type/edit-attachment-type";
import PublicLeaveRequest from "@domain/employee-request/public-leave-request/public-leave-request";
import EmployeeLoan from "@domain/employee-loan/employee-loan";
import ListEmployeeLoan from "@domain/employee-loan/list-employee-loan/list-employee-loan";
import NewEmployeeLoan from "@domain/employee-loan/new-employee-loan/new-employee-loan";
import EditEmployeeLoan from "@domain/employee-loan/edit-employee-loan/edit-employee-loan";
import EmployeePayment from "@domain/employee-payment/employee-payment";
import ListEmployeePayment from "@domain/employee-payment/list-employee-payment/list-employee-payment";
import NewEmployeePayment from "@domain/employee-payment/new-employee-payment/new-employee-payment";
import EditEmployeePayment from "@domain/employee-payment/edit-employee-payment/edit-employee-payment";
import AmendDtr from "@domain/daily-time-record/amend-dtr/amend-dtr";
import FileOvertime from "@domain/daily-time-record/file-overtime/file-overtime";
import EmployeeBranch from "@domain/employee-branch/employee-branch";
import ListEmployeeBranch from "@domain/employee-branch/list-employee-branch/list-employee-branch";
import NewEmployeeBranch from "@domain/employee-branch/new-employee-branch/new-employee-branch";
import EditEmployeeBranch from "@domain/employee-branch/edit-employee-branch/edit-employee-branch";
import EmployeeOverage from "@domain/employee-overage/employee-overage";
import ListEmployeeOverage from "@domain/employee-overage/list-employee-overage/list-employee-overage";
import NewEmployeeOverage from "@domain/employee-overage/new-employee-overage/new-employee-overage";
import EditEmployeeOverage from "@domain/employee-overage/edit-employee-overage/edit-employee-overage";
import GovernmentFee from "@domain/person/government-fee/government-fee";
import AmendBreak from "@domain/daily-time-record/amend-break/amend-break";
import Faq from "@domain/faq/faq";
import ListFaq from "@domain/faq/list-faq/list-faq";
import NewFaq from "@domain/faq/new-faq/new-faq";
import EditFaq from "@domain/faq/edit-faq/edit-faq";
import DeviceAuthorization from "@domain/device-authorization/device-authorization";
import NewDeviceAuthorization from "@domain/device-authorization/new-device-authorization/new-device-authorization";
import Favorite from "@domain/favorite/favorite";
import ListFavorite from "@domain/favorite/list-favorite/list-favorite";
import NewFavorite from "@domain/favorite/new-favorite/new-favorite";
import EditFavorite from "@domain/favorite/edit-favorite/edit-favorite";
import EmployeeFavorite from "@domain/employee-favorite/employee-favorite";
import ListEmployeeFavorite from "@domain/employee-favorite/list-employee-favorite/list-employee-favorite";
import NewEmployeeFavorite from "@domain/employee-favorite/new-employee-favorite/new-employee-favorite";
import EditEmployeeFavorite from "@domain/employee-favorite/edit-employee-favorite/edit-employee-favorite";
// ROUTE IMPORT

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainShell />,
    children: [
      { path: "login", element: <Login /> },
      {
        path: "clock",
        element: <ClockShell />,
      },
      { path: "clock/:action", element: <QrScanner /> },
      {
        path: "leave-request",
        element: (
          <section className="flex h-screen items-center justify-center">
            <PublicLeaveRequest />
          </section>
        ),
      },
      {
        path: "",
        element: (
          <UserIdentityProvider>
            <SessionProvider>
              <ProtectedRoute>
                <AuthShell />
              </ProtectedRoute>
            </SessionProvider>
          </UserIdentityProvider>
        ),
        children: [
          {
            path: "",
            element: (
              <FormDropdownBranchProvider>
                <Home />
              </FormDropdownBranchProvider>
            ),
          },
          {
            path: "event",
            element: <EventPage />,
            children: [
              {
                path: "",
                element: <ListEvent />,
                children: [
                  { path: "list", element: <QuickViewDate /> },
                  { path: "new-shift", element: <NewShiftShell /> },
                ],
              },
            ],
          },
          {
            path: "personnel",
            element: <EmployeeBucket />,
            children: [
              {
                path: "",
                element: <ListEmployeeBucket returnParam="employee" />,
              },
            ],
          },
          {
            path: "employee/:branchId",
            element: <Person />,
            children: [
              {
                path: "new",
                element: <NewPerson />,
              },
              {
                path: ":personId",
                element: <EditPerson />,
                children: [
                  {
                    path: "",
                    element: (
                      <section className="p-7">
                        <FormPerson isEdit={true} />
                      </section>
                    ),
                  },
                  {
                    path: "government",
                    element: <GovernmentFee />,
                  },
                  {
                    path: "attachment",
                    element: <EmployeeAttachment />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeAttachment />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeAttachment />,
                      },
                      {
                        path: ":employeeAttachmentId",
                        element: <EditEmployeeAttachment />,
                      },
                    ],
                  },
                  {
                    path: "shift",
                    element: <EmployeeShift />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeShift />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeShift />,
                      },
                      {
                        path: ":employeeShiftId",
                        element: <EditEmployeeShift />,
                      },
                    ],
                  },
                  {
                    path: "daily-time-record",
                    element: <DailyTimeRecord />,
                    children: [
                      {
                        path: "",
                        element: <ListDailyTimeRecord />,
                        children: [
                          { path: "amend-dtr", element: <AmendDtr /> },
                          { path: "file-ot", element: <FileOvertime /> },
                          { path: "amend-break", element: <AmendBreak /> },
                        ],
                      },
                      {
                        path: "new",
                        element: <NewDailyTimeRecord />,
                      },
                      {
                        path: ":dailyTimeRecordId",
                        element: <EditDailyTimeRecord />,
                      },
                    ],
                  },
                  {
                    path: "score",
                    element: <EmployeeScore />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeScore />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeScore />,
                      },
                      {
                        path: ":employeeScoreId",
                        element: <EditEmployeeScore />,
                      },
                    ],
                  },
                  {
                    path: "request",
                    element: <EmployeeRequest />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeRequest />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeRequest />,
                      },
                      {
                        path: ":employeeRequestId",
                        element: <EditEmployeeRequest />,
                      },
                    ],
                  },
                  {
                    path: "incident",
                    element: <EmployeeIncident />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeIncident />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeIncident />,
                      },
                      {
                        path: ":employeeIncidentId",
                        element: <EditEmployeeIncident />,
                      },
                    ],
                  },
                  {
                    path: "deduction",
                    element: <EmployeeDeduction />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeDeduction />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeDeduction />,
                      },
                      {
                        path: ":employeeDeductionId",
                        element: <EditEmployeeDeduction />,
                      },
                    ],
                  },
                  {
                    path: "salary",
                    element: <EmployeeSalary />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeSalary />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeSalary />,
                      },
                      {
                        path: ":employeeSalaryId",
                        element: <EditEmployeeSalary />,
                      },
                    ],
                  },
                  {
                    path: "penalty",
                    element: <EmployeePenalty />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeePenalty />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeePenalty />,
                      },
                      {
                        path: ":employeePenaltyId",
                        element: <EditEmployeePenalty />,
                      },
                    ],
                  },
                  {
                    path: "timeline",
                    element: <EmployeeTimeline />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeTimeline />,
                      },
                    ],
                  },
                  {
                    path: "ledger",
                    element: <EmployeeLedger />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeLedger />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeLedger />,
                      },
                      {
                        path: ":employeeLedgerId",
                        element: <EditEmployeeLedger />,
                      },
                    ],
                  },
                  {
                    path: "incentive",
                    element: <EmployeeIncentive />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeIncentive />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeIncentive />,
                      },
                      {
                        path: ":employeeIncentiveId",
                        element: <EditEmployeeIncentive />,
                      },
                    ],
                  },
                  {
                    path: "disciplinary",
                    element: <EmployeeDiciplinary />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeDiciplinary />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeDiciplinary />,
                      },
                      {
                        path: ":employeeDiciplinaryId",
                        element: <EditEmployeeDiciplinary />,
                      },
                    ],
                  },
                  {
                    path: "loan",
                    element: <EmployeeLoan />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeLoan />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeLoan />,
                      },
                      {
                        path: ":employeeLoanId",
                        element: <EditEmployeeLoan />,
                      },
                    ],
                  },
                  {
                    path: "payment",
                    element: <EmployeePayment />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeePayment />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeePayment />,
                      },
                      {
                        path: ":employeePaymentId",
                        element: <EditEmployeePayment />,
                      },
                    ],
                  },
                  {
                    path: "transfer",
                    element: <EmployeeBranch />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeBranch />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeBranch />,
                      },
                      {
                        path: ":employeeBranchId",
                        element: <EditEmployeeBranch />,
                      },
                    ],
                  },
                  {
                    path: "overage",
                    element: <EmployeeOverage />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeOverage />,
                      },
                      {
                        path: "new",
                        element: <NewEmployeeOverage />,
                      },
                      {
                        path: ":employeeOverageId",
                        element: <EditEmployeeOverage />,
                      },
                    ],
                  },
                  // EMPLOYEE MENU HERE
                ],
              },
            ],
          },
          {
            path: "request",
            element: <EmployeeRequest />,
            children: [
              {
                path: "",
                element: <ListEmployeeRequest isAll={true} />,
              },
              {
                path: "new",
                element: <NewEmployeeRequest />,
              },
              {
                path: ":employeeRequestId",
                element: <EditEmployeeRequest />,
              },
            ],
          },

          {
            path: "loan",
            element: <EmployeeLoan />,
            children: [
              {
                path: "",
                element: <ListEmployeeLoan isMain={true} />,
              },
              {
                path: "new",
                element: <NewEmployeeLoan />,
              },
              {
                path: ":employeeLoanId",
                element: <EditEmployeeLoan />,
              },
            ],
          },
          {
            path: "person",
            element: <Person />,
            children: [
              {
                path: "",
                element: <ListPerson />,
              },
            ],
          },
          {
            path: "branch",
            element: <Branch />,
            children: [
              {
                path: "",
                element: <ListBranch />,
              },
              {
                path: "new",
                element: <NewBranch />,
              },
              {
                path: ":branchId",
                element: <EditBranch />,
                children: [
                  {
                    path: "",
                    element: <EmployeeBucket />,
                    children: [
                      {
                        path: "",
                        element: <ListEmployeeBucket returnParam="branch" />,
                      },
                    ],
                  },
                ],
              },
              {
                path: ":branchId/employee/new",
                element: <NewEmployee />,
              },
              {
                path: ":branchId/employee/:employeeId",
                element: <EditEmployee />,
              },
            ],
          },
          {
            path: "setting",
            element: <Setting />,
            children: [
              {
                path: "",
                element: <Department />,
                children: [
                  {
                    path: "",
                    element: <ListDepartment />,
                  },
                  {
                    path: "new",
                    element: <NewDepartment />,
                  },
                  {
                    path: ":departmentId",
                    element: <EditDepartment />,
                  },
                ],
              },
              {
                path: "role",
                element: <Role />,
                children: [
                  {
                    path: "",
                    element: <ListRole />,
                  },
                  {
                    path: "new",
                    element: <NewRole />,
                  },
                  {
                    path: ":roleId",
                    element: <EditRole />,
                  },
                ],
              },
              {
                path: "employment-type",
                element: <EmploymentType />,
                children: [
                  {
                    path: "",
                    element: <ListEmploymentType />,
                  },
                  {
                    path: "new",
                    element: <NewEmploymentType />,
                  },
                  {
                    path: ":employmentTypeId",
                    element: <EditEmploymentType />,
                  },
                ],
              },
              {
                path: "holiday",
                element: <Holiday />,
                children: [
                  {
                    path: "",
                    element: <ListHoliday />,
                  },
                  {
                    path: "new",
                    element: <NewHoliday />,
                  },
                  {
                    path: ":holidayId",
                    element: <EditHoliday />,
                  },
                ],
              },
              {
                path: "holiday-type",
                element: <HolidayType />,
                children: [
                  {
                    path: "",
                    element: <ListHolidayType />,
                  },
                  {
                    path: "new",
                    element: <NewHolidayType />,
                  },
                  {
                    path: ":holidayTypeId",
                    element: <EditHolidayType />,
                  },
                ],
              },
              {
                path: "job-title",
                element: <JobTitle />,
                children: [
                  {
                    path: "",
                    element: <ListJobTitle />,
                  },
                  {
                    path: "new",
                    element: <NewJobTitle />,
                  },
                  {
                    path: ":jobTitleId",
                    element: <EditJobTitle />,
                  },
                ],
              },
              {
                path: "job-grade",
                element: <JobGrade />,
                children: [
                  {
                    path: "",
                    element: <ListJobGrade />,
                  },
                  {
                    path: "new",
                    element: <NewJobGrade />,
                  },
                  {
                    path: ":jobGradeId",
                    element: <EditJobGrade />,
                  },
                ],
              },
              {
                path: "shift",
                element: <Shift />,
                children: [
                  {
                    path: "",
                    element: <ListShift />,
                  },
                  {
                    path: "new",
                    element: <NewShift />,
                  },
                  {
                    path: ":shiftId",
                    element: <EditShift />,
                  },
                ],
              },
              {
                path: "shift-type",
                element: <ShiftType />,
                children: [
                  {
                    path: "",
                    element: <ListShiftType />,
                  },
                  {
                    path: "new",
                    element: <NewShiftType />,
                  },
                  {
                    path: ":shiftTypeId",
                    element: <EditShiftType />,
                  },
                ],
              },
              {
                path: "utility-status",
                element: <Status />,
                children: [
                  {
                    path: "",
                    element: <ListStatus />,
                  },
                  {
                    path: "new",
                    element: <NewStatus />,
                  },
                  {
                    path: ":statusId",
                    element: <EditStatus />,
                  },
                ],
              },
              {
                path: "questions",
                element: <Questionnaire />,
                children: [
                  {
                    path: "",
                    element: <ListQuestionnaire />,
                  },
                  {
                    path: "new",
                    element: <NewQuestionnaire />,
                  },
                  {
                    path: ":questionnaireId",
                    element: <EditQuestionnaire />,
                  },
                ],
              },
              {
                path: "leave-subcategory",
                element: <LeaveSubcategory />,
                children: [
                  {
                    path: "",
                    element: <ListLeaveSubcategory />,
                  },
                  {
                    path: "new",
                    element: <NewLeaveSubcategory />,
                  },
                  {
                    path: ":leaveSubcategoryId",
                    element: <EditLeaveSubcategory />,
                  },
                ],
              },
              {
                path: "leave-category",
                element: <LeaveCategory />,
                children: [
                  {
                    path: "",
                    element: <ListLeaveCategory />,
                  },
                  {
                    path: "new",
                    element: <NewLeaveCategory />,
                  },
                  {
                    path: ":leaveCategoryId",
                    element: <EditLeaveCategory />,
                  },
                ],
              },
              {
                path: "leave-type",
                element: <LeaveType />,
                children: [
                  {
                    path: "",
                    element: <ListLeaveType />,
                  },
                  {
                    path: "new",
                    element: <NewLeaveType />,
                  },
                  {
                    path: ":leaveTypeId",
                    element: <EditLeaveType />,
                  },
                ],
              },
              {
                path: "request-type",
                element: <RequestType />,
                children: [
                  {
                    path: "",
                    element: <ListRequestType />,
                  },
                  {
                    path: "new",
                    element: <NewRequestType />,
                  },
                  {
                    path: ":requestTypeId",
                    element: <EditRequestType />,
                  },
                ],
              },
              {
                path: "payment-method",
                element: <PaymentMethod />,
                children: [
                  {
                    path: "",
                    element: <ListPaymentMethod />,
                  },
                  {
                    path: "new",
                    element: <NewPaymentMethod />,
                  },
                  {
                    path: ":paymentMethodId",
                    element: <EditPaymentMethod />,
                  },
                ],
              },
              {
                path: "incident-type",
                element: <IncidentType />,
                children: [
                  {
                    path: "",
                    element: <ListIncidentType />,
                  },
                  {
                    path: "new",
                    element: <NewIncidentType />,
                  },
                  {
                    path: ":incidentTypeId",
                    element: <EditIncidentType />,
                  },
                ],
              },
              {
                path: "salary-type",
                element: <SalaryType />,
                children: [
                  {
                    path: "",
                    element: <ListSalaryType />,
                  },
                  {
                    path: "new",
                    element: <NewSalaryType />,
                  },
                  {
                    path: ":salaryTypeId",
                    element: <EditSalaryType />,
                  },
                ],
              },
              {
                path: "violation",
                element: <Violation />,
                children: [
                  {
                    path: "",
                    element: <ListViolation />,
                  },
                  {
                    path: "new",
                    element: <NewViolation />,
                  },
                  {
                    path: ":violationId",
                    element: <EditViolation />,
                  },
                ],
              },
              {
                path: "infraction",
                element: <Infraction />,
                children: [
                  {
                    path: "",
                    element: <ListInfraction />,
                  },
                  {
                    path: "new",
                    element: <NewInfraction />,
                  },
                  {
                    path: ":infractionId",
                    element: <EditInfraction />,
                  },
                ],
              },
              {
                path: "penalty-schedule",
                element: <PenaltySchedule />,
                children: [
                  {
                    path: "",
                    element: <ListPenaltySchedule />,
                  },
                  {
                    path: "new",
                    element: <NewPenaltySchedule />,
                  },
                  {
                    path: ":penaltyScheduleId",
                    element: <EditPenaltySchedule />,
                  },
                ],
              },
              {
                path: "attachment-type",
                element: <AttachmentType />,
                children: [
                  {
                    path: "",
                    element: <ListAttachmentType />,
                  },
                  {
                    path: "new",
                    element: <NewAttachmentType />,
                  },
                  {
                    path: ":attachmentTypeId",
                    element: <EditAttachmentType />,
                  },
                ],
              },
              {
                path: "device-authorization",
                element: <DeviceAuthorization />,
                children: [
                  {
                    path: "",
                    element: <NewDeviceAuthorization />,
                  },
                ],
              },
              {
                path: "grouping",
                element: <Favorite />,
                children: [
                  {
                    path: "",
                    element: <ListFavorite />,
                  },
                  {
                    path: "new",
                    element: <NewFavorite />,
                  },
                  {
                    path: ":favoriteId",
                    element: <EditFavorite />,
                  },
                ],
              },
            ],
            // END OF SETTINGS
          },
          {
            path: "person-address",
            element: <PersonAddress />,
            children: [
              {
                path: "",
                element: <ListPersonAddress />,
              },
              {
                path: "new",
                element: <NewPersonAddress />,
              },
              {
                path: ":personAddressId",
                element: <EditPersonAddress />,
              },
            ],
          },
          {
            path: "timeline",
            element: <EmployeeTimeline />,
            children: [
              {
                path: "",
                element: <ListEmployeeTimeline />,
              },
              {
                path: "new",
                element: <NewEmployeeTimeline />,
              },
              {
                path: ":employeeTimelineId",
                element: <EditEmployeeTimeline />,
              },
            ],
          },
          {
            path: "payroll",
            element: <Payroll />,
            children: [
              {
                path: "",
                element: <ListPayroll />,
              },
            ],
          },
          {
            path: "faq",
            element: <Faq />,
            children: [
              {
                path: "",
                element: <ListFaq />,
              },
              {
                path: "new",
                element: <NewFaq />,
              },
              {
                path: ":faqId",
                element: <EditFaq />,
              },
            ],
          },
          {
            path: "group",
            element: <EmployeeFavorite />,
            children: [
              {
                path: "",
                element: <ListEmployeeFavorite />,
              },
              {
                path: "new",
                element: <NewEmployeeFavorite />,
              },
              {
                path: ":employeeFavoriteId",
                element: <EditEmployeeFavorite />,
              },
            ],
          },
          // ROUTE ADD
        ],
      },
    ],
  },
]);
export function AppRoute() {
  return <RouterProvider router={router} />;
}
