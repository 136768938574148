import { Outlet } from "react-router-dom";

import { FilterEmployeeTimelineProvider } from "./filter-employee-timeline/filter-employee-timeline.context";
import './employee-timeline.scss';

export function EmployeeTimeline() {
  return (
    <div id="EmployeeTimeline" className="employee-timeline">
       <FilterEmployeeTimelineProvider>
        <Outlet />
      </FilterEmployeeTimelineProvider>
    </div>
  );
}

export default EmployeeTimeline;
