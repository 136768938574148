import { 
    MessageResponseDto, 
    GetBranchDto, 
    CreateBranchDto, 
    EditBranchDto,
    UtilBranchControllerGet200Response,
    UtilityBranchApiFp,
    DeleteBranchDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseBranchModel = UtilBranchControllerGet200Response;
export type GetBranchModel = GetBranchDto;
export type MutateResponseBranchModel = MessageResponseDto;
export type CreateBranchModel = CreateBranchDto;
export type UpdateBranchModel = EditBranchDto;
export type DeleteBranchModel = DeleteBranchDto;

export const apiBranchFp =  UtilityBranchApiFp(apiConfig);
export const getBranchController = apiBranchFp.utilBranchControllerGet;
export const createBranchController = apiBranchFp.utilBranchControllerCreate;
export const updateBranchController = apiBranchFp.utilBranchControllerEdit;
export const deleteBranchController = apiBranchFp.utilBranchControllerDelete;

export const QueryKeyBranch = QueryKey.Branch;