import { useFormContext } from "react-hook-form";
import { PersonFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import "./form-person-address.scss";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { PH_PROVINCES } from "@shared/data/ph-province";
import { LabelValue } from "@shared/models/label-value.interface";

type InputFormSchema = PersonFormSchema;
export function FormPersonAddress() {
  const mappedProvinces = PH_PROVINCES.map(
    (item) => new LabelValue(item.name, item.name)
  );
  const { control, setValue } = useFormContext<InputFormSchema>();

  const handleProvinceSelect = (province: string) => {
    const provinceFind = PH_PROVINCES.find((item) => item.name === province);
    if (!!provinceFind) {
      setValue("address.region", provinceFind.region);
    }
  };
  return (
    <div id="FormPersonAddress" className="form-person-address">
      <InputControl<InputFormSchema>
        control={control}
        name="address.streetName"
        label="Street Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter streetName"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.subdivision"
        label="Subdivision"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter subdivision"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.zone"
        label="Zone"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter zone"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.sitio"
        label="Sitio"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter sitio"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.purok"
        label="Purok"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter purok"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.barangay"
        label="Barangay"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter barangay"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.municipality"
        label="Municipality"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter municipality"
      />
      <DropdownControl<InputFormSchema>
        control={control}
        options={mappedProvinces}
        name="address.province"
        label="Province"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter province"
        filter
        onSelect={handleProvinceSelect}
      />
      <InputControl<InputFormSchema>
        control={control}
        name="address.zip"
        label="Zip"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter zip"
      />
    </div>
  );
}

export default FormPersonAddress;
