import { useCallback, useState } from "react";
import { CreateRequestTypeModel } from "@core/model/query-model-request-type";
import {
  useQyCreateRequestType,
  useQyGetRequestType,
} from "@core/query/request-type.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { DesignatedRequestType } from "./designated-request-type.enum";

export const useFormDropdownRequestType = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newRequestType, setNewRequestType] = useState<CreateRequestTypeModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewRequestType({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newRequestType.name) {
      showWarning("Please fill in RequestType details");
      return;
    }
    setIsCreating(true);
    addRequestType(newRequestType);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New RequestType is added. Check and select the RequestType in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addRequestType } = useQyCreateRequestType(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: requestTypeResponse } = useQyGetRequestType("");
  const mappedRequestType = (requestTypeResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );
  const getDesignationMapping = useCallback(() => {
    const breakAmendment =
      mappedRequestType.filter(
        (x) =>
          x.label.toLowerCase().indexOf(DesignatedRequestType.BreakAmendment) >=
          0
      )[0] || null;
    const overtime =
      mappedRequestType.filter(
        (x) =>
          x.label.toLowerCase().indexOf(DesignatedRequestType.Overtime) >= 0
      )[0] || null;
    const amendment =
      mappedRequestType.filter(
        (x) =>
          x.label.toLowerCase().indexOf(DesignatedRequestType.DtrAmendment) >= 0
      )[0] || null;
    const leave =
      mappedRequestType.filter(
        (x) => x.label.toLowerCase().indexOf(DesignatedRequestType.Leave) >= 0
      )[0] || null;
    const changeShift =
      mappedRequestType.filter(
        (x) =>
          x.label.toLowerCase().indexOf(DesignatedRequestType.ChangeShift) >= 0
      )[0] || null;

    const mapped = [
      overtime,
      amendment,
      leave,
      changeShift,
      breakAmendment,
    ].filter((x) => !!x);
    return mapped;
  }, [mappedRequestType]);
  const getAmendDtrMap = useCallback(() => {
    const amendment =
      mappedRequestType.filter(
        (x) =>
          x.label.toLowerCase().indexOf(DesignatedRequestType.DtrAmendment) >= 0
      )[0] || null;
    return amendment;
  }, [mappedRequestType]);
  const getAmendBreakMap = useCallback(() => {
    const breakAmend =
      mappedRequestType.filter(
        (x) =>
          x.label.toLowerCase().indexOf(DesignatedRequestType.BreakAmendment) >= 0
      )[0] || null;
    return breakAmend;
  }, [mappedRequestType]);

  const getDesignatedRequestTypeEnumValue = (
    designatedRequestType: LabelValue[],
    selectedCode: string
  ) => {
    if (!selectedCode || designatedRequestType.length <= 0) return;

    const selectedObject = designatedRequestType.filter(
      (x) => x.value === selectedCode
    )[0];
    const isOvertime =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.Overtime) >= 0;
    const isBreakAmendment =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.BreakAmendment) >= 0;
    const isDtrAmendment =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.DtrAmendment) >= 0;
    const isLeave =
      selectedObject.label.toLowerCase().indexOf(DesignatedRequestType.Leave) >=
      0;
    const isChangeShift =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.ChangeShift) >= 0;

    if (isOvertime) {
      return DesignatedRequestType.Overtime;
    } else if (isDtrAmendment) {
      return DesignatedRequestType.DtrAmendment;
    } else if (isLeave) {
      return DesignatedRequestType.Leave;
    } else if (isChangeShift) {
      return DesignatedRequestType.ChangeShift;
    } else if (isBreakAmendment) {
      return DesignatedRequestType.BreakAmendment;
    } else {
      return null;
    }
  };

  return {
    sidebar,
    filter,
    newRequestType,
    mappedRequestType,
    isCreating,
    requestTypeResponse,
    setSidebar,
    setFilter,
    setNewRequestType,
    handleFilterInput,
    handleAdd,
    addRequestType,
    getDesignationMapping,
    getDesignatedRequestTypeEnumValue,
    getAmendDtrMap,
    getAmendBreakMap
  };
};
