import "./form-employee-branch.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeBranchFormSchema } from "@core/form/form.rule";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownBranch from "@domain/branch/form-dropdown-branch/form-dropdown-branch";

type InputFormSchema = EmployeeBranchFormSchema;
export function FormEmployeeBranch() {
  const { control } = useFormContext<EmployeeBranchFormSchema>();
  return (
    <div id="EmployeeBranch" className="employee-branch">
      <FormDropdownBranch<InputFormSchema> name="branchCode" />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default FormEmployeeBranch;
