import classNames from "classnames";
import { SyntheticEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Chip, ChipRemoveEvent } from "primereact/chip";

const defaultFilter = (date = "") => {
  let defaultFilter = {} as Record<string, any>;

  if (date) {
    defaultFilter.date = date;
  }

  return defaultFilter;
};

export function useFilterJobTitle() {
  // THIS IS THE LOCAL DECLARATION
  let [searchParams, setSearchParams] = useSearchParams();
  const reportsParam = searchParams.get("reports");
  const dateNameParam = searchParams.get("dateName") || undefined;
  const startDateParam = searchParams.get("startDate") || undefined;
  const endDateParam = searchParams.get("endDate") || undefined;
  const [filterEntity, setFilterEntity] = useState<Record<string, string>>(
    defaultFilter("")
  );

  // ADD/REPLACE DEFAULT FILTER STATE
  const [selectedDate, setSelectedDate] = useState("");

  // THIS IS THE LOCAL FUNCTION
  const getFilterCount = () => {
    const values = Object.values(filterEntity).filter((x) => !!x);
    let filterEntityCount = values.length || 0;

    if (!!dateNameParam) {
      filterEntityCount++;
    }
    if (!!startDateParam) {
      filterEntityCount++;
    }
    if (!!endDateParam) {
      filterEntityCount++;
    }

    return filterEntityCount;
  };
  const applyFilter = (field: string, value: string) => {
    const filterVal = {
      ...filterEntity,
      [field]: value,
    } as Record<string, string>;

    setFilterEntity(filterVal);
  };
  const removeAllFilters = () => {
    const filterVal = {} as Record<string, string>;
    setSearchParams({});
    setFilterEntity(filterVal);
    setSelectedDate("");
  };
  const removeFilterBy = (filterName: string) => {
    const updatedFilterEntity = {
      ...filterEntity,
      [filterName]: "",
    };
    setFilterEntity(updatedFilterEntity);

    if (filterName === "date") {
      setSelectedDate("");
    }
  };
  const handleRemoveChip = (e: ChipRemoveEvent) => {
    if (e.value.indexOf(":") < 0) {
      return;
    }

    const filterName = e.value.split(":")[0];
    removeFilterBy(filterName);
  };
  const handleClear = (e: SyntheticEvent) => {
    e.preventDefault();
    removeAllFilters();
  };

  const hasReportFilterElements =
    !!dateNameParam && !!startDateParam && !!endDateParam;
  const reportFilterElements = hasReportFilterElements ? (
    <div>
      <h5>Report filters:</h5>
      {!!dateNameParam ? (
        <Tag severity="info">
          <div className="flex align-items-center gap-2">
            <span className="text-base">Date Name: {dateNameParam}</span>
          </div>
        </Tag>
      ) : null}
      {!!startDateParam ? (
        <Tag severity="info">
          <div className="flex align-items-center gap-2">
            <span className="text-base">Start Date: {startDateParam}</span>
          </div>
        </Tag>
      ) : null}
      {!!endDateParam ? (
        <Tag severity="info">
          <div className="flex align-items-center gap-2">
            <span className="text-base">End Date: {endDateParam}</span>
          </div>
        </Tag>
      ) : null}
    </div>
  ) : null;
  const clearFiltersElement = (
    <Button
      label="Clear Filters"
      severity="secondary"
      className="block mt-4"
      outlined
      onClick={handleClear}
    />
  );
  const hasAppliedFilters = Object.values(filterEntity).filter((i) => !!i).length > 0;
  const filterChipsElement = hasAppliedFilters ? (
    <section>
      <small>Applied Filters:</small>
      <div className="flex">
        {Object.keys(filterEntity).map((i) => {
          if (filterEntity[i] === "") {
            return null;
          }
          return (
            <Chip
              key={i}
              label={`${i}: ${filterEntity[i]}`}
              removable
              onRemove={handleRemoveChip}
            />
          );
        })}
      </div>
    </section>
  ) : null; 

  // ADD / REPLACE FILTER ELEMENT
  const dateFilterElement = (
    <span className={classNames("flex flex-col-reverse gap-1")}>
      <InputText
        value={selectedDate}
        type="date"
        id="date"
        onInput={(e: any) => {
          setSelectedDate(e.target.value);
          applyFilter("date", e.target.value);
        }}
      />

      <label className="text-xs text-gray-500" htmlFor="date">
        Date
      </label>
    </span>
  );

  return {
    dateFilterElement,
    filterEntity,
    reportFilterElements,
    clearFiltersElement,
    filterChipsElement,
    removeFilters: removeAllFilters,
    getFilterCount,
  };
}