/* tslint:disable */
/* eslint-disable */
/**
 * 7s API
 * The 7s API description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessTokenOnlyDto
 */
export interface AccessTokenOnlyDto {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenOnlyDto
     */
    'access_token'?: string;
}
/**
 * 
 * @export
 * @interface AddEmployeeBranchDto
 */
export interface AddEmployeeBranchDto {
    /**
     * 
     * @type {Array<CreateEmployeeBranchDto>}
     * @memberof AddEmployeeBranchDto
     */
    'branch': Array<CreateEmployeeBranchDto>;
}
/**
 * 
 * @export
 * @interface AddEmployeeFavoriteDto
 */
export interface AddEmployeeFavoriteDto {
    /**
     * 
     * @type {Array<CreateEmployeeFavoriteDto>}
     * @memberof AddEmployeeFavoriteDto
     */
    'favorite': Array<CreateEmployeeFavoriteDto>;
}
/**
 * 
 * @export
 * @interface AddEmployeeScoreDto
 */
export interface AddEmployeeScoreDto {
    /**
     * 
     * @type {Array<CreateEmployeeScoreDto>}
     * @memberof AddEmployeeScoreDto
     */
    'score': Array<CreateEmployeeScoreDto>;
}
/**
 * 
 * @export
 * @interface AddEmployeeShiftDto
 */
export interface AddEmployeeShiftDto {
    /**
     * 
     * @type {Array<CreateEmployeeShiftDto>}
     * @memberof AddEmployeeShiftDto
     */
    'shift': Array<CreateEmployeeShiftDto>;
}
/**
 * 
 * @export
 * @interface AmendBreakDto
 */
export interface AmendBreakDto {
    /**
     * 
     * @type {string}
     * @memberof AmendBreakDto
     */
    'break_code': string;
    /**
     * 
     * @type {string}
     * @memberof AmendBreakDto
     */
    'request_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof AmendBreakDto
     */
    'is_break_permision': boolean;
    /**
     * 
     * @type {string}
     * @memberof AmendBreakDto
     */
    'request_reason': string;
    /**
     * 
     * @type {string}
     * @memberof AmendBreakDto
     */
    'amend_break_start': string;
    /**
     * 
     * @type {string}
     * @memberof AmendBreakDto
     */
    'amend_break_end': string;
}
/**
 * 
 * @export
 * @interface AmendmentEmployeeRequestDto
 */
export interface AmendmentEmployeeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'dtr_code': string;
    /**
     * 
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'request_reason': string;
    /**
     * 
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'new_clockin': string;
    /**
     * 
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'new_clockout': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof AmendmentEmployeeRequestDto
     */
    'work_date': string;
}
/**
 * 
 * @export
 * @interface AuthenticationControllerAuthenticate201Response
 */
export interface AuthenticationControllerAuthenticate201Response {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationControllerAuthenticate201Response
     */
    'message'?: string;
    /**
     * 
     * @type {TokenDto}
     * @memberof AuthenticationControllerAuthenticate201Response
     */
    'data'?: TokenDto;
}
/**
 * 
 * @export
 * @interface AuthenticationControllerRefreshRenew201Response
 */
export interface AuthenticationControllerRefreshRenew201Response {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationControllerRefreshRenew201Response
     */
    'message'?: string;
    /**
     * 
     * @type {RenewRefreshTokenDto}
     * @memberof AuthenticationControllerRefreshRenew201Response
     */
    'data'?: RenewRefreshTokenDto;
}
/**
 * 
 * @export
 * @interface BlockPersonDto
 */
export interface BlockPersonDto {
    /**
     * 
     * @type {boolean}
     * @memberof BlockPersonDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof BlockPersonDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPersonDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof BlockPersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPersonDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof BlockPersonDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPersonDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPersonDto
     */
    'code': string;
    /**
     * IF set as TRUE then the user will not be allowed to login ELSE the user will still be allowed to login.
     * @type {boolean}
     * @memberof BlockPersonDto
     */
    'is_blocked'?: boolean;
}
/**
 * 
 * @export
 * @interface ClockinDto
 */
export interface ClockinDto {
    /**
     * This input is optional.
     * @type {string}
     * @memberof ClockinDto
     */
    'clockin'?: string;
}
/**
 * 
 * @export
 * @interface ClockoutDto
 */
export interface ClockoutDto {
    /**
     * This input is optional.
     * @type {string}
     * @memberof ClockoutDto
     */
    'clockout'?: string;
}
/**
 * 
 * @export
 * @interface CoreApiPaginatedResponse
 */
export interface CoreApiPaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof CoreApiPaginatedResponse
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface CreateAttachmentTypeDto
 */
export interface CreateAttachmentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAttachmentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateBranchDto
 */
export interface CreateBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchDto
     */
    'head'?: string;
}
/**
 * 
 * @export
 * @interface CreateConfigDto
 */
export interface CreateConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateConfigDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateConfigDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateConfigDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateConfigDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface CreateDepartmentDto
 */
export interface CreateDepartmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'head'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeBranchDto
 */
export interface CreateEmployeeBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'branch_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBranchDto
     */
    'effectivity_date': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeBucketDto
 */
export interface CreateEmployeeBucketDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeBucketDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'employee_no': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'signed_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'start_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'reporting_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'interviewer'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketDto
     */
    'orientation_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeBucketDto
     */
    'sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeBucketDto
     */
    'philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeBucketDto
     */
    'pagibig'?: number;
}
/**
 * 
 * @export
 * @interface CreateEmployeeBucketExtendedDto
 */
export interface CreateEmployeeBucketExtendedDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'employee_no': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'signed_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'start_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'reporting_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'role': string;
    /**
     * 
     * @type {CreateEmployeeSalaryDto}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'salary'?: CreateEmployeeSalaryDto;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'interviewer'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'orientation_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeBucketExtendedDto
     */
    'pagibig'?: number;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDeductionDto
 */
export interface CreateEmployeeDeductionDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDeductionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'incident': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeDeductionDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDeductionDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDiciplinaryDto
 */
export interface CreateEmployeeDiciplinaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'branch_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'incident_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'issued_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'violation_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'infraction_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'penalty_schedule_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'action_plan'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'followup_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'issuer': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDiciplinaryDto
     */
    'supervisor': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeFavoriteDto
 */
export interface CreateEmployeeFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeFavoriteDto
     */
    'favorite_code': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeIncentiveDto
 */
export interface CreateEmployeeIncentiveDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeIncentiveDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeIncentiveDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncentiveDto
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeIncidentDto
 */
export interface CreateEmployeeIncidentDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeIncidentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'branch_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'incident_type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'payment_method': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeIncidentDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeIncidentDto
     */
    'balance': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeIncidentDto
     */
    'is_paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeIncidentDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeIncidentDto
     */
    'payment_start': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeLoanDto
 */
export interface CreateEmployeeLoanDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeLoanDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeLoanDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeLoanDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeLoanDto
     */
    'payment_start': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeOverageDto
 */
export interface CreateEmployeeOverageDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeOverageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeOverageDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeOverageDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeePaymentDto
 */
export interface CreateEmployeePaymentDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeePaymentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'loan': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeePaymentDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePaymentDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeePenaltyDto
 */
export interface CreateEmployeePenaltyDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeePenaltyDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeePenaltyDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeePenaltyDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeRequestDto
 */
export interface CreateEmployeeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'request_reason': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'leave_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'leave_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'leave_subcategory'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'amend_current_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'amend_current_clockout'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'amend_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'amend_clockout'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'shift_current_schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'shift_schedule'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeRequestDto
     */
    'is_permanent'?: boolean;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'shift_day'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeRequestDto
     */
    'ot_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeRequestDto
     */
    'ot_advance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeRequestDto
     */
    'ot_hazard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'status'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'cancelled_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequestDto
     */
    'rejected_reason'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeSalaryDto
 */
export interface CreateEmployeeSalaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeSalaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'salary_type': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeSalaryDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeSalaryDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeScoreDto
 */
export interface CreateEmployeeScoreDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeScoreDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'question_code': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeScoreDto
     */
    'score'?: number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeScoreDto
     */
    'effectivity_date': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeShiftDto
 */
export interface CreateEmployeeShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'shift_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'effectivity_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'end_date'?: string;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof CreateEmployeeShiftDto
     */
    'day': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeShiftDto
     */
    'is_permanent'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateEmployeeTimelineDto
 */
export interface CreateEmployeeTimelineDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeTimelineDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeTimelineDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateEmploymentTypeDto
 */
export interface CreateEmploymentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmploymentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmploymentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateFaqDto
 */
export interface CreateFaqDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateFaqDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateFaqDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateFaqDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateFaqDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface CreateFavoriteDto
 */
export interface CreateFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateHolidayDto
 */
export interface CreateHolidayDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateHolidayDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'description'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayDto
     */
    'branch_code'?: string;
}
/**
 * 
 * @export
 * @interface CreateHolidayTypeDto
 */
export interface CreateHolidayTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateHolidayTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHolidayTypeDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateHolidayTypeDto
     */
    'percentage'?: number;
}
/**
 * 
 * @export
 * @interface CreateIncidentTypeDto
 */
export interface CreateIncidentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateIncidentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateInfractionDto
 */
export interface CreateInfractionDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateInfractionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInfractionDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateJobGradeDto
 */
export interface CreateJobGradeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateJobGradeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobGradeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateJobTitleDto
 */
export interface CreateJobTitleDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateJobTitleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobTitleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateLeaveCategoryDto
 */
export interface CreateLeaveCategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCategoryDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateLeaveSubcategoryDto
 */
export interface CreateLeaveSubcategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveSubcategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveSubcategoryDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateLeaveTypeDto
 */
export interface CreateLeaveTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateMemoDto
 */
export interface CreateMemoDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateMemoDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateMemoDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemoDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateMemoDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemoDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateMemoDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemoDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemoDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemoDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreatePaymentMethodDto
 */
export interface CreatePaymentMethodDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentMethodDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreatePenaltyScheduleDto
 */
export interface CreatePenaltyScheduleDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePenaltyScheduleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePenaltyScheduleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreatePersonAddressDto
 */
export interface CreatePersonAddressDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonAddressDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonAddressDto
     */
    'zip'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePersonAddressDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePersonAddressDto
     */
    'latitude'?: number;
}
/**
 * 
 * @export
 * @interface CreatePersonExtendedDto
 */
export interface CreatePersonExtendedDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonExtendedDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonExtendedDto
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'activation_code'?: string;
    /**
     * MALE | FEMALE
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'gender': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonExtendedDto
     */
    'is_blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'mobile_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'father_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'mother_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'emergency_contact_1_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'emergency_contact_1_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'emergency_contact_1_relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'emergency_contact_2_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'emergency_contact_2_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'emergency_contact_2_relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'sss'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'philhealth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'pagibig'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'medical_condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'blood_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'elementary'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'high_school'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'college'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'post_college'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'undergraduate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'curriculum_vitae'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'supporting_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'intent_letter'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonExtendedDto
     */
    'application_letter'?: string;
    /**
     * 
     * @type {CreatePersonAddressDto}
     * @memberof CreatePersonExtendedDto
     */
    'address'?: CreatePersonAddressDto;
    /**
     * 
     * @type {CreateEmployeeBucketExtendedDto}
     * @memberof CreatePersonExtendedDto
     */
    'bucket'?: CreateEmployeeBucketExtendedDto;
}
/**
 * 
 * @export
 * @interface CreateQuestionnaireDto
 */
export interface CreateQuestionnaireDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateQuestionnaireDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateRequestTypeDto
 */
export interface CreateRequestTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateRequestTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateRoleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateRoleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateRoleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateRoleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateSalaryTypeDto
 */
export interface CreateSalaryTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateSalaryTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalaryTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateShiftDto
 */
export interface CreateShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'work_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'break'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'break_start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShiftDto
     */
    'is_open'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateShiftTypeDto
 */
export interface CreateShiftTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateShiftTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateStatusDto
 */
export interface CreateStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateTerminationTypeDto
 */
export interface CreateTerminationTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTerminationTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminationTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateViolationDto
 */
export interface CreateViolationDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateViolationDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateViolationDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateViolationDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateViolationDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CsrRequestDto
 */
export interface CsrRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CsrRequestDto
     */
    'bucket_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CsrRequestDto
     */
    'effectivity_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CsrRequestDto
     */
    'effectivity_date_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof CsrRequestDto
     */
    'request_reason': string;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof CsrRequestDto
     */
    'shift_day'?: string;
    /**
     * 
     * @type {string}
     * @memberof CsrRequestDto
     */
    'shift_schedule_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CsrRequestDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CsrRequestDto
     */
    'is_permanent': boolean;
}
/**
 * 
 * @export
 * @interface DailyPerformanceDto
 */
export interface DailyPerformanceDto {
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'calendar_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'calendar_month'?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceDto
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'user_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'bucket_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'shift_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'effectivity_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyPerformanceDto
     */
    'is_permanent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'util_shift_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'shift_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'shift_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'utc_shift_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'utc_shift_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'ph_shift_start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'ph_shift_start_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'utc_shift_start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'utc_shift_start_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceDto
     */
    'grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceDto
     */
    'half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceDto
     */
    'work_hours'?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'util_shift_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'username'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyPerformanceDto
     */
    'is_cutoff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyPerformanceDto
     */
    'is_payday'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'branch_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'branch_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'branch_description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyPerformanceDto
     */
    'has_score'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceDto
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'request_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'request_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_category_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_category_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_category_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_sub_category_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_sub_category_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceDto
     */
    'leave_sub_category_description'?: string;
}
/**
 * 
 * @export
 * @interface DailyTimeRecordDto
 */
export interface DailyTimeRecordDto {
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordDto
     */
    'bucket_code': string;
}
/**
 * 
 * @export
 * @interface DailyTimeRecordEntity
 */
export interface DailyTimeRecordEntity {
    /**
     * 
     * @type {boolean}
     * @memberof DailyTimeRecordEntity
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyTimeRecordEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'shift_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'work_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'clockout'?: string;
    /**
     * ON_TIME, LATE
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'clockin_status': string;
    /**
     * FINISHED_DAY, UNDERTIME, NO_CLOCKOUT
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'clockout_status': string | null;
    /**
     * 
     * @type {number}
     * @memberof DailyTimeRecordEntity
     */
    'late'?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyTimeRecordEntity
     */
    'undertime'?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyTimeRecordEntity
     */
    'excess_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyTimeRecordEntity
     */
    'total_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyTimeRecordEntity
     */
    'break_hours'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DailyTimeRecordEntity
     */
    'is_absent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyTimeRecordEntity
     */
    'is_onleave'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyTimeRecordEntity
     */
    'is_amended'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyTimeRecordEntity
     */
    'is_overtime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyTimeRecordEntity
     */
    'request_code'?: string;
}
/**
 * 
 * @export
 * @interface DeleteAttachmentTypeDto
 */
export interface DeleteAttachmentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteAttachmentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteAttachmentTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteBranchDto
 */
export interface DeleteBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteBranchDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteConfigDto
 */
export interface DeleteConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteConfigDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteConfigDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteDepartmentDto
 */
export interface DeleteDepartmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDepartmentDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeAttachmentDto
 */
export interface DeleteEmployeeAttachmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeAttachmentDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeBranchDto
 */
export interface DeleteEmployeeBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBranchDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeBucketDto
 */
export interface DeleteEmployeeBucketDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeBucketDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeBucketDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeDeductionDto
 */
export interface DeleteEmployeeDeductionDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeDeductionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDeductionDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeDiciplinaryDto
 */
export interface DeleteEmployeeDiciplinaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeDiciplinaryDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeFavoriteDto
 */
export interface DeleteEmployeeFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeFavoriteDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeIncentiveDto
 */
export interface DeleteEmployeeIncentiveDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncentiveDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeIncidentDto
 */
export interface DeleteEmployeeIncidentDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeIncidentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeIncidentDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeLoanDto
 */
export interface DeleteEmployeeLoanDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeLoanDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeLoanDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeOverageDto
 */
export interface DeleteEmployeeOverageDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeOverageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeOverageDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeePaymentDto
 */
export interface DeleteEmployeePaymentDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeePaymentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePaymentDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeePenaltyDto
 */
export interface DeleteEmployeePenaltyDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeePenaltyDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeePenaltyDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeRequestDto
 */
export interface DeleteEmployeeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeRequestDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeSalaryDto
 */
export interface DeleteEmployeeSalaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeSalaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeSalaryDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeScoreDto
 */
export interface DeleteEmployeeScoreDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeScoreDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeScoreDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeShiftDto
 */
export interface DeleteEmployeeShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeShiftDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeTimelineDto
 */
export interface DeleteEmployeeTimelineDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmployeeTimelineDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeTimelineDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteEmploymentTypeDto
 */
export interface DeleteEmploymentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmploymentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmploymentTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteFaqDto
 */
export interface DeleteFaqDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteFaqDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFaqDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteFavoriteDto
 */
export interface DeleteFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFavoriteDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteHolidayDto
 */
export interface DeleteHolidayDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteHolidayDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteHolidayTypeDto
 */
export interface DeleteHolidayTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteHolidayTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHolidayTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteIncidentTypeDto
 */
export interface DeleteIncidentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteIncidentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteIncidentTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteInfractionDto
 */
export interface DeleteInfractionDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteInfractionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInfractionDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteJobGradeDto
 */
export interface DeleteJobGradeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteJobGradeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobGradeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteJobTitleDto
 */
export interface DeleteJobTitleDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteJobTitleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteJobTitleDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteLeaveCategoryDto
 */
export interface DeleteLeaveCategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteLeaveCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveCategoryDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteLeaveSubcategoryDto
 */
export interface DeleteLeaveSubcategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveSubcategoryDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteLeaveTypeDto
 */
export interface DeleteLeaveTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteLeaveTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteLeaveTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteMemoDto
 */
export interface DeleteMemoDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteMemoDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteMemoDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeletePaymentMethodDto
 */
export interface DeletePaymentMethodDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeletePaymentMethodDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePaymentMethodDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeletePenaltyScheduleDto
 */
export interface DeletePenaltyScheduleDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeletePenaltyScheduleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePenaltyScheduleDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeletePersonAddressDto
 */
export interface DeletePersonAddressDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeletePersonAddressDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonAddressDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeletePersonDto
 */
export interface DeletePersonDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeletePersonDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteQuestionnaireDto
 */
export interface DeleteQuestionnaireDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteQuestionnaireDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteQuestionnaireDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteRequestTypeDto
 */
export interface DeleteRequestTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteRequestTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRequestTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteRoleDto
 */
export interface DeleteRoleDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteRoleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRoleDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteSalaryTypeDto
 */
export interface DeleteSalaryTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteSalaryTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSalaryTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteShiftDto
 */
export interface DeleteShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteShiftTypeDto
 */
export interface DeleteShiftTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteShiftTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteShiftTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteStatusDto
 */
export interface DeleteStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteStatusDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteTerminationTypeDto
 */
export interface DeleteTerminationTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteTerminationTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTerminationTypeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeleteViolationDto
 */
export interface DeleteViolationDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteViolationDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteViolationDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DeviceAuthorization
 */
export interface DeviceAuthorization {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceAuthorization
     */
    'authorize': boolean;
}
/**
 * 
 * @export
 * @interface DtrPublicDto
 */
export interface DtrPublicDto {
    /**
     * This input is optional.
     * @type {string}
     * @memberof DtrPublicDto
     */
    'dtr_time'?: string;
    /**
     * This input is required.
     * @type {string}
     * @memberof DtrPublicDto
     */
    'bucket_code': string;
    /**
     * This input is optional. for debugging purpose only.
     * @type {string}
     * @memberof DtrPublicDto
     */
    'work_date'?: string;
    /**
     * This input is optional.
     * @type {boolean}
     * @memberof DtrPublicDto
     */
    'is_skippable'?: boolean;
}
/**
 * 
 * @export
 * @interface EditAttachmentTypeDto
 */
export interface EditAttachmentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditAttachmentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditAttachmentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditBranchDto
 */
export interface EditBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBranchDto
     */
    'head'?: string;
}
/**
 * 
 * @export
 * @interface EditConfigDto
 */
export interface EditConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditConfigDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditConfigDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditConfigDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditConfigDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof EditConfigDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface EditDepartmentDto
 */
export interface EditDepartmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditDepartmentDto
     */
    'head'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeeBranchDto
 */
export interface EditEmployeeBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'branch_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBranchDto
     */
    'effectivity_date': string;
}
/**
 * 
 * @export
 * @interface EditEmployeeBucketDto
 */
export interface EditEmployeeBucketDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeBucketDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'employee_no': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'signed_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'start_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'reporting_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'interviewer'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketDto
     */
    'orientation_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeBucketDto
     */
    'sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeBucketDto
     */
    'philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeBucketDto
     */
    'pagibig'?: number;
}
/**
 * 
 * @export
 * @interface EditEmployeeBucketExtendedDto
 */
export interface EditEmployeeBucketExtendedDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'employee_no': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'signed_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'start_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'reporting_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'role': string;
    /**
     * 
     * @type {CreateEmployeeSalaryDto}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'salary'?: CreateEmployeeSalaryDto;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'interviewer'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'orientation_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeBucketExtendedDto
     */
    'pagibig'?: number;
}
/**
 * 
 * @export
 * @interface EditEmployeeDeductionDto
 */
export interface EditEmployeeDeductionDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeDeductionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'incident': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeDeductionDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDeductionDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeeDiciplinaryDto
 */
export interface EditEmployeeDiciplinaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'branch_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'incident_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'issued_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'violation_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'infraction_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'penalty_schedule_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'action_plan'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'followup_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'issuer': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeDiciplinaryDto
     */
    'supervisor': string;
}
/**
 * 
 * @export
 * @interface EditEmployeeIncentiveDto
 */
export interface EditEmployeeIncentiveDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeIncentiveDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeIncentiveDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncentiveDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeeIncidentDto
 */
export interface EditEmployeeIncidentDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeIncidentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'branch_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'incident_type': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'payment_method': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeIncidentDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeIncidentDto
     */
    'balance': number;
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeIncidentDto
     */
    'is_paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeIncidentDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeIncidentDto
     */
    'payment_start': string;
}
/**
 * 
 * @export
 * @interface EditEmployeeLoanDto
 */
export interface EditEmployeeLoanDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeLoanDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeLoanDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeLoanDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeLoanDto
     */
    'payment_start': string;
}
/**
 * 
 * @export
 * @interface EditEmployeeOverageDto
 */
export interface EditEmployeeOverageDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeOverageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeOverageDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeOverageDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeePaymentDto
 */
export interface EditEmployeePaymentDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeePaymentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'loan': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeePaymentDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePaymentDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeePenaltyDto
 */
export interface EditEmployeePenaltyDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeePenaltyDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeePenaltyDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeePenaltyDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeeRequestDto
 */
export interface EditEmployeeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'request_reason': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'leave_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'leave_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'leave_subcategory'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'amend_current_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'amend_current_clockout'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'amend_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'amend_clockout'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'shift_current_schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'shift_schedule'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeRequestDto
     */
    'is_permanent'?: boolean;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'shift_day'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeRequestDto
     */
    'ot_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeRequestDto
     */
    'ot_advance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeRequestDto
     */
    'ot_hazard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'status'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'cancelled_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeRequestDto
     */
    'rejected_reason'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeeSalaryDto
 */
export interface EditEmployeeSalaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeSalaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'salary_type': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeSalaryDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeSalaryDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditEmployeeScoreDto
 */
export interface EditEmployeeScoreDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeScoreDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof EditEmployeeScoreDto
     */
    'score'?: number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeScoreDto
     */
    'effectivity_date': string;
}
/**
 * 
 * @export
 * @interface EditEmployeeShiftDto
 */
export interface EditEmployeeShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'shift_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'effectivity_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'end_date'?: string;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof EditEmployeeShiftDto
     */
    'day': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeShiftDto
     */
    'is_permanent'?: boolean;
}
/**
 * 
 * @export
 * @interface EditEmployeeTimelineDto
 */
export interface EditEmployeeTimelineDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmployeeTimelineDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmployeeTimelineDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface EditEmploymentTypeDto
 */
export interface EditEmploymentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditEmploymentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditEmploymentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditFaqDto
 */
export interface EditFaqDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditFaqDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditFaqDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditFaqDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditFaqDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFaqDto
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface EditFavoriteDto
 */
export interface EditFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditFavoriteDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditHolidayDto
 */
export interface EditHolidayDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditHolidayDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditHolidayDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditHolidayDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditHolidayDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'description'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditHolidayDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayDto
     */
    'branch_code'?: string;
}
/**
 * 
 * @export
 * @interface EditHolidayTypeDto
 */
export interface EditHolidayTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditHolidayTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditHolidayTypeDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditHolidayTypeDto
     */
    'percentage'?: number;
}
/**
 * 
 * @export
 * @interface EditIncidentTypeDto
 */
export interface EditIncidentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditIncidentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditIncidentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditInfractionDto
 */
export interface EditInfractionDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditInfractionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInfractionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInfractionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInfractionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInfractionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInfractionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInfractionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInfractionDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditInfractionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditInfractionDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditJobGradeDto
 */
export interface EditJobGradeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditJobGradeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditJobGradeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditJobTitleDto
 */
export interface EditJobTitleDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditJobTitleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditJobTitleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditLeaveCategoryDto
 */
export interface EditLeaveCategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditLeaveCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveCategoryDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditLeaveSubcategoryDto
 */
export interface EditLeaveSubcategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditLeaveSubcategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveSubcategoryDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditLeaveTypeDto
 */
export interface EditLeaveTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditLeaveTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditLeaveTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditMemoDto
 */
export interface EditMemoDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditMemoDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditMemoDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditMemoDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditMemoDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditMemoDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditMemoDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditMemoDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditMemoDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditMemoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditMemoDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditPaymentMethodDto
 */
export interface EditPaymentMethodDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditPaymentMethodDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentMethodDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditPenaltyScheduleDto
 */
export interface EditPenaltyScheduleDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditPenaltyScheduleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditPenaltyScheduleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditPersonAddressDto
 */
export interface EditPersonAddressDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonAddressDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonAddressDto
     */
    'zip'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditPersonAddressDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditPersonAddressDto
     */
    'latitude'?: number;
}
/**
 * 
 * @export
 * @interface EditPersonExtendedDto
 */
export interface EditPersonExtendedDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonExtendedDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonExtendedDto
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'activation_code'?: string;
    /**
     * MALE | FEMALE
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'gender': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonExtendedDto
     */
    'is_blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'mobile_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'father_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'mother_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'emergency_contact_1_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'emergency_contact_1_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'emergency_contact_1_relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'emergency_contact_2_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'emergency_contact_2_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'emergency_contact_2_relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'sss'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'philhealth'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'pagibig'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'medical_condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'blood_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'elementary'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'high_school'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'college'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'post_college'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'undergraduate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'curriculum_vitae'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'supporting_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'intent_letter'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonExtendedDto
     */
    'application_letter'?: string;
    /**
     * 
     * @type {EditPersonAddressDto}
     * @memberof EditPersonExtendedDto
     */
    'address'?: EditPersonAddressDto;
    /**
     * 
     * @type {EditEmployeeBucketExtendedDto}
     * @memberof EditPersonExtendedDto
     */
    'bucket'?: EditEmployeeBucketExtendedDto;
}
/**
 * 
 * @export
 * @interface EditQuestionnaireDto
 */
export interface EditQuestionnaireDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditQuestionnaireDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditQuestionnaireDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditRequestTypeDto
 */
export interface EditRequestTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditRequestTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditRequestTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditRoleDto
 */
export interface EditRoleDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditRoleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditRoleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRoleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditRoleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRoleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditRoleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRoleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRoleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditRoleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditSalaryTypeDto
 */
export interface EditSalaryTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditSalaryTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditSalaryTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditShiftDto
 */
export interface EditShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof EditShiftDto
     */
    'half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditShiftDto
     */
    'grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditShiftDto
     */
    'work_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditShiftDto
     */
    'break'?: number;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'break_start': string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditShiftDto
     */
    'is_open'?: boolean;
}
/**
 * 
 * @export
 * @interface EditShiftTypeDto
 */
export interface EditShiftTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditShiftTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditStatusDto
 */
export interface EditStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStatusDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStatusDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditStatusDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditTerminationTypeDto
 */
export interface EditTerminationTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditTerminationTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditTerminationTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EditViolationDto
 */
export interface EditViolationDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditViolationDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditViolationDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditViolationDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditViolationDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditViolationDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditViolationDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditViolationDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditViolationDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditViolationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditViolationDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeAttachmentControllerGet200Response
 */
export interface EmployeeAttachmentControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeAttachmentControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof EmployeeAttachmentControllerGet200Response
     */
    'data'?: Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface EmployeeBranchControllerGet200Response
 */
export interface EmployeeBranchControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeBranchControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeBranchDto>}
     * @memberof EmployeeBranchControllerGet200Response
     */
    'data'?: Array<GetEmployeeBranchDto>;
}
/**
 * 
 * @export
 * @interface EmployeeBreakEntity
 */
export interface EmployeeBreakEntity {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeBreakEntity
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeBreakEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'bucket_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'work_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'break_start'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'break_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeBreakEntity
     */
    'total_minutes'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeBreakEntity
     */
    'is_amended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBreakEntity
     */
    'request_code'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeBucketControllerBucketWithTemporaryBranch200Response
 */
export interface EmployeeBucketControllerBucketWithTemporaryBranch200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeBucketControllerBucketWithTemporaryBranch200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeBucketWithTempBranchDto>}
     * @memberof EmployeeBucketControllerBucketWithTemporaryBranch200Response
     */
    'data'?: Array<GetEmployeeBucketWithTempBranchDto>;
}
/**
 * 
 * @export
 * @interface EmployeeBucketControllerGet200Response
 */
export interface EmployeeBucketControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeBucketControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeBucketDto>}
     * @memberof EmployeeBucketControllerGet200Response
     */
    'data'?: Array<GetEmployeeBucketDto>;
}
/**
 * 
 * @export
 * @interface EmployeeDeductionControllerGet200Response
 */
export interface EmployeeDeductionControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeDeductionControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeDeductionDto>}
     * @memberof EmployeeDeductionControllerGet200Response
     */
    'data'?: Array<GetEmployeeDeductionDto>;
}
/**
 * 
 * @export
 * @interface EmployeeDiciplinaryControllerGet200Response
 */
export interface EmployeeDiciplinaryControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeDiciplinaryControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeDiciplinaryDto>}
     * @memberof EmployeeDiciplinaryControllerGet200Response
     */
    'data'?: Array<GetEmployeeDiciplinaryDto>;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerEmployeeShift200Response
 */
export interface EmployeeDtrControllerEmployeeShift200Response {
    /**
     * 
     * @type {Array<EmployeeShiftScheduleStoredProcEntity>}
     * @memberof EmployeeDtrControllerEmployeeShift200Response
     */
    'data'?: Array<EmployeeShiftScheduleStoredProcEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerGenerateDtrQR201Response
 */
export interface EmployeeDtrControllerGenerateDtrQR201Response {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDtrControllerGenerateDtrQR201Response
     */
    'message'?: string;
    /**
     * 
     * @type {AccessTokenOnlyDto}
     * @memberof EmployeeDtrControllerGenerateDtrQR201Response
     */
    'data'?: AccessTokenOnlyDto;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerGet200Response
 */
export interface EmployeeDtrControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeDtrControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetViewDtrDto>}
     * @memberof EmployeeDtrControllerGet200Response
     */
    'data'?: Array<GetViewDtrDto>;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerGetBreak200Response
 */
export interface EmployeeDtrControllerGetBreak200Response {
    /**
     * 
     * @type {Array<EmployeeBreakEntity>}
     * @memberof EmployeeDtrControllerGetBreak200Response
     */
    'data'?: Array<EmployeeBreakEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerGetEmployeeDailyTimeRecord200Response
 */
export interface EmployeeDtrControllerGetEmployeeDailyTimeRecord200Response {
    /**
     * 
     * @type {Array<DailyTimeRecordEntity>}
     * @memberof EmployeeDtrControllerGetEmployeeDailyTimeRecord200Response
     */
    'data'?: Array<DailyTimeRecordEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerPrivateClockinBreak201Response
 */
export interface EmployeeDtrControllerPrivateClockinBreak201Response {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDtrControllerPrivateClockinBreak201Response
     */
    'message'?: string;
    /**
     * 
     * @type {EmployeeBreakEntity}
     * @memberof EmployeeDtrControllerPrivateClockinBreak201Response
     */
    'data'?: EmployeeBreakEntity;
}
/**
 * 
 * @export
 * @interface EmployeeDtrControllerProcessClockin201Response
 */
export interface EmployeeDtrControllerProcessClockin201Response {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDtrControllerProcessClockin201Response
     */
    'message'?: string;
    /**
     * 
     * @type {DailyTimeRecordEntity}
     * @memberof EmployeeDtrControllerProcessClockin201Response
     */
    'data'?: DailyTimeRecordEntity;
}
/**
 * 
 * @export
 * @interface EmployeeFavoriteControllerGet200Response
 */
export interface EmployeeFavoriteControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeFavoriteControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeFavoriteDto>}
     * @memberof EmployeeFavoriteControllerGet200Response
     */
    'data'?: Array<GetEmployeeFavoriteDto>;
}
/**
 * 
 * @export
 * @interface EmployeeIncentiveControllerGet200Response
 */
export interface EmployeeIncentiveControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeIncentiveControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeIncentiveDto>}
     * @memberof EmployeeIncentiveControllerGet200Response
     */
    'data'?: Array<GetEmployeeIncentiveDto>;
}
/**
 * 
 * @export
 * @interface EmployeeIncentiveControllerProcess200Response
 */
export interface EmployeeIncentiveControllerProcess200Response {
    /**
     * 
     * @type {Array<EmployeeIncentiveEntity>}
     * @memberof EmployeeIncentiveControllerProcess200Response
     */
    'data'?: Array<EmployeeIncentiveEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeIncentiveEntity
 */
export interface EmployeeIncentiveEntity {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeIncentiveEntity
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeIncentiveEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeIncentiveEntity
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'status'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'cancelled_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeIncentiveEntity
     */
    'rejected_reason'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeIncidentControllerGet200Response
 */
export interface EmployeeIncidentControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeIncidentControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeIncidentDto>}
     * @memberof EmployeeIncidentControllerGet200Response
     */
    'data'?: Array<GetEmployeeIncidentDto>;
}
/**
 * 
 * @export
 * @interface EmployeeLedgerControllerGetIndividualLeger200Response
 */
export interface EmployeeLedgerControllerGetIndividualLeger200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerControllerGetIndividualLeger200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<EmployeeLedgerResponseDto>}
     * @memberof EmployeeLedgerControllerGetIndividualLeger200Response
     */
    'data'?: Array<EmployeeLedgerResponseDto>;
}
/**
 * 
 * @export
 * @interface EmployeeLedgerControllerGetLegderList200Response
 */
export interface EmployeeLedgerControllerGetLegderList200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerControllerGetLegderList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<EmployeeLedgerListResponseDto>}
     * @memberof EmployeeLedgerControllerGetLegderList200Response
     */
    'data'?: Array<EmployeeLedgerListResponseDto>;
}
/**
 * 
 * @export
 * @interface EmployeeLedgerDto
 */
export interface EmployeeLedgerDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'bucket_id': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerDto
     */
    'ledger_value': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'ledger_action': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'ledger_status': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'transaction_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'ledger_remarks': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerDto
     */
    'effectivity_date'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeLedgerListDto
 */
export interface EmployeeLedgerListDto {
    /**
     * 
     * @type {Array<EmployeeLedgerDto>}
     * @memberof EmployeeLedgerListDto
     */
    'ledger': Array<EmployeeLedgerDto>;
}
/**
 * 
 * @export
 * @interface EmployeeLedgerListResponseDto
 */
export interface EmployeeLedgerListResponseDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerListResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'ledger_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'bucket_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'user_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'employee_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'person_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'person_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'employment_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'employment_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'employment_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'job_grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'job_title_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerListResponseDto
     */
    'job_grade_description'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'branch_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'branch_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerListResponseDto
     */
    'total_balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerListResponseDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeLedgerResponseDto
 */
export interface EmployeeLedgerResponseDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'ledger_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'ledger_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'ledger_status'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerResponseDto
     */
    'ledger_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLedgerResponseDto
     */
    'total_balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'ledger_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'bucket_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'user_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'employee_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'person_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'person_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'employment_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'employment_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'employment_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'job_grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'job_title_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'job_grade_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'request_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_category_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_category_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_category_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_sub_category_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_sub_category_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'leave_sub_category_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'approved_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'cancelled_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'created_by_firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'created_by_lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLedgerResponseDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeLoanControllerGet200Response
 */
export interface EmployeeLoanControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeLoanControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeLoanDto>}
     * @memberof EmployeeLoanControllerGet200Response
     */
    'data'?: Array<GetEmployeeLoanDto>;
}
/**
 * 
 * @export
 * @interface EmployeeLoanControllerProcess200Response
 */
export interface EmployeeLoanControllerProcess200Response {
    /**
     * 
     * @type {Array<EmployeeLoanEntity>}
     * @memberof EmployeeLoanControllerProcess200Response
     */
    'data'?: Array<EmployeeLoanEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeLoanEntity
 */
export interface EmployeeLoanEntity {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeLoanEntity
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLoanEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLoanEntity
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLoanEntity
     */
    'intervals'?: number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'payment_start': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'cancelled_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLoanEntity
     */
    'rejected_reason'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeOverageControllerGet200Response
 */
export interface EmployeeOverageControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverageControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeOverageDto>}
     * @memberof EmployeeOverageControllerGet200Response
     */
    'data'?: Array<GetEmployeeOverageDto>;
}
/**
 * 
 * @export
 * @interface EmployeePaymentControllerGet200Response
 */
export interface EmployeePaymentControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeePaymentControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeePaymentDto>}
     * @memberof EmployeePaymentControllerGet200Response
     */
    'data'?: Array<GetEmployeePaymentDto>;
}
/**
 * 
 * @export
 * @interface EmployeePenaltyControllerGet200Response
 */
export interface EmployeePenaltyControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeePenaltyControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeePenaltyDto>}
     * @memberof EmployeePenaltyControllerGet200Response
     */
    'data'?: Array<GetEmployeePenaltyDto>;
}
/**
 * 
 * @export
 * @interface EmployeeRequestControllerAmandment201Response
 */
export interface EmployeeRequestControllerAmandment201Response {
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestControllerAmandment201Response
     */
    'message'?: string;
    /**
     * 
     * @type {EmployeeRequestEntity}
     * @memberof EmployeeRequestControllerAmandment201Response
     */
    'data'?: EmployeeRequestEntity;
}
/**
 * 
 * @export
 * @interface EmployeeRequestControllerGet200Response
 */
export interface EmployeeRequestControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeRequestControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeRequestDto>}
     * @memberof EmployeeRequestControllerGet200Response
     */
    'data'?: Array<GetEmployeeRequestDto>;
}
/**
 * 
 * @export
 * @interface EmployeeRequestControllerProcess200Response
 */
export interface EmployeeRequestControllerProcess200Response {
    /**
     * 
     * @type {Array<EmployeeRequestEntity>}
     * @memberof EmployeeRequestControllerProcess200Response
     */
    'data'?: Array<EmployeeRequestEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeRequestEntity
 */
export interface EmployeeRequestEntity {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeRequestEntity
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeRequestEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'request_reason': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'leave_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'leave_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'leave_subcategory'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_current_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_current_clockout'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_clockout'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'shift_current_schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'shift_schedule'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeRequestEntity
     */
    'is_permanent'?: boolean;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'shift_day'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'end_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeRequestEntity
     */
    'ot_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeRequestEntity
     */
    'ot_advance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeRequestEntity
     */
    'ot_hazard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'status'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'cancelled_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'rejected_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'effectivity_end'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_current_break_start'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_current_break_end'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'amend_break_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequestEntity
     */
    'break_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeRequestEntity
     */
    'is_break_permision'?: boolean;
}
/**
 * 
 * @export
 * @interface EmployeeSalaryControllerGet200Response
 */
export interface EmployeeSalaryControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeSalaryControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeSalaryDto>}
     * @memberof EmployeeSalaryControllerGet200Response
     */
    'data'?: Array<GetEmployeeSalaryDto>;
}
/**
 * 
 * @export
 * @interface EmployeeScoreControllerGet200Response
 */
export interface EmployeeScoreControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeScoreControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeScoreDto>}
     * @memberof EmployeeScoreControllerGet200Response
     */
    'data'?: Array<GetEmployeeScoreDto>;
}
/**
 * 
 * @export
 * @interface EmployeeScoreControllerGetAvgBranch200Response
 */
export interface EmployeeScoreControllerGetAvgBranch200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeScoreControllerGetAvgBranch200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetBranchScoreDto>}
     * @memberof EmployeeScoreControllerGetAvgBranch200Response
     */
    'data'?: Array<GetBranchScoreDto>;
}
/**
 * 
 * @export
 * @interface EmployeeShiftControllerGet200Response
 */
export interface EmployeeShiftControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeShiftControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeShiftDto>}
     * @memberof EmployeeShiftControllerGet200Response
     */
    'data'?: Array<GetEmployeeShiftDto>;
}
/**
 * 
 * @export
 * @interface EmployeeShiftScheduleStoredProcEntity
 */
export interface EmployeeShiftScheduleStoredProcEntity {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'calendar_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'calendar_month'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'user_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'bucket_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'shift_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'effectivity_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'end_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'is_permanent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'util_shift_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'shift_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'shift_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'utc_shift_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'utc_shift_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'ph_shift_start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'ph_shift_start_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'utc_shift_start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'utc_shift_start_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'work_hours'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'util_shift_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'username'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'is_cutoff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'is_payday'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'origin_branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'origin_branch_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'origin_branch_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'util_shift_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShiftScheduleStoredProcEntity
     */
    'util_shift_description'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeTimelineControllerGet200Response
 */
export interface EmployeeTimelineControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeTimelineControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmployeeTimelineDto>}
     * @memberof EmployeeTimelineControllerGet200Response
     */
    'data'?: Array<GetEmployeeTimelineDto>;
}
/**
 * 
 * @export
 * @interface GenericErrorResponseDto
 */
export interface GenericErrorResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GenericErrorResponseDto
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof GenericErrorResponseDto
     */
    'status_code'?: number;
}
/**
 * 
 * @export
 * @interface GetAttachmentTypeDto
 */
export interface GetAttachmentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetAttachmentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetBirthdayDto
 */
export interface GetBirthdayDto {
    /**
     * 
     * @type {string}
     * @memberof GetBirthdayDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBirthdayDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBirthdayDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBirthdayDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBirthdayDto
     */
    'person_last_name': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBirthdayDto
     */
    'birthday'?: string;
}
/**
 * 
 * @export
 * @interface GetBlacklistDto
 */
export interface GetBlacklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetBlacklistDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'mobile_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'email': string;
    /**
     * MALE | FEMALE
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'gender': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'employee_no': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'signed_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'start_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'job_title_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'job_title_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'job_grade_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'job_grade_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'employment_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'employment_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'branch_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'termination_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'termination_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'termination_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetBlacklistDto
     */
    'has_resignation_letter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBlacklistDto
     */
    'has_clearance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'mgmt_feedback'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'memo_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'memo_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBlacklistDto
     */
    'memo_type_description'?: string;
}
/**
 * 
 * @export
 * @interface GetBranchDailyDemographicsDto
 */
export interface GetBranchDailyDemographicsDto {
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetBranchDailyDemographicsDto
     */
    'work_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDailyDemographicsDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDailyDemographicsDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDailyDemographicsDto
     */
    'branch_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsDto
     */
    'absent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsDto
     */
    'onleave'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsDto
     */
    'onduty'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsDto
     */
    'incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsDto
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsDto
     */
    'suspend'?: number;
}
/**
 * 
 * @export
 * @interface GetBranchDailyDemographicsV2Dto
 */
export interface GetBranchDailyDemographicsV2Dto {
    /**
     * 
     * @type {string}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'branch_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'absent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'onleave'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'onduty'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'suspend'?: number;
    /**
     * 
     * @type {Array<GetBirthdayDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'birthdays': Array<GetBirthdayDto>;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'approved_request'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'rejected_request'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'pending_request'?: number;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'incident_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'suspend_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'absent_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'onleave_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'onduty_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'approved_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'rejected_metadata': Array<GetMetadataDto>;
    /**
     * 
     * @type {Array<GetMetadataDto>}
     * @memberof GetBranchDailyDemographicsV2Dto
     */
    'pending_metadata': Array<GetMetadataDto>;
}
/**
 * 
 * @export
 * @interface GetBranchDto
 */
export interface GetBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'head'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'head_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'head_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchDto
     */
    'head_last_name': string;
    /**
     * 
     * @type {number}
     * @memberof GetBranchDto
     */
    'population'?: number;
}
/**
 * 
 * @export
 * @interface GetBranchScoreDto
 */
export interface GetBranchScoreDto {
    /**
     * 
     * @type {string}
     * @memberof GetBranchScoreDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchScoreDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBranchScoreDto
     */
    'branch_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetBranchScoreDto
     */
    'tally'?: number;
}
/**
 * 
 * @export
 * @interface GetConfigDto
 */
export interface GetConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetConfigDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetConfigDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetConfigDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetConfigDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface GetDepartmentDto
 */
export interface GetDepartmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'head'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'head_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'head_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentDto
     */
    'head_last_name': string;
}
/**
 * 
 * @export
 * @interface GetEmployeeAttachmentDto
 */
export interface GetEmployeeAttachmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeAttachmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'attachment_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'attachment_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'attachment_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'request_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'file': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeAttachmentDto
     */
    'mime_type'?: string;
}
/**
 * 
 * @export
 * @interface GetEmployeeBranchDto
 */
export interface GetEmployeeBranchDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeBranchDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'branch_description': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'effectivity_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeBranchDto
     */
    'end_date'?: string;
}
/**
 * 
 * @export
 * @interface GetEmployeeBucketDto
 */
export interface GetEmployeeBucketDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeBucketDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'employee_no': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'signed_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'start_date'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'job_title_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'job_title_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'job_grade_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'job_grade_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'employment_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'employment_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'branch_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'reporting_to': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'reporting_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'reporting_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'reporting_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'role_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'role_description': string;
    /**
     * 
     * @type {GetEmployeeSalaryDto}
     * @memberof GetEmployeeBucketDto
     */
    'salary': GetEmployeeSalaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'termination_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'termination_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'termination_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeBucketDto
     */
    'has_resignation_letter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeBucketDto
     */
    'has_clearance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'mgmt_feedback'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'memo_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'memo_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketDto
     */
    'memo_type_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeBucketDto
     */
    'sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeBucketDto
     */
    'philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeBucketDto
     */
    'pagibig'?: number;
}
/**
 * 
 * @export
 * @interface GetEmployeeBucketWithTempBranchDto
 */
export interface GetEmployeeBucketWithTempBranchDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeBucketWithTempBranchDto
     */
    'branch_description': string;
}
/**
 * 
 * @export
 * @interface GetEmployeeDeductionDto
 */
export interface GetEmployeeDeductionDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeDeductionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeDeductionDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDeductionDto
     */
    'incident_code': string;
    /**
     * 
     * @type {GetEmployeeIncidentDto}
     * @memberof GetEmployeeDeductionDto
     */
    'incident': GetEmployeeIncidentDto;
}
/**
 * 
 * @export
 * @interface GetEmployeeDiciplinaryDto
 */
export interface GetEmployeeDiciplinaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'branch_description': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'incident_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'issued_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'violation_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'violation_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'violation_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'infraction_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'infraction_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'infraction_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'penalty_schedule_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'penalty_schedule_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'penalty_schedule_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'action_plan'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'followup_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'issuer': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'issuer_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'issuer_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'issuer_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'supervisor': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'supervisor_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'supervisor_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'supervisor_last_name': string;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeeDiciplinaryDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface GetEmployeeFavoriteDto
 */
export interface GetEmployeeFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'favorite_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'favorite_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'favorite_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'branch_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'job_title_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'job_title_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'job_grade_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'job_grade_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'employment_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'employment_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeFavoriteDto
     */
    'department_description': string;
}
/**
 * 
 * @export
 * @interface GetEmployeeIncentiveDto
 */
export interface GetEmployeeIncentiveDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeIncentiveDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeIncentiveDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'status_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'status_description': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'approved_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'approved_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'approved_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'cancelled_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'cancelled_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'cancelled_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'cancelled_last_name': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'rejected_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'rejected_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'rejected_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncentiveDto
     */
    'rejected_last_name': string;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeeIncentiveDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface GetEmployeeIncidentBasicDto
 */
export interface GetEmployeeIncidentBasicDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'incident_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'payment_method': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeIncidentBasicDto
     */
    'payment_start': string;
}
/**
 * 
 * @export
 * @interface GetEmployeeIncidentDto
 */
export interface GetEmployeeIncidentDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeIncidentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'branch_description': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'incident_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'incident_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'incident_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'payment_method_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'payment_method_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeIncidentDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeIncidentDto
     */
    'balance': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeIncidentDto
     */
    'is_paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeIncidentDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeIncidentDto
     */
    'payment_start': string;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeeIncidentDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface GetEmployeeLoanBasicDto
 */
export interface GetEmployeeLoanBasicDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeLoanBasicDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeLoanBasicDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeLoanBasicDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeLoanBasicDto
     */
    'payment_start': string;
}
/**
 * 
 * @export
 * @interface GetEmployeeLoanDto
 */
export interface GetEmployeeLoanDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeLoanDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeLoanDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'status_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'status_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeLoanDto
     */
    'intervals': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'payment_start': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'approved_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'approved_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'approved_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'cancelled_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'cancelled_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'cancelled_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'cancelled_last_name': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'rejected_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'rejected_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'rejected_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanDto
     */
    'rejected_last_name': string;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeeLoanDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
    /**
     * 
     * @type {Array<GetEmployeeLoanPaymentDto>}
     * @memberof GetEmployeeLoanDto
     */
    'payments': Array<GetEmployeeLoanPaymentDto>;
}
/**
 * 
 * @export
 * @interface GetEmployeeLoanPaymentDto
 */
export interface GetEmployeeLoanPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanPaymentDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeLoanPaymentDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeLoanPaymentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeLoanPaymentDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface GetEmployeeOverageDto
 */
export interface GetEmployeeOverageDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeOverageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeOverageDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeOverageDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeeOverageDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface GetEmployeePaymentDto
 */
export interface GetEmployeePaymentDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeePaymentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeePaymentDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePaymentDto
     */
    'loan_code': string;
    /**
     * 
     * @type {GetEmployeeLoanDto}
     * @memberof GetEmployeePaymentDto
     */
    'loan': GetEmployeeLoanDto;
}
/**
 * 
 * @export
 * @interface GetEmployeePenaltyDto
 */
export interface GetEmployeePenaltyDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeePenaltyDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeePenaltyDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeePenaltyDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeePenaltyDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface GetEmployeeRequestDto
 */
export interface GetEmployeeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'request_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'request_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'request_reason': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_cateogry_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_cateogry_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_subcategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_subcateogry_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'leave_subcateogry_description': string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_current_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_current_clockout'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_clockout'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'shift_current_schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'shift_schedule'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeRequestDto
     */
    'is_permanent'?: boolean;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'shift_day'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'end_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeRequestDto
     */
    'ot_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeRequestDto
     */
    'ot_advance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeRequestDto
     */
    'ot_hazard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'status_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'status_description': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'approved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'approved_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'approved_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'approved_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'approved_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'approved_reason'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'cancelled_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'cancelled_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'cancelled_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'cancelled_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'cancelled_last_name': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'rejected_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'rejected_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'rejected_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'rejected_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'rejected_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'rejected_last_name': string;
    /**
     * 
     * @type {GetShiftDto}
     * @memberof GetEmployeeRequestDto
     */
    'shift_current_schedule_obj': GetShiftDto;
    /**
     * 
     * @type {GetShiftDto}
     * @memberof GetEmployeeRequestDto
     */
    'shift_schedule_obj': GetShiftDto;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetEmployeeRequestDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'requestor_role_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'requestor_role_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'break_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeRequestDto
     */
    'is_break_permision': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_current_break_start': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_current_break_end': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_break_start': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'amend_break_end': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRequestDto
     */
    'branch_description': string;
}
/**
 * 
 * @export
 * @interface GetEmployeeSalaryDto
 */
export interface GetEmployeeSalaryDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeSalaryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'salary_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'salary_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'salary_type_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeSalaryDto
     */
    'amount': number;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeSalaryDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface GetEmployeeScoreDto
 */
export interface GetEmployeeScoreDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeScoreDto
     */
    'branch_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmployeeScoreDto
     */
    'tally'?: number;
}
/**
 * 
 * @export
 * @interface GetEmployeeShiftDto
 */
export interface GetEmployeeShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'shift_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'effectivity_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'end_date'?: string;
    /**
     * MONDAY, TUESDAY, etc...
     * @type {string}
     * @memberof GetEmployeeShiftDto
     */
    'day': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeShiftDto
     */
    'is_permanent'?: boolean;
    /**
     * 
     * @type {GetShiftDto}
     * @memberof GetEmployeeShiftDto
     */
    'shift': GetShiftDto;
}
/**
 * 
 * @export
 * @interface GetEmployeeTimelineDto
 */
export interface GetEmployeeTimelineDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeTimelineDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeTimelineDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface GetEmploymentTypeDto
 */
export interface GetEmploymentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmploymentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmploymentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetFaqDto
 */
export interface GetFaqDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetFaqDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetFaqDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetFaqDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetFaqDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFaqDto
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface GetFavoriteDto
 */
export interface GetFavoriteDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetFavoriteDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFavoriteDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetHolidayDto
 */
export interface GetHolidayDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetHolidayDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetHolidayDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetHolidayDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetHolidayDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'holiday_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'holiday_type_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHolidayDto
     */
    'holiday_type_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'description'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetHolidayDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'branch_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayDto
     */
    'branch_name'?: string;
}
/**
 * 
 * @export
 * @interface GetHolidayTypeDto
 */
export interface GetHolidayTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetHolidayTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetHolidayTypeDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHolidayTypeDto
     */
    'percentage'?: number;
}
/**
 * 
 * @export
 * @interface GetIncidentTypeDto
 */
export interface GetIncidentTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetIncidentTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetInfractionDto
 */
export interface GetInfractionDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetInfractionDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInfractionDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInfractionDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInfractionDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfractionDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetJobGradeDto
 */
export interface GetJobGradeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetJobGradeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobGradeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetJobTitleDto
 */
export interface GetJobTitleDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetJobTitleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetJobTitleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetLeaveCategoryDto
 */
export interface GetLeaveCategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetLeaveCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveCategoryDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetLeaveSubcategoryDto
 */
export interface GetLeaveSubcategoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetLeaveSubcategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveSubcategoryDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetLeaveTypeDto
 */
export interface GetLeaveTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetLeaveTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetLeaveTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetMemoDto
 */
export interface GetMemoDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetMemoDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetMemoDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetMemoDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetMemoDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetMetadataDto
 */
export interface GetMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'person_last_name': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetMetadataDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetadataDto
     */
    'branch_description': string;
}
/**
 * 
 * @export
 * @interface GetPaymentMethodDto
 */
export interface GetPaymentMethodDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentMethodDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetPayrollDto
 */
export interface GetPayrollDto {
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'branch_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'job_title_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'job_title_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'job_grade_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'job_grade_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'employment_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'employment_type_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'util_shift_type_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'util_shift_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'util_shift_type_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'contribution_sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'contribution_philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'contribution_pagibig'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'sss'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'philhealth'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'pagibig'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'tin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPayrollDto
     */
    'work_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'util_shift_schedule': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'util_shift_day': string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPayrollDto
     */
    'clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPayrollDto
     */
    'clockout'?: string;
    /**
     * ON_TIME, LATE
     * @type {string}
     * @memberof GetPayrollDto
     */
    'clockin_status': string;
    /**
     * FINISHED_DAY, UNDERTIME, NO_CLOCKOUT
     * @type {string}
     * @memberof GetPayrollDto
     */
    'clockout_status': string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'late'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'undertime'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'excess_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'total_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'break_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'util_shift_work_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'util_shift_grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'util_shift_break'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPayrollDto
     */
    'is_absent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPayrollDto
     */
    'is_onleave'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPayrollDto
     */
    'is_overtime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'daily_salary': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'minute_rate': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'deduction_late': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'deduction_undertime': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'deduction_break': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'deduction_penalty': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'deduction_overage': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'bonus_incentive': number;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'holiday_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'holiday_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'holiday_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollDto
     */
    'holiday_type_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'bonus_holiday': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'ot_minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'ot_pay': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollDto
     */
    'daily_rate': number;
    /**
     * 
     * @type {Array<PayrollBreakDto>}
     * @memberof GetPayrollDto
     */
    'break': Array<PayrollBreakDto>;
}
/**
 * 
 * @export
 * @interface GetPayrollSummaryDto
 */
export interface GetPayrollSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'branch_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'job_title_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'job_title_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'job_grade': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'job_grade_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'job_grade_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'employment_type': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'employment_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPayrollSummaryDto
     */
    'employment_type_description': string;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'sss'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'philhealth'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'pagibig'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'lates'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'undertimes'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'breaks'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'absents'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'leaves'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'overtimes'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'daily_salary': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'minute_rate': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_late': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_undertime': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_break': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_absent': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_penalty': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_overage': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_incident': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'deduction_payment': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'incident_balance': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'loan_balance': number;
    /**
     * 
     * @type {Array<GetEmployeeIncidentBasicDto>}
     * @memberof GetPayrollSummaryDto
     */
    'incident': Array<GetEmployeeIncidentBasicDto>;
    /**
     * 
     * @type {Array<GetEmployeeLoanBasicDto>}
     * @memberof GetPayrollSummaryDto
     */
    'loan': Array<GetEmployeeLoanBasicDto>;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'bonus_incentive': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'bonus_holiday': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'holidays': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'overtime_minutes': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'overtime_pay': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'gross_salary_with_addendum': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'net_salary_after_deductions_without_contribution': number;
    /**
     * 
     * @type {number}
     * @memberof GetPayrollSummaryDto
     */
    'no_of_days': number;
}
/**
 * 
 * @export
 * @interface GetPenaltyScheduleDto
 */
export interface GetPenaltyScheduleDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetPenaltyScheduleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPenaltyScheduleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetPersonAddressDto
 */
export interface GetPersonAddressDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonAddressDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonAddressDto
     */
    'zip'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPersonAddressDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPersonAddressDto
     */
    'latitude'?: number;
}
/**
 * 
 * @export
 * @interface GetPersonDto
 */
export interface GetPersonDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'activation_code'?: string;
    /**
     * MALE | FEMALE
     * @type {string}
     * @memberof GetPersonDto
     */
    'gender': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPersonDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    'is_blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'mobile_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'father_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'mother_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'emergency_contact_1_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'emergency_contact_1_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'emergency_contact_1_relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'emergency_contact_2_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'emergency_contact_2_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'emergency_contact_2_relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'sss'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'philhealth'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'pagibig'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'medical_condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'blood_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'elementary'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'high_school'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'college'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'post_college'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'undergraduate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'curriculum_vitae'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'supporting_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'intent_letter'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'application_letter'?: string;
    /**
     * 
     * @type {GetEmployeeBucketDto}
     * @memberof GetPersonDto
     */
    'bucket': GetEmployeeBucketDto;
    /**
     * 
     * @type {GetPersonAddressDto}
     * @memberof GetPersonDto
     */
    'address': GetPersonAddressDto;
    /**
     * 
     * @type {Array<GetEmployeeAttachmentDto>}
     * @memberof GetPersonDto
     */
    'attachments': Array<GetEmployeeAttachmentDto>;
}
/**
 * 
 * @export
 * @interface GetQuestionnaireDto
 */
export interface GetQuestionnaireDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetQuestionnaireDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetQuestionnaireDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetRequestTypeDto
 */
export interface GetRequestTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetRequestTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetRoleDto
 */
export interface GetRoleDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetRoleDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetRoleDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetRoleDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetRoleDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetSalaryTypeDto
 */
export interface GetSalaryTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetSalaryTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetSalaryTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetShiftDto
 */
export interface GetShiftDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetShiftDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetShiftDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetShiftDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetShiftDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'shift_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'shift_type_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof GetShiftDto
     */
    'half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetShiftDto
     */
    'grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetShiftDto
     */
    'work_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetShiftDto
     */
    'break'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'break_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetShiftDto
     */
    'is_open'?: boolean;
}
/**
 * 
 * @export
 * @interface GetShiftTypeDto
 */
export interface GetShiftTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetShiftTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetShiftTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetStatusDto
 */
export interface GetStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetTerminationTypeDto
 */
export interface GetTerminationTypeDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetTerminationTypeDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetTerminationTypeDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetViewDtrDto
 */
export interface GetViewDtrDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetViewDtrDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'shift_code': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'work_date': string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'clockin'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'clockout'?: string;
    /**
     * ON_TIME, LATE
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'clockin_status': string;
    /**
     * FINISHED_DAY, UNDERTIME, NO_CLOCKOUT
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'clockout_status': string | null;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'late'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'undertime'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'excess_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'total_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'break_hours'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetViewDtrDto
     */
    'is_absent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetViewDtrDto
     */
    'is_onleave'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetViewDtrDto
     */
    'is_amended'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetViewDtrDto
     */
    'is_overtime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'request_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'branch_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'branch_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'util_shift_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'util_shift_schedule': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'util_shift_day': string;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'util_shift_half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'util_shift_grace_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'util_shift_work_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetViewDtrDto
     */
    'util_shift_break'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'util_shift_type_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'util_shift_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViewDtrDto
     */
    'util_shift_type_description': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetViewDtrDto
     */
    'has_approved_otr'?: boolean;
}
/**
 * 
 * @export
 * @interface GetViolationDto
 */
export interface GetViolationDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetViolationDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViolationDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViolationDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetViolationDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'created_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'created_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'created_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'updated_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'updated_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'updated_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'deleted_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'deleted_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'deleted_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetViolationDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface LeaveEmployeeRequestDto
 */
export interface LeaveEmployeeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof LeaveEmployeeRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'request_reason': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'leave_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'leave_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'leave_subcategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEmployeeRequestDto
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface MessageArrayDataResponseDto
 */
export interface MessageArrayDataResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MessageArrayDataResponseDto
     */
    'message': string;
    /**
     * 
     * @type {Array<MessageWithIdentifierDto>}
     * @memberof MessageArrayDataResponseDto
     */
    'data': Array<MessageWithIdentifierDto>;
}
/**
 * 
 * @export
 * @interface MessageResponseDto
 */
export interface MessageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof MessageResponseDto
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface MessageWithIdentifierDto
 */
export interface MessageWithIdentifierDto {
    /**
     * 
     * @type {string}
     * @memberof MessageWithIdentifierDto
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof MessageWithIdentifierDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface OffboardEmployeeBucketDto
 */
export interface OffboardEmployeeBucketDto {
    /**
     * 
     * @type {boolean}
     * @memberof OffboardEmployeeBucketDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'offboard_reason'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'resignation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'termination_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardEmployeeBucketDto
     */
    'has_resignation_letter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardEmployeeBucketDto
     */
    'has_clearance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'mgmt_feedback'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffboardEmployeeBucketDto
     */
    'memo_type'?: string;
}
/**
 * 
 * @export
 * @interface OvertimeEmployeeRequestDto
 */
export interface OvertimeEmployeeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof OvertimeEmployeeRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'user_code': string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'request_type': string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'request_reason': string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'effectivity_date': string;
    /**
     * 
     * @type {number}
     * @memberof OvertimeEmployeeRequestDto
     */
    'ot_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OvertimeEmployeeRequestDto
     */
    'ot_advance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OvertimeEmployeeRequestDto
     */
    'ot_hazard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OvertimeEmployeeRequestDto
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface PayrollBreakDto
 */
export interface PayrollBreakDto {
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof PayrollBreakDto
     */
    'break_start'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof PayrollBreakDto
     */
    'break_end'?: string;
}
/**
 * 
 * @export
 * @interface PayrollControllerGet200Response
 */
export interface PayrollControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof PayrollControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPayrollDto>}
     * @memberof PayrollControllerGet200Response
     */
    'data'?: Array<GetPayrollDto>;
}
/**
 * 
 * @export
 * @interface PayrollControllerSummary200Response
 */
export interface PayrollControllerSummary200Response {
    /**
     * 
     * @type {number}
     * @memberof PayrollControllerSummary200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPayrollSummaryDto>}
     * @memberof PayrollControllerSummary200Response
     */
    'data'?: Array<GetPayrollSummaryDto>;
}
/**
 * 
 * @export
 * @interface PerBranchShiftScheduleDto
 */
export interface PerBranchShiftScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof PerBranchShiftScheduleDto
     */
    'date_from': string;
    /**
     * 
     * @type {string}
     * @memberof PerBranchShiftScheduleDto
     */
    'date_to': string;
    /**
     * 
     * @type {string}
     * @memberof PerBranchShiftScheduleDto
     */
    'branch_code': string;
}
/**
 * 
 * @export
 * @interface PersonAddressControllerGet200Response
 */
export interface PersonAddressControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof PersonAddressControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPersonAddressDto>}
     * @memberof PersonAddressControllerGet200Response
     */
    'data'?: Array<GetPersonAddressDto>;
}
/**
 * 
 * @export
 * @interface PersonControllerGet200Response
 */
export interface PersonControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof PersonControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPersonDto>}
     * @memberof PersonControllerGet200Response
     */
    'data'?: Array<GetPersonDto>;
}
/**
 * 
 * @export
 * @interface PersonControllerGetBlacklist200Response
 */
export interface PersonControllerGetBlacklist200Response {
    /**
     * 
     * @type {number}
     * @memberof PersonControllerGetBlacklist200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetBlacklistDto>}
     * @memberof PersonControllerGetBlacklist200Response
     */
    'data'?: Array<GetBlacklistDto>;
}
/**
 * 
 * @export
 * @interface ProcessEmployeeRequestDto
 */
export interface ProcessEmployeeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessEmployeeRequestDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessEmployeeRequestDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessEmployeeRequestDto
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface ProcessIncentiveDto
 */
export interface ProcessIncentiveDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessIncentiveDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessIncentiveDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessIncentiveDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessIncentiveDto
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface ProcessLoanDto
 */
export interface ProcessLoanDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessLoanDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessLoanDto
     */
    'bucket_code': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessLoanDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessLoanDto
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface RenewRefreshTokenDto
 */
export interface RenewRefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RenewRefreshTokenDto
     */
    'user_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewRefreshTokenDto
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof RenewRefreshTokenDto
     */
    'refresh_exp'?: number;
}
/**
 * 
 * @export
 * @interface ReportControllerBranchDailyDemographics200Response
 */
export interface ReportControllerBranchDailyDemographics200Response {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerBranchDailyDemographics200Response
     */
    'message'?: string;
    /**
     * 
     * @type {GetBranchDailyDemographicsDto}
     * @memberof ReportControllerBranchDailyDemographics200Response
     */
    'data'?: GetBranchDailyDemographicsDto;
}
/**
 * 
 * @export
 * @interface ReportControllerBranchDailyDemographicsV2200Response
 */
export interface ReportControllerBranchDailyDemographicsV2200Response {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerBranchDailyDemographicsV2200Response
     */
    'message'?: string;
    /**
     * 
     * @type {GetBranchDailyDemographicsV2Dto}
     * @memberof ReportControllerBranchDailyDemographicsV2200Response
     */
    'data'?: GetBranchDailyDemographicsV2Dto;
}
/**
 * 
 * @export
 * @interface ReportControllerDailyPerformance200Response
 */
export interface ReportControllerDailyPerformance200Response {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerDailyPerformance200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DailyPerformanceDto}
     * @memberof ReportControllerDailyPerformance200Response
     */
    'data'?: DailyPerformanceDto;
}
/**
 * 
 * @export
 * @interface ShiftScheduleListDto
 */
export interface ShiftScheduleListDto {
    /**
     * 
     * @type {string}
     * @memberof ShiftScheduleListDto
     */
    'date_from': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftScheduleListDto
     */
    'date_to': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShiftScheduleListDto
     */
    'bucket_code': Array<string>;
}
/**
 * 
 * @export
 * @interface SigninUserRequestDto
 */
export interface SigninUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SigninUserRequestDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SigninUserRequestDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SwaggerExceptionResponseDto
 */
export interface SwaggerExceptionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SwaggerExceptionResponseDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    'user_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenDto
     */
    'access_exp'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenDto
     */
    'refresh_exp'?: number;
}
/**
 * 
 * @export
 * @interface UtilAttachmentTypeControllerGet200Response
 */
export interface UtilAttachmentTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilAttachmentTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetAttachmentTypeDto>}
     * @memberof UtilAttachmentTypeControllerGet200Response
     */
    'data'?: Array<GetAttachmentTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilBranchControllerGet200Response
 */
export interface UtilBranchControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilBranchControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetBranchDto>}
     * @memberof UtilBranchControllerGet200Response
     */
    'data'?: Array<GetBranchDto>;
}
/**
 * 
 * @export
 * @interface UtilConfigControllerGet200Response
 */
export interface UtilConfigControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilConfigControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetConfigDto>}
     * @memberof UtilConfigControllerGet200Response
     */
    'data'?: Array<GetConfigDto>;
}
/**
 * 
 * @export
 * @interface UtilDepartmentControllerGet200Response
 */
export interface UtilDepartmentControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilDepartmentControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetDepartmentDto>}
     * @memberof UtilDepartmentControllerGet200Response
     */
    'data'?: Array<GetDepartmentDto>;
}
/**
 * 
 * @export
 * @interface UtilEmploymentTypeControllerGet200Response
 */
export interface UtilEmploymentTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilEmploymentTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetEmploymentTypeDto>}
     * @memberof UtilEmploymentTypeControllerGet200Response
     */
    'data'?: Array<GetEmploymentTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilFaqControllerGet200Response
 */
export interface UtilFaqControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilFaqControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetFaqDto>}
     * @memberof UtilFaqControllerGet200Response
     */
    'data'?: Array<GetFaqDto>;
}
/**
 * 
 * @export
 * @interface UtilFavoriteControllerGet200Response
 */
export interface UtilFavoriteControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilFavoriteControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetFavoriteDto>}
     * @memberof UtilFavoriteControllerGet200Response
     */
    'data'?: Array<GetFavoriteDto>;
}
/**
 * 
 * @export
 * @interface UtilHolidayControllerGet200Response
 */
export interface UtilHolidayControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilHolidayControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetHolidayDto>}
     * @memberof UtilHolidayControllerGet200Response
     */
    'data'?: Array<GetHolidayDto>;
}
/**
 * 
 * @export
 * @interface UtilHolidayTypeControllerGet200Response
 */
export interface UtilHolidayTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilHolidayTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetHolidayTypeDto>}
     * @memberof UtilHolidayTypeControllerGet200Response
     */
    'data'?: Array<GetHolidayTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilIncidentTypeControllerGet200Response
 */
export interface UtilIncidentTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilIncidentTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetIncidentTypeDto>}
     * @memberof UtilIncidentTypeControllerGet200Response
     */
    'data'?: Array<GetIncidentTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilInfractionControllerGet200Response
 */
export interface UtilInfractionControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilInfractionControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetInfractionDto>}
     * @memberof UtilInfractionControllerGet200Response
     */
    'data'?: Array<GetInfractionDto>;
}
/**
 * 
 * @export
 * @interface UtilJobGradeControllerGet200Response
 */
export interface UtilJobGradeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilJobGradeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetJobGradeDto>}
     * @memberof UtilJobGradeControllerGet200Response
     */
    'data'?: Array<GetJobGradeDto>;
}
/**
 * 
 * @export
 * @interface UtilJobTitleControllerGet200Response
 */
export interface UtilJobTitleControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilJobTitleControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetJobTitleDto>}
     * @memberof UtilJobTitleControllerGet200Response
     */
    'data'?: Array<GetJobTitleDto>;
}
/**
 * 
 * @export
 * @interface UtilLeaveCategoryControllerGet200Response
 */
export interface UtilLeaveCategoryControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilLeaveCategoryControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetLeaveCategoryDto>}
     * @memberof UtilLeaveCategoryControllerGet200Response
     */
    'data'?: Array<GetLeaveCategoryDto>;
}
/**
 * 
 * @export
 * @interface UtilLeaveSubcategoryControllerGet200Response
 */
export interface UtilLeaveSubcategoryControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilLeaveSubcategoryControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetLeaveSubcategoryDto>}
     * @memberof UtilLeaveSubcategoryControllerGet200Response
     */
    'data'?: Array<GetLeaveSubcategoryDto>;
}
/**
 * 
 * @export
 * @interface UtilLeaveTypeControllerGet200Response
 */
export interface UtilLeaveTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilLeaveTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetLeaveTypeDto>}
     * @memberof UtilLeaveTypeControllerGet200Response
     */
    'data'?: Array<GetLeaveTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilMemoControllerGet200Response
 */
export interface UtilMemoControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilMemoControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetMemoDto>}
     * @memberof UtilMemoControllerGet200Response
     */
    'data'?: Array<GetMemoDto>;
}
/**
 * 
 * @export
 * @interface UtilPaymentMethodControllerGet200Response
 */
export interface UtilPaymentMethodControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilPaymentMethodControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPaymentMethodDto>}
     * @memberof UtilPaymentMethodControllerGet200Response
     */
    'data'?: Array<GetPaymentMethodDto>;
}
/**
 * 
 * @export
 * @interface UtilPenaltyScheduleControllerGet200Response
 */
export interface UtilPenaltyScheduleControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilPenaltyScheduleControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPenaltyScheduleDto>}
     * @memberof UtilPenaltyScheduleControllerGet200Response
     */
    'data'?: Array<GetPenaltyScheduleDto>;
}
/**
 * 
 * @export
 * @interface UtilQuestionnaireControllerGet200Response
 */
export interface UtilQuestionnaireControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilQuestionnaireControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetQuestionnaireDto>}
     * @memberof UtilQuestionnaireControllerGet200Response
     */
    'data'?: Array<GetQuestionnaireDto>;
}
/**
 * 
 * @export
 * @interface UtilRequestTypeControllerGet200Response
 */
export interface UtilRequestTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilRequestTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetRequestTypeDto>}
     * @memberof UtilRequestTypeControllerGet200Response
     */
    'data'?: Array<GetRequestTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilRoleControllerGet200Response
 */
export interface UtilRoleControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilRoleControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetRoleDto>}
     * @memberof UtilRoleControllerGet200Response
     */
    'data'?: Array<GetRoleDto>;
}
/**
 * 
 * @export
 * @interface UtilSalaryTypeControllerGet200Response
 */
export interface UtilSalaryTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilSalaryTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetSalaryTypeDto>}
     * @memberof UtilSalaryTypeControllerGet200Response
     */
    'data'?: Array<GetSalaryTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilShiftControllerGet200Response
 */
export interface UtilShiftControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilShiftControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetShiftDto>}
     * @memberof UtilShiftControllerGet200Response
     */
    'data'?: Array<GetShiftDto>;
}
/**
 * 
 * @export
 * @interface UtilShiftTypeControllerGet200Response
 */
export interface UtilShiftTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilShiftTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetShiftTypeDto>}
     * @memberof UtilShiftTypeControllerGet200Response
     */
    'data'?: Array<GetShiftTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilStatusControllerGet200Response
 */
export interface UtilStatusControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilStatusControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetStatusDto>}
     * @memberof UtilStatusControllerGet200Response
     */
    'data'?: Array<GetStatusDto>;
}
/**
 * 
 * @export
 * @interface UtilTerminationTypeControllerGet200Response
 */
export interface UtilTerminationTypeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilTerminationTypeControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetTerminationTypeDto>}
     * @memberof UtilTerminationTypeControllerGet200Response
     */
    'data'?: Array<GetTerminationTypeDto>;
}
/**
 * 
 * @export
 * @interface UtilViolationControllerGet200Response
 */
export interface UtilViolationControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilViolationControllerGet200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetViolationDto>}
     * @memberof UtilViolationControllerGet200Response
     */
    'data'?: Array<GetViolationDto>;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SigninUserRequestDto} signinUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerAuthenticate: async (signinUserRequestDto: SigninUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signinUserRequestDto' is not null or undefined
            assertParamExists('authenticationControllerAuthenticate', 'signinUserRequestDto', signinUserRequestDto)
            const localVarPath = `/api/v1/authentication/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signinUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerIntrospect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authentication/instrospect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerRefresh: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authentication/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerRefreshRenew: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authentication/refresh/renew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SigninUserRequestDto} signinUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerAuthenticate(signinUserRequestDto: SigninUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationControllerAuthenticate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerAuthenticate(signinUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationControllerAuthenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerIntrospect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerIntrospect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationControllerIntrospect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerRefresh(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationControllerAuthenticate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerRefresh(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationControllerRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerRefreshRenew(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationControllerRefreshRenew201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerRefreshRenew(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationControllerRefreshRenew']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {SigninUserRequestDto} signinUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerAuthenticate(signinUserRequestDto: SigninUserRequestDto, options?: any): AxiosPromise<AuthenticationControllerAuthenticate201Response> {
            return localVarFp.authenticationControllerAuthenticate(signinUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerIntrospect(options?: any): AxiosPromise<boolean> {
            return localVarFp.authenticationControllerIntrospect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerRefresh(options?: any): AxiosPromise<AuthenticationControllerAuthenticate201Response> {
            return localVarFp.authenticationControllerRefresh(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerRefreshRenew(options?: any): AxiosPromise<AuthenticationControllerRefreshRenew201Response> {
            return localVarFp.authenticationControllerRefreshRenew(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {SigninUserRequestDto} signinUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationControllerAuthenticate(signinUserRequestDto: SigninUserRequestDto, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationControllerAuthenticate(signinUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationControllerIntrospect(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationControllerIntrospect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationControllerRefresh(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationControllerRefresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationControllerRefreshRenew(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationControllerRefreshRenew(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DailyTimeRecordApi - axios parameter creator
 * @export
 */
export const DailyTimeRecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerClockinBreak: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerClockinBreak', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/public/break/clockin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerClockoutBreak: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerClockoutBreak', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/public/break/clockout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PerBranchShiftScheduleDto} perBranchShiftScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerEmployeePerBranchShift: async (perBranchShiftScheduleDto: PerBranchShiftScheduleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perBranchShiftScheduleDto' is not null or undefined
            assertParamExists('employeeDtrControllerEmployeePerBranchShift', 'perBranchShiftScheduleDto', perBranchShiftScheduleDto)
            const localVarPath = `/api/v1/dtr/branch/shift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(perBranchShiftScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShiftScheduleListDto} shiftScheduleListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerEmployeeShift: async (shiftScheduleListDto: ShiftScheduleListDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shiftScheduleListDto' is not null or undefined
            assertParamExists('employeeDtrControllerEmployeeShift', 'shiftScheduleListDto', shiftScheduleListDto)
            const localVarPath = `/api/v1/dtr/shift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shiftScheduleListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DailyTimeRecordDto} dailyTimeRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGenerateDtrQR: async (dailyTimeRecordDto: DailyTimeRecordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dailyTimeRecordDto' is not null or undefined
            assertParamExists('employeeDtrControllerGenerateDtrQR', 'dailyTimeRecordDto', dailyTimeRecordDto)
            const localVarPath = `/api/v1/dtr/generate/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dailyTimeRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dtr/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workDate 
         * @param {string} bucketCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGetBreak: async (workDate: string, bucketCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workDate' is not null or undefined
            assertParamExists('employeeDtrControllerGetBreak', 'workDate', workDate)
            // verify required parameter 'bucketCode' is not null or undefined
            assertParamExists('employeeDtrControllerGetBreak', 'bucketCode', bucketCode)
            const localVarPath = `/api/v1/dtr/get/breaks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workDate !== undefined) {
                localVarQueryParameter['work_date'] = (workDate as any instanceof Date) ?
                    (workDate as any).toISOString() :
                    workDate;
            }

            if (bucketCode !== undefined) {
                localVarQueryParameter['bucket_code'] = bucketCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGetDtrForOvertime: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dtr/for-overtime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShiftScheduleListDto} shiftScheduleListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGetEmployeeDailyTimeRecord: async (shiftScheduleListDto: ShiftScheduleListDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shiftScheduleListDto' is not null or undefined
            assertParamExists('employeeDtrControllerGetEmployeeDailyTimeRecord', 'shiftScheduleListDto', shiftScheduleListDto)
            const localVarPath = `/api/v1/dtr/records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shiftScheduleListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerPrivateClockinBreak: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerPrivateClockinBreak', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/private/break/clockin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerPrivateClockoutBreak: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerPrivateClockoutBreak', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/private/break/clockout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClockinDto} clockinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessClockin: async (clockinDto: ClockinDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clockinDto' is not null or undefined
            assertParamExists('employeeDtrControllerProcessClockin', 'clockinDto', clockinDto)
            const localVarPath = `/api/v1/dtr/clockin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clockinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClockoutDto} clockoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessClockout: async (clockoutDto: ClockoutDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clockoutDto' is not null or undefined
            assertParamExists('employeeDtrControllerProcessClockout', 'clockoutDto', clockoutDto)
            const localVarPath = `/api/v1/dtr/clockout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clockoutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPrivateClockin: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerProcessPrivateClockin', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/private/clockin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPrivateClockout: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerProcessPrivateClockout', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/private/clockout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPublicClockin: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerProcessPublicClockin', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/public/clockin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPublicClockout: async (dtrPublicDto: DtrPublicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dtrPublicDto' is not null or undefined
            assertParamExists('employeeDtrControllerProcessPublicClockout', 'dtrPublicDto', dtrPublicDto)
            const localVarPath = `/api/v1/dtr/public/clockout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dtrPublicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DailyTimeRecordApi - functional programming interface
 * @export
 */
export const DailyTimeRecordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DailyTimeRecordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerClockinBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerClockinBreak(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerClockinBreak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerClockoutBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerClockoutBreak(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerClockoutBreak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PerBranchShiftScheduleDto} perBranchShiftScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerEmployeePerBranchShift(perBranchShiftScheduleDto: PerBranchShiftScheduleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerEmployeeShift200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerEmployeePerBranchShift(perBranchShiftScheduleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerEmployeePerBranchShift']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ShiftScheduleListDto} shiftScheduleListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerEmployeeShift(shiftScheduleListDto: ShiftScheduleListDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerEmployeeShift200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerEmployeeShift(shiftScheduleListDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerEmployeeShift']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DailyTimeRecordDto} dailyTimeRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerGenerateDtrQR(dailyTimeRecordDto: DailyTimeRecordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerGenerateDtrQR201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerGenerateDtrQR(dailyTimeRecordDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerGenerateDtrQR']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workDate 
         * @param {string} bucketCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerGetBreak(workDate: string, bucketCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerGetBreak200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerGetBreak(workDate, bucketCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerGetBreak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerGetDtrForOvertime(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerGetDtrForOvertime(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerGetDtrForOvertime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ShiftScheduleListDto} shiftScheduleListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerGetEmployeeDailyTimeRecord(shiftScheduleListDto: ShiftScheduleListDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerGetEmployeeDailyTimeRecord200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerGetEmployeeDailyTimeRecord(shiftScheduleListDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerGetEmployeeDailyTimeRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerPrivateClockinBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerPrivateClockinBreak(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerPrivateClockinBreak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerPrivateClockoutBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerPrivateClockoutBreak(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerPrivateClockoutBreak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ClockinDto} clockinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerProcessClockin(clockinDto: ClockinDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerProcessClockin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerProcessClockin(clockinDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerProcessClockin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ClockoutDto} clockoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerProcessClockout(clockoutDto: ClockoutDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerProcessClockin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerProcessClockout(clockoutDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerProcessClockout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerProcessPrivateClockin(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerProcessClockin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerProcessPrivateClockin(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerProcessPrivateClockin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerProcessPrivateClockout(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerProcessClockin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerProcessPrivateClockout(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerProcessPrivateClockout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerProcessPublicClockin(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerProcessClockin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerProcessPublicClockin(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerProcessPublicClockin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDtrControllerProcessPublicClockout(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerProcessClockin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDtrControllerProcessPublicClockout(dtrPublicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyTimeRecordApi.employeeDtrControllerProcessPublicClockout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DailyTimeRecordApi - factory interface
 * @export
 */
export const DailyTimeRecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DailyTimeRecordApiFp(configuration)
    return {
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerClockinBreak(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response> {
            return localVarFp.employeeDtrControllerClockinBreak(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerClockoutBreak(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response> {
            return localVarFp.employeeDtrControllerClockoutBreak(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PerBranchShiftScheduleDto} perBranchShiftScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerEmployeePerBranchShift(perBranchShiftScheduleDto: PerBranchShiftScheduleDto, options?: any): AxiosPromise<EmployeeDtrControllerEmployeeShift200Response> {
            return localVarFp.employeeDtrControllerEmployeePerBranchShift(perBranchShiftScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShiftScheduleListDto} shiftScheduleListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerEmployeeShift(shiftScheduleListDto: ShiftScheduleListDto, options?: any): AxiosPromise<EmployeeDtrControllerEmployeeShift200Response> {
            return localVarFp.employeeDtrControllerEmployeeShift(shiftScheduleListDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DailyTimeRecordDto} dailyTimeRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGenerateDtrQR(dailyTimeRecordDto: DailyTimeRecordDto, options?: any): AxiosPromise<EmployeeDtrControllerGenerateDtrQR201Response> {
            return localVarFp.employeeDtrControllerGenerateDtrQR(dailyTimeRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeDtrControllerGet200Response> {
            return localVarFp.employeeDtrControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workDate 
         * @param {string} bucketCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGetBreak(workDate: string, bucketCode: string, options?: any): AxiosPromise<EmployeeDtrControllerGetBreak200Response> {
            return localVarFp.employeeDtrControllerGetBreak(workDate, bucketCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGetDtrForOvertime(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeDtrControllerGet200Response> {
            return localVarFp.employeeDtrControllerGetDtrForOvertime(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShiftScheduleListDto} shiftScheduleListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerGetEmployeeDailyTimeRecord(shiftScheduleListDto: ShiftScheduleListDto, options?: any): AxiosPromise<EmployeeDtrControllerGetEmployeeDailyTimeRecord200Response> {
            return localVarFp.employeeDtrControllerGetEmployeeDailyTimeRecord(shiftScheduleListDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerPrivateClockinBreak(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response> {
            return localVarFp.employeeDtrControllerPrivateClockinBreak(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerPrivateClockoutBreak(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerPrivateClockinBreak201Response> {
            return localVarFp.employeeDtrControllerPrivateClockoutBreak(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClockinDto} clockinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessClockin(clockinDto: ClockinDto, options?: any): AxiosPromise<EmployeeDtrControllerProcessClockin201Response> {
            return localVarFp.employeeDtrControllerProcessClockin(clockinDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClockoutDto} clockoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessClockout(clockoutDto: ClockoutDto, options?: any): AxiosPromise<EmployeeDtrControllerProcessClockin201Response> {
            return localVarFp.employeeDtrControllerProcessClockout(clockoutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPrivateClockin(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerProcessClockin201Response> {
            return localVarFp.employeeDtrControllerProcessPrivateClockin(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPrivateClockout(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerProcessClockin201Response> {
            return localVarFp.employeeDtrControllerProcessPrivateClockout(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPublicClockin(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerProcessClockin201Response> {
            return localVarFp.employeeDtrControllerProcessPublicClockin(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DtrPublicDto} dtrPublicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDtrControllerProcessPublicClockout(dtrPublicDto: DtrPublicDto, options?: any): AxiosPromise<EmployeeDtrControllerProcessClockin201Response> {
            return localVarFp.employeeDtrControllerProcessPublicClockout(dtrPublicDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DailyTimeRecordApi - object-oriented interface
 * @export
 * @class DailyTimeRecordApi
 * @extends {BaseAPI}
 */
export class DailyTimeRecordApi extends BaseAPI {
    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerClockinBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerClockinBreak(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerClockoutBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerClockoutBreak(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PerBranchShiftScheduleDto} perBranchShiftScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerEmployeePerBranchShift(perBranchShiftScheduleDto: PerBranchShiftScheduleDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerEmployeePerBranchShift(perBranchShiftScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShiftScheduleListDto} shiftScheduleListDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerEmployeeShift(shiftScheduleListDto: ShiftScheduleListDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerEmployeeShift(shiftScheduleListDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DailyTimeRecordDto} dailyTimeRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerGenerateDtrQR(dailyTimeRecordDto: DailyTimeRecordDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerGenerateDtrQR(dailyTimeRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workDate 
     * @param {string} bucketCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerGetBreak(workDate: string, bucketCode: string, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerGetBreak(workDate, bucketCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerGetDtrForOvertime(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerGetDtrForOvertime(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShiftScheduleListDto} shiftScheduleListDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerGetEmployeeDailyTimeRecord(shiftScheduleListDto: ShiftScheduleListDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerGetEmployeeDailyTimeRecord(shiftScheduleListDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerPrivateClockinBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerPrivateClockinBreak(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerPrivateClockoutBreak(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerPrivateClockoutBreak(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClockinDto} clockinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerProcessClockin(clockinDto: ClockinDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerProcessClockin(clockinDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClockoutDto} clockoutDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerProcessClockout(clockoutDto: ClockoutDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerProcessClockout(clockoutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerProcessPrivateClockin(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerProcessPrivateClockin(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerProcessPrivateClockout(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerProcessPrivateClockout(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerProcessPublicClockin(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerProcessPublicClockin(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DtrPublicDto} dtrPublicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyTimeRecordApi
     */
    public employeeDtrControllerProcessPublicClockout(dtrPublicDto: DtrPublicDto, options?: RawAxiosRequestConfig) {
        return DailyTimeRecordApiFp(this.configuration).employeeDtrControllerProcessPublicClockout(dtrPublicDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceAuthorizationApi - axios parameter creator
 * @export
 */
export const DeviceAuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeviceAuthorization} deviceAuthorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRegistrationControllerRegister: async (deviceAuthorization: DeviceAuthorization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceAuthorization' is not null or undefined
            assertParamExists('deviceRegistrationControllerRegister', 'deviceAuthorization', deviceAuthorization)
            const localVarPath = `/device/authorization/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAuthorization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceAuthorizationApi - functional programming interface
 * @export
 */
export const DeviceAuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceAuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeviceAuthorization} deviceAuthorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceRegistrationControllerRegister(deviceAuthorization: DeviceAuthorization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDtrControllerGenerateDtrQR201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceRegistrationControllerRegister(deviceAuthorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceAuthorizationApi.deviceRegistrationControllerRegister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceAuthorizationApi - factory interface
 * @export
 */
export const DeviceAuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceAuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @param {DeviceAuthorization} deviceAuthorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRegistrationControllerRegister(deviceAuthorization: DeviceAuthorization, options?: any): AxiosPromise<EmployeeDtrControllerGenerateDtrQR201Response> {
            return localVarFp.deviceRegistrationControllerRegister(deviceAuthorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceAuthorizationApi - object-oriented interface
 * @export
 * @class DeviceAuthorizationApi
 * @extends {BaseAPI}
 */
export class DeviceAuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {DeviceAuthorization} deviceAuthorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceAuthorizationApi
     */
    public deviceRegistrationControllerRegister(deviceAuthorization: DeviceAuthorization, options?: RawAxiosRequestConfig) {
        return DeviceAuthorizationApiFp(this.configuration).deviceRegistrationControllerRegister(deviceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeAttachmentApi - axios parameter creator
 * @export
 */
export const EmployeeAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userCode 
         * @param {string} bucketCode 
         * @param {boolean} [isActive] 
         * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [createdBy] 
         * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [updatedBy] 
         * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [deletedBy] 
         * @param {string} [code] 
         * @param {string} [source] 
         * @param {string} [attachmentType] 
         * @param {string} [requestCode] 
         * @param {File} [file] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerCreate: async (userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCode' is not null or undefined
            assertParamExists('employeeAttachmentControllerCreate', 'userCode', userCode)
            // verify required parameter 'bucketCode' is not null or undefined
            assertParamExists('employeeAttachmentControllerCreate', 'bucketCode', bucketCode)
            const localVarPath = `/api/v1/employee-attachment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (isActive !== undefined) { 
                localVarFormParams.append('is_active', isActive as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('created_at', createdAt as any);
            }
    
            if (createdBy !== undefined) { 
                localVarFormParams.append('created_by', createdBy as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('updated_at', updatedAt as any);
            }
    
            if (updatedBy !== undefined) { 
                localVarFormParams.append('updated_by', updatedBy as any);
            }
    
            if (deletedAt !== undefined) { 
                localVarFormParams.append('deleted_at', deletedAt as any);
            }
    
            if (deletedBy !== undefined) { 
                localVarFormParams.append('deleted_by', deletedBy as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.append('code', code as any);
            }
    
            if (userCode !== undefined) { 
                localVarFormParams.append('user_code', userCode as any);
            }
    
            if (bucketCode !== undefined) { 
                localVarFormParams.append('bucket_code', bucketCode as any);
            }
    
            if (source !== undefined) { 
                localVarFormParams.append('source', source as any);
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', attachmentType as any);
            }
    
            if (requestCode !== undefined) { 
                localVarFormParams.append('request_code', requestCode as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (mimeType !== undefined) { 
                localVarFormParams.append('mime_type', mimeType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeAttachmentDto} deleteEmployeeAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerDelete: async (deleteEmployeeAttachmentDto: DeleteEmployeeAttachmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeAttachmentDto' is not null or undefined
            assertParamExists('employeeAttachmentControllerDelete', 'deleteEmployeeAttachmentDto', deleteEmployeeAttachmentDto)
            const localVarPath = `/api/v1/employee-attachment/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeAttachmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userCode 
         * @param {string} bucketCode 
         * @param {boolean} [isActive] 
         * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [createdBy] 
         * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [updatedBy] 
         * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [deletedBy] 
         * @param {string} [code] 
         * @param {string} [source] 
         * @param {string} [attachmentType] 
         * @param {string} [requestCode] 
         * @param {File} [file] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerEdit: async (userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCode' is not null or undefined
            assertParamExists('employeeAttachmentControllerEdit', 'userCode', userCode)
            // verify required parameter 'bucketCode' is not null or undefined
            assertParamExists('employeeAttachmentControllerEdit', 'bucketCode', bucketCode)
            const localVarPath = `/api/v1/employee-attachment/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (isActive !== undefined) { 
                localVarFormParams.append('is_active', isActive as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('created_at', createdAt as any);
            }
    
            if (createdBy !== undefined) { 
                localVarFormParams.append('created_by', createdBy as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('updated_at', updatedAt as any);
            }
    
            if (updatedBy !== undefined) { 
                localVarFormParams.append('updated_by', updatedBy as any);
            }
    
            if (deletedAt !== undefined) { 
                localVarFormParams.append('deleted_at', deletedAt as any);
            }
    
            if (deletedBy !== undefined) { 
                localVarFormParams.append('deleted_by', deletedBy as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.append('code', code as any);
            }
    
            if (userCode !== undefined) { 
                localVarFormParams.append('user_code', userCode as any);
            }
    
            if (bucketCode !== undefined) { 
                localVarFormParams.append('bucket_code', bucketCode as any);
            }
    
            if (source !== undefined) { 
                localVarFormParams.append('source', source as any);
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', attachmentType as any);
            }
    
            if (requestCode !== undefined) { 
                localVarFormParams.append('request_code', requestCode as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (mimeType !== undefined) { 
                localVarFormParams.append('mime_type', mimeType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-attachment/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeAttachmentApi - functional programming interface
 * @export
 */
export const EmployeeAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userCode 
         * @param {string} bucketCode 
         * @param {boolean} [isActive] 
         * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [createdBy] 
         * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [updatedBy] 
         * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [deletedBy] 
         * @param {string} [code] 
         * @param {string} [source] 
         * @param {string} [attachmentType] 
         * @param {string} [requestCode] 
         * @param {File} [file] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeAttachmentControllerCreate(userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeAttachmentControllerCreate(userCode, bucketCode, isActive, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, code, source, attachmentType, requestCode, file, mimeType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeAttachmentApi.employeeAttachmentControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeAttachmentDto} deleteEmployeeAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeAttachmentControllerDelete(deleteEmployeeAttachmentDto: DeleteEmployeeAttachmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeAttachmentControllerDelete(deleteEmployeeAttachmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeAttachmentApi.employeeAttachmentControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userCode 
         * @param {string} bucketCode 
         * @param {boolean} [isActive] 
         * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [createdBy] 
         * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [updatedBy] 
         * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [deletedBy] 
         * @param {string} [code] 
         * @param {string} [source] 
         * @param {string} [attachmentType] 
         * @param {string} [requestCode] 
         * @param {File} [file] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeAttachmentControllerEdit(userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeAttachmentControllerEdit(userCode, bucketCode, isActive, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, code, source, attachmentType, requestCode, file, mimeType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeAttachmentApi.employeeAttachmentControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeAttachmentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeAttachmentControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeAttachmentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeAttachmentApi.employeeAttachmentControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeAttachmentApi - factory interface
 * @export
 */
export const EmployeeAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userCode 
         * @param {string} bucketCode 
         * @param {boolean} [isActive] 
         * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [createdBy] 
         * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [updatedBy] 
         * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [deletedBy] 
         * @param {string} [code] 
         * @param {string} [source] 
         * @param {string} [attachmentType] 
         * @param {string} [requestCode] 
         * @param {File} [file] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerCreate(userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeAttachmentControllerCreate(userCode, bucketCode, isActive, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, code, source, attachmentType, requestCode, file, mimeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeAttachmentDto} deleteEmployeeAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerDelete(deleteEmployeeAttachmentDto: DeleteEmployeeAttachmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeAttachmentControllerDelete(deleteEmployeeAttachmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userCode 
         * @param {string} bucketCode 
         * @param {boolean} [isActive] 
         * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [createdBy] 
         * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [updatedBy] 
         * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
         * @param {string} [deletedBy] 
         * @param {string} [code] 
         * @param {string} [source] 
         * @param {string} [attachmentType] 
         * @param {string} [requestCode] 
         * @param {File} [file] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerEdit(userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeAttachmentControllerEdit(userCode, bucketCode, isActive, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, code, source, attachmentType, requestCode, file, mimeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeAttachmentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeAttachmentControllerGet200Response> {
            return localVarFp.employeeAttachmentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeAttachmentApi - object-oriented interface
 * @export
 * @class EmployeeAttachmentApi
 * @extends {BaseAPI}
 */
export class EmployeeAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {string} userCode 
     * @param {string} bucketCode 
     * @param {boolean} [isActive] 
     * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @param {string} [createdBy] 
     * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @param {string} [updatedBy] 
     * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @param {string} [deletedBy] 
     * @param {string} [code] 
     * @param {string} [source] 
     * @param {string} [attachmentType] 
     * @param {string} [requestCode] 
     * @param {File} [file] 
     * @param {string} [mimeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeAttachmentApi
     */
    public employeeAttachmentControllerCreate(userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options?: RawAxiosRequestConfig) {
        return EmployeeAttachmentApiFp(this.configuration).employeeAttachmentControllerCreate(userCode, bucketCode, isActive, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, code, source, attachmentType, requestCode, file, mimeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeAttachmentDto} deleteEmployeeAttachmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeAttachmentApi
     */
    public employeeAttachmentControllerDelete(deleteEmployeeAttachmentDto: DeleteEmployeeAttachmentDto, options?: RawAxiosRequestConfig) {
        return EmployeeAttachmentApiFp(this.configuration).employeeAttachmentControllerDelete(deleteEmployeeAttachmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userCode 
     * @param {string} bucketCode 
     * @param {boolean} [isActive] 
     * @param {string} [createdAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @param {string} [createdBy] 
     * @param {string} [updatedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @param {string} [updatedBy] 
     * @param {string} [deletedAt] The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @param {string} [deletedBy] 
     * @param {string} [code] 
     * @param {string} [source] 
     * @param {string} [attachmentType] 
     * @param {string} [requestCode] 
     * @param {File} [file] 
     * @param {string} [mimeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeAttachmentApi
     */
    public employeeAttachmentControllerEdit(userCode: string, bucketCode: string, isActive?: boolean, createdAt?: string, createdBy?: string, updatedAt?: string, updatedBy?: string, deletedAt?: string, deletedBy?: string, code?: string, source?: string, attachmentType?: string, requestCode?: string, file?: File, mimeType?: string, options?: RawAxiosRequestConfig) {
        return EmployeeAttachmentApiFp(this.configuration).employeeAttachmentControllerEdit(userCode, bucketCode, isActive, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, code, source, attachmentType, requestCode, file, mimeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeAttachmentApi
     */
    public employeeAttachmentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeAttachmentApiFp(this.configuration).employeeAttachmentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeBranchApi - axios parameter creator
 * @export
 */
export const EmployeeBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddEmployeeBranchDto} addEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerCreate: async (addEmployeeBranchDto: AddEmployeeBranchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addEmployeeBranchDto' is not null or undefined
            assertParamExists('employeeBranchControllerCreate', 'addEmployeeBranchDto', addEmployeeBranchDto)
            const localVarPath = `/api/v1/employee-branch/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addEmployeeBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeBranchDto} deleteEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerDelete: async (deleteEmployeeBranchDto: DeleteEmployeeBranchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeBranchDto' is not null or undefined
            assertParamExists('employeeBranchControllerDelete', 'deleteEmployeeBranchDto', deleteEmployeeBranchDto)
            const localVarPath = `/api/v1/employee-branch/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeBranchDto} editEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerEdit: async (editEmployeeBranchDto: EditEmployeeBranchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeBranchDto' is not null or undefined
            assertParamExists('employeeBranchControllerEdit', 'editEmployeeBranchDto', editEmployeeBranchDto)
            const localVarPath = `/api/v1/employee-branch/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-branch/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeBranchApi - functional programming interface
 * @export
 */
export const EmployeeBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeBranchDto} addEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBranchControllerCreate(addEmployeeBranchDto: AddEmployeeBranchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBranchControllerCreate(addEmployeeBranchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBranchApi.employeeBranchControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeBranchDto} deleteEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBranchControllerDelete(deleteEmployeeBranchDto: DeleteEmployeeBranchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBranchControllerDelete(deleteEmployeeBranchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBranchApi.employeeBranchControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeBranchDto} editEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBranchControllerEdit(editEmployeeBranchDto: EditEmployeeBranchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBranchControllerEdit(editEmployeeBranchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBranchApi.employeeBranchControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBranchControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeBranchControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBranchControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBranchApi.employeeBranchControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeBranchApi - factory interface
 * @export
 */
export const EmployeeBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeBranchApiFp(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeBranchDto} addEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerCreate(addEmployeeBranchDto: AddEmployeeBranchDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBranchControllerCreate(addEmployeeBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeBranchDto} deleteEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerDelete(deleteEmployeeBranchDto: DeleteEmployeeBranchDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBranchControllerDelete(deleteEmployeeBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeBranchDto} editEmployeeBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerEdit(editEmployeeBranchDto: EditEmployeeBranchDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBranchControllerEdit(editEmployeeBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBranchControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeBranchControllerGet200Response> {
            return localVarFp.employeeBranchControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeBranchApi - object-oriented interface
 * @export
 * @class EmployeeBranchApi
 * @extends {BaseAPI}
 */
export class EmployeeBranchApi extends BaseAPI {
    /**
     * 
     * @param {AddEmployeeBranchDto} addEmployeeBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBranchApi
     */
    public employeeBranchControllerCreate(addEmployeeBranchDto: AddEmployeeBranchDto, options?: RawAxiosRequestConfig) {
        return EmployeeBranchApiFp(this.configuration).employeeBranchControllerCreate(addEmployeeBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeBranchDto} deleteEmployeeBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBranchApi
     */
    public employeeBranchControllerDelete(deleteEmployeeBranchDto: DeleteEmployeeBranchDto, options?: RawAxiosRequestConfig) {
        return EmployeeBranchApiFp(this.configuration).employeeBranchControllerDelete(deleteEmployeeBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeBranchDto} editEmployeeBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBranchApi
     */
    public employeeBranchControllerEdit(editEmployeeBranchDto: EditEmployeeBranchDto, options?: RawAxiosRequestConfig) {
        return EmployeeBranchApiFp(this.configuration).employeeBranchControllerEdit(editEmployeeBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBranchApi
     */
    public employeeBranchControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeBranchApiFp(this.configuration).employeeBranchControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeBucketApi - axios parameter creator
 * @export
 */
export const EmployeeBucketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerBucketWithTemporaryBranch: async (startDate: string, endDate: string, branchCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('employeeBucketControllerBucketWithTemporaryBranch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('employeeBucketControllerBucketWithTemporaryBranch', 'endDate', endDate)
            // verify required parameter 'branchCode' is not null or undefined
            assertParamExists('employeeBucketControllerBucketWithTemporaryBranch', 'branchCode', branchCode)
            const localVarPath = `/api/v1/employee-bucket/employee-with-temp-branch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branch_code'] = branchCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmployeeBucketDto} createEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerCreate: async (createEmployeeBucketDto: CreateEmployeeBucketDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeBucketDto' is not null or undefined
            assertParamExists('employeeBucketControllerCreate', 'createEmployeeBucketDto', createEmployeeBucketDto)
            const localVarPath = `/api/v1/employee-bucket/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeBucketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeBucketDto} deleteEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerDelete: async (deleteEmployeeBucketDto: DeleteEmployeeBucketDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeBucketDto' is not null or undefined
            assertParamExists('employeeBucketControllerDelete', 'deleteEmployeeBucketDto', deleteEmployeeBucketDto)
            const localVarPath = `/api/v1/employee-bucket/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeBucketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeBucketDto} editEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerEdit: async (editEmployeeBucketDto: EditEmployeeBucketDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeBucketDto' is not null or undefined
            assertParamExists('employeeBucketControllerEdit', 'editEmployeeBucketDto', editEmployeeBucketDto)
            const localVarPath = `/api/v1/employee-bucket/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeBucketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-bucket/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OffboardEmployeeBucketDto} offboardEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerOffboard: async (offboardEmployeeBucketDto: OffboardEmployeeBucketDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offboardEmployeeBucketDto' is not null or undefined
            assertParamExists('employeeBucketControllerOffboard', 'offboardEmployeeBucketDto', offboardEmployeeBucketDto)
            const localVarPath = `/api/v1/employee-bucket/offboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offboardEmployeeBucketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeBucketApi - functional programming interface
 * @export
 */
export const EmployeeBucketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeBucketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBucketControllerBucketWithTemporaryBranch(startDate: string, endDate: string, branchCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeBucketControllerBucketWithTemporaryBranch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBucketControllerBucketWithTemporaryBranch(startDate, endDate, branchCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBucketApi.employeeBucketControllerBucketWithTemporaryBranch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateEmployeeBucketDto} createEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBucketControllerCreate(createEmployeeBucketDto: CreateEmployeeBucketDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBucketControllerCreate(createEmployeeBucketDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBucketApi.employeeBucketControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeBucketDto} deleteEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBucketControllerDelete(deleteEmployeeBucketDto: DeleteEmployeeBucketDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBucketControllerDelete(deleteEmployeeBucketDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBucketApi.employeeBucketControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeBucketDto} editEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBucketControllerEdit(editEmployeeBucketDto: EditEmployeeBucketDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBucketControllerEdit(editEmployeeBucketDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBucketApi.employeeBucketControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBucketControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeBucketControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBucketControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBucketApi.employeeBucketControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OffboardEmployeeBucketDto} offboardEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBucketControllerOffboard(offboardEmployeeBucketDto: OffboardEmployeeBucketDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBucketControllerOffboard(offboardEmployeeBucketDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeBucketApi.employeeBucketControllerOffboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeBucketApi - factory interface
 * @export
 */
export const EmployeeBucketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeBucketApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerBucketWithTemporaryBranch(startDate: string, endDate: string, branchCode: string, options?: any): AxiosPromise<EmployeeBucketControllerBucketWithTemporaryBranch200Response> {
            return localVarFp.employeeBucketControllerBucketWithTemporaryBranch(startDate, endDate, branchCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEmployeeBucketDto} createEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerCreate(createEmployeeBucketDto: CreateEmployeeBucketDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBucketControllerCreate(createEmployeeBucketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeBucketDto} deleteEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerDelete(deleteEmployeeBucketDto: DeleteEmployeeBucketDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBucketControllerDelete(deleteEmployeeBucketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeBucketDto} editEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerEdit(editEmployeeBucketDto: EditEmployeeBucketDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBucketControllerEdit(editEmployeeBucketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeBucketControllerGet200Response> {
            return localVarFp.employeeBucketControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OffboardEmployeeBucketDto} offboardEmployeeBucketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBucketControllerOffboard(offboardEmployeeBucketDto: OffboardEmployeeBucketDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeBucketControllerOffboard(offboardEmployeeBucketDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeBucketApi - object-oriented interface
 * @export
 * @class EmployeeBucketApi
 * @extends {BaseAPI}
 */
export class EmployeeBucketApi extends BaseAPI {
    /**
     * 
     * @param {string} startDate The date of the event. Format: YYYY-MM-DD
     * @param {string} endDate The date of the event. Format: YYYY-MM-DD
     * @param {string} branchCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBucketApi
     */
    public employeeBucketControllerBucketWithTemporaryBranch(startDate: string, endDate: string, branchCode: string, options?: RawAxiosRequestConfig) {
        return EmployeeBucketApiFp(this.configuration).employeeBucketControllerBucketWithTemporaryBranch(startDate, endDate, branchCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEmployeeBucketDto} createEmployeeBucketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBucketApi
     */
    public employeeBucketControllerCreate(createEmployeeBucketDto: CreateEmployeeBucketDto, options?: RawAxiosRequestConfig) {
        return EmployeeBucketApiFp(this.configuration).employeeBucketControllerCreate(createEmployeeBucketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeBucketDto} deleteEmployeeBucketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBucketApi
     */
    public employeeBucketControllerDelete(deleteEmployeeBucketDto: DeleteEmployeeBucketDto, options?: RawAxiosRequestConfig) {
        return EmployeeBucketApiFp(this.configuration).employeeBucketControllerDelete(deleteEmployeeBucketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeBucketDto} editEmployeeBucketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBucketApi
     */
    public employeeBucketControllerEdit(editEmployeeBucketDto: EditEmployeeBucketDto, options?: RawAxiosRequestConfig) {
        return EmployeeBucketApiFp(this.configuration).employeeBucketControllerEdit(editEmployeeBucketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBucketApi
     */
    public employeeBucketControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeBucketApiFp(this.configuration).employeeBucketControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OffboardEmployeeBucketDto} offboardEmployeeBucketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeBucketApi
     */
    public employeeBucketControllerOffboard(offboardEmployeeBucketDto: OffboardEmployeeBucketDto, options?: RawAxiosRequestConfig) {
        return EmployeeBucketApiFp(this.configuration).employeeBucketControllerOffboard(offboardEmployeeBucketDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeDeductionApi - axios parameter creator
 * @export
 */
export const EmployeeDeductionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeDeductionDto} createEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerCreate: async (createEmployeeDeductionDto: CreateEmployeeDeductionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeDeductionDto' is not null or undefined
            assertParamExists('employeeDeductionControllerCreate', 'createEmployeeDeductionDto', createEmployeeDeductionDto)
            const localVarPath = `/api/v1/employee-deduction/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDeductionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeDeductionDto} deleteEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerDelete: async (deleteEmployeeDeductionDto: DeleteEmployeeDeductionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeDeductionDto' is not null or undefined
            assertParamExists('employeeDeductionControllerDelete', 'deleteEmployeeDeductionDto', deleteEmployeeDeductionDto)
            const localVarPath = `/api/v1/employee-deduction/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeDeductionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeDeductionDto} editEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerEdit: async (editEmployeeDeductionDto: EditEmployeeDeductionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeDeductionDto' is not null or undefined
            assertParamExists('employeeDeductionControllerEdit', 'editEmployeeDeductionDto', editEmployeeDeductionDto)
            const localVarPath = `/api/v1/employee-deduction/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeDeductionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-deduction/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeDeductionApi - functional programming interface
 * @export
 */
export const EmployeeDeductionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeDeductionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDeductionDto} createEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDeductionControllerCreate(createEmployeeDeductionDto: CreateEmployeeDeductionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDeductionControllerCreate(createEmployeeDeductionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDeductionApi.employeeDeductionControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeDeductionDto} deleteEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDeductionControllerDelete(deleteEmployeeDeductionDto: DeleteEmployeeDeductionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDeductionControllerDelete(deleteEmployeeDeductionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDeductionApi.employeeDeductionControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeDeductionDto} editEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDeductionControllerEdit(editEmployeeDeductionDto: EditEmployeeDeductionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDeductionControllerEdit(editEmployeeDeductionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDeductionApi.employeeDeductionControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDeductionControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDeductionControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDeductionControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDeductionApi.employeeDeductionControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeDeductionApi - factory interface
 * @export
 */
export const EmployeeDeductionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeDeductionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDeductionDto} createEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerCreate(createEmployeeDeductionDto: CreateEmployeeDeductionDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeDeductionControllerCreate(createEmployeeDeductionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeDeductionDto} deleteEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerDelete(deleteEmployeeDeductionDto: DeleteEmployeeDeductionDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeDeductionControllerDelete(deleteEmployeeDeductionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeDeductionDto} editEmployeeDeductionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerEdit(editEmployeeDeductionDto: EditEmployeeDeductionDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeDeductionControllerEdit(editEmployeeDeductionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDeductionControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeDeductionControllerGet200Response> {
            return localVarFp.employeeDeductionControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeDeductionApi - object-oriented interface
 * @export
 * @class EmployeeDeductionApi
 * @extends {BaseAPI}
 */
export class EmployeeDeductionApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeDeductionDto} createEmployeeDeductionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDeductionApi
     */
    public employeeDeductionControllerCreate(createEmployeeDeductionDto: CreateEmployeeDeductionDto, options?: RawAxiosRequestConfig) {
        return EmployeeDeductionApiFp(this.configuration).employeeDeductionControllerCreate(createEmployeeDeductionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeDeductionDto} deleteEmployeeDeductionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDeductionApi
     */
    public employeeDeductionControllerDelete(deleteEmployeeDeductionDto: DeleteEmployeeDeductionDto, options?: RawAxiosRequestConfig) {
        return EmployeeDeductionApiFp(this.configuration).employeeDeductionControllerDelete(deleteEmployeeDeductionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeDeductionDto} editEmployeeDeductionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDeductionApi
     */
    public employeeDeductionControllerEdit(editEmployeeDeductionDto: EditEmployeeDeductionDto, options?: RawAxiosRequestConfig) {
        return EmployeeDeductionApiFp(this.configuration).employeeDeductionControllerEdit(editEmployeeDeductionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDeductionApi
     */
    public employeeDeductionControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeDeductionApiFp(this.configuration).employeeDeductionControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeDiciplinaryApi - axios parameter creator
 * @export
 */
export const EmployeeDiciplinaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeDiciplinaryDto} createEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerCreate: async (createEmployeeDiciplinaryDto: CreateEmployeeDiciplinaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeDiciplinaryDto' is not null or undefined
            assertParamExists('employeeDiciplinaryControllerCreate', 'createEmployeeDiciplinaryDto', createEmployeeDiciplinaryDto)
            const localVarPath = `/api/v1/employee-diciplinary/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDiciplinaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeDiciplinaryDto} deleteEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerDelete: async (deleteEmployeeDiciplinaryDto: DeleteEmployeeDiciplinaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeDiciplinaryDto' is not null or undefined
            assertParamExists('employeeDiciplinaryControllerDelete', 'deleteEmployeeDiciplinaryDto', deleteEmployeeDiciplinaryDto)
            const localVarPath = `/api/v1/employee-diciplinary/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeDiciplinaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeDiciplinaryDto} editEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerEdit: async (editEmployeeDiciplinaryDto: EditEmployeeDiciplinaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeDiciplinaryDto' is not null or undefined
            assertParamExists('employeeDiciplinaryControllerEdit', 'editEmployeeDiciplinaryDto', editEmployeeDiciplinaryDto)
            const localVarPath = `/api/v1/employee-diciplinary/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeDiciplinaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-diciplinary/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeDiciplinaryApi - functional programming interface
 * @export
 */
export const EmployeeDiciplinaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeDiciplinaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDiciplinaryDto} createEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDiciplinaryControllerCreate(createEmployeeDiciplinaryDto: CreateEmployeeDiciplinaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDiciplinaryControllerCreate(createEmployeeDiciplinaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDiciplinaryApi.employeeDiciplinaryControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeDiciplinaryDto} deleteEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDiciplinaryControllerDelete(deleteEmployeeDiciplinaryDto: DeleteEmployeeDiciplinaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDiciplinaryControllerDelete(deleteEmployeeDiciplinaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDiciplinaryApi.employeeDiciplinaryControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeDiciplinaryDto} editEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDiciplinaryControllerEdit(editEmployeeDiciplinaryDto: EditEmployeeDiciplinaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDiciplinaryControllerEdit(editEmployeeDiciplinaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDiciplinaryApi.employeeDiciplinaryControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDiciplinaryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDiciplinaryControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDiciplinaryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeDiciplinaryApi.employeeDiciplinaryControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeDiciplinaryApi - factory interface
 * @export
 */
export const EmployeeDiciplinaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeDiciplinaryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDiciplinaryDto} createEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerCreate(createEmployeeDiciplinaryDto: CreateEmployeeDiciplinaryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeDiciplinaryControllerCreate(createEmployeeDiciplinaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeDiciplinaryDto} deleteEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerDelete(deleteEmployeeDiciplinaryDto: DeleteEmployeeDiciplinaryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeDiciplinaryControllerDelete(deleteEmployeeDiciplinaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeDiciplinaryDto} editEmployeeDiciplinaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerEdit(editEmployeeDiciplinaryDto: EditEmployeeDiciplinaryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeDiciplinaryControllerEdit(editEmployeeDiciplinaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDiciplinaryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeDiciplinaryControllerGet200Response> {
            return localVarFp.employeeDiciplinaryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeDiciplinaryApi - object-oriented interface
 * @export
 * @class EmployeeDiciplinaryApi
 * @extends {BaseAPI}
 */
export class EmployeeDiciplinaryApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeDiciplinaryDto} createEmployeeDiciplinaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDiciplinaryApi
     */
    public employeeDiciplinaryControllerCreate(createEmployeeDiciplinaryDto: CreateEmployeeDiciplinaryDto, options?: RawAxiosRequestConfig) {
        return EmployeeDiciplinaryApiFp(this.configuration).employeeDiciplinaryControllerCreate(createEmployeeDiciplinaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeDiciplinaryDto} deleteEmployeeDiciplinaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDiciplinaryApi
     */
    public employeeDiciplinaryControllerDelete(deleteEmployeeDiciplinaryDto: DeleteEmployeeDiciplinaryDto, options?: RawAxiosRequestConfig) {
        return EmployeeDiciplinaryApiFp(this.configuration).employeeDiciplinaryControllerDelete(deleteEmployeeDiciplinaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeDiciplinaryDto} editEmployeeDiciplinaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDiciplinaryApi
     */
    public employeeDiciplinaryControllerEdit(editEmployeeDiciplinaryDto: EditEmployeeDiciplinaryDto, options?: RawAxiosRequestConfig) {
        return EmployeeDiciplinaryApiFp(this.configuration).employeeDiciplinaryControllerEdit(editEmployeeDiciplinaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDiciplinaryApi
     */
    public employeeDiciplinaryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeDiciplinaryApiFp(this.configuration).employeeDiciplinaryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeFavoriteApi - axios parameter creator
 * @export
 */
export const EmployeeFavoriteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddEmployeeFavoriteDto} addEmployeeFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFavoriteControllerCreate: async (addEmployeeFavoriteDto: AddEmployeeFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addEmployeeFavoriteDto' is not null or undefined
            assertParamExists('employeeFavoriteControllerCreate', 'addEmployeeFavoriteDto', addEmployeeFavoriteDto)
            const localVarPath = `/api/v1/employee-favorite/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addEmployeeFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeFavoriteDto} deleteEmployeeFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFavoriteControllerDelete: async (deleteEmployeeFavoriteDto: DeleteEmployeeFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeFavoriteDto' is not null or undefined
            assertParamExists('employeeFavoriteControllerDelete', 'deleteEmployeeFavoriteDto', deleteEmployeeFavoriteDto)
            const localVarPath = `/api/v1/employee-favorite/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFavoriteControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-favorite/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeFavoriteApi - functional programming interface
 * @export
 */
export const EmployeeFavoriteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeFavoriteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeFavoriteDto} addEmployeeFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeFavoriteControllerCreate(addEmployeeFavoriteDto: AddEmployeeFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFavoriteControllerCreate(addEmployeeFavoriteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeFavoriteApi.employeeFavoriteControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeFavoriteDto} deleteEmployeeFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeFavoriteControllerDelete(deleteEmployeeFavoriteDto: DeleteEmployeeFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFavoriteControllerDelete(deleteEmployeeFavoriteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeFavoriteApi.employeeFavoriteControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeFavoriteControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeFavoriteControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFavoriteControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeFavoriteApi.employeeFavoriteControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeFavoriteApi - factory interface
 * @export
 */
export const EmployeeFavoriteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeFavoriteApiFp(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeFavoriteDto} addEmployeeFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFavoriteControllerCreate(addEmployeeFavoriteDto: AddEmployeeFavoriteDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeFavoriteControllerCreate(addEmployeeFavoriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeFavoriteDto} deleteEmployeeFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFavoriteControllerDelete(deleteEmployeeFavoriteDto: DeleteEmployeeFavoriteDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeFavoriteControllerDelete(deleteEmployeeFavoriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFavoriteControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeFavoriteControllerGet200Response> {
            return localVarFp.employeeFavoriteControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeFavoriteApi - object-oriented interface
 * @export
 * @class EmployeeFavoriteApi
 * @extends {BaseAPI}
 */
export class EmployeeFavoriteApi extends BaseAPI {
    /**
     * 
     * @param {AddEmployeeFavoriteDto} addEmployeeFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFavoriteApi
     */
    public employeeFavoriteControllerCreate(addEmployeeFavoriteDto: AddEmployeeFavoriteDto, options?: RawAxiosRequestConfig) {
        return EmployeeFavoriteApiFp(this.configuration).employeeFavoriteControllerCreate(addEmployeeFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeFavoriteDto} deleteEmployeeFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFavoriteApi
     */
    public employeeFavoriteControllerDelete(deleteEmployeeFavoriteDto: DeleteEmployeeFavoriteDto, options?: RawAxiosRequestConfig) {
        return EmployeeFavoriteApiFp(this.configuration).employeeFavoriteControllerDelete(deleteEmployeeFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFavoriteApi
     */
    public employeeFavoriteControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeFavoriteApiFp(this.configuration).employeeFavoriteControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeIncentiveApi - axios parameter creator
 * @export
 */
export const EmployeeIncentiveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeIncentiveDto} createEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerCreate: async (createEmployeeIncentiveDto: CreateEmployeeIncentiveDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeIncentiveDto' is not null or undefined
            assertParamExists('employeeIncentiveControllerCreate', 'createEmployeeIncentiveDto', createEmployeeIncentiveDto)
            const localVarPath = `/api/v1/employee-incentive/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeIncentiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeIncentiveDto} deleteEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerDelete: async (deleteEmployeeIncentiveDto: DeleteEmployeeIncentiveDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeIncentiveDto' is not null or undefined
            assertParamExists('employeeIncentiveControllerDelete', 'deleteEmployeeIncentiveDto', deleteEmployeeIncentiveDto)
            const localVarPath = `/api/v1/employee-incentive/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeIncentiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeIncentiveDto} editEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerEdit: async (editEmployeeIncentiveDto: EditEmployeeIncentiveDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeIncentiveDto' is not null or undefined
            assertParamExists('employeeIncentiveControllerEdit', 'editEmployeeIncentiveDto', editEmployeeIncentiveDto)
            const localVarPath = `/api/v1/employee-incentive/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeIncentiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-incentive/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessIncentiveDto} processIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerProcess: async (processIncentiveDto: ProcessIncentiveDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processIncentiveDto' is not null or undefined
            assertParamExists('employeeIncentiveControllerProcess', 'processIncentiveDto', processIncentiveDto)
            const localVarPath = `/api/v1/employee-incentive/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processIncentiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeIncentiveApi - functional programming interface
 * @export
 */
export const EmployeeIncentiveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeIncentiveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeIncentiveDto} createEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncentiveControllerCreate(createEmployeeIncentiveDto: CreateEmployeeIncentiveDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncentiveControllerCreate(createEmployeeIncentiveDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncentiveApi.employeeIncentiveControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeIncentiveDto} deleteEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncentiveControllerDelete(deleteEmployeeIncentiveDto: DeleteEmployeeIncentiveDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncentiveControllerDelete(deleteEmployeeIncentiveDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncentiveApi.employeeIncentiveControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeIncentiveDto} editEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncentiveControllerEdit(editEmployeeIncentiveDto: EditEmployeeIncentiveDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncentiveControllerEdit(editEmployeeIncentiveDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncentiveApi.employeeIncentiveControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncentiveControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeIncentiveControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncentiveControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncentiveApi.employeeIncentiveControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessIncentiveDto} processIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncentiveControllerProcess(processIncentiveDto: ProcessIncentiveDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeIncentiveControllerProcess200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncentiveControllerProcess(processIncentiveDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncentiveApi.employeeIncentiveControllerProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeIncentiveApi - factory interface
 * @export
 */
export const EmployeeIncentiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeIncentiveApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeIncentiveDto} createEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerCreate(createEmployeeIncentiveDto: CreateEmployeeIncentiveDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeIncentiveControllerCreate(createEmployeeIncentiveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeIncentiveDto} deleteEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerDelete(deleteEmployeeIncentiveDto: DeleteEmployeeIncentiveDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeIncentiveControllerDelete(deleteEmployeeIncentiveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeIncentiveDto} editEmployeeIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerEdit(editEmployeeIncentiveDto: EditEmployeeIncentiveDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeIncentiveControllerEdit(editEmployeeIncentiveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeIncentiveControllerGet200Response> {
            return localVarFp.employeeIncentiveControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessIncentiveDto} processIncentiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncentiveControllerProcess(processIncentiveDto: ProcessIncentiveDto, options?: any): AxiosPromise<EmployeeIncentiveControllerProcess200Response> {
            return localVarFp.employeeIncentiveControllerProcess(processIncentiveDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeIncentiveApi - object-oriented interface
 * @export
 * @class EmployeeIncentiveApi
 * @extends {BaseAPI}
 */
export class EmployeeIncentiveApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeIncentiveDto} createEmployeeIncentiveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncentiveApi
     */
    public employeeIncentiveControllerCreate(createEmployeeIncentiveDto: CreateEmployeeIncentiveDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncentiveApiFp(this.configuration).employeeIncentiveControllerCreate(createEmployeeIncentiveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeIncentiveDto} deleteEmployeeIncentiveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncentiveApi
     */
    public employeeIncentiveControllerDelete(deleteEmployeeIncentiveDto: DeleteEmployeeIncentiveDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncentiveApiFp(this.configuration).employeeIncentiveControllerDelete(deleteEmployeeIncentiveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeIncentiveDto} editEmployeeIncentiveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncentiveApi
     */
    public employeeIncentiveControllerEdit(editEmployeeIncentiveDto: EditEmployeeIncentiveDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncentiveApiFp(this.configuration).employeeIncentiveControllerEdit(editEmployeeIncentiveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncentiveApi
     */
    public employeeIncentiveControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeIncentiveApiFp(this.configuration).employeeIncentiveControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessIncentiveDto} processIncentiveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncentiveApi
     */
    public employeeIncentiveControllerProcess(processIncentiveDto: ProcessIncentiveDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncentiveApiFp(this.configuration).employeeIncentiveControllerProcess(processIncentiveDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeIncidentApi - axios parameter creator
 * @export
 */
export const EmployeeIncidentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeIncidentDto} createEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerCreate: async (createEmployeeIncidentDto: CreateEmployeeIncidentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeIncidentDto' is not null or undefined
            assertParamExists('employeeIncidentControllerCreate', 'createEmployeeIncidentDto', createEmployeeIncidentDto)
            const localVarPath = `/api/v1/employee-incident/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeIncidentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeIncidentDto} deleteEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerDelete: async (deleteEmployeeIncidentDto: DeleteEmployeeIncidentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeIncidentDto' is not null or undefined
            assertParamExists('employeeIncidentControllerDelete', 'deleteEmployeeIncidentDto', deleteEmployeeIncidentDto)
            const localVarPath = `/api/v1/employee-incident/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeIncidentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeIncidentDto} editEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerEdit: async (editEmployeeIncidentDto: EditEmployeeIncidentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeIncidentDto' is not null or undefined
            assertParamExists('employeeIncidentControllerEdit', 'editEmployeeIncidentDto', editEmployeeIncidentDto)
            const localVarPath = `/api/v1/employee-incident/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeIncidentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-incident/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeIncidentApi - functional programming interface
 * @export
 */
export const EmployeeIncidentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeIncidentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeIncidentDto} createEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncidentControllerCreate(createEmployeeIncidentDto: CreateEmployeeIncidentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncidentControllerCreate(createEmployeeIncidentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncidentApi.employeeIncidentControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeIncidentDto} deleteEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncidentControllerDelete(deleteEmployeeIncidentDto: DeleteEmployeeIncidentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncidentControllerDelete(deleteEmployeeIncidentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncidentApi.employeeIncidentControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeIncidentDto} editEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncidentControllerEdit(editEmployeeIncidentDto: EditEmployeeIncidentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncidentControllerEdit(editEmployeeIncidentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncidentApi.employeeIncidentControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeIncidentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeIncidentControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeIncidentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeIncidentApi.employeeIncidentControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeIncidentApi - factory interface
 * @export
 */
export const EmployeeIncidentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeIncidentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeIncidentDto} createEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerCreate(createEmployeeIncidentDto: CreateEmployeeIncidentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeIncidentControllerCreate(createEmployeeIncidentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeIncidentDto} deleteEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerDelete(deleteEmployeeIncidentDto: DeleteEmployeeIncidentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeIncidentControllerDelete(deleteEmployeeIncidentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeIncidentDto} editEmployeeIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerEdit(editEmployeeIncidentDto: EditEmployeeIncidentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeIncidentControllerEdit(editEmployeeIncidentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeIncidentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeIncidentControllerGet200Response> {
            return localVarFp.employeeIncidentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeIncidentApi - object-oriented interface
 * @export
 * @class EmployeeIncidentApi
 * @extends {BaseAPI}
 */
export class EmployeeIncidentApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeIncidentDto} createEmployeeIncidentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncidentApi
     */
    public employeeIncidentControllerCreate(createEmployeeIncidentDto: CreateEmployeeIncidentDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncidentApiFp(this.configuration).employeeIncidentControllerCreate(createEmployeeIncidentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeIncidentDto} deleteEmployeeIncidentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncidentApi
     */
    public employeeIncidentControllerDelete(deleteEmployeeIncidentDto: DeleteEmployeeIncidentDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncidentApiFp(this.configuration).employeeIncidentControllerDelete(deleteEmployeeIncidentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeIncidentDto} editEmployeeIncidentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncidentApi
     */
    public employeeIncidentControllerEdit(editEmployeeIncidentDto: EditEmployeeIncidentDto, options?: RawAxiosRequestConfig) {
        return EmployeeIncidentApiFp(this.configuration).employeeIncidentControllerEdit(editEmployeeIncidentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeIncidentApi
     */
    public employeeIncidentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeIncidentApiFp(this.configuration).employeeIncidentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeLedgerApi - axios parameter creator
 * @export
 */
export const EmployeeLedgerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EmployeeLedgerListDto} employeeLedgerListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLedgerControllerCreate: async (employeeLedgerListDto: EmployeeLedgerListDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeLedgerListDto' is not null or undefined
            assertParamExists('employeeLedgerControllerCreate', 'employeeLedgerListDto', employeeLedgerListDto)
            const localVarPath = `/api/v1/employee/ledger/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeLedgerListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLedgerControllerGetIndividualLeger: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee/ledger/individual/entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLedgerControllerGetLegderList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee/ledger/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeLedgerApi - functional programming interface
 * @export
 */
export const EmployeeLedgerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeLedgerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EmployeeLedgerListDto} employeeLedgerListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLedgerControllerCreate(employeeLedgerListDto: EmployeeLedgerListDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLedgerControllerCreate(employeeLedgerListDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLedgerApi.employeeLedgerControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLedgerControllerGetIndividualLeger(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLedgerControllerGetIndividualLeger200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLedgerControllerGetIndividualLeger(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLedgerApi.employeeLedgerControllerGetIndividualLeger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLedgerControllerGetLegderList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLedgerControllerGetLegderList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLedgerControllerGetLegderList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLedgerApi.employeeLedgerControllerGetLegderList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeLedgerApi - factory interface
 * @export
 */
export const EmployeeLedgerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeLedgerApiFp(configuration)
    return {
        /**
         * 
         * @param {EmployeeLedgerListDto} employeeLedgerListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLedgerControllerCreate(employeeLedgerListDto: EmployeeLedgerListDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeLedgerControllerCreate(employeeLedgerListDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLedgerControllerGetIndividualLeger(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeLedgerControllerGetIndividualLeger200Response> {
            return localVarFp.employeeLedgerControllerGetIndividualLeger(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLedgerControllerGetLegderList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeLedgerControllerGetLegderList200Response> {
            return localVarFp.employeeLedgerControllerGetLegderList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeLedgerApi - object-oriented interface
 * @export
 * @class EmployeeLedgerApi
 * @extends {BaseAPI}
 */
export class EmployeeLedgerApi extends BaseAPI {
    /**
     * 
     * @param {EmployeeLedgerListDto} employeeLedgerListDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLedgerApi
     */
    public employeeLedgerControllerCreate(employeeLedgerListDto: EmployeeLedgerListDto, options?: RawAxiosRequestConfig) {
        return EmployeeLedgerApiFp(this.configuration).employeeLedgerControllerCreate(employeeLedgerListDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLedgerApi
     */
    public employeeLedgerControllerGetIndividualLeger(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeLedgerApiFp(this.configuration).employeeLedgerControllerGetIndividualLeger(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLedgerApi
     */
    public employeeLedgerControllerGetLegderList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeLedgerApiFp(this.configuration).employeeLedgerControllerGetLegderList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeLoanApi - axios parameter creator
 * @export
 */
export const EmployeeLoanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeLoanDto} createEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerCreate: async (createEmployeeLoanDto: CreateEmployeeLoanDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeLoanDto' is not null or undefined
            assertParamExists('employeeLoanControllerCreate', 'createEmployeeLoanDto', createEmployeeLoanDto)
            const localVarPath = `/api/v1/employee-loan/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeLoanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeLoanDto} deleteEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerDelete: async (deleteEmployeeLoanDto: DeleteEmployeeLoanDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeLoanDto' is not null or undefined
            assertParamExists('employeeLoanControllerDelete', 'deleteEmployeeLoanDto', deleteEmployeeLoanDto)
            const localVarPath = `/api/v1/employee-loan/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeLoanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeLoanDto} editEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerEdit: async (editEmployeeLoanDto: EditEmployeeLoanDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeLoanDto' is not null or undefined
            assertParamExists('employeeLoanControllerEdit', 'editEmployeeLoanDto', editEmployeeLoanDto)
            const localVarPath = `/api/v1/employee-loan/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeLoanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-loan/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessLoanDto} processLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerProcess: async (processLoanDto: ProcessLoanDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processLoanDto' is not null or undefined
            assertParamExists('employeeLoanControllerProcess', 'processLoanDto', processLoanDto)
            const localVarPath = `/api/v1/employee-loan/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processLoanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeLoanApi - functional programming interface
 * @export
 */
export const EmployeeLoanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeLoanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeLoanDto} createEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLoanControllerCreate(createEmployeeLoanDto: CreateEmployeeLoanDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLoanControllerCreate(createEmployeeLoanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLoanApi.employeeLoanControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeLoanDto} deleteEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLoanControllerDelete(deleteEmployeeLoanDto: DeleteEmployeeLoanDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLoanControllerDelete(deleteEmployeeLoanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLoanApi.employeeLoanControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeLoanDto} editEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLoanControllerEdit(editEmployeeLoanDto: EditEmployeeLoanDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLoanControllerEdit(editEmployeeLoanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLoanApi.employeeLoanControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLoanControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLoanControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLoanControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLoanApi.employeeLoanControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessLoanDto} processLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLoanControllerProcess(processLoanDto: ProcessLoanDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLoanControllerProcess200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLoanControllerProcess(processLoanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeLoanApi.employeeLoanControllerProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeLoanApi - factory interface
 * @export
 */
export const EmployeeLoanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeLoanApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeLoanDto} createEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerCreate(createEmployeeLoanDto: CreateEmployeeLoanDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeLoanControllerCreate(createEmployeeLoanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeLoanDto} deleteEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerDelete(deleteEmployeeLoanDto: DeleteEmployeeLoanDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeLoanControllerDelete(deleteEmployeeLoanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeLoanDto} editEmployeeLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerEdit(editEmployeeLoanDto: EditEmployeeLoanDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeLoanControllerEdit(editEmployeeLoanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeLoanControllerGet200Response> {
            return localVarFp.employeeLoanControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessLoanDto} processLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLoanControllerProcess(processLoanDto: ProcessLoanDto, options?: any): AxiosPromise<EmployeeLoanControllerProcess200Response> {
            return localVarFp.employeeLoanControllerProcess(processLoanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeLoanApi - object-oriented interface
 * @export
 * @class EmployeeLoanApi
 * @extends {BaseAPI}
 */
export class EmployeeLoanApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeLoanDto} createEmployeeLoanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLoanApi
     */
    public employeeLoanControllerCreate(createEmployeeLoanDto: CreateEmployeeLoanDto, options?: RawAxiosRequestConfig) {
        return EmployeeLoanApiFp(this.configuration).employeeLoanControllerCreate(createEmployeeLoanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeLoanDto} deleteEmployeeLoanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLoanApi
     */
    public employeeLoanControllerDelete(deleteEmployeeLoanDto: DeleteEmployeeLoanDto, options?: RawAxiosRequestConfig) {
        return EmployeeLoanApiFp(this.configuration).employeeLoanControllerDelete(deleteEmployeeLoanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeLoanDto} editEmployeeLoanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLoanApi
     */
    public employeeLoanControllerEdit(editEmployeeLoanDto: EditEmployeeLoanDto, options?: RawAxiosRequestConfig) {
        return EmployeeLoanApiFp(this.configuration).employeeLoanControllerEdit(editEmployeeLoanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLoanApi
     */
    public employeeLoanControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeLoanApiFp(this.configuration).employeeLoanControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessLoanDto} processLoanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeLoanApi
     */
    public employeeLoanControllerProcess(processLoanDto: ProcessLoanDto, options?: RawAxiosRequestConfig) {
        return EmployeeLoanApiFp(this.configuration).employeeLoanControllerProcess(processLoanDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeOverageApi - axios parameter creator
 * @export
 */
export const EmployeeOverageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeOverageDto} createEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerCreate: async (createEmployeeOverageDto: CreateEmployeeOverageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeOverageDto' is not null or undefined
            assertParamExists('employeeOverageControllerCreate', 'createEmployeeOverageDto', createEmployeeOverageDto)
            const localVarPath = `/api/v1/employee-overage/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeOverageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeOverageDto} deleteEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerDelete: async (deleteEmployeeOverageDto: DeleteEmployeeOverageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeOverageDto' is not null or undefined
            assertParamExists('employeeOverageControllerDelete', 'deleteEmployeeOverageDto', deleteEmployeeOverageDto)
            const localVarPath = `/api/v1/employee-overage/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeOverageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeOverageDto} editEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerEdit: async (editEmployeeOverageDto: EditEmployeeOverageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeOverageDto' is not null or undefined
            assertParamExists('employeeOverageControllerEdit', 'editEmployeeOverageDto', editEmployeeOverageDto)
            const localVarPath = `/api/v1/employee-overage/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeOverageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-overage/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeOverageApi - functional programming interface
 * @export
 */
export const EmployeeOverageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeOverageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeOverageDto} createEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeOverageControllerCreate(createEmployeeOverageDto: CreateEmployeeOverageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeOverageControllerCreate(createEmployeeOverageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeOverageApi.employeeOverageControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeOverageDto} deleteEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeOverageControllerDelete(deleteEmployeeOverageDto: DeleteEmployeeOverageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeOverageControllerDelete(deleteEmployeeOverageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeOverageApi.employeeOverageControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeOverageDto} editEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeOverageControllerEdit(editEmployeeOverageDto: EditEmployeeOverageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeOverageControllerEdit(editEmployeeOverageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeOverageApi.employeeOverageControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeOverageControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeOverageControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeOverageControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeOverageApi.employeeOverageControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeOverageApi - factory interface
 * @export
 */
export const EmployeeOverageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeOverageApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeOverageDto} createEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerCreate(createEmployeeOverageDto: CreateEmployeeOverageDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeOverageControllerCreate(createEmployeeOverageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeOverageDto} deleteEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerDelete(deleteEmployeeOverageDto: DeleteEmployeeOverageDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeOverageControllerDelete(deleteEmployeeOverageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeOverageDto} editEmployeeOverageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerEdit(editEmployeeOverageDto: EditEmployeeOverageDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeOverageControllerEdit(editEmployeeOverageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeOverageControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeOverageControllerGet200Response> {
            return localVarFp.employeeOverageControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeOverageApi - object-oriented interface
 * @export
 * @class EmployeeOverageApi
 * @extends {BaseAPI}
 */
export class EmployeeOverageApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeOverageDto} createEmployeeOverageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeOverageApi
     */
    public employeeOverageControllerCreate(createEmployeeOverageDto: CreateEmployeeOverageDto, options?: RawAxiosRequestConfig) {
        return EmployeeOverageApiFp(this.configuration).employeeOverageControllerCreate(createEmployeeOverageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeOverageDto} deleteEmployeeOverageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeOverageApi
     */
    public employeeOverageControllerDelete(deleteEmployeeOverageDto: DeleteEmployeeOverageDto, options?: RawAxiosRequestConfig) {
        return EmployeeOverageApiFp(this.configuration).employeeOverageControllerDelete(deleteEmployeeOverageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeOverageDto} editEmployeeOverageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeOverageApi
     */
    public employeeOverageControllerEdit(editEmployeeOverageDto: EditEmployeeOverageDto, options?: RawAxiosRequestConfig) {
        return EmployeeOverageApiFp(this.configuration).employeeOverageControllerEdit(editEmployeeOverageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeOverageApi
     */
    public employeeOverageControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeOverageApiFp(this.configuration).employeeOverageControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeePaymentApi - axios parameter creator
 * @export
 */
export const EmployeePaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeePaymentDto} createEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerCreate: async (createEmployeePaymentDto: CreateEmployeePaymentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeePaymentDto' is not null or undefined
            assertParamExists('employeePaymentControllerCreate', 'createEmployeePaymentDto', createEmployeePaymentDto)
            const localVarPath = `/api/v1/employee-payment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeePaymentDto} deleteEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerDelete: async (deleteEmployeePaymentDto: DeleteEmployeePaymentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeePaymentDto' is not null or undefined
            assertParamExists('employeePaymentControllerDelete', 'deleteEmployeePaymentDto', deleteEmployeePaymentDto)
            const localVarPath = `/api/v1/employee-payment/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeePaymentDto} editEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerEdit: async (editEmployeePaymentDto: EditEmployeePaymentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeePaymentDto' is not null or undefined
            assertParamExists('employeePaymentControllerEdit', 'editEmployeePaymentDto', editEmployeePaymentDto)
            const localVarPath = `/api/v1/employee-payment/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-payment/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeePaymentApi - functional programming interface
 * @export
 */
export const EmployeePaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeePaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeePaymentDto} createEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePaymentControllerCreate(createEmployeePaymentDto: CreateEmployeePaymentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePaymentControllerCreate(createEmployeePaymentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePaymentApi.employeePaymentControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeePaymentDto} deleteEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePaymentControllerDelete(deleteEmployeePaymentDto: DeleteEmployeePaymentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePaymentControllerDelete(deleteEmployeePaymentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePaymentApi.employeePaymentControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeePaymentDto} editEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePaymentControllerEdit(editEmployeePaymentDto: EditEmployeePaymentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePaymentControllerEdit(editEmployeePaymentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePaymentApi.employeePaymentControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePaymentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeePaymentControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePaymentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePaymentApi.employeePaymentControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeePaymentApi - factory interface
 * @export
 */
export const EmployeePaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeePaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeePaymentDto} createEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerCreate(createEmployeePaymentDto: CreateEmployeePaymentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeePaymentControllerCreate(createEmployeePaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeePaymentDto} deleteEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerDelete(deleteEmployeePaymentDto: DeleteEmployeePaymentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeePaymentControllerDelete(deleteEmployeePaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeePaymentDto} editEmployeePaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerEdit(editEmployeePaymentDto: EditEmployeePaymentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeePaymentControllerEdit(editEmployeePaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePaymentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeePaymentControllerGet200Response> {
            return localVarFp.employeePaymentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeePaymentApi - object-oriented interface
 * @export
 * @class EmployeePaymentApi
 * @extends {BaseAPI}
 */
export class EmployeePaymentApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeePaymentDto} createEmployeePaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentApi
     */
    public employeePaymentControllerCreate(createEmployeePaymentDto: CreateEmployeePaymentDto, options?: RawAxiosRequestConfig) {
        return EmployeePaymentApiFp(this.configuration).employeePaymentControllerCreate(createEmployeePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeePaymentDto} deleteEmployeePaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentApi
     */
    public employeePaymentControllerDelete(deleteEmployeePaymentDto: DeleteEmployeePaymentDto, options?: RawAxiosRequestConfig) {
        return EmployeePaymentApiFp(this.configuration).employeePaymentControllerDelete(deleteEmployeePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeePaymentDto} editEmployeePaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentApi
     */
    public employeePaymentControllerEdit(editEmployeePaymentDto: EditEmployeePaymentDto, options?: RawAxiosRequestConfig) {
        return EmployeePaymentApiFp(this.configuration).employeePaymentControllerEdit(editEmployeePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentApi
     */
    public employeePaymentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeePaymentApiFp(this.configuration).employeePaymentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeePenaltyApi - axios parameter creator
 * @export
 */
export const EmployeePenaltyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeePenaltyDto} createEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerCreate: async (createEmployeePenaltyDto: CreateEmployeePenaltyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeePenaltyDto' is not null or undefined
            assertParamExists('employeePenaltyControllerCreate', 'createEmployeePenaltyDto', createEmployeePenaltyDto)
            const localVarPath = `/api/v1/employee-penalty/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeePenaltyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeePenaltyDto} deleteEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerDelete: async (deleteEmployeePenaltyDto: DeleteEmployeePenaltyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeePenaltyDto' is not null or undefined
            assertParamExists('employeePenaltyControllerDelete', 'deleteEmployeePenaltyDto', deleteEmployeePenaltyDto)
            const localVarPath = `/api/v1/employee-penalty/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeePenaltyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeePenaltyDto} editEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerEdit: async (editEmployeePenaltyDto: EditEmployeePenaltyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeePenaltyDto' is not null or undefined
            assertParamExists('employeePenaltyControllerEdit', 'editEmployeePenaltyDto', editEmployeePenaltyDto)
            const localVarPath = `/api/v1/employee-penalty/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeePenaltyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-penalty/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeePenaltyApi - functional programming interface
 * @export
 */
export const EmployeePenaltyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeePenaltyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeePenaltyDto} createEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePenaltyControllerCreate(createEmployeePenaltyDto: CreateEmployeePenaltyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePenaltyControllerCreate(createEmployeePenaltyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePenaltyApi.employeePenaltyControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeePenaltyDto} deleteEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePenaltyControllerDelete(deleteEmployeePenaltyDto: DeleteEmployeePenaltyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePenaltyControllerDelete(deleteEmployeePenaltyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePenaltyApi.employeePenaltyControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeePenaltyDto} editEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePenaltyControllerEdit(editEmployeePenaltyDto: EditEmployeePenaltyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePenaltyControllerEdit(editEmployeePenaltyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePenaltyApi.employeePenaltyControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePenaltyControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeePenaltyControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePenaltyControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeePenaltyApi.employeePenaltyControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeePenaltyApi - factory interface
 * @export
 */
export const EmployeePenaltyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeePenaltyApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeePenaltyDto} createEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerCreate(createEmployeePenaltyDto: CreateEmployeePenaltyDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeePenaltyControllerCreate(createEmployeePenaltyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeePenaltyDto} deleteEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerDelete(deleteEmployeePenaltyDto: DeleteEmployeePenaltyDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeePenaltyControllerDelete(deleteEmployeePenaltyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeePenaltyDto} editEmployeePenaltyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerEdit(editEmployeePenaltyDto: EditEmployeePenaltyDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeePenaltyControllerEdit(editEmployeePenaltyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePenaltyControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeePenaltyControllerGet200Response> {
            return localVarFp.employeePenaltyControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeePenaltyApi - object-oriented interface
 * @export
 * @class EmployeePenaltyApi
 * @extends {BaseAPI}
 */
export class EmployeePenaltyApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeePenaltyDto} createEmployeePenaltyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePenaltyApi
     */
    public employeePenaltyControllerCreate(createEmployeePenaltyDto: CreateEmployeePenaltyDto, options?: RawAxiosRequestConfig) {
        return EmployeePenaltyApiFp(this.configuration).employeePenaltyControllerCreate(createEmployeePenaltyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeePenaltyDto} deleteEmployeePenaltyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePenaltyApi
     */
    public employeePenaltyControllerDelete(deleteEmployeePenaltyDto: DeleteEmployeePenaltyDto, options?: RawAxiosRequestConfig) {
        return EmployeePenaltyApiFp(this.configuration).employeePenaltyControllerDelete(deleteEmployeePenaltyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeePenaltyDto} editEmployeePenaltyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePenaltyApi
     */
    public employeePenaltyControllerEdit(editEmployeePenaltyDto: EditEmployeePenaltyDto, options?: RawAxiosRequestConfig) {
        return EmployeePenaltyApiFp(this.configuration).employeePenaltyControllerEdit(editEmployeePenaltyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePenaltyApi
     */
    public employeePenaltyControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeePenaltyApiFp(this.configuration).employeePenaltyControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeRequestApi - axios parameter creator
 * @export
 */
export const EmployeeRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AmendmentEmployeeRequestDto} amendment 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerAmandment: async (amendment: AmendmentEmployeeRequestDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'amendment' is not null or undefined
            assertParamExists('employeeRequestControllerAmandment', 'amendment', amendment)
            const localVarPath = `/api/v1/employee-request/amendment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (amendment !== undefined) { 
                localVarFormParams.append('amendment', new Blob([JSON.stringify(amendment)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AmendBreakDto} _break 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerAmandmentBreak: async (_break: AmendBreakDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter '_break' is not null or undefined
            assertParamExists('employeeRequestControllerAmandmentBreak', '_break', _break)
            const localVarPath = `/api/v1/employee-request/break/amendment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (_break !== undefined) { 
                localVarFormParams.append('break', new Blob([JSON.stringify(_break)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CsrRequestDto} csr 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerChangeShift: async (csr: CsrRequestDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csr' is not null or undefined
            assertParamExists('employeeRequestControllerChangeShift', 'csr', csr)
            const localVarPath = `/api/v1/employee-request/change-shift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (csr !== undefined) { 
                localVarFormParams.append('csr', new Blob([JSON.stringify(csr)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmployeeRequestDto} createEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerCreate: async (createEmployeeRequestDto: CreateEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeRequestDto' is not null or undefined
            assertParamExists('employeeRequestControllerCreate', 'createEmployeeRequestDto', createEmployeeRequestDto)
            const localVarPath = `/api/v1/employee-request/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeRequestDto} deleteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerDelete: async (deleteEmployeeRequestDto: DeleteEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeRequestDto' is not null or undefined
            assertParamExists('employeeRequestControllerDelete', 'deleteEmployeeRequestDto', deleteEmployeeRequestDto)
            const localVarPath = `/api/v1/employee-request/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeRequestDto} editEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerEdit: async (editEmployeeRequestDto: EditEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeRequestDto' is not null or undefined
            assertParamExists('employeeRequestControllerEdit', 'editEmployeeRequestDto', editEmployeeRequestDto)
            const localVarPath = `/api/v1/employee-request/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-request/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LeaveEmployeeRequestDto} leave 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerLeave: async (leave: LeaveEmployeeRequestDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leave' is not null or undefined
            assertParamExists('employeeRequestControllerLeave', 'leave', leave)
            const localVarPath = `/api/v1/employee-request/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (leave !== undefined) { 
                localVarFormParams.append('leave', new Blob([JSON.stringify(leave)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OvertimeEmployeeRequestDto} overtime 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerOvertime: async (overtime: OvertimeEmployeeRequestDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'overtime' is not null or undefined
            assertParamExists('employeeRequestControllerOvertime', 'overtime', overtime)
            const localVarPath = `/api/v1/employee-request/overtime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (overtime !== undefined) { 
                localVarFormParams.append('overtime', new Blob([JSON.stringify(overtime)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessEmployeeRequestDto} processEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerProcess: async (processEmployeeRequestDto: ProcessEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processEmployeeRequestDto' is not null or undefined
            assertParamExists('employeeRequestControllerProcess', 'processEmployeeRequestDto', processEmployeeRequestDto)
            const localVarPath = `/api/v1/employee-request/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LeaveEmployeeRequestDto} leave 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerPublicLeave: async (leave: LeaveEmployeeRequestDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leave' is not null or undefined
            assertParamExists('employeeRequestControllerPublicLeave', 'leave', leave)
            const localVarPath = `/api/v1/employee-request/public/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (leave !== undefined) { 
                localVarFormParams.append('leave', new Blob([JSON.stringify(leave)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeRequestApi - functional programming interface
 * @export
 */
export const EmployeeRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AmendmentEmployeeRequestDto} amendment 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerAmandment(amendment: AmendmentEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerAmandment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerAmandment(amendment, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerAmandment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AmendBreakDto} _break 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerAmandmentBreak(_break: AmendBreakDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerAmandment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerAmandmentBreak(_break, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerAmandmentBreak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CsrRequestDto} csr 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerChangeShift(csr: CsrRequestDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerAmandment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerChangeShift(csr, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerChangeShift']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateEmployeeRequestDto} createEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerCreate(createEmployeeRequestDto: CreateEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerCreate(createEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeRequestDto} deleteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerDelete(deleteEmployeeRequestDto: DeleteEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerDelete(deleteEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeRequestDto} editEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerEdit(editEmployeeRequestDto: EditEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerEdit(editEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LeaveEmployeeRequestDto} leave 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerLeave(leave: LeaveEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerProcess200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerLeave(leave, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerLeave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OvertimeEmployeeRequestDto} overtime 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerOvertime(overtime: OvertimeEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerProcess200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerOvertime(overtime, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerOvertime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessEmployeeRequestDto} processEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerProcess(processEmployeeRequestDto: ProcessEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerProcess200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerProcess(processEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LeaveEmployeeRequestDto} leave 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeRequestControllerPublicLeave(leave: LeaveEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeRequestControllerProcess200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeRequestControllerPublicLeave(leave, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeRequestApi.employeeRequestControllerPublicLeave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeRequestApi - factory interface
 * @export
 */
export const EmployeeRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {AmendmentEmployeeRequestDto} amendment 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerAmandment(amendment: AmendmentEmployeeRequestDto, file?: File, options?: any): AxiosPromise<EmployeeRequestControllerAmandment201Response> {
            return localVarFp.employeeRequestControllerAmandment(amendment, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AmendBreakDto} _break 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerAmandmentBreak(_break: AmendBreakDto, file?: File, options?: any): AxiosPromise<EmployeeRequestControllerAmandment201Response> {
            return localVarFp.employeeRequestControllerAmandmentBreak(_break, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CsrRequestDto} csr 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerChangeShift(csr: CsrRequestDto, file?: File, options?: any): AxiosPromise<EmployeeRequestControllerAmandment201Response> {
            return localVarFp.employeeRequestControllerChangeShift(csr, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEmployeeRequestDto} createEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerCreate(createEmployeeRequestDto: CreateEmployeeRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeRequestControllerCreate(createEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeRequestDto} deleteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerDelete(deleteEmployeeRequestDto: DeleteEmployeeRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeRequestControllerDelete(deleteEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeRequestDto} editEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerEdit(editEmployeeRequestDto: EditEmployeeRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeRequestControllerEdit(editEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeRequestControllerGet200Response> {
            return localVarFp.employeeRequestControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LeaveEmployeeRequestDto} leave 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerLeave(leave: LeaveEmployeeRequestDto, file?: File, options?: any): AxiosPromise<EmployeeRequestControllerProcess200Response> {
            return localVarFp.employeeRequestControllerLeave(leave, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OvertimeEmployeeRequestDto} overtime 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerOvertime(overtime: OvertimeEmployeeRequestDto, file?: File, options?: any): AxiosPromise<EmployeeRequestControllerProcess200Response> {
            return localVarFp.employeeRequestControllerOvertime(overtime, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessEmployeeRequestDto} processEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerProcess(processEmployeeRequestDto: ProcessEmployeeRequestDto, options?: any): AxiosPromise<EmployeeRequestControllerProcess200Response> {
            return localVarFp.employeeRequestControllerProcess(processEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LeaveEmployeeRequestDto} leave 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeRequestControllerPublicLeave(leave: LeaveEmployeeRequestDto, file?: File, options?: any): AxiosPromise<EmployeeRequestControllerProcess200Response> {
            return localVarFp.employeeRequestControllerPublicLeave(leave, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeRequestApi - object-oriented interface
 * @export
 * @class EmployeeRequestApi
 * @extends {BaseAPI}
 */
export class EmployeeRequestApi extends BaseAPI {
    /**
     * 
     * @param {AmendmentEmployeeRequestDto} amendment 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerAmandment(amendment: AmendmentEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerAmandment(amendment, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AmendBreakDto} _break 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerAmandmentBreak(_break: AmendBreakDto, file?: File, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerAmandmentBreak(_break, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsrRequestDto} csr 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerChangeShift(csr: CsrRequestDto, file?: File, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerChangeShift(csr, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEmployeeRequestDto} createEmployeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerCreate(createEmployeeRequestDto: CreateEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerCreate(createEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeRequestDto} deleteEmployeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerDelete(deleteEmployeeRequestDto: DeleteEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerDelete(deleteEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeRequestDto} editEmployeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerEdit(editEmployeeRequestDto: EditEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerEdit(editEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeaveEmployeeRequestDto} leave 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerLeave(leave: LeaveEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerLeave(leave, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OvertimeEmployeeRequestDto} overtime 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerOvertime(overtime: OvertimeEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerOvertime(overtime, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessEmployeeRequestDto} processEmployeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerProcess(processEmployeeRequestDto: ProcessEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerProcess(processEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeaveEmployeeRequestDto} leave 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeRequestApi
     */
    public employeeRequestControllerPublicLeave(leave: LeaveEmployeeRequestDto, file?: File, options?: RawAxiosRequestConfig) {
        return EmployeeRequestApiFp(this.configuration).employeeRequestControllerPublicLeave(leave, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeSalaryApi - axios parameter creator
 * @export
 */
export const EmployeeSalaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeSalaryDto} createEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerCreate: async (createEmployeeSalaryDto: CreateEmployeeSalaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeSalaryDto' is not null or undefined
            assertParamExists('employeeSalaryControllerCreate', 'createEmployeeSalaryDto', createEmployeeSalaryDto)
            const localVarPath = `/api/v1/employee-salary/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeSalaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeSalaryDto} deleteEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerDelete: async (deleteEmployeeSalaryDto: DeleteEmployeeSalaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeSalaryDto' is not null or undefined
            assertParamExists('employeeSalaryControllerDelete', 'deleteEmployeeSalaryDto', deleteEmployeeSalaryDto)
            const localVarPath = `/api/v1/employee-salary/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeSalaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeSalaryDto} editEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerEdit: async (editEmployeeSalaryDto: EditEmployeeSalaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeSalaryDto' is not null or undefined
            assertParamExists('employeeSalaryControllerEdit', 'editEmployeeSalaryDto', editEmployeeSalaryDto)
            const localVarPath = `/api/v1/employee-salary/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeSalaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-salary/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeSalaryApi - functional programming interface
 * @export
 */
export const EmployeeSalaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeSalaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeSalaryDto} createEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeSalaryControllerCreate(createEmployeeSalaryDto: CreateEmployeeSalaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeSalaryControllerCreate(createEmployeeSalaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeSalaryApi.employeeSalaryControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeSalaryDto} deleteEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeSalaryControllerDelete(deleteEmployeeSalaryDto: DeleteEmployeeSalaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeSalaryControllerDelete(deleteEmployeeSalaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeSalaryApi.employeeSalaryControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeSalaryDto} editEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeSalaryControllerEdit(editEmployeeSalaryDto: EditEmployeeSalaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeSalaryControllerEdit(editEmployeeSalaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeSalaryApi.employeeSalaryControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeSalaryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeSalaryControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeSalaryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeSalaryApi.employeeSalaryControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeSalaryApi - factory interface
 * @export
 */
export const EmployeeSalaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeSalaryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeSalaryDto} createEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerCreate(createEmployeeSalaryDto: CreateEmployeeSalaryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeSalaryControllerCreate(createEmployeeSalaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeSalaryDto} deleteEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerDelete(deleteEmployeeSalaryDto: DeleteEmployeeSalaryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeSalaryControllerDelete(deleteEmployeeSalaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeSalaryDto} editEmployeeSalaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerEdit(editEmployeeSalaryDto: EditEmployeeSalaryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeSalaryControllerEdit(editEmployeeSalaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSalaryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeSalaryControllerGet200Response> {
            return localVarFp.employeeSalaryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeSalaryApi - object-oriented interface
 * @export
 * @class EmployeeSalaryApi
 * @extends {BaseAPI}
 */
export class EmployeeSalaryApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeSalaryDto} createEmployeeSalaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeSalaryApi
     */
    public employeeSalaryControllerCreate(createEmployeeSalaryDto: CreateEmployeeSalaryDto, options?: RawAxiosRequestConfig) {
        return EmployeeSalaryApiFp(this.configuration).employeeSalaryControllerCreate(createEmployeeSalaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeSalaryDto} deleteEmployeeSalaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeSalaryApi
     */
    public employeeSalaryControllerDelete(deleteEmployeeSalaryDto: DeleteEmployeeSalaryDto, options?: RawAxiosRequestConfig) {
        return EmployeeSalaryApiFp(this.configuration).employeeSalaryControllerDelete(deleteEmployeeSalaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeSalaryDto} editEmployeeSalaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeSalaryApi
     */
    public employeeSalaryControllerEdit(editEmployeeSalaryDto: EditEmployeeSalaryDto, options?: RawAxiosRequestConfig) {
        return EmployeeSalaryApiFp(this.configuration).employeeSalaryControllerEdit(editEmployeeSalaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeSalaryApi
     */
    public employeeSalaryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeSalaryApiFp(this.configuration).employeeSalaryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeScoreApi - axios parameter creator
 * @export
 */
export const EmployeeScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddEmployeeScoreDto} addEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerCreate: async (addEmployeeScoreDto: AddEmployeeScoreDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addEmployeeScoreDto' is not null or undefined
            assertParamExists('employeeScoreControllerCreate', 'addEmployeeScoreDto', addEmployeeScoreDto)
            const localVarPath = `/api/v1/employee-score/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addEmployeeScoreDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeScoreDto} deleteEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerDelete: async (deleteEmployeeScoreDto: DeleteEmployeeScoreDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeScoreDto' is not null or undefined
            assertParamExists('employeeScoreControllerDelete', 'deleteEmployeeScoreDto', deleteEmployeeScoreDto)
            const localVarPath = `/api/v1/employee-score/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeScoreDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeScoreDto} editEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerEdit: async (editEmployeeScoreDto: EditEmployeeScoreDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeScoreDto' is not null or undefined
            assertParamExists('employeeScoreControllerEdit', 'editEmployeeScoreDto', editEmployeeScoreDto)
            const localVarPath = `/api/v1/employee-score/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeScoreDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-score/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerGetAvg: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-score/get-avg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerGetAvgBranch: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-score/get-avg-branch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeScoreApi - functional programming interface
 * @export
 */
export const EmployeeScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeScoreDto} addEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeScoreControllerCreate(addEmployeeScoreDto: AddEmployeeScoreDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeScoreControllerCreate(addEmployeeScoreDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeScoreApi.employeeScoreControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeScoreDto} deleteEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeScoreControllerDelete(deleteEmployeeScoreDto: DeleteEmployeeScoreDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeScoreControllerDelete(deleteEmployeeScoreDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeScoreApi.employeeScoreControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeScoreDto} editEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeScoreControllerEdit(editEmployeeScoreDto: EditEmployeeScoreDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeScoreControllerEdit(editEmployeeScoreDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeScoreApi.employeeScoreControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeScoreControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeScoreControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeScoreControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeScoreApi.employeeScoreControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeScoreControllerGetAvg(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeScoreControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeScoreControllerGetAvg(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeScoreApi.employeeScoreControllerGetAvg']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeScoreControllerGetAvgBranch(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeScoreControllerGetAvgBranch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeScoreControllerGetAvgBranch(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeScoreApi.employeeScoreControllerGetAvgBranch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeScoreApi - factory interface
 * @export
 */
export const EmployeeScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeScoreApiFp(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeScoreDto} addEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerCreate(addEmployeeScoreDto: AddEmployeeScoreDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeScoreControllerCreate(addEmployeeScoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeScoreDto} deleteEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerDelete(deleteEmployeeScoreDto: DeleteEmployeeScoreDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeScoreControllerDelete(deleteEmployeeScoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeScoreDto} editEmployeeScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerEdit(editEmployeeScoreDto: EditEmployeeScoreDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeScoreControllerEdit(editEmployeeScoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeScoreControllerGet200Response> {
            return localVarFp.employeeScoreControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerGetAvg(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeScoreControllerGet200Response> {
            return localVarFp.employeeScoreControllerGetAvg(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeScoreControllerGetAvgBranch(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeScoreControllerGetAvgBranch200Response> {
            return localVarFp.employeeScoreControllerGetAvgBranch(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeScoreApi - object-oriented interface
 * @export
 * @class EmployeeScoreApi
 * @extends {BaseAPI}
 */
export class EmployeeScoreApi extends BaseAPI {
    /**
     * 
     * @param {AddEmployeeScoreDto} addEmployeeScoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeScoreApi
     */
    public employeeScoreControllerCreate(addEmployeeScoreDto: AddEmployeeScoreDto, options?: RawAxiosRequestConfig) {
        return EmployeeScoreApiFp(this.configuration).employeeScoreControllerCreate(addEmployeeScoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeScoreDto} deleteEmployeeScoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeScoreApi
     */
    public employeeScoreControllerDelete(deleteEmployeeScoreDto: DeleteEmployeeScoreDto, options?: RawAxiosRequestConfig) {
        return EmployeeScoreApiFp(this.configuration).employeeScoreControllerDelete(deleteEmployeeScoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeScoreDto} editEmployeeScoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeScoreApi
     */
    public employeeScoreControllerEdit(editEmployeeScoreDto: EditEmployeeScoreDto, options?: RawAxiosRequestConfig) {
        return EmployeeScoreApiFp(this.configuration).employeeScoreControllerEdit(editEmployeeScoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeScoreApi
     */
    public employeeScoreControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeScoreApiFp(this.configuration).employeeScoreControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeScoreApi
     */
    public employeeScoreControllerGetAvg(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeScoreApiFp(this.configuration).employeeScoreControllerGetAvg(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeScoreApi
     */
    public employeeScoreControllerGetAvgBranch(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeScoreApiFp(this.configuration).employeeScoreControllerGetAvgBranch(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeShiftApi - axios parameter creator
 * @export
 */
export const EmployeeShiftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddEmployeeShiftDto} addEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerCreate: async (addEmployeeShiftDto: AddEmployeeShiftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addEmployeeShiftDto' is not null or undefined
            assertParamExists('employeeShiftControllerCreate', 'addEmployeeShiftDto', addEmployeeShiftDto)
            const localVarPath = `/api/v1/employee-shift/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addEmployeeShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeShiftDto} deleteEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerDelete: async (deleteEmployeeShiftDto: DeleteEmployeeShiftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeShiftDto' is not null or undefined
            assertParamExists('employeeShiftControllerDelete', 'deleteEmployeeShiftDto', deleteEmployeeShiftDto)
            const localVarPath = `/api/v1/employee-shift/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeShiftDto} editEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerEdit: async (editEmployeeShiftDto: EditEmployeeShiftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeShiftDto' is not null or undefined
            assertParamExists('employeeShiftControllerEdit', 'editEmployeeShiftDto', editEmployeeShiftDto)
            const localVarPath = `/api/v1/employee-shift/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-shift/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeShiftApi - functional programming interface
 * @export
 */
export const EmployeeShiftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeShiftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeShiftDto} addEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeShiftControllerCreate(addEmployeeShiftDto: AddEmployeeShiftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeShiftControllerCreate(addEmployeeShiftDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeShiftApi.employeeShiftControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeShiftDto} deleteEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeShiftControllerDelete(deleteEmployeeShiftDto: DeleteEmployeeShiftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeShiftControllerDelete(deleteEmployeeShiftDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeShiftApi.employeeShiftControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeShiftDto} editEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeShiftControllerEdit(editEmployeeShiftDto: EditEmployeeShiftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeShiftControllerEdit(editEmployeeShiftDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeShiftApi.employeeShiftControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeShiftControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeShiftControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeShiftControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeShiftApi.employeeShiftControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeShiftApi - factory interface
 * @export
 */
export const EmployeeShiftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeShiftApiFp(configuration)
    return {
        /**
         * 
         * @param {AddEmployeeShiftDto} addEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerCreate(addEmployeeShiftDto: AddEmployeeShiftDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeShiftControllerCreate(addEmployeeShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeShiftDto} deleteEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerDelete(deleteEmployeeShiftDto: DeleteEmployeeShiftDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeShiftControllerDelete(deleteEmployeeShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeShiftDto} editEmployeeShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerEdit(editEmployeeShiftDto: EditEmployeeShiftDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeShiftControllerEdit(editEmployeeShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeShiftControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeShiftControllerGet200Response> {
            return localVarFp.employeeShiftControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeShiftApi - object-oriented interface
 * @export
 * @class EmployeeShiftApi
 * @extends {BaseAPI}
 */
export class EmployeeShiftApi extends BaseAPI {
    /**
     * 
     * @param {AddEmployeeShiftDto} addEmployeeShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeShiftApi
     */
    public employeeShiftControllerCreate(addEmployeeShiftDto: AddEmployeeShiftDto, options?: RawAxiosRequestConfig) {
        return EmployeeShiftApiFp(this.configuration).employeeShiftControllerCreate(addEmployeeShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeShiftDto} deleteEmployeeShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeShiftApi
     */
    public employeeShiftControllerDelete(deleteEmployeeShiftDto: DeleteEmployeeShiftDto, options?: RawAxiosRequestConfig) {
        return EmployeeShiftApiFp(this.configuration).employeeShiftControllerDelete(deleteEmployeeShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeShiftDto} editEmployeeShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeShiftApi
     */
    public employeeShiftControllerEdit(editEmployeeShiftDto: EditEmployeeShiftDto, options?: RawAxiosRequestConfig) {
        return EmployeeShiftApiFp(this.configuration).employeeShiftControllerEdit(editEmployeeShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeShiftApi
     */
    public employeeShiftControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeShiftApiFp(this.configuration).employeeShiftControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeTimelineApi - axios parameter creator
 * @export
 */
export const EmployeeTimelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeTimelineDto} createEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerCreate: async (createEmployeeTimelineDto: CreateEmployeeTimelineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeTimelineDto' is not null or undefined
            assertParamExists('employeeTimelineControllerCreate', 'createEmployeeTimelineDto', createEmployeeTimelineDto)
            const localVarPath = `/api/v1/employee-timeline/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeTimelineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmployeeTimelineDto} deleteEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerDelete: async (deleteEmployeeTimelineDto: DeleteEmployeeTimelineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeTimelineDto' is not null or undefined
            assertParamExists('employeeTimelineControllerDelete', 'deleteEmployeeTimelineDto', deleteEmployeeTimelineDto)
            const localVarPath = `/api/v1/employee-timeline/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeTimelineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmployeeTimelineDto} editEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerEdit: async (editEmployeeTimelineDto: EditEmployeeTimelineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmployeeTimelineDto' is not null or undefined
            assertParamExists('employeeTimelineControllerEdit', 'editEmployeeTimelineDto', editEmployeeTimelineDto)
            const localVarPath = `/api/v1/employee-timeline/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmployeeTimelineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-timeline/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerGetMilestone: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-timeline/milestone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeTimelineApi - functional programming interface
 * @export
 */
export const EmployeeTimelineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeTimelineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeTimelineDto} createEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeTimelineControllerCreate(createEmployeeTimelineDto: CreateEmployeeTimelineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeTimelineControllerCreate(createEmployeeTimelineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeTimelineApi.employeeTimelineControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmployeeTimelineDto} deleteEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeTimelineControllerDelete(deleteEmployeeTimelineDto: DeleteEmployeeTimelineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeTimelineControllerDelete(deleteEmployeeTimelineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeTimelineApi.employeeTimelineControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmployeeTimelineDto} editEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeTimelineControllerEdit(editEmployeeTimelineDto: EditEmployeeTimelineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeTimelineControllerEdit(editEmployeeTimelineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeTimelineApi.employeeTimelineControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeTimelineControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeTimelineControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeTimelineControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeTimelineApi.employeeTimelineControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeTimelineControllerGetMilestone(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeTimelineControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeTimelineControllerGetMilestone(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeTimelineApi.employeeTimelineControllerGetMilestone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeTimelineApi - factory interface
 * @export
 */
export const EmployeeTimelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeTimelineApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeTimelineDto} createEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerCreate(createEmployeeTimelineDto: CreateEmployeeTimelineDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeTimelineControllerCreate(createEmployeeTimelineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmployeeTimelineDto} deleteEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerDelete(deleteEmployeeTimelineDto: DeleteEmployeeTimelineDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeTimelineControllerDelete(deleteEmployeeTimelineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmployeeTimelineDto} editEmployeeTimelineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerEdit(editEmployeeTimelineDto: EditEmployeeTimelineDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.employeeTimelineControllerEdit(editEmployeeTimelineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeTimelineControllerGet200Response> {
            return localVarFp.employeeTimelineControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeTimelineControllerGetMilestone(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<EmployeeTimelineControllerGet200Response> {
            return localVarFp.employeeTimelineControllerGetMilestone(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeTimelineApi - object-oriented interface
 * @export
 * @class EmployeeTimelineApi
 * @extends {BaseAPI}
 */
export class EmployeeTimelineApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeTimelineDto} createEmployeeTimelineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeTimelineApi
     */
    public employeeTimelineControllerCreate(createEmployeeTimelineDto: CreateEmployeeTimelineDto, options?: RawAxiosRequestConfig) {
        return EmployeeTimelineApiFp(this.configuration).employeeTimelineControllerCreate(createEmployeeTimelineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmployeeTimelineDto} deleteEmployeeTimelineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeTimelineApi
     */
    public employeeTimelineControllerDelete(deleteEmployeeTimelineDto: DeleteEmployeeTimelineDto, options?: RawAxiosRequestConfig) {
        return EmployeeTimelineApiFp(this.configuration).employeeTimelineControllerDelete(deleteEmployeeTimelineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmployeeTimelineDto} editEmployeeTimelineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeTimelineApi
     */
    public employeeTimelineControllerEdit(editEmployeeTimelineDto: EditEmployeeTimelineDto, options?: RawAxiosRequestConfig) {
        return EmployeeTimelineApiFp(this.configuration).employeeTimelineControllerEdit(editEmployeeTimelineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeTimelineApi
     */
    public employeeTimelineControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeTimelineApiFp(this.configuration).employeeTimelineControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeTimelineApi
     */
    public employeeTimelineControllerGetMilestone(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return EmployeeTimelineApiFp(this.configuration).employeeTimelineControllerGetMilestone(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GoogleCloudStorageApi - axios parameter creator
 * @export
 */
export const GoogleCloudStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filepath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudStorageControllerGetImage: async (filepath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filepath' is not null or undefined
            assertParamExists('cloudStorageControllerGetImage', 'filepath', filepath)
            const localVarPath = `/api/v1/cloud/storage/asset/{filepath}`
                .replace(`{${"filepath"}}`, encodeURIComponent(String(filepath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudStorageControllerGetSampleAssets: async (params: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'params' is not null or undefined
            assertParamExists('cloudStorageControllerGetSampleAssets', 'params', params)
            const localVarPath = `/api/v1/cloud/storage/sample/{params}`
                .replace(`{${"params"}}`, encodeURIComponent(String(params)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudStorageControllerUploadMedia: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('cloudStorageControllerUploadMedia', 'file', file)
            const localVarPath = `/api/v1/cloud/storage/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleCloudStorageApi - functional programming interface
 * @export
 */
export const GoogleCloudStorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleCloudStorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filepath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudStorageControllerGetImage(filepath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudStorageControllerGetImage(filepath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleCloudStorageApi.cloudStorageControllerGetImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudStorageControllerGetSampleAssets(params: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudStorageControllerGetSampleAssets(params, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleCloudStorageApi.cloudStorageControllerGetSampleAssets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudStorageControllerUploadMedia(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudStorageControllerUploadMedia(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleCloudStorageApi.cloudStorageControllerUploadMedia']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GoogleCloudStorageApi - factory interface
 * @export
 */
export const GoogleCloudStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleCloudStorageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filepath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudStorageControllerGetImage(filepath: string, options?: any): AxiosPromise<File> {
            return localVarFp.cloudStorageControllerGetImage(filepath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudStorageControllerGetSampleAssets(params: string, options?: any): AxiosPromise<void> {
            return localVarFp.cloudStorageControllerGetSampleAssets(params, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudStorageControllerUploadMedia(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.cloudStorageControllerUploadMedia(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleCloudStorageApi - object-oriented interface
 * @export
 * @class GoogleCloudStorageApi
 * @extends {BaseAPI}
 */
export class GoogleCloudStorageApi extends BaseAPI {
    /**
     * 
     * @param {string} filepath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleCloudStorageApi
     */
    public cloudStorageControllerGetImage(filepath: string, options?: RawAxiosRequestConfig) {
        return GoogleCloudStorageApiFp(this.configuration).cloudStorageControllerGetImage(filepath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} params 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleCloudStorageApi
     */
    public cloudStorageControllerGetSampleAssets(params: string, options?: RawAxiosRequestConfig) {
        return GoogleCloudStorageApiFp(this.configuration).cloudStorageControllerGetSampleAssets(params, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleCloudStorageApi
     */
    public cloudStorageControllerUploadMedia(file: File, options?: RawAxiosRequestConfig) {
        return GoogleCloudStorageApiFp(this.configuration).cloudStorageControllerUploadMedia(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PayrollApi - axios parameter creator
 * @export
 */
export const PayrollApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
         * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
         * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
         * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGet: async (dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('payrollControllerGet', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('payrollControllerGet', 'dateTo', dateTo)
            const localVarPath = `/api/v1/payroll/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branch_code'] = branchCode;
            }

            if (employmentTypeCode !== undefined) {
                localVarQueryParameter['employment_type_code'] = employmentTypeCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
         * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
         * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
         * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerSummary: async (dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('payrollControllerSummary', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('payrollControllerSummary', 'dateTo', dateTo)
            const localVarPath = `/api/v1/payroll/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branch_code'] = branchCode;
            }

            if (employmentTypeCode !== undefined) {
                localVarQueryParameter['employment_type_code'] = employmentTypeCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayrollApi - functional programming interface
 * @export
 */
export const PayrollApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayrollApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
         * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
         * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
         * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerGet(dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayrollControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerGet(dateFrom, dateTo, branchCode, employmentTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PayrollApi.payrollControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
         * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
         * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
         * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerSummary(dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayrollControllerSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerSummary(dateFrom, dateTo, branchCode, employmentTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PayrollApi.payrollControllerSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PayrollApi - factory interface
 * @export
 */
export const PayrollApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayrollApiFp(configuration)
    return {
        /**
         * 
         * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
         * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
         * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
         * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGet(dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options?: any): AxiosPromise<PayrollControllerGet200Response> {
            return localVarFp.payrollControllerGet(dateFrom, dateTo, branchCode, employmentTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
         * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
         * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
         * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerSummary(dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options?: any): AxiosPromise<PayrollControllerSummary200Response> {
            return localVarFp.payrollControllerSummary(dateFrom, dateTo, branchCode, employmentTypeCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayrollApi - object-oriented interface
 * @export
 * @class PayrollApi
 * @extends {BaseAPI}
 */
export class PayrollApi extends BaseAPI {
    /**
     * 
     * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
     * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
     * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
     * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayrollApi
     */
    public payrollControllerGet(dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options?: RawAxiosRequestConfig) {
        return PayrollApiFp(this.configuration).payrollControllerGet(dateFrom, dateTo, branchCode, employmentTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateFrom The date of the event. Format: YYYY-MM-DD
     * @param {string} dateTo The date of the event. Format: YYYY-MM-DD
     * @param {string | null} [branchCode] To enter multiple branch, it must be separated by comma (,)
     * @param {string | null} [employmentTypeCode] To enter multiple employment type, it must be separated by comma (,)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayrollApi
     */
    public payrollControllerSummary(dateFrom: string, dateTo: string, branchCode?: string | null, employmentTypeCode?: string | null, options?: RawAxiosRequestConfig) {
        return PayrollApiFp(this.configuration).payrollControllerSummary(dateFrom, dateTo, branchCode, employmentTypeCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerAttachFile: async (code: string, attachmentType: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('personControllerAttachFile', 'code', code)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('personControllerAttachFile', 'attachmentType', attachmentType)
            const localVarPath = `/api/v1/person/attach-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (code !== undefined) { 
                localVarFormParams.append('code', code as any);
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', attachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BlockPersonDto} blockPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerBlock: async (blockPersonDto: BlockPersonDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockPersonDto' is not null or undefined
            assertParamExists('personControllerBlock', 'blockPersonDto', blockPersonDto)
            const localVarPath = `/api/v1/person/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePersonExtendedDto} person 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerCreate: async (person: CreatePersonExtendedDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'person' is not null or undefined
            assertParamExists('personControllerCreate', 'person', person)
            const localVarPath = `/api/v1/person/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (person !== undefined) { 
                localVarFormParams.append('person', new Blob([JSON.stringify(person)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerDelete: async (deletePersonDto: DeletePersonDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePersonDto' is not null or undefined
            assertParamExists('personControllerDelete', 'deletePersonDto', deletePersonDto)
            const localVarPath = `/api/v1/person/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPersonExtendedDto} person 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerEdit: async (person: EditPersonExtendedDto, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'person' is not null or undefined
            assertParamExists('personControllerEdit', 'person', person)
            const localVarPath = `/api/v1/person/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (person !== undefined) { 
                localVarFormParams.append('person', new Blob([JSON.stringify(person)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/person/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGetBlacklist: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/person/blacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerAttachFile(code: string, attachmentType: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerAttachFile(code, attachmentType, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerAttachFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BlockPersonDto} blockPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerBlock(blockPersonDto: BlockPersonDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerBlock(blockPersonDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerBlock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreatePersonExtendedDto} person 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerCreate(person: CreatePersonExtendedDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerCreate(person, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerDelete(deletePersonDto: DeletePersonDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerDelete(deletePersonDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditPersonExtendedDto} person 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerEdit(person: EditPersonExtendedDto, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerEdit(person, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerGetBlacklist(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonControllerGetBlacklist200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerGetBlacklist(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonApi.personControllerGetBlacklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerAttachFile(code: string, attachmentType: string, file?: File, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerAttachFile(code, attachmentType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BlockPersonDto} blockPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerBlock(blockPersonDto: BlockPersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerBlock(blockPersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePersonExtendedDto} person 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerCreate(person: CreatePersonExtendedDto, file?: File, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerCreate(person, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerDelete(deletePersonDto: DeletePersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerDelete(deletePersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPersonExtendedDto} person 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerEdit(person: EditPersonExtendedDto, file?: File, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerEdit(person, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PersonControllerGet200Response> {
            return localVarFp.personControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGetBlacklist(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PersonControllerGetBlacklist200Response> {
            return localVarFp.personControllerGetBlacklist(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerAttachFile(code: string, attachmentType: string, file?: File, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerAttachFile(code, attachmentType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlockPersonDto} blockPersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerBlock(blockPersonDto: BlockPersonDto, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerBlock(blockPersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePersonExtendedDto} person 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerCreate(person: CreatePersonExtendedDto, file?: File, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerCreate(person, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePersonDto} deletePersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerDelete(deletePersonDto: DeletePersonDto, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerDelete(deletePersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPersonExtendedDto} person 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerEdit(person: EditPersonExtendedDto, file?: File, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerEdit(person, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerGetBlacklist(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerGetBlacklist(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonAddressApi - axios parameter creator
 * @export
 */
export const PersonAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePersonAddressDto} createPersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerCreate: async (createPersonAddressDto: CreatePersonAddressDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPersonAddressDto' is not null or undefined
            assertParamExists('personAddressControllerCreate', 'createPersonAddressDto', createPersonAddressDto)
            const localVarPath = `/api/v1/person-address/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPersonAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePersonAddressDto} deletePersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerDelete: async (deletePersonAddressDto: DeletePersonAddressDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePersonAddressDto' is not null or undefined
            assertParamExists('personAddressControllerDelete', 'deletePersonAddressDto', deletePersonAddressDto)
            const localVarPath = `/api/v1/person-address/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePersonAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPersonAddressDto} editPersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerEdit: async (editPersonAddressDto: EditPersonAddressDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPersonAddressDto' is not null or undefined
            assertParamExists('personAddressControllerEdit', 'editPersonAddressDto', editPersonAddressDto)
            const localVarPath = `/api/v1/person-address/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPersonAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/person-address/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonAddressApi - functional programming interface
 * @export
 */
export const PersonAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePersonAddressDto} createPersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAddressControllerCreate(createPersonAddressDto: CreatePersonAddressDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAddressControllerCreate(createPersonAddressDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonAddressApi.personAddressControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeletePersonAddressDto} deletePersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAddressControllerDelete(deletePersonAddressDto: DeletePersonAddressDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAddressControllerDelete(deletePersonAddressDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonAddressApi.personAddressControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditPersonAddressDto} editPersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAddressControllerEdit(editPersonAddressDto: EditPersonAddressDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAddressControllerEdit(editPersonAddressDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonAddressApi.personAddressControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAddressControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonAddressControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAddressControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonAddressApi.personAddressControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PersonAddressApi - factory interface
 * @export
 */
export const PersonAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonAddressApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePersonAddressDto} createPersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerCreate(createPersonAddressDto: CreatePersonAddressDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personAddressControllerCreate(createPersonAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePersonAddressDto} deletePersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerDelete(deletePersonAddressDto: DeletePersonAddressDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personAddressControllerDelete(deletePersonAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPersonAddressDto} editPersonAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerEdit(editPersonAddressDto: EditPersonAddressDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personAddressControllerEdit(editPersonAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAddressControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PersonAddressControllerGet200Response> {
            return localVarFp.personAddressControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonAddressApi - object-oriented interface
 * @export
 * @class PersonAddressApi
 * @extends {BaseAPI}
 */
export class PersonAddressApi extends BaseAPI {
    /**
     * 
     * @param {CreatePersonAddressDto} createPersonAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAddressApi
     */
    public personAddressControllerCreate(createPersonAddressDto: CreatePersonAddressDto, options?: RawAxiosRequestConfig) {
        return PersonAddressApiFp(this.configuration).personAddressControllerCreate(createPersonAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePersonAddressDto} deletePersonAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAddressApi
     */
    public personAddressControllerDelete(deletePersonAddressDto: DeletePersonAddressDto, options?: RawAxiosRequestConfig) {
        return PersonAddressApiFp(this.configuration).personAddressControllerDelete(deletePersonAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPersonAddressDto} editPersonAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAddressApi
     */
    public personAddressControllerEdit(editPersonAddressDto: EditPersonAddressDto, options?: RawAxiosRequestConfig) {
        return PersonAddressApiFp(this.configuration).personAddressControllerEdit(editPersonAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAddressApi
     */
    public personAddressControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return PersonAddressApiFp(this.configuration).personAddressControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} workDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerBranchDailyDemographics: async (workDate: string, branchCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workDate' is not null or undefined
            assertParamExists('reportControllerBranchDailyDemographics', 'workDate', workDate)
            // verify required parameter 'branchCode' is not null or undefined
            assertParamExists('reportControllerBranchDailyDemographics', 'branchCode', branchCode)
            const localVarPath = `/api/v1/report/branch-daily-demographics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workDate !== undefined) {
                localVarQueryParameter['work_date'] = (workDate as any instanceof Date) ?
                    (workDate as any).toISOString() :
                    workDate;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branch_code'] = branchCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerBranchDailyDemographicsV2: async (startDate: string, endDate: string, branchCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('reportControllerBranchDailyDemographicsV2', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('reportControllerBranchDailyDemographicsV2', 'endDate', endDate)
            // verify required parameter 'branchCode' is not null or undefined
            assertParamExists('reportControllerBranchDailyDemographicsV2', 'branchCode', branchCode)
            const localVarPath = `/api/v1/report/branch-daily-demographics-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branch_code'] = branchCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerDailyPerformance: async (startDate: string, endDate: string, branchCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('reportControllerDailyPerformance', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('reportControllerDailyPerformance', 'endDate', endDate)
            // verify required parameter 'branchCode' is not null or undefined
            assertParamExists('reportControllerDailyPerformance', 'branchCode', branchCode)
            const localVarPath = `/api/v1/report/daily-performance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (branchCode !== undefined) {
                localVarQueryParameter['branch_code'] = branchCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} workDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerBranchDailyDemographics(workDate: string, branchCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerBranchDailyDemographics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerBranchDailyDemographics(workDate, branchCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.reportControllerBranchDailyDemographics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerBranchDailyDemographicsV2(startDate: string, endDate: string, branchCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerBranchDailyDemographicsV2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerBranchDailyDemographicsV2(startDate, endDate, branchCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.reportControllerBranchDailyDemographicsV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerDailyPerformance(startDate: string, endDate: string, branchCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerDailyPerformance200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerDailyPerformance(startDate, endDate, branchCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.reportControllerDailyPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} workDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerBranchDailyDemographics(workDate: string, branchCode: string, options?: any): AxiosPromise<ReportControllerBranchDailyDemographics200Response> {
            return localVarFp.reportControllerBranchDailyDemographics(workDate, branchCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerBranchDailyDemographicsV2(startDate: string, endDate: string, branchCode: string, options?: any): AxiosPromise<ReportControllerBranchDailyDemographicsV2200Response> {
            return localVarFp.reportControllerBranchDailyDemographicsV2(startDate, endDate, branchCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate The date of the event. Format: YYYY-MM-DD
         * @param {string} endDate The date of the event. Format: YYYY-MM-DD
         * @param {string} branchCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerDailyPerformance(startDate: string, endDate: string, branchCode: string, options?: any): AxiosPromise<ReportControllerDailyPerformance200Response> {
            return localVarFp.reportControllerDailyPerformance(startDate, endDate, branchCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {string} workDate The date of the event. Format: YYYY-MM-DD
     * @param {string} branchCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerBranchDailyDemographics(workDate: string, branchCode: string, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerBranchDailyDemographics(workDate, branchCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate The date of the event. Format: YYYY-MM-DD
     * @param {string} endDate The date of the event. Format: YYYY-MM-DD
     * @param {string} branchCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerBranchDailyDemographicsV2(startDate: string, endDate: string, branchCode: string, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerBranchDailyDemographicsV2(startDate, endDate, branchCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate The date of the event. Format: YYYY-MM-DD
     * @param {string} endDate The date of the event. Format: YYYY-MM-DD
     * @param {string} branchCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerDailyPerformance(startDate: string, endDate: string, branchCode: string, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerDailyPerformance(startDate, endDate, branchCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAbsentTagging: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scheduler/absent-tagging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerNoClockout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scheduler/no-clockout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerAbsentTagging(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerAbsentTagging(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.schedulerControllerAbsentTagging']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerNoClockout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerNoClockout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.schedulerControllerNoClockout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAbsentTagging(options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.schedulerControllerAbsentTagging(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerNoClockout(options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.schedulerControllerNoClockout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerAbsentTagging(options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerAbsentTagging(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerNoClockout(options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerNoClockout(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityAttachmentTypeApi - axios parameter creator
 * @export
 */
export const UtilityAttachmentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAttachmentTypeDto} createAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerCreate: async (createAttachmentTypeDto: CreateAttachmentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAttachmentTypeDto' is not null or undefined
            assertParamExists('utilAttachmentTypeControllerCreate', 'createAttachmentTypeDto', createAttachmentTypeDto)
            const localVarPath = `/api/v1/util-attachment-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAttachmentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerDelete: async (deleteAttachmentTypeDto: DeleteAttachmentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteAttachmentTypeDto' is not null or undefined
            assertParamExists('utilAttachmentTypeControllerDelete', 'deleteAttachmentTypeDto', deleteAttachmentTypeDto)
            const localVarPath = `/api/v1/util-attachment-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAttachmentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditAttachmentTypeDto} editAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerEdit: async (editAttachmentTypeDto: EditAttachmentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editAttachmentTypeDto' is not null or undefined
            assertParamExists('utilAttachmentTypeControllerEdit', 'editAttachmentTypeDto', editAttachmentTypeDto)
            const localVarPath = `/api/v1/util-attachment-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editAttachmentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-attachment-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityAttachmentTypeApi - functional programming interface
 * @export
 */
export const UtilityAttachmentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityAttachmentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAttachmentTypeDto} createAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilAttachmentTypeControllerCreate(createAttachmentTypeDto: CreateAttachmentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilAttachmentTypeControllerCreate(createAttachmentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityAttachmentTypeApi.utilAttachmentTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto: DeleteAttachmentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityAttachmentTypeApi.utilAttachmentTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditAttachmentTypeDto} editAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilAttachmentTypeControllerEdit(editAttachmentTypeDto: EditAttachmentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilAttachmentTypeControllerEdit(editAttachmentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityAttachmentTypeApi.utilAttachmentTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilAttachmentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilAttachmentTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilAttachmentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityAttachmentTypeApi.utilAttachmentTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityAttachmentTypeApi - factory interface
 * @export
 */
export const UtilityAttachmentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityAttachmentTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAttachmentTypeDto} createAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerCreate(createAttachmentTypeDto: CreateAttachmentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilAttachmentTypeControllerCreate(createAttachmentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto: DeleteAttachmentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditAttachmentTypeDto} editAttachmentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerEdit(editAttachmentTypeDto: EditAttachmentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilAttachmentTypeControllerEdit(editAttachmentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilAttachmentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilAttachmentTypeControllerGet200Response> {
            return localVarFp.utilAttachmentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityAttachmentTypeApi - object-oriented interface
 * @export
 * @class UtilityAttachmentTypeApi
 * @extends {BaseAPI}
 */
export class UtilityAttachmentTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateAttachmentTypeDto} createAttachmentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityAttachmentTypeApi
     */
    public utilAttachmentTypeControllerCreate(createAttachmentTypeDto: CreateAttachmentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityAttachmentTypeApiFp(this.configuration).utilAttachmentTypeControllerCreate(createAttachmentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityAttachmentTypeApi
     */
    public utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto: DeleteAttachmentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityAttachmentTypeApiFp(this.configuration).utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditAttachmentTypeDto} editAttachmentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityAttachmentTypeApi
     */
    public utilAttachmentTypeControllerEdit(editAttachmentTypeDto: EditAttachmentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityAttachmentTypeApiFp(this.configuration).utilAttachmentTypeControllerEdit(editAttachmentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityAttachmentTypeApi
     */
    public utilAttachmentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityAttachmentTypeApiFp(this.configuration).utilAttachmentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityBranchApi - axios parameter creator
 * @export
 */
export const UtilityBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBranchDto} createBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerCreate: async (createBranchDto: CreateBranchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBranchDto' is not null or undefined
            assertParamExists('utilBranchControllerCreate', 'createBranchDto', createBranchDto)
            const localVarPath = `/api/v1/util-branch/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteBranchDto} deleteBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerDelete: async (deleteBranchDto: DeleteBranchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteBranchDto' is not null or undefined
            assertParamExists('utilBranchControllerDelete', 'deleteBranchDto', deleteBranchDto)
            const localVarPath = `/api/v1/util-branch/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditBranchDto} editBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerEdit: async (editBranchDto: EditBranchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editBranchDto' is not null or undefined
            assertParamExists('utilBranchControllerEdit', 'editBranchDto', editBranchDto)
            const localVarPath = `/api/v1/util-branch/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-branch/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityBranchApi - functional programming interface
 * @export
 */
export const UtilityBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBranchDto} createBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilBranchControllerCreate(createBranchDto: CreateBranchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilBranchControllerCreate(createBranchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityBranchApi.utilBranchControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteBranchDto} deleteBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilBranchControllerDelete(deleteBranchDto: DeleteBranchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilBranchControllerDelete(deleteBranchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityBranchApi.utilBranchControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditBranchDto} editBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilBranchControllerEdit(editBranchDto: EditBranchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilBranchControllerEdit(editBranchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityBranchApi.utilBranchControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilBranchControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilBranchControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilBranchControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityBranchApi.utilBranchControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityBranchApi - factory interface
 * @export
 */
export const UtilityBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityBranchApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBranchDto} createBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerCreate(createBranchDto: CreateBranchDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilBranchControllerCreate(createBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteBranchDto} deleteBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerDelete(deleteBranchDto: DeleteBranchDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilBranchControllerDelete(deleteBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditBranchDto} editBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerEdit(editBranchDto: EditBranchDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilBranchControllerEdit(editBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilBranchControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilBranchControllerGet200Response> {
            return localVarFp.utilBranchControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityBranchApi - object-oriented interface
 * @export
 * @class UtilityBranchApi
 * @extends {BaseAPI}
 */
export class UtilityBranchApi extends BaseAPI {
    /**
     * 
     * @param {CreateBranchDto} createBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityBranchApi
     */
    public utilBranchControllerCreate(createBranchDto: CreateBranchDto, options?: RawAxiosRequestConfig) {
        return UtilityBranchApiFp(this.configuration).utilBranchControllerCreate(createBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteBranchDto} deleteBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityBranchApi
     */
    public utilBranchControllerDelete(deleteBranchDto: DeleteBranchDto, options?: RawAxiosRequestConfig) {
        return UtilityBranchApiFp(this.configuration).utilBranchControllerDelete(deleteBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditBranchDto} editBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityBranchApi
     */
    public utilBranchControllerEdit(editBranchDto: EditBranchDto, options?: RawAxiosRequestConfig) {
        return UtilityBranchApiFp(this.configuration).utilBranchControllerEdit(editBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityBranchApi
     */
    public utilBranchControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityBranchApiFp(this.configuration).utilBranchControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityConfigApi - axios parameter creator
 * @export
 */
export const UtilityConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateConfigDto} createConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerCreate: async (createConfigDto: CreateConfigDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConfigDto' is not null or undefined
            assertParamExists('utilConfigControllerCreate', 'createConfigDto', createConfigDto)
            const localVarPath = `/api/v1/util-config/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteConfigDto} deleteConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerDelete: async (deleteConfigDto: DeleteConfigDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteConfigDto' is not null or undefined
            assertParamExists('utilConfigControllerDelete', 'deleteConfigDto', deleteConfigDto)
            const localVarPath = `/api/v1/util-config/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditConfigDto} editConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerEdit: async (editConfigDto: EditConfigDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editConfigDto' is not null or undefined
            assertParamExists('utilConfigControllerEdit', 'editConfigDto', editConfigDto)
            const localVarPath = `/api/v1/util-config/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-config/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityConfigApi - functional programming interface
 * @export
 */
export const UtilityConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateConfigDto} createConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilConfigControllerCreate(createConfigDto: CreateConfigDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilConfigControllerCreate(createConfigDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityConfigApi.utilConfigControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteConfigDto} deleteConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilConfigControllerDelete(deleteConfigDto: DeleteConfigDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilConfigControllerDelete(deleteConfigDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityConfigApi.utilConfigControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditConfigDto} editConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilConfigControllerEdit(editConfigDto: EditConfigDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilConfigControllerEdit(editConfigDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityConfigApi.utilConfigControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilConfigControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilConfigControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilConfigControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityConfigApi.utilConfigControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityConfigApi - factory interface
 * @export
 */
export const UtilityConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateConfigDto} createConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerCreate(createConfigDto: CreateConfigDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilConfigControllerCreate(createConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteConfigDto} deleteConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerDelete(deleteConfigDto: DeleteConfigDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilConfigControllerDelete(deleteConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditConfigDto} editConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerEdit(editConfigDto: EditConfigDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilConfigControllerEdit(editConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilConfigControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilConfigControllerGet200Response> {
            return localVarFp.utilConfigControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityConfigApi - object-oriented interface
 * @export
 * @class UtilityConfigApi
 * @extends {BaseAPI}
 */
export class UtilityConfigApi extends BaseAPI {
    /**
     * 
     * @param {CreateConfigDto} createConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityConfigApi
     */
    public utilConfigControllerCreate(createConfigDto: CreateConfigDto, options?: RawAxiosRequestConfig) {
        return UtilityConfigApiFp(this.configuration).utilConfigControllerCreate(createConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteConfigDto} deleteConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityConfigApi
     */
    public utilConfigControllerDelete(deleteConfigDto: DeleteConfigDto, options?: RawAxiosRequestConfig) {
        return UtilityConfigApiFp(this.configuration).utilConfigControllerDelete(deleteConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditConfigDto} editConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityConfigApi
     */
    public utilConfigControllerEdit(editConfigDto: EditConfigDto, options?: RawAxiosRequestConfig) {
        return UtilityConfigApiFp(this.configuration).utilConfigControllerEdit(editConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityConfigApi
     */
    public utilConfigControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityConfigApiFp(this.configuration).utilConfigControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityDepartmentApi - axios parameter creator
 * @export
 */
export const UtilityDepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDepartmentDto} createDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerCreate: async (createDepartmentDto: CreateDepartmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentDto' is not null or undefined
            assertParamExists('utilDepartmentControllerCreate', 'createDepartmentDto', createDepartmentDto)
            const localVarPath = `/api/v1/util-department/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteDepartmentDto} deleteDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerDelete: async (deleteDepartmentDto: DeleteDepartmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteDepartmentDto' is not null or undefined
            assertParamExists('utilDepartmentControllerDelete', 'deleteDepartmentDto', deleteDepartmentDto)
            const localVarPath = `/api/v1/util-department/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditDepartmentDto} editDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerEdit: async (editDepartmentDto: EditDepartmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editDepartmentDto' is not null or undefined
            assertParamExists('utilDepartmentControllerEdit', 'editDepartmentDto', editDepartmentDto)
            const localVarPath = `/api/v1/util-department/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-department/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityDepartmentApi - functional programming interface
 * @export
 */
export const UtilityDepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityDepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDepartmentDto} createDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilDepartmentControllerCreate(createDepartmentDto: CreateDepartmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilDepartmentControllerCreate(createDepartmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityDepartmentApi.utilDepartmentControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteDepartmentDto} deleteDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilDepartmentControllerDelete(deleteDepartmentDto: DeleteDepartmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilDepartmentControllerDelete(deleteDepartmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityDepartmentApi.utilDepartmentControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditDepartmentDto} editDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilDepartmentControllerEdit(editDepartmentDto: EditDepartmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilDepartmentControllerEdit(editDepartmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityDepartmentApi.utilDepartmentControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilDepartmentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilDepartmentControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilDepartmentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityDepartmentApi.utilDepartmentControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityDepartmentApi - factory interface
 * @export
 */
export const UtilityDepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityDepartmentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDepartmentDto} createDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerCreate(createDepartmentDto: CreateDepartmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilDepartmentControllerCreate(createDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteDepartmentDto} deleteDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerDelete(deleteDepartmentDto: DeleteDepartmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilDepartmentControllerDelete(deleteDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditDepartmentDto} editDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerEdit(editDepartmentDto: EditDepartmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilDepartmentControllerEdit(editDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilDepartmentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilDepartmentControllerGet200Response> {
            return localVarFp.utilDepartmentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityDepartmentApi - object-oriented interface
 * @export
 * @class UtilityDepartmentApi
 * @extends {BaseAPI}
 */
export class UtilityDepartmentApi extends BaseAPI {
    /**
     * 
     * @param {CreateDepartmentDto} createDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityDepartmentApi
     */
    public utilDepartmentControllerCreate(createDepartmentDto: CreateDepartmentDto, options?: RawAxiosRequestConfig) {
        return UtilityDepartmentApiFp(this.configuration).utilDepartmentControllerCreate(createDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteDepartmentDto} deleteDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityDepartmentApi
     */
    public utilDepartmentControllerDelete(deleteDepartmentDto: DeleteDepartmentDto, options?: RawAxiosRequestConfig) {
        return UtilityDepartmentApiFp(this.configuration).utilDepartmentControllerDelete(deleteDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditDepartmentDto} editDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityDepartmentApi
     */
    public utilDepartmentControllerEdit(editDepartmentDto: EditDepartmentDto, options?: RawAxiosRequestConfig) {
        return UtilityDepartmentApiFp(this.configuration).utilDepartmentControllerEdit(editDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityDepartmentApi
     */
    public utilDepartmentControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityDepartmentApiFp(this.configuration).utilDepartmentControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityEmploymentTypeApi - axios parameter creator
 * @export
 */
export const UtilityEmploymentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmploymentTypeDto} createEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerCreate: async (createEmploymentTypeDto: CreateEmploymentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmploymentTypeDto' is not null or undefined
            assertParamExists('utilEmploymentTypeControllerCreate', 'createEmploymentTypeDto', createEmploymentTypeDto)
            const localVarPath = `/api/v1/util-employment-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmploymentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmploymentTypeDto} deleteEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerDelete: async (deleteEmploymentTypeDto: DeleteEmploymentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmploymentTypeDto' is not null or undefined
            assertParamExists('utilEmploymentTypeControllerDelete', 'deleteEmploymentTypeDto', deleteEmploymentTypeDto)
            const localVarPath = `/api/v1/util-employment-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmploymentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditEmploymentTypeDto} editEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerEdit: async (editEmploymentTypeDto: EditEmploymentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editEmploymentTypeDto' is not null or undefined
            assertParamExists('utilEmploymentTypeControllerEdit', 'editEmploymentTypeDto', editEmploymentTypeDto)
            const localVarPath = `/api/v1/util-employment-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmploymentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-employment-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityEmploymentTypeApi - functional programming interface
 * @export
 */
export const UtilityEmploymentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityEmploymentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmploymentTypeDto} createEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilEmploymentTypeControllerCreate(createEmploymentTypeDto: CreateEmploymentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilEmploymentTypeControllerCreate(createEmploymentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityEmploymentTypeApi.utilEmploymentTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteEmploymentTypeDto} deleteEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilEmploymentTypeControllerDelete(deleteEmploymentTypeDto: DeleteEmploymentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilEmploymentTypeControllerDelete(deleteEmploymentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityEmploymentTypeApi.utilEmploymentTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditEmploymentTypeDto} editEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilEmploymentTypeControllerEdit(editEmploymentTypeDto: EditEmploymentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilEmploymentTypeControllerEdit(editEmploymentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityEmploymentTypeApi.utilEmploymentTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilEmploymentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilEmploymentTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilEmploymentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityEmploymentTypeApi.utilEmploymentTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityEmploymentTypeApi - factory interface
 * @export
 */
export const UtilityEmploymentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityEmploymentTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmploymentTypeDto} createEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerCreate(createEmploymentTypeDto: CreateEmploymentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilEmploymentTypeControllerCreate(createEmploymentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmploymentTypeDto} deleteEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerDelete(deleteEmploymentTypeDto: DeleteEmploymentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilEmploymentTypeControllerDelete(deleteEmploymentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditEmploymentTypeDto} editEmploymentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerEdit(editEmploymentTypeDto: EditEmploymentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilEmploymentTypeControllerEdit(editEmploymentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilEmploymentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilEmploymentTypeControllerGet200Response> {
            return localVarFp.utilEmploymentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityEmploymentTypeApi - object-oriented interface
 * @export
 * @class UtilityEmploymentTypeApi
 * @extends {BaseAPI}
 */
export class UtilityEmploymentTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmploymentTypeDto} createEmploymentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityEmploymentTypeApi
     */
    public utilEmploymentTypeControllerCreate(createEmploymentTypeDto: CreateEmploymentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityEmploymentTypeApiFp(this.configuration).utilEmploymentTypeControllerCreate(createEmploymentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmploymentTypeDto} deleteEmploymentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityEmploymentTypeApi
     */
    public utilEmploymentTypeControllerDelete(deleteEmploymentTypeDto: DeleteEmploymentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityEmploymentTypeApiFp(this.configuration).utilEmploymentTypeControllerDelete(deleteEmploymentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditEmploymentTypeDto} editEmploymentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityEmploymentTypeApi
     */
    public utilEmploymentTypeControllerEdit(editEmploymentTypeDto: EditEmploymentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityEmploymentTypeApiFp(this.configuration).utilEmploymentTypeControllerEdit(editEmploymentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityEmploymentTypeApi
     */
    public utilEmploymentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityEmploymentTypeApiFp(this.configuration).utilEmploymentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityFAQApi - axios parameter creator
 * @export
 */
export const UtilityFAQApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerCreate: async (createFaqDto: CreateFaqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFaqDto' is not null or undefined
            assertParamExists('utilFaqControllerCreate', 'createFaqDto', createFaqDto)
            const localVarPath = `/api/v1/util-faq/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteFaqDto} deleteFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerDelete: async (deleteFaqDto: DeleteFaqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFaqDto' is not null or undefined
            assertParamExists('utilFaqControllerDelete', 'deleteFaqDto', deleteFaqDto)
            const localVarPath = `/api/v1/util-faq/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditFaqDto} editFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerEdit: async (editFaqDto: EditFaqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editFaqDto' is not null or undefined
            assertParamExists('utilFaqControllerEdit', 'editFaqDto', editFaqDto)
            const localVarPath = `/api/v1/util-faq/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-faq/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityFAQApi - functional programming interface
 * @export
 */
export const UtilityFAQApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityFAQApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFaqControllerCreate(createFaqDto: CreateFaqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFaqControllerCreate(createFaqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFAQApi.utilFaqControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteFaqDto} deleteFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFaqControllerDelete(deleteFaqDto: DeleteFaqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFaqControllerDelete(deleteFaqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFAQApi.utilFaqControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditFaqDto} editFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFaqControllerEdit(editFaqDto: EditFaqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFaqControllerEdit(editFaqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFAQApi.utilFaqControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFaqControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilFaqControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFaqControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFAQApi.utilFaqControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityFAQApi - factory interface
 * @export
 */
export const UtilityFAQApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityFAQApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerCreate(createFaqDto: CreateFaqDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilFaqControllerCreate(createFaqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteFaqDto} deleteFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerDelete(deleteFaqDto: DeleteFaqDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilFaqControllerDelete(deleteFaqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditFaqDto} editFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerEdit(editFaqDto: EditFaqDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilFaqControllerEdit(editFaqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFaqControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilFaqControllerGet200Response> {
            return localVarFp.utilFaqControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityFAQApi - object-oriented interface
 * @export
 * @class UtilityFAQApi
 * @extends {BaseAPI}
 */
export class UtilityFAQApi extends BaseAPI {
    /**
     * 
     * @param {CreateFaqDto} createFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFAQApi
     */
    public utilFaqControllerCreate(createFaqDto: CreateFaqDto, options?: RawAxiosRequestConfig) {
        return UtilityFAQApiFp(this.configuration).utilFaqControllerCreate(createFaqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteFaqDto} deleteFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFAQApi
     */
    public utilFaqControllerDelete(deleteFaqDto: DeleteFaqDto, options?: RawAxiosRequestConfig) {
        return UtilityFAQApiFp(this.configuration).utilFaqControllerDelete(deleteFaqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditFaqDto} editFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFAQApi
     */
    public utilFaqControllerEdit(editFaqDto: EditFaqDto, options?: RawAxiosRequestConfig) {
        return UtilityFAQApiFp(this.configuration).utilFaqControllerEdit(editFaqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFAQApi
     */
    public utilFaqControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityFAQApiFp(this.configuration).utilFaqControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityFavoriteApi - axios parameter creator
 * @export
 */
export const UtilityFavoriteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFavoriteDto} createFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerCreate: async (createFavoriteDto: CreateFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFavoriteDto' is not null or undefined
            assertParamExists('utilFavoriteControllerCreate', 'createFavoriteDto', createFavoriteDto)
            const localVarPath = `/api/v1/util-favorite/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteFavoriteDto} deleteFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerDelete: async (deleteFavoriteDto: DeleteFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFavoriteDto' is not null or undefined
            assertParamExists('utilFavoriteControllerDelete', 'deleteFavoriteDto', deleteFavoriteDto)
            const localVarPath = `/api/v1/util-favorite/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditFavoriteDto} editFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerEdit: async (editFavoriteDto: EditFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editFavoriteDto' is not null or undefined
            assertParamExists('utilFavoriteControllerEdit', 'editFavoriteDto', editFavoriteDto)
            const localVarPath = `/api/v1/util-favorite/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-favorite/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityFavoriteApi - functional programming interface
 * @export
 */
export const UtilityFavoriteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityFavoriteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFavoriteDto} createFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFavoriteControllerCreate(createFavoriteDto: CreateFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFavoriteControllerCreate(createFavoriteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFavoriteApi.utilFavoriteControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteFavoriteDto} deleteFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFavoriteControllerDelete(deleteFavoriteDto: DeleteFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFavoriteControllerDelete(deleteFavoriteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFavoriteApi.utilFavoriteControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditFavoriteDto} editFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFavoriteControllerEdit(editFavoriteDto: EditFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFavoriteControllerEdit(editFavoriteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFavoriteApi.utilFavoriteControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilFavoriteControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilFavoriteControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilFavoriteControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityFavoriteApi.utilFavoriteControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityFavoriteApi - factory interface
 * @export
 */
export const UtilityFavoriteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityFavoriteApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFavoriteDto} createFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerCreate(createFavoriteDto: CreateFavoriteDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilFavoriteControllerCreate(createFavoriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteFavoriteDto} deleteFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerDelete(deleteFavoriteDto: DeleteFavoriteDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilFavoriteControllerDelete(deleteFavoriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditFavoriteDto} editFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerEdit(editFavoriteDto: EditFavoriteDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilFavoriteControllerEdit(editFavoriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilFavoriteControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilFavoriteControllerGet200Response> {
            return localVarFp.utilFavoriteControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityFavoriteApi - object-oriented interface
 * @export
 * @class UtilityFavoriteApi
 * @extends {BaseAPI}
 */
export class UtilityFavoriteApi extends BaseAPI {
    /**
     * 
     * @param {CreateFavoriteDto} createFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFavoriteApi
     */
    public utilFavoriteControllerCreate(createFavoriteDto: CreateFavoriteDto, options?: RawAxiosRequestConfig) {
        return UtilityFavoriteApiFp(this.configuration).utilFavoriteControllerCreate(createFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteFavoriteDto} deleteFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFavoriteApi
     */
    public utilFavoriteControllerDelete(deleteFavoriteDto: DeleteFavoriteDto, options?: RawAxiosRequestConfig) {
        return UtilityFavoriteApiFp(this.configuration).utilFavoriteControllerDelete(deleteFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditFavoriteDto} editFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFavoriteApi
     */
    public utilFavoriteControllerEdit(editFavoriteDto: EditFavoriteDto, options?: RawAxiosRequestConfig) {
        return UtilityFavoriteApiFp(this.configuration).utilFavoriteControllerEdit(editFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityFavoriteApi
     */
    public utilFavoriteControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityFavoriteApiFp(this.configuration).utilFavoriteControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityHolidayApi - axios parameter creator
 * @export
 */
export const UtilityHolidayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHolidayDto} createHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerCreate: async (createHolidayDto: CreateHolidayDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHolidayDto' is not null or undefined
            assertParamExists('utilHolidayControllerCreate', 'createHolidayDto', createHolidayDto)
            const localVarPath = `/api/v1/util-holiday/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHolidayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteHolidayDto} deleteHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerDelete: async (deleteHolidayDto: DeleteHolidayDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteHolidayDto' is not null or undefined
            assertParamExists('utilHolidayControllerDelete', 'deleteHolidayDto', deleteHolidayDto)
            const localVarPath = `/api/v1/util-holiday/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteHolidayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditHolidayDto} editHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerEdit: async (editHolidayDto: EditHolidayDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editHolidayDto' is not null or undefined
            assertParamExists('utilHolidayControllerEdit', 'editHolidayDto', editHolidayDto)
            const localVarPath = `/api/v1/util-holiday/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editHolidayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-holiday/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityHolidayApi - functional programming interface
 * @export
 */
export const UtilityHolidayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityHolidayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHolidayDto} createHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayControllerCreate(createHolidayDto: CreateHolidayDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayControllerCreate(createHolidayDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayApi.utilHolidayControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteHolidayDto} deleteHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayControllerDelete(deleteHolidayDto: DeleteHolidayDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayControllerDelete(deleteHolidayDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayApi.utilHolidayControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditHolidayDto} editHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayControllerEdit(editHolidayDto: EditHolidayDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayControllerEdit(editHolidayDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayApi.utilHolidayControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilHolidayControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayApi.utilHolidayControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityHolidayApi - factory interface
 * @export
 */
export const UtilityHolidayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityHolidayApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHolidayDto} createHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerCreate(createHolidayDto: CreateHolidayDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilHolidayControllerCreate(createHolidayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteHolidayDto} deleteHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerDelete(deleteHolidayDto: DeleteHolidayDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilHolidayControllerDelete(deleteHolidayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditHolidayDto} editHolidayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerEdit(editHolidayDto: EditHolidayDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilHolidayControllerEdit(editHolidayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilHolidayControllerGet200Response> {
            return localVarFp.utilHolidayControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityHolidayApi - object-oriented interface
 * @export
 * @class UtilityHolidayApi
 * @extends {BaseAPI}
 */
export class UtilityHolidayApi extends BaseAPI {
    /**
     * 
     * @param {CreateHolidayDto} createHolidayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayApi
     */
    public utilHolidayControllerCreate(createHolidayDto: CreateHolidayDto, options?: RawAxiosRequestConfig) {
        return UtilityHolidayApiFp(this.configuration).utilHolidayControllerCreate(createHolidayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteHolidayDto} deleteHolidayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayApi
     */
    public utilHolidayControllerDelete(deleteHolidayDto: DeleteHolidayDto, options?: RawAxiosRequestConfig) {
        return UtilityHolidayApiFp(this.configuration).utilHolidayControllerDelete(deleteHolidayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditHolidayDto} editHolidayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayApi
     */
    public utilHolidayControllerEdit(editHolidayDto: EditHolidayDto, options?: RawAxiosRequestConfig) {
        return UtilityHolidayApiFp(this.configuration).utilHolidayControllerEdit(editHolidayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayApi
     */
    public utilHolidayControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityHolidayApiFp(this.configuration).utilHolidayControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityHolidayTypeApi - axios parameter creator
 * @export
 */
export const UtilityHolidayTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHolidayTypeDto} createHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerCreate: async (createHolidayTypeDto: CreateHolidayTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHolidayTypeDto' is not null or undefined
            assertParamExists('utilHolidayTypeControllerCreate', 'createHolidayTypeDto', createHolidayTypeDto)
            const localVarPath = `/api/v1/util-holiday-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHolidayTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteHolidayTypeDto} deleteHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerDelete: async (deleteHolidayTypeDto: DeleteHolidayTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteHolidayTypeDto' is not null or undefined
            assertParamExists('utilHolidayTypeControllerDelete', 'deleteHolidayTypeDto', deleteHolidayTypeDto)
            const localVarPath = `/api/v1/util-holiday-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteHolidayTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditHolidayTypeDto} editHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerEdit: async (editHolidayTypeDto: EditHolidayTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editHolidayTypeDto' is not null or undefined
            assertParamExists('utilHolidayTypeControllerEdit', 'editHolidayTypeDto', editHolidayTypeDto)
            const localVarPath = `/api/v1/util-holiday-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editHolidayTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-holiday-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityHolidayTypeApi - functional programming interface
 * @export
 */
export const UtilityHolidayTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityHolidayTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHolidayTypeDto} createHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayTypeControllerCreate(createHolidayTypeDto: CreateHolidayTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayTypeControllerCreate(createHolidayTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayTypeApi.utilHolidayTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteHolidayTypeDto} deleteHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayTypeControllerDelete(deleteHolidayTypeDto: DeleteHolidayTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayTypeControllerDelete(deleteHolidayTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayTypeApi.utilHolidayTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditHolidayTypeDto} editHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayTypeControllerEdit(editHolidayTypeDto: EditHolidayTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayTypeControllerEdit(editHolidayTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayTypeApi.utilHolidayTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilHolidayTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilHolidayTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilHolidayTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityHolidayTypeApi.utilHolidayTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityHolidayTypeApi - factory interface
 * @export
 */
export const UtilityHolidayTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityHolidayTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHolidayTypeDto} createHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerCreate(createHolidayTypeDto: CreateHolidayTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilHolidayTypeControllerCreate(createHolidayTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteHolidayTypeDto} deleteHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerDelete(deleteHolidayTypeDto: DeleteHolidayTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilHolidayTypeControllerDelete(deleteHolidayTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditHolidayTypeDto} editHolidayTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerEdit(editHolidayTypeDto: EditHolidayTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilHolidayTypeControllerEdit(editHolidayTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilHolidayTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilHolidayTypeControllerGet200Response> {
            return localVarFp.utilHolidayTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityHolidayTypeApi - object-oriented interface
 * @export
 * @class UtilityHolidayTypeApi
 * @extends {BaseAPI}
 */
export class UtilityHolidayTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateHolidayTypeDto} createHolidayTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayTypeApi
     */
    public utilHolidayTypeControllerCreate(createHolidayTypeDto: CreateHolidayTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityHolidayTypeApiFp(this.configuration).utilHolidayTypeControllerCreate(createHolidayTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteHolidayTypeDto} deleteHolidayTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayTypeApi
     */
    public utilHolidayTypeControllerDelete(deleteHolidayTypeDto: DeleteHolidayTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityHolidayTypeApiFp(this.configuration).utilHolidayTypeControllerDelete(deleteHolidayTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditHolidayTypeDto} editHolidayTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayTypeApi
     */
    public utilHolidayTypeControllerEdit(editHolidayTypeDto: EditHolidayTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityHolidayTypeApiFp(this.configuration).utilHolidayTypeControllerEdit(editHolidayTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityHolidayTypeApi
     */
    public utilHolidayTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityHolidayTypeApiFp(this.configuration).utilHolidayTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityIncidentTypeApi - axios parameter creator
 * @export
 */
export const UtilityIncidentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateIncidentTypeDto} createIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerCreate: async (createIncidentTypeDto: CreateIncidentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIncidentTypeDto' is not null or undefined
            assertParamExists('utilIncidentTypeControllerCreate', 'createIncidentTypeDto', createIncidentTypeDto)
            const localVarPath = `/api/v1/util-incident-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIncidentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteIncidentTypeDto} deleteIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerDelete: async (deleteIncidentTypeDto: DeleteIncidentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteIncidentTypeDto' is not null or undefined
            assertParamExists('utilIncidentTypeControllerDelete', 'deleteIncidentTypeDto', deleteIncidentTypeDto)
            const localVarPath = `/api/v1/util-incident-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteIncidentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditIncidentTypeDto} editIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerEdit: async (editIncidentTypeDto: EditIncidentTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editIncidentTypeDto' is not null or undefined
            assertParamExists('utilIncidentTypeControllerEdit', 'editIncidentTypeDto', editIncidentTypeDto)
            const localVarPath = `/api/v1/util-incident-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editIncidentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-incident-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityIncidentTypeApi - functional programming interface
 * @export
 */
export const UtilityIncidentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityIncidentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateIncidentTypeDto} createIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilIncidentTypeControllerCreate(createIncidentTypeDto: CreateIncidentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilIncidentTypeControllerCreate(createIncidentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityIncidentTypeApi.utilIncidentTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteIncidentTypeDto} deleteIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilIncidentTypeControllerDelete(deleteIncidentTypeDto: DeleteIncidentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilIncidentTypeControllerDelete(deleteIncidentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityIncidentTypeApi.utilIncidentTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditIncidentTypeDto} editIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilIncidentTypeControllerEdit(editIncidentTypeDto: EditIncidentTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilIncidentTypeControllerEdit(editIncidentTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityIncidentTypeApi.utilIncidentTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilIncidentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilIncidentTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilIncidentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityIncidentTypeApi.utilIncidentTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityIncidentTypeApi - factory interface
 * @export
 */
export const UtilityIncidentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityIncidentTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateIncidentTypeDto} createIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerCreate(createIncidentTypeDto: CreateIncidentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilIncidentTypeControllerCreate(createIncidentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteIncidentTypeDto} deleteIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerDelete(deleteIncidentTypeDto: DeleteIncidentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilIncidentTypeControllerDelete(deleteIncidentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditIncidentTypeDto} editIncidentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerEdit(editIncidentTypeDto: EditIncidentTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilIncidentTypeControllerEdit(editIncidentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilIncidentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilIncidentTypeControllerGet200Response> {
            return localVarFp.utilIncidentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityIncidentTypeApi - object-oriented interface
 * @export
 * @class UtilityIncidentTypeApi
 * @extends {BaseAPI}
 */
export class UtilityIncidentTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateIncidentTypeDto} createIncidentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityIncidentTypeApi
     */
    public utilIncidentTypeControllerCreate(createIncidentTypeDto: CreateIncidentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityIncidentTypeApiFp(this.configuration).utilIncidentTypeControllerCreate(createIncidentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteIncidentTypeDto} deleteIncidentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityIncidentTypeApi
     */
    public utilIncidentTypeControllerDelete(deleteIncidentTypeDto: DeleteIncidentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityIncidentTypeApiFp(this.configuration).utilIncidentTypeControllerDelete(deleteIncidentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditIncidentTypeDto} editIncidentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityIncidentTypeApi
     */
    public utilIncidentTypeControllerEdit(editIncidentTypeDto: EditIncidentTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityIncidentTypeApiFp(this.configuration).utilIncidentTypeControllerEdit(editIncidentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityIncidentTypeApi
     */
    public utilIncidentTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityIncidentTypeApiFp(this.configuration).utilIncidentTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityInfractionApi - axios parameter creator
 * @export
 */
export const UtilityInfractionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateInfractionDto} createInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerCreate: async (createInfractionDto: CreateInfractionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInfractionDto' is not null or undefined
            assertParamExists('utilInfractionControllerCreate', 'createInfractionDto', createInfractionDto)
            const localVarPath = `/api/v1/util-infraction/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInfractionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteInfractionDto} deleteInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerDelete: async (deleteInfractionDto: DeleteInfractionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteInfractionDto' is not null or undefined
            assertParamExists('utilInfractionControllerDelete', 'deleteInfractionDto', deleteInfractionDto)
            const localVarPath = `/api/v1/util-infraction/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteInfractionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditInfractionDto} editInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerEdit: async (editInfractionDto: EditInfractionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editInfractionDto' is not null or undefined
            assertParamExists('utilInfractionControllerEdit', 'editInfractionDto', editInfractionDto)
            const localVarPath = `/api/v1/util-infraction/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editInfractionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-infraction/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityInfractionApi - functional programming interface
 * @export
 */
export const UtilityInfractionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityInfractionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateInfractionDto} createInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilInfractionControllerCreate(createInfractionDto: CreateInfractionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilInfractionControllerCreate(createInfractionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityInfractionApi.utilInfractionControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteInfractionDto} deleteInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilInfractionControllerDelete(deleteInfractionDto: DeleteInfractionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilInfractionControllerDelete(deleteInfractionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityInfractionApi.utilInfractionControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditInfractionDto} editInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilInfractionControllerEdit(editInfractionDto: EditInfractionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilInfractionControllerEdit(editInfractionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityInfractionApi.utilInfractionControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilInfractionControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilInfractionControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilInfractionControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityInfractionApi.utilInfractionControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityInfractionApi - factory interface
 * @export
 */
export const UtilityInfractionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityInfractionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateInfractionDto} createInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerCreate(createInfractionDto: CreateInfractionDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilInfractionControllerCreate(createInfractionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteInfractionDto} deleteInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerDelete(deleteInfractionDto: DeleteInfractionDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilInfractionControllerDelete(deleteInfractionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditInfractionDto} editInfractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerEdit(editInfractionDto: EditInfractionDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilInfractionControllerEdit(editInfractionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilInfractionControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilInfractionControllerGet200Response> {
            return localVarFp.utilInfractionControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityInfractionApi - object-oriented interface
 * @export
 * @class UtilityInfractionApi
 * @extends {BaseAPI}
 */
export class UtilityInfractionApi extends BaseAPI {
    /**
     * 
     * @param {CreateInfractionDto} createInfractionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityInfractionApi
     */
    public utilInfractionControllerCreate(createInfractionDto: CreateInfractionDto, options?: RawAxiosRequestConfig) {
        return UtilityInfractionApiFp(this.configuration).utilInfractionControllerCreate(createInfractionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteInfractionDto} deleteInfractionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityInfractionApi
     */
    public utilInfractionControllerDelete(deleteInfractionDto: DeleteInfractionDto, options?: RawAxiosRequestConfig) {
        return UtilityInfractionApiFp(this.configuration).utilInfractionControllerDelete(deleteInfractionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditInfractionDto} editInfractionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityInfractionApi
     */
    public utilInfractionControllerEdit(editInfractionDto: EditInfractionDto, options?: RawAxiosRequestConfig) {
        return UtilityInfractionApiFp(this.configuration).utilInfractionControllerEdit(editInfractionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityInfractionApi
     */
    public utilInfractionControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityInfractionApiFp(this.configuration).utilInfractionControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityJobGradeApi - axios parameter creator
 * @export
 */
export const UtilityJobGradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateJobGradeDto} createJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerCreate: async (createJobGradeDto: CreateJobGradeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createJobGradeDto' is not null or undefined
            assertParamExists('utilJobGradeControllerCreate', 'createJobGradeDto', createJobGradeDto)
            const localVarPath = `/api/v1/util-job-grade/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJobGradeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteJobGradeDto} deleteJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerDelete: async (deleteJobGradeDto: DeleteJobGradeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteJobGradeDto' is not null or undefined
            assertParamExists('utilJobGradeControllerDelete', 'deleteJobGradeDto', deleteJobGradeDto)
            const localVarPath = `/api/v1/util-job-grade/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteJobGradeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditJobGradeDto} editJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerEdit: async (editJobGradeDto: EditJobGradeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editJobGradeDto' is not null or undefined
            assertParamExists('utilJobGradeControllerEdit', 'editJobGradeDto', editJobGradeDto)
            const localVarPath = `/api/v1/util-job-grade/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editJobGradeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-job-grade/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityJobGradeApi - functional programming interface
 * @export
 */
export const UtilityJobGradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityJobGradeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateJobGradeDto} createJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobGradeControllerCreate(createJobGradeDto: CreateJobGradeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobGradeControllerCreate(createJobGradeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobGradeApi.utilJobGradeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteJobGradeDto} deleteJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobGradeControllerDelete(deleteJobGradeDto: DeleteJobGradeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobGradeControllerDelete(deleteJobGradeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobGradeApi.utilJobGradeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditJobGradeDto} editJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobGradeControllerEdit(editJobGradeDto: EditJobGradeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobGradeControllerEdit(editJobGradeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobGradeApi.utilJobGradeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobGradeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilJobGradeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobGradeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobGradeApi.utilJobGradeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityJobGradeApi - factory interface
 * @export
 */
export const UtilityJobGradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityJobGradeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateJobGradeDto} createJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerCreate(createJobGradeDto: CreateJobGradeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilJobGradeControllerCreate(createJobGradeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteJobGradeDto} deleteJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerDelete(deleteJobGradeDto: DeleteJobGradeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilJobGradeControllerDelete(deleteJobGradeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditJobGradeDto} editJobGradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerEdit(editJobGradeDto: EditJobGradeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilJobGradeControllerEdit(editJobGradeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobGradeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilJobGradeControllerGet200Response> {
            return localVarFp.utilJobGradeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityJobGradeApi - object-oriented interface
 * @export
 * @class UtilityJobGradeApi
 * @extends {BaseAPI}
 */
export class UtilityJobGradeApi extends BaseAPI {
    /**
     * 
     * @param {CreateJobGradeDto} createJobGradeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobGradeApi
     */
    public utilJobGradeControllerCreate(createJobGradeDto: CreateJobGradeDto, options?: RawAxiosRequestConfig) {
        return UtilityJobGradeApiFp(this.configuration).utilJobGradeControllerCreate(createJobGradeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteJobGradeDto} deleteJobGradeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobGradeApi
     */
    public utilJobGradeControllerDelete(deleteJobGradeDto: DeleteJobGradeDto, options?: RawAxiosRequestConfig) {
        return UtilityJobGradeApiFp(this.configuration).utilJobGradeControllerDelete(deleteJobGradeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditJobGradeDto} editJobGradeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobGradeApi
     */
    public utilJobGradeControllerEdit(editJobGradeDto: EditJobGradeDto, options?: RawAxiosRequestConfig) {
        return UtilityJobGradeApiFp(this.configuration).utilJobGradeControllerEdit(editJobGradeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobGradeApi
     */
    public utilJobGradeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityJobGradeApiFp(this.configuration).utilJobGradeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityJobTitleApi - axios parameter creator
 * @export
 */
export const UtilityJobTitleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateJobTitleDto} createJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerCreate: async (createJobTitleDto: CreateJobTitleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createJobTitleDto' is not null or undefined
            assertParamExists('utilJobTitleControllerCreate', 'createJobTitleDto', createJobTitleDto)
            const localVarPath = `/api/v1/util-job-title/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJobTitleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteJobTitleDto} deleteJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerDelete: async (deleteJobTitleDto: DeleteJobTitleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteJobTitleDto' is not null or undefined
            assertParamExists('utilJobTitleControllerDelete', 'deleteJobTitleDto', deleteJobTitleDto)
            const localVarPath = `/api/v1/util-job-title/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteJobTitleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditJobTitleDto} editJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerEdit: async (editJobTitleDto: EditJobTitleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editJobTitleDto' is not null or undefined
            assertParamExists('utilJobTitleControllerEdit', 'editJobTitleDto', editJobTitleDto)
            const localVarPath = `/api/v1/util-job-title/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editJobTitleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-job-title/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityJobTitleApi - functional programming interface
 * @export
 */
export const UtilityJobTitleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityJobTitleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateJobTitleDto} createJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobTitleControllerCreate(createJobTitleDto: CreateJobTitleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobTitleControllerCreate(createJobTitleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobTitleApi.utilJobTitleControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteJobTitleDto} deleteJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobTitleControllerDelete(deleteJobTitleDto: DeleteJobTitleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobTitleControllerDelete(deleteJobTitleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobTitleApi.utilJobTitleControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditJobTitleDto} editJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobTitleControllerEdit(editJobTitleDto: EditJobTitleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobTitleControllerEdit(editJobTitleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobTitleApi.utilJobTitleControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilJobTitleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilJobTitleControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilJobTitleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityJobTitleApi.utilJobTitleControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityJobTitleApi - factory interface
 * @export
 */
export const UtilityJobTitleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityJobTitleApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateJobTitleDto} createJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerCreate(createJobTitleDto: CreateJobTitleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilJobTitleControllerCreate(createJobTitleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteJobTitleDto} deleteJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerDelete(deleteJobTitleDto: DeleteJobTitleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilJobTitleControllerDelete(deleteJobTitleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditJobTitleDto} editJobTitleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerEdit(editJobTitleDto: EditJobTitleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilJobTitleControllerEdit(editJobTitleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilJobTitleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilJobTitleControllerGet200Response> {
            return localVarFp.utilJobTitleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityJobTitleApi - object-oriented interface
 * @export
 * @class UtilityJobTitleApi
 * @extends {BaseAPI}
 */
export class UtilityJobTitleApi extends BaseAPI {
    /**
     * 
     * @param {CreateJobTitleDto} createJobTitleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobTitleApi
     */
    public utilJobTitleControllerCreate(createJobTitleDto: CreateJobTitleDto, options?: RawAxiosRequestConfig) {
        return UtilityJobTitleApiFp(this.configuration).utilJobTitleControllerCreate(createJobTitleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteJobTitleDto} deleteJobTitleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobTitleApi
     */
    public utilJobTitleControllerDelete(deleteJobTitleDto: DeleteJobTitleDto, options?: RawAxiosRequestConfig) {
        return UtilityJobTitleApiFp(this.configuration).utilJobTitleControllerDelete(deleteJobTitleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditJobTitleDto} editJobTitleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobTitleApi
     */
    public utilJobTitleControllerEdit(editJobTitleDto: EditJobTitleDto, options?: RawAxiosRequestConfig) {
        return UtilityJobTitleApiFp(this.configuration).utilJobTitleControllerEdit(editJobTitleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityJobTitleApi
     */
    public utilJobTitleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityJobTitleApiFp(this.configuration).utilJobTitleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityLeaveCategoryApi - axios parameter creator
 * @export
 */
export const UtilityLeaveCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLeaveCategoryDto} createLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerCreate: async (createLeaveCategoryDto: CreateLeaveCategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeaveCategoryDto' is not null or undefined
            assertParamExists('utilLeaveCategoryControllerCreate', 'createLeaveCategoryDto', createLeaveCategoryDto)
            const localVarPath = `/api/v1/util-leave-category/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeaveCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteLeaveCategoryDto} deleteLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerDelete: async (deleteLeaveCategoryDto: DeleteLeaveCategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteLeaveCategoryDto' is not null or undefined
            assertParamExists('utilLeaveCategoryControllerDelete', 'deleteLeaveCategoryDto', deleteLeaveCategoryDto)
            const localVarPath = `/api/v1/util-leave-category/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteLeaveCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditLeaveCategoryDto} editLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerEdit: async (editLeaveCategoryDto: EditLeaveCategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editLeaveCategoryDto' is not null or undefined
            assertParamExists('utilLeaveCategoryControllerEdit', 'editLeaveCategoryDto', editLeaveCategoryDto)
            const localVarPath = `/api/v1/util-leave-category/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editLeaveCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-leave-category/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityLeaveCategoryApi - functional programming interface
 * @export
 */
export const UtilityLeaveCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityLeaveCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLeaveCategoryDto} createLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveCategoryControllerCreate(createLeaveCategoryDto: CreateLeaveCategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveCategoryControllerCreate(createLeaveCategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveCategoryApi.utilLeaveCategoryControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteLeaveCategoryDto} deleteLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveCategoryControllerDelete(deleteLeaveCategoryDto: DeleteLeaveCategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveCategoryControllerDelete(deleteLeaveCategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveCategoryApi.utilLeaveCategoryControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditLeaveCategoryDto} editLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveCategoryControllerEdit(editLeaveCategoryDto: EditLeaveCategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveCategoryControllerEdit(editLeaveCategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveCategoryApi.utilLeaveCategoryControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveCategoryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilLeaveCategoryControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveCategoryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveCategoryApi.utilLeaveCategoryControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityLeaveCategoryApi - factory interface
 * @export
 */
export const UtilityLeaveCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityLeaveCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLeaveCategoryDto} createLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerCreate(createLeaveCategoryDto: CreateLeaveCategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveCategoryControllerCreate(createLeaveCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteLeaveCategoryDto} deleteLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerDelete(deleteLeaveCategoryDto: DeleteLeaveCategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveCategoryControllerDelete(deleteLeaveCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditLeaveCategoryDto} editLeaveCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerEdit(editLeaveCategoryDto: EditLeaveCategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveCategoryControllerEdit(editLeaveCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveCategoryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilLeaveCategoryControllerGet200Response> {
            return localVarFp.utilLeaveCategoryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityLeaveCategoryApi - object-oriented interface
 * @export
 * @class UtilityLeaveCategoryApi
 * @extends {BaseAPI}
 */
export class UtilityLeaveCategoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateLeaveCategoryDto} createLeaveCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveCategoryApi
     */
    public utilLeaveCategoryControllerCreate(createLeaveCategoryDto: CreateLeaveCategoryDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveCategoryApiFp(this.configuration).utilLeaveCategoryControllerCreate(createLeaveCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteLeaveCategoryDto} deleteLeaveCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveCategoryApi
     */
    public utilLeaveCategoryControllerDelete(deleteLeaveCategoryDto: DeleteLeaveCategoryDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveCategoryApiFp(this.configuration).utilLeaveCategoryControllerDelete(deleteLeaveCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditLeaveCategoryDto} editLeaveCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveCategoryApi
     */
    public utilLeaveCategoryControllerEdit(editLeaveCategoryDto: EditLeaveCategoryDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveCategoryApiFp(this.configuration).utilLeaveCategoryControllerEdit(editLeaveCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveCategoryApi
     */
    public utilLeaveCategoryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityLeaveCategoryApiFp(this.configuration).utilLeaveCategoryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityLeaveSubcategoryApi - axios parameter creator
 * @export
 */
export const UtilityLeaveSubcategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLeaveSubcategoryDto} createLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerCreate: async (createLeaveSubcategoryDto: CreateLeaveSubcategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeaveSubcategoryDto' is not null or undefined
            assertParamExists('utilLeaveSubcategoryControllerCreate', 'createLeaveSubcategoryDto', createLeaveSubcategoryDto)
            const localVarPath = `/api/v1/util-leave-subcategory/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeaveSubcategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteLeaveSubcategoryDto} deleteLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerDelete: async (deleteLeaveSubcategoryDto: DeleteLeaveSubcategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteLeaveSubcategoryDto' is not null or undefined
            assertParamExists('utilLeaveSubcategoryControllerDelete', 'deleteLeaveSubcategoryDto', deleteLeaveSubcategoryDto)
            const localVarPath = `/api/v1/util-leave-subcategory/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteLeaveSubcategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditLeaveSubcategoryDto} editLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerEdit: async (editLeaveSubcategoryDto: EditLeaveSubcategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editLeaveSubcategoryDto' is not null or undefined
            assertParamExists('utilLeaveSubcategoryControllerEdit', 'editLeaveSubcategoryDto', editLeaveSubcategoryDto)
            const localVarPath = `/api/v1/util-leave-subcategory/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editLeaveSubcategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-leave-subcategory/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityLeaveSubcategoryApi - functional programming interface
 * @export
 */
export const UtilityLeaveSubcategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityLeaveSubcategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLeaveSubcategoryDto} createLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveSubcategoryControllerCreate(createLeaveSubcategoryDto: CreateLeaveSubcategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveSubcategoryControllerCreate(createLeaveSubcategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveSubcategoryApi.utilLeaveSubcategoryControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteLeaveSubcategoryDto} deleteLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveSubcategoryControllerDelete(deleteLeaveSubcategoryDto: DeleteLeaveSubcategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveSubcategoryControllerDelete(deleteLeaveSubcategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveSubcategoryApi.utilLeaveSubcategoryControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditLeaveSubcategoryDto} editLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveSubcategoryControllerEdit(editLeaveSubcategoryDto: EditLeaveSubcategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveSubcategoryControllerEdit(editLeaveSubcategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveSubcategoryApi.utilLeaveSubcategoryControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveSubcategoryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilLeaveSubcategoryControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveSubcategoryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveSubcategoryApi.utilLeaveSubcategoryControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityLeaveSubcategoryApi - factory interface
 * @export
 */
export const UtilityLeaveSubcategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityLeaveSubcategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLeaveSubcategoryDto} createLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerCreate(createLeaveSubcategoryDto: CreateLeaveSubcategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveSubcategoryControllerCreate(createLeaveSubcategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteLeaveSubcategoryDto} deleteLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerDelete(deleteLeaveSubcategoryDto: DeleteLeaveSubcategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveSubcategoryControllerDelete(deleteLeaveSubcategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditLeaveSubcategoryDto} editLeaveSubcategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerEdit(editLeaveSubcategoryDto: EditLeaveSubcategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveSubcategoryControllerEdit(editLeaveSubcategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveSubcategoryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilLeaveSubcategoryControllerGet200Response> {
            return localVarFp.utilLeaveSubcategoryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityLeaveSubcategoryApi - object-oriented interface
 * @export
 * @class UtilityLeaveSubcategoryApi
 * @extends {BaseAPI}
 */
export class UtilityLeaveSubcategoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateLeaveSubcategoryDto} createLeaveSubcategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveSubcategoryApi
     */
    public utilLeaveSubcategoryControllerCreate(createLeaveSubcategoryDto: CreateLeaveSubcategoryDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveSubcategoryApiFp(this.configuration).utilLeaveSubcategoryControllerCreate(createLeaveSubcategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteLeaveSubcategoryDto} deleteLeaveSubcategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveSubcategoryApi
     */
    public utilLeaveSubcategoryControllerDelete(deleteLeaveSubcategoryDto: DeleteLeaveSubcategoryDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveSubcategoryApiFp(this.configuration).utilLeaveSubcategoryControllerDelete(deleteLeaveSubcategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditLeaveSubcategoryDto} editLeaveSubcategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveSubcategoryApi
     */
    public utilLeaveSubcategoryControllerEdit(editLeaveSubcategoryDto: EditLeaveSubcategoryDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveSubcategoryApiFp(this.configuration).utilLeaveSubcategoryControllerEdit(editLeaveSubcategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveSubcategoryApi
     */
    public utilLeaveSubcategoryControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityLeaveSubcategoryApiFp(this.configuration).utilLeaveSubcategoryControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityLeaveTypeApi - axios parameter creator
 * @export
 */
export const UtilityLeaveTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLeaveTypeDto} createLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerCreate: async (createLeaveTypeDto: CreateLeaveTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeaveTypeDto' is not null or undefined
            assertParamExists('utilLeaveTypeControllerCreate', 'createLeaveTypeDto', createLeaveTypeDto)
            const localVarPath = `/api/v1/util-leave-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeaveTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteLeaveTypeDto} deleteLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerDelete: async (deleteLeaveTypeDto: DeleteLeaveTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteLeaveTypeDto' is not null or undefined
            assertParamExists('utilLeaveTypeControllerDelete', 'deleteLeaveTypeDto', deleteLeaveTypeDto)
            const localVarPath = `/api/v1/util-leave-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteLeaveTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditLeaveTypeDto} editLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerEdit: async (editLeaveTypeDto: EditLeaveTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editLeaveTypeDto' is not null or undefined
            assertParamExists('utilLeaveTypeControllerEdit', 'editLeaveTypeDto', editLeaveTypeDto)
            const localVarPath = `/api/v1/util-leave-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editLeaveTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-leave-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityLeaveTypeApi - functional programming interface
 * @export
 */
export const UtilityLeaveTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityLeaveTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLeaveTypeDto} createLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveTypeControllerCreate(createLeaveTypeDto: CreateLeaveTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveTypeControllerCreate(createLeaveTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveTypeApi.utilLeaveTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteLeaveTypeDto} deleteLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveTypeControllerDelete(deleteLeaveTypeDto: DeleteLeaveTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveTypeControllerDelete(deleteLeaveTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveTypeApi.utilLeaveTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditLeaveTypeDto} editLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveTypeControllerEdit(editLeaveTypeDto: EditLeaveTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveTypeControllerEdit(editLeaveTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveTypeApi.utilLeaveTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilLeaveTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilLeaveTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilLeaveTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityLeaveTypeApi.utilLeaveTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityLeaveTypeApi - factory interface
 * @export
 */
export const UtilityLeaveTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityLeaveTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLeaveTypeDto} createLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerCreate(createLeaveTypeDto: CreateLeaveTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveTypeControllerCreate(createLeaveTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteLeaveTypeDto} deleteLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerDelete(deleteLeaveTypeDto: DeleteLeaveTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveTypeControllerDelete(deleteLeaveTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditLeaveTypeDto} editLeaveTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerEdit(editLeaveTypeDto: EditLeaveTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilLeaveTypeControllerEdit(editLeaveTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilLeaveTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilLeaveTypeControllerGet200Response> {
            return localVarFp.utilLeaveTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityLeaveTypeApi - object-oriented interface
 * @export
 * @class UtilityLeaveTypeApi
 * @extends {BaseAPI}
 */
export class UtilityLeaveTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateLeaveTypeDto} createLeaveTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveTypeApi
     */
    public utilLeaveTypeControllerCreate(createLeaveTypeDto: CreateLeaveTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveTypeApiFp(this.configuration).utilLeaveTypeControllerCreate(createLeaveTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteLeaveTypeDto} deleteLeaveTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveTypeApi
     */
    public utilLeaveTypeControllerDelete(deleteLeaveTypeDto: DeleteLeaveTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveTypeApiFp(this.configuration).utilLeaveTypeControllerDelete(deleteLeaveTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditLeaveTypeDto} editLeaveTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveTypeApi
     */
    public utilLeaveTypeControllerEdit(editLeaveTypeDto: EditLeaveTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityLeaveTypeApiFp(this.configuration).utilLeaveTypeControllerEdit(editLeaveTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityLeaveTypeApi
     */
    public utilLeaveTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityLeaveTypeApiFp(this.configuration).utilLeaveTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityMemoApi - axios parameter creator
 * @export
 */
export const UtilityMemoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMemoDto} createMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerCreate: async (createMemoDto: CreateMemoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMemoDto' is not null or undefined
            assertParamExists('utilMemoControllerCreate', 'createMemoDto', createMemoDto)
            const localVarPath = `/api/v1/util-memo/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMemoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteMemoDto} deleteMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerDelete: async (deleteMemoDto: DeleteMemoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteMemoDto' is not null or undefined
            assertParamExists('utilMemoControllerDelete', 'deleteMemoDto', deleteMemoDto)
            const localVarPath = `/api/v1/util-memo/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteMemoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditMemoDto} editMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerEdit: async (editMemoDto: EditMemoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editMemoDto' is not null or undefined
            assertParamExists('utilMemoControllerEdit', 'editMemoDto', editMemoDto)
            const localVarPath = `/api/v1/util-memo/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editMemoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-memo/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityMemoApi - functional programming interface
 * @export
 */
export const UtilityMemoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityMemoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMemoDto} createMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilMemoControllerCreate(createMemoDto: CreateMemoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilMemoControllerCreate(createMemoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityMemoApi.utilMemoControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteMemoDto} deleteMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilMemoControllerDelete(deleteMemoDto: DeleteMemoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilMemoControllerDelete(deleteMemoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityMemoApi.utilMemoControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditMemoDto} editMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilMemoControllerEdit(editMemoDto: EditMemoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilMemoControllerEdit(editMemoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityMemoApi.utilMemoControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilMemoControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilMemoControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilMemoControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityMemoApi.utilMemoControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityMemoApi - factory interface
 * @export
 */
export const UtilityMemoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityMemoApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMemoDto} createMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerCreate(createMemoDto: CreateMemoDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilMemoControllerCreate(createMemoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteMemoDto} deleteMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerDelete(deleteMemoDto: DeleteMemoDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilMemoControllerDelete(deleteMemoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditMemoDto} editMemoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerEdit(editMemoDto: EditMemoDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilMemoControllerEdit(editMemoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilMemoControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilMemoControllerGet200Response> {
            return localVarFp.utilMemoControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityMemoApi - object-oriented interface
 * @export
 * @class UtilityMemoApi
 * @extends {BaseAPI}
 */
export class UtilityMemoApi extends BaseAPI {
    /**
     * 
     * @param {CreateMemoDto} createMemoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityMemoApi
     */
    public utilMemoControllerCreate(createMemoDto: CreateMemoDto, options?: RawAxiosRequestConfig) {
        return UtilityMemoApiFp(this.configuration).utilMemoControllerCreate(createMemoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteMemoDto} deleteMemoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityMemoApi
     */
    public utilMemoControllerDelete(deleteMemoDto: DeleteMemoDto, options?: RawAxiosRequestConfig) {
        return UtilityMemoApiFp(this.configuration).utilMemoControllerDelete(deleteMemoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditMemoDto} editMemoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityMemoApi
     */
    public utilMemoControllerEdit(editMemoDto: EditMemoDto, options?: RawAxiosRequestConfig) {
        return UtilityMemoApiFp(this.configuration).utilMemoControllerEdit(editMemoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityMemoApi
     */
    public utilMemoControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityMemoApiFp(this.configuration).utilMemoControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityPaymentMethodApi - axios parameter creator
 * @export
 */
export const UtilityPaymentMethodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerCreate: async (createPaymentMethodDto: CreatePaymentMethodDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentMethodDto' is not null or undefined
            assertParamExists('utilPaymentMethodControllerCreate', 'createPaymentMethodDto', createPaymentMethodDto)
            const localVarPath = `/api/v1/util-payment-method/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePaymentMethodDto} deletePaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerDelete: async (deletePaymentMethodDto: DeletePaymentMethodDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePaymentMethodDto' is not null or undefined
            assertParamExists('utilPaymentMethodControllerDelete', 'deletePaymentMethodDto', deletePaymentMethodDto)
            const localVarPath = `/api/v1/util-payment-method/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPaymentMethodDto} editPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerEdit: async (editPaymentMethodDto: EditPaymentMethodDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPaymentMethodDto' is not null or undefined
            assertParamExists('utilPaymentMethodControllerEdit', 'editPaymentMethodDto', editPaymentMethodDto)
            const localVarPath = `/api/v1/util-payment-method/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-payment-method/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityPaymentMethodApi - functional programming interface
 * @export
 */
export const UtilityPaymentMethodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityPaymentMethodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPaymentMethodControllerCreate(createPaymentMethodDto: CreatePaymentMethodDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPaymentMethodControllerCreate(createPaymentMethodDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPaymentMethodApi.utilPaymentMethodControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeletePaymentMethodDto} deletePaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPaymentMethodControllerDelete(deletePaymentMethodDto: DeletePaymentMethodDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPaymentMethodControllerDelete(deletePaymentMethodDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPaymentMethodApi.utilPaymentMethodControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditPaymentMethodDto} editPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPaymentMethodControllerEdit(editPaymentMethodDto: EditPaymentMethodDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPaymentMethodControllerEdit(editPaymentMethodDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPaymentMethodApi.utilPaymentMethodControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPaymentMethodControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilPaymentMethodControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPaymentMethodControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPaymentMethodApi.utilPaymentMethodControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityPaymentMethodApi - factory interface
 * @export
 */
export const UtilityPaymentMethodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityPaymentMethodApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerCreate(createPaymentMethodDto: CreatePaymentMethodDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilPaymentMethodControllerCreate(createPaymentMethodDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePaymentMethodDto} deletePaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerDelete(deletePaymentMethodDto: DeletePaymentMethodDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilPaymentMethodControllerDelete(deletePaymentMethodDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPaymentMethodDto} editPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerEdit(editPaymentMethodDto: EditPaymentMethodDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilPaymentMethodControllerEdit(editPaymentMethodDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPaymentMethodControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilPaymentMethodControllerGet200Response> {
            return localVarFp.utilPaymentMethodControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityPaymentMethodApi - object-oriented interface
 * @export
 * @class UtilityPaymentMethodApi
 * @extends {BaseAPI}
 */
export class UtilityPaymentMethodApi extends BaseAPI {
    /**
     * 
     * @param {CreatePaymentMethodDto} createPaymentMethodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPaymentMethodApi
     */
    public utilPaymentMethodControllerCreate(createPaymentMethodDto: CreatePaymentMethodDto, options?: RawAxiosRequestConfig) {
        return UtilityPaymentMethodApiFp(this.configuration).utilPaymentMethodControllerCreate(createPaymentMethodDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePaymentMethodDto} deletePaymentMethodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPaymentMethodApi
     */
    public utilPaymentMethodControllerDelete(deletePaymentMethodDto: DeletePaymentMethodDto, options?: RawAxiosRequestConfig) {
        return UtilityPaymentMethodApiFp(this.configuration).utilPaymentMethodControllerDelete(deletePaymentMethodDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPaymentMethodDto} editPaymentMethodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPaymentMethodApi
     */
    public utilPaymentMethodControllerEdit(editPaymentMethodDto: EditPaymentMethodDto, options?: RawAxiosRequestConfig) {
        return UtilityPaymentMethodApiFp(this.configuration).utilPaymentMethodControllerEdit(editPaymentMethodDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPaymentMethodApi
     */
    public utilPaymentMethodControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityPaymentMethodApiFp(this.configuration).utilPaymentMethodControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityPenaltyScheduleApi - axios parameter creator
 * @export
 */
export const UtilityPenaltyScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePenaltyScheduleDto} createPenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerCreate: async (createPenaltyScheduleDto: CreatePenaltyScheduleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPenaltyScheduleDto' is not null or undefined
            assertParamExists('utilPenaltyScheduleControllerCreate', 'createPenaltyScheduleDto', createPenaltyScheduleDto)
            const localVarPath = `/api/v1/util-penalty-schedule/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPenaltyScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePenaltyScheduleDto} deletePenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerDelete: async (deletePenaltyScheduleDto: DeletePenaltyScheduleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePenaltyScheduleDto' is not null or undefined
            assertParamExists('utilPenaltyScheduleControllerDelete', 'deletePenaltyScheduleDto', deletePenaltyScheduleDto)
            const localVarPath = `/api/v1/util-penalty-schedule/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePenaltyScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPenaltyScheduleDto} editPenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerEdit: async (editPenaltyScheduleDto: EditPenaltyScheduleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPenaltyScheduleDto' is not null or undefined
            assertParamExists('utilPenaltyScheduleControllerEdit', 'editPenaltyScheduleDto', editPenaltyScheduleDto)
            const localVarPath = `/api/v1/util-penalty-schedule/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPenaltyScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-penalty-schedule/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityPenaltyScheduleApi - functional programming interface
 * @export
 */
export const UtilityPenaltyScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityPenaltyScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePenaltyScheduleDto} createPenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPenaltyScheduleControllerCreate(createPenaltyScheduleDto: CreatePenaltyScheduleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPenaltyScheduleControllerCreate(createPenaltyScheduleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPenaltyScheduleApi.utilPenaltyScheduleControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeletePenaltyScheduleDto} deletePenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPenaltyScheduleControllerDelete(deletePenaltyScheduleDto: DeletePenaltyScheduleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPenaltyScheduleControllerDelete(deletePenaltyScheduleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPenaltyScheduleApi.utilPenaltyScheduleControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditPenaltyScheduleDto} editPenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPenaltyScheduleControllerEdit(editPenaltyScheduleDto: EditPenaltyScheduleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPenaltyScheduleControllerEdit(editPenaltyScheduleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPenaltyScheduleApi.utilPenaltyScheduleControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilPenaltyScheduleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilPenaltyScheduleControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilPenaltyScheduleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityPenaltyScheduleApi.utilPenaltyScheduleControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityPenaltyScheduleApi - factory interface
 * @export
 */
export const UtilityPenaltyScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityPenaltyScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePenaltyScheduleDto} createPenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerCreate(createPenaltyScheduleDto: CreatePenaltyScheduleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilPenaltyScheduleControllerCreate(createPenaltyScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePenaltyScheduleDto} deletePenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerDelete(deletePenaltyScheduleDto: DeletePenaltyScheduleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilPenaltyScheduleControllerDelete(deletePenaltyScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPenaltyScheduleDto} editPenaltyScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerEdit(editPenaltyScheduleDto: EditPenaltyScheduleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilPenaltyScheduleControllerEdit(editPenaltyScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilPenaltyScheduleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilPenaltyScheduleControllerGet200Response> {
            return localVarFp.utilPenaltyScheduleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityPenaltyScheduleApi - object-oriented interface
 * @export
 * @class UtilityPenaltyScheduleApi
 * @extends {BaseAPI}
 */
export class UtilityPenaltyScheduleApi extends BaseAPI {
    /**
     * 
     * @param {CreatePenaltyScheduleDto} createPenaltyScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPenaltyScheduleApi
     */
    public utilPenaltyScheduleControllerCreate(createPenaltyScheduleDto: CreatePenaltyScheduleDto, options?: RawAxiosRequestConfig) {
        return UtilityPenaltyScheduleApiFp(this.configuration).utilPenaltyScheduleControllerCreate(createPenaltyScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePenaltyScheduleDto} deletePenaltyScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPenaltyScheduleApi
     */
    public utilPenaltyScheduleControllerDelete(deletePenaltyScheduleDto: DeletePenaltyScheduleDto, options?: RawAxiosRequestConfig) {
        return UtilityPenaltyScheduleApiFp(this.configuration).utilPenaltyScheduleControllerDelete(deletePenaltyScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPenaltyScheduleDto} editPenaltyScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPenaltyScheduleApi
     */
    public utilPenaltyScheduleControllerEdit(editPenaltyScheduleDto: EditPenaltyScheduleDto, options?: RawAxiosRequestConfig) {
        return UtilityPenaltyScheduleApiFp(this.configuration).utilPenaltyScheduleControllerEdit(editPenaltyScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityPenaltyScheduleApi
     */
    public utilPenaltyScheduleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityPenaltyScheduleApiFp(this.configuration).utilPenaltyScheduleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityQuestionnaireApi - axios parameter creator
 * @export
 */
export const UtilityQuestionnaireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateQuestionnaireDto} createQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerCreate: async (createQuestionnaireDto: CreateQuestionnaireDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createQuestionnaireDto' is not null or undefined
            assertParamExists('utilQuestionnaireControllerCreate', 'createQuestionnaireDto', createQuestionnaireDto)
            const localVarPath = `/api/v1/util-questionnaire/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQuestionnaireDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteQuestionnaireDto} deleteQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerDelete: async (deleteQuestionnaireDto: DeleteQuestionnaireDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteQuestionnaireDto' is not null or undefined
            assertParamExists('utilQuestionnaireControllerDelete', 'deleteQuestionnaireDto', deleteQuestionnaireDto)
            const localVarPath = `/api/v1/util-questionnaire/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteQuestionnaireDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditQuestionnaireDto} editQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerEdit: async (editQuestionnaireDto: EditQuestionnaireDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editQuestionnaireDto' is not null or undefined
            assertParamExists('utilQuestionnaireControllerEdit', 'editQuestionnaireDto', editQuestionnaireDto)
            const localVarPath = `/api/v1/util-questionnaire/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editQuestionnaireDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-questionnaire/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityQuestionnaireApi - functional programming interface
 * @export
 */
export const UtilityQuestionnaireApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityQuestionnaireApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateQuestionnaireDto} createQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilQuestionnaireControllerCreate(createQuestionnaireDto: CreateQuestionnaireDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilQuestionnaireControllerCreate(createQuestionnaireDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityQuestionnaireApi.utilQuestionnaireControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteQuestionnaireDto} deleteQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilQuestionnaireControllerDelete(deleteQuestionnaireDto: DeleteQuestionnaireDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilQuestionnaireControllerDelete(deleteQuestionnaireDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityQuestionnaireApi.utilQuestionnaireControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditQuestionnaireDto} editQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilQuestionnaireControllerEdit(editQuestionnaireDto: EditQuestionnaireDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilQuestionnaireControllerEdit(editQuestionnaireDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityQuestionnaireApi.utilQuestionnaireControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilQuestionnaireControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilQuestionnaireControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilQuestionnaireControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityQuestionnaireApi.utilQuestionnaireControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityQuestionnaireApi - factory interface
 * @export
 */
export const UtilityQuestionnaireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityQuestionnaireApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateQuestionnaireDto} createQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerCreate(createQuestionnaireDto: CreateQuestionnaireDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilQuestionnaireControllerCreate(createQuestionnaireDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteQuestionnaireDto} deleteQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerDelete(deleteQuestionnaireDto: DeleteQuestionnaireDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilQuestionnaireControllerDelete(deleteQuestionnaireDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditQuestionnaireDto} editQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerEdit(editQuestionnaireDto: EditQuestionnaireDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilQuestionnaireControllerEdit(editQuestionnaireDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilQuestionnaireControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilQuestionnaireControllerGet200Response> {
            return localVarFp.utilQuestionnaireControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityQuestionnaireApi - object-oriented interface
 * @export
 * @class UtilityQuestionnaireApi
 * @extends {BaseAPI}
 */
export class UtilityQuestionnaireApi extends BaseAPI {
    /**
     * 
     * @param {CreateQuestionnaireDto} createQuestionnaireDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityQuestionnaireApi
     */
    public utilQuestionnaireControllerCreate(createQuestionnaireDto: CreateQuestionnaireDto, options?: RawAxiosRequestConfig) {
        return UtilityQuestionnaireApiFp(this.configuration).utilQuestionnaireControllerCreate(createQuestionnaireDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteQuestionnaireDto} deleteQuestionnaireDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityQuestionnaireApi
     */
    public utilQuestionnaireControllerDelete(deleteQuestionnaireDto: DeleteQuestionnaireDto, options?: RawAxiosRequestConfig) {
        return UtilityQuestionnaireApiFp(this.configuration).utilQuestionnaireControllerDelete(deleteQuestionnaireDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditQuestionnaireDto} editQuestionnaireDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityQuestionnaireApi
     */
    public utilQuestionnaireControllerEdit(editQuestionnaireDto: EditQuestionnaireDto, options?: RawAxiosRequestConfig) {
        return UtilityQuestionnaireApiFp(this.configuration).utilQuestionnaireControllerEdit(editQuestionnaireDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityQuestionnaireApi
     */
    public utilQuestionnaireControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityQuestionnaireApiFp(this.configuration).utilQuestionnaireControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityRequestTypeApi - axios parameter creator
 * @export
 */
export const UtilityRequestTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRequestTypeDto} createRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerCreate: async (createRequestTypeDto: CreateRequestTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequestTypeDto' is not null or undefined
            assertParamExists('utilRequestTypeControllerCreate', 'createRequestTypeDto', createRequestTypeDto)
            const localVarPath = `/api/v1/util-request-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequestTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteRequestTypeDto} deleteRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerDelete: async (deleteRequestTypeDto: DeleteRequestTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteRequestTypeDto' is not null or undefined
            assertParamExists('utilRequestTypeControllerDelete', 'deleteRequestTypeDto', deleteRequestTypeDto)
            const localVarPath = `/api/v1/util-request-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRequestTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditRequestTypeDto} editRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerEdit: async (editRequestTypeDto: EditRequestTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editRequestTypeDto' is not null or undefined
            assertParamExists('utilRequestTypeControllerEdit', 'editRequestTypeDto', editRequestTypeDto)
            const localVarPath = `/api/v1/util-request-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequestTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-request-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityRequestTypeApi - functional programming interface
 * @export
 */
export const UtilityRequestTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityRequestTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRequestTypeDto} createRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRequestTypeControllerCreate(createRequestTypeDto: CreateRequestTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRequestTypeControllerCreate(createRequestTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRequestTypeApi.utilRequestTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteRequestTypeDto} deleteRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRequestTypeControllerDelete(deleteRequestTypeDto: DeleteRequestTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRequestTypeControllerDelete(deleteRequestTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRequestTypeApi.utilRequestTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditRequestTypeDto} editRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRequestTypeControllerEdit(editRequestTypeDto: EditRequestTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRequestTypeControllerEdit(editRequestTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRequestTypeApi.utilRequestTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRequestTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilRequestTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRequestTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRequestTypeApi.utilRequestTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityRequestTypeApi - factory interface
 * @export
 */
export const UtilityRequestTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityRequestTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRequestTypeDto} createRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerCreate(createRequestTypeDto: CreateRequestTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilRequestTypeControllerCreate(createRequestTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteRequestTypeDto} deleteRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerDelete(deleteRequestTypeDto: DeleteRequestTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilRequestTypeControllerDelete(deleteRequestTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditRequestTypeDto} editRequestTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerEdit(editRequestTypeDto: EditRequestTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilRequestTypeControllerEdit(editRequestTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRequestTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilRequestTypeControllerGet200Response> {
            return localVarFp.utilRequestTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityRequestTypeApi - object-oriented interface
 * @export
 * @class UtilityRequestTypeApi
 * @extends {BaseAPI}
 */
export class UtilityRequestTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateRequestTypeDto} createRequestTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRequestTypeApi
     */
    public utilRequestTypeControllerCreate(createRequestTypeDto: CreateRequestTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityRequestTypeApiFp(this.configuration).utilRequestTypeControllerCreate(createRequestTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteRequestTypeDto} deleteRequestTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRequestTypeApi
     */
    public utilRequestTypeControllerDelete(deleteRequestTypeDto: DeleteRequestTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityRequestTypeApiFp(this.configuration).utilRequestTypeControllerDelete(deleteRequestTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditRequestTypeDto} editRequestTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRequestTypeApi
     */
    public utilRequestTypeControllerEdit(editRequestTypeDto: EditRequestTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityRequestTypeApiFp(this.configuration).utilRequestTypeControllerEdit(editRequestTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRequestTypeApi
     */
    public utilRequestTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityRequestTypeApiFp(this.configuration).utilRequestTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityRoleApi - axios parameter creator
 * @export
 */
export const UtilityRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerCreate: async (createRoleDto: CreateRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('utilRoleControllerCreate', 'createRoleDto', createRoleDto)
            const localVarPath = `/api/v1/util-role/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteRoleDto} deleteRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerDelete: async (deleteRoleDto: DeleteRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteRoleDto' is not null or undefined
            assertParamExists('utilRoleControllerDelete', 'deleteRoleDto', deleteRoleDto)
            const localVarPath = `/api/v1/util-role/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditRoleDto} editRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerEdit: async (editRoleDto: EditRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editRoleDto' is not null or undefined
            assertParamExists('utilRoleControllerEdit', 'editRoleDto', editRoleDto)
            const localVarPath = `/api/v1/util-role/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-role/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityRoleApi - functional programming interface
 * @export
 */
export const UtilityRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRoleControllerCreate(createRoleDto: CreateRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRoleControllerCreate(createRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRoleApi.utilRoleControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteRoleDto} deleteRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRoleControllerDelete(deleteRoleDto: DeleteRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRoleControllerDelete(deleteRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRoleApi.utilRoleControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditRoleDto} editRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRoleControllerEdit(editRoleDto: EditRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRoleControllerEdit(editRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRoleApi.utilRoleControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilRoleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilRoleControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilRoleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityRoleApi.utilRoleControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityRoleApi - factory interface
 * @export
 */
export const UtilityRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityRoleApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerCreate(createRoleDto: CreateRoleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilRoleControllerCreate(createRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteRoleDto} deleteRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerDelete(deleteRoleDto: DeleteRoleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilRoleControllerDelete(deleteRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditRoleDto} editRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerEdit(editRoleDto: EditRoleDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilRoleControllerEdit(editRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilRoleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilRoleControllerGet200Response> {
            return localVarFp.utilRoleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityRoleApi - object-oriented interface
 * @export
 * @class UtilityRoleApi
 * @extends {BaseAPI}
 */
export class UtilityRoleApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoleDto} createRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRoleApi
     */
    public utilRoleControllerCreate(createRoleDto: CreateRoleDto, options?: RawAxiosRequestConfig) {
        return UtilityRoleApiFp(this.configuration).utilRoleControllerCreate(createRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteRoleDto} deleteRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRoleApi
     */
    public utilRoleControllerDelete(deleteRoleDto: DeleteRoleDto, options?: RawAxiosRequestConfig) {
        return UtilityRoleApiFp(this.configuration).utilRoleControllerDelete(deleteRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditRoleDto} editRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRoleApi
     */
    public utilRoleControllerEdit(editRoleDto: EditRoleDto, options?: RawAxiosRequestConfig) {
        return UtilityRoleApiFp(this.configuration).utilRoleControllerEdit(editRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityRoleApi
     */
    public utilRoleControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityRoleApiFp(this.configuration).utilRoleControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilitySalaryTypeApi - axios parameter creator
 * @export
 */
export const UtilitySalaryTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSalaryTypeDto} createSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerCreate: async (createSalaryTypeDto: CreateSalaryTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSalaryTypeDto' is not null or undefined
            assertParamExists('utilSalaryTypeControllerCreate', 'createSalaryTypeDto', createSalaryTypeDto)
            const localVarPath = `/api/v1/util-salary-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSalaryTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteSalaryTypeDto} deleteSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerDelete: async (deleteSalaryTypeDto: DeleteSalaryTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSalaryTypeDto' is not null or undefined
            assertParamExists('utilSalaryTypeControllerDelete', 'deleteSalaryTypeDto', deleteSalaryTypeDto)
            const localVarPath = `/api/v1/util-salary-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSalaryTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditSalaryTypeDto} editSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerEdit: async (editSalaryTypeDto: EditSalaryTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editSalaryTypeDto' is not null or undefined
            assertParamExists('utilSalaryTypeControllerEdit', 'editSalaryTypeDto', editSalaryTypeDto)
            const localVarPath = `/api/v1/util-salary-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSalaryTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-salary-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitySalaryTypeApi - functional programming interface
 * @export
 */
export const UtilitySalaryTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitySalaryTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSalaryTypeDto} createSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilSalaryTypeControllerCreate(createSalaryTypeDto: CreateSalaryTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilSalaryTypeControllerCreate(createSalaryTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitySalaryTypeApi.utilSalaryTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteSalaryTypeDto} deleteSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilSalaryTypeControllerDelete(deleteSalaryTypeDto: DeleteSalaryTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilSalaryTypeControllerDelete(deleteSalaryTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitySalaryTypeApi.utilSalaryTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditSalaryTypeDto} editSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilSalaryTypeControllerEdit(editSalaryTypeDto: EditSalaryTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilSalaryTypeControllerEdit(editSalaryTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitySalaryTypeApi.utilSalaryTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilSalaryTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilSalaryTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilSalaryTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitySalaryTypeApi.utilSalaryTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilitySalaryTypeApi - factory interface
 * @export
 */
export const UtilitySalaryTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitySalaryTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSalaryTypeDto} createSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerCreate(createSalaryTypeDto: CreateSalaryTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilSalaryTypeControllerCreate(createSalaryTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteSalaryTypeDto} deleteSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerDelete(deleteSalaryTypeDto: DeleteSalaryTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilSalaryTypeControllerDelete(deleteSalaryTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditSalaryTypeDto} editSalaryTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerEdit(editSalaryTypeDto: EditSalaryTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilSalaryTypeControllerEdit(editSalaryTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilSalaryTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilSalaryTypeControllerGet200Response> {
            return localVarFp.utilSalaryTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitySalaryTypeApi - object-oriented interface
 * @export
 * @class UtilitySalaryTypeApi
 * @extends {BaseAPI}
 */
export class UtilitySalaryTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateSalaryTypeDto} createSalaryTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitySalaryTypeApi
     */
    public utilSalaryTypeControllerCreate(createSalaryTypeDto: CreateSalaryTypeDto, options?: RawAxiosRequestConfig) {
        return UtilitySalaryTypeApiFp(this.configuration).utilSalaryTypeControllerCreate(createSalaryTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteSalaryTypeDto} deleteSalaryTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitySalaryTypeApi
     */
    public utilSalaryTypeControllerDelete(deleteSalaryTypeDto: DeleteSalaryTypeDto, options?: RawAxiosRequestConfig) {
        return UtilitySalaryTypeApiFp(this.configuration).utilSalaryTypeControllerDelete(deleteSalaryTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditSalaryTypeDto} editSalaryTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitySalaryTypeApi
     */
    public utilSalaryTypeControllerEdit(editSalaryTypeDto: EditSalaryTypeDto, options?: RawAxiosRequestConfig) {
        return UtilitySalaryTypeApiFp(this.configuration).utilSalaryTypeControllerEdit(editSalaryTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitySalaryTypeApi
     */
    public utilSalaryTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilitySalaryTypeApiFp(this.configuration).utilSalaryTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityShiftApi - axios parameter creator
 * @export
 */
export const UtilityShiftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateShiftDto} createShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerCreate: async (createShiftDto: CreateShiftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShiftDto' is not null or undefined
            assertParamExists('utilShiftControllerCreate', 'createShiftDto', createShiftDto)
            const localVarPath = `/api/v1/util-shift/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteShiftDto} deleteShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerDelete: async (deleteShiftDto: DeleteShiftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteShiftDto' is not null or undefined
            assertParamExists('utilShiftControllerDelete', 'deleteShiftDto', deleteShiftDto)
            const localVarPath = `/api/v1/util-shift/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditShiftDto} editShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerEdit: async (editShiftDto: EditShiftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editShiftDto' is not null or undefined
            assertParamExists('utilShiftControllerEdit', 'editShiftDto', editShiftDto)
            const localVarPath = `/api/v1/util-shift/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-shift/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityShiftApi - functional programming interface
 * @export
 */
export const UtilityShiftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityShiftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateShiftDto} createShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftControllerCreate(createShiftDto: CreateShiftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftControllerCreate(createShiftDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftApi.utilShiftControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteShiftDto} deleteShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftControllerDelete(deleteShiftDto: DeleteShiftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftControllerDelete(deleteShiftDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftApi.utilShiftControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditShiftDto} editShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftControllerEdit(editShiftDto: EditShiftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftControllerEdit(editShiftDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftApi.utilShiftControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilShiftControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftApi.utilShiftControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityShiftApi - factory interface
 * @export
 */
export const UtilityShiftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityShiftApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateShiftDto} createShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerCreate(createShiftDto: CreateShiftDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilShiftControllerCreate(createShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteShiftDto} deleteShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerDelete(deleteShiftDto: DeleteShiftDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilShiftControllerDelete(deleteShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditShiftDto} editShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerEdit(editShiftDto: EditShiftDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilShiftControllerEdit(editShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilShiftControllerGet200Response> {
            return localVarFp.utilShiftControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityShiftApi - object-oriented interface
 * @export
 * @class UtilityShiftApi
 * @extends {BaseAPI}
 */
export class UtilityShiftApi extends BaseAPI {
    /**
     * 
     * @param {CreateShiftDto} createShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftApi
     */
    public utilShiftControllerCreate(createShiftDto: CreateShiftDto, options?: RawAxiosRequestConfig) {
        return UtilityShiftApiFp(this.configuration).utilShiftControllerCreate(createShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteShiftDto} deleteShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftApi
     */
    public utilShiftControllerDelete(deleteShiftDto: DeleteShiftDto, options?: RawAxiosRequestConfig) {
        return UtilityShiftApiFp(this.configuration).utilShiftControllerDelete(deleteShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditShiftDto} editShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftApi
     */
    public utilShiftControllerEdit(editShiftDto: EditShiftDto, options?: RawAxiosRequestConfig) {
        return UtilityShiftApiFp(this.configuration).utilShiftControllerEdit(editShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftApi
     */
    public utilShiftControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityShiftApiFp(this.configuration).utilShiftControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityShiftTypeApi - axios parameter creator
 * @export
 */
export const UtilityShiftTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateShiftTypeDto} createShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerCreate: async (createShiftTypeDto: CreateShiftTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShiftTypeDto' is not null or undefined
            assertParamExists('utilShiftTypeControllerCreate', 'createShiftTypeDto', createShiftTypeDto)
            const localVarPath = `/api/v1/util-shift-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShiftTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteShiftTypeDto} deleteShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerDelete: async (deleteShiftTypeDto: DeleteShiftTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteShiftTypeDto' is not null or undefined
            assertParamExists('utilShiftTypeControllerDelete', 'deleteShiftTypeDto', deleteShiftTypeDto)
            const localVarPath = `/api/v1/util-shift-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteShiftTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditShiftTypeDto} editShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerEdit: async (editShiftTypeDto: EditShiftTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editShiftTypeDto' is not null or undefined
            assertParamExists('utilShiftTypeControllerEdit', 'editShiftTypeDto', editShiftTypeDto)
            const localVarPath = `/api/v1/util-shift-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editShiftTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-shift-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityShiftTypeApi - functional programming interface
 * @export
 */
export const UtilityShiftTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityShiftTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateShiftTypeDto} createShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftTypeControllerCreate(createShiftTypeDto: CreateShiftTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftTypeControllerCreate(createShiftTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftTypeApi.utilShiftTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteShiftTypeDto} deleteShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftTypeControllerDelete(deleteShiftTypeDto: DeleteShiftTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftTypeControllerDelete(deleteShiftTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftTypeApi.utilShiftTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditShiftTypeDto} editShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftTypeControllerEdit(editShiftTypeDto: EditShiftTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftTypeControllerEdit(editShiftTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftTypeApi.utilShiftTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilShiftTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilShiftTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilShiftTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityShiftTypeApi.utilShiftTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityShiftTypeApi - factory interface
 * @export
 */
export const UtilityShiftTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityShiftTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateShiftTypeDto} createShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerCreate(createShiftTypeDto: CreateShiftTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilShiftTypeControllerCreate(createShiftTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteShiftTypeDto} deleteShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerDelete(deleteShiftTypeDto: DeleteShiftTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilShiftTypeControllerDelete(deleteShiftTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditShiftTypeDto} editShiftTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerEdit(editShiftTypeDto: EditShiftTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilShiftTypeControllerEdit(editShiftTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilShiftTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilShiftTypeControllerGet200Response> {
            return localVarFp.utilShiftTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityShiftTypeApi - object-oriented interface
 * @export
 * @class UtilityShiftTypeApi
 * @extends {BaseAPI}
 */
export class UtilityShiftTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateShiftTypeDto} createShiftTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftTypeApi
     */
    public utilShiftTypeControllerCreate(createShiftTypeDto: CreateShiftTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityShiftTypeApiFp(this.configuration).utilShiftTypeControllerCreate(createShiftTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteShiftTypeDto} deleteShiftTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftTypeApi
     */
    public utilShiftTypeControllerDelete(deleteShiftTypeDto: DeleteShiftTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityShiftTypeApiFp(this.configuration).utilShiftTypeControllerDelete(deleteShiftTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditShiftTypeDto} editShiftTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftTypeApi
     */
    public utilShiftTypeControllerEdit(editShiftTypeDto: EditShiftTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityShiftTypeApiFp(this.configuration).utilShiftTypeControllerEdit(editShiftTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityShiftTypeApi
     */
    public utilShiftTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityShiftTypeApiFp(this.configuration).utilShiftTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityStatusApi - axios parameter creator
 * @export
 */
export const UtilityStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStatusDto} createStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerCreate: async (createStatusDto: CreateStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStatusDto' is not null or undefined
            assertParamExists('utilStatusControllerCreate', 'createStatusDto', createStatusDto)
            const localVarPath = `/api/v1/util-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteStatusDto} deleteStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerDelete: async (deleteStatusDto: DeleteStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteStatusDto' is not null or undefined
            assertParamExists('utilStatusControllerDelete', 'deleteStatusDto', deleteStatusDto)
            const localVarPath = `/api/v1/util-status/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditStatusDto} editStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerEdit: async (editStatusDto: EditStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editStatusDto' is not null or undefined
            assertParamExists('utilStatusControllerEdit', 'editStatusDto', editStatusDto)
            const localVarPath = `/api/v1/util-status/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-status/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityStatusApi - functional programming interface
 * @export
 */
export const UtilityStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateStatusDto} createStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilStatusControllerCreate(createStatusDto: CreateStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilStatusControllerCreate(createStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityStatusApi.utilStatusControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteStatusDto} deleteStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilStatusControllerDelete(deleteStatusDto: DeleteStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilStatusControllerDelete(deleteStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityStatusApi.utilStatusControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditStatusDto} editStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilStatusControllerEdit(editStatusDto: EditStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilStatusControllerEdit(editStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityStatusApi.utilStatusControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilStatusControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilStatusControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilStatusControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityStatusApi.utilStatusControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityStatusApi - factory interface
 * @export
 */
export const UtilityStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityStatusApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateStatusDto} createStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerCreate(createStatusDto: CreateStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilStatusControllerCreate(createStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteStatusDto} deleteStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerDelete(deleteStatusDto: DeleteStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilStatusControllerDelete(deleteStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditStatusDto} editStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerEdit(editStatusDto: EditStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilStatusControllerEdit(editStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilStatusControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilStatusControllerGet200Response> {
            return localVarFp.utilStatusControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityStatusApi - object-oriented interface
 * @export
 * @class UtilityStatusApi
 * @extends {BaseAPI}
 */
export class UtilityStatusApi extends BaseAPI {
    /**
     * 
     * @param {CreateStatusDto} createStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityStatusApi
     */
    public utilStatusControllerCreate(createStatusDto: CreateStatusDto, options?: RawAxiosRequestConfig) {
        return UtilityStatusApiFp(this.configuration).utilStatusControllerCreate(createStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteStatusDto} deleteStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityStatusApi
     */
    public utilStatusControllerDelete(deleteStatusDto: DeleteStatusDto, options?: RawAxiosRequestConfig) {
        return UtilityStatusApiFp(this.configuration).utilStatusControllerDelete(deleteStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditStatusDto} editStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityStatusApi
     */
    public utilStatusControllerEdit(editStatusDto: EditStatusDto, options?: RawAxiosRequestConfig) {
        return UtilityStatusApiFp(this.configuration).utilStatusControllerEdit(editStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityStatusApi
     */
    public utilStatusControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityStatusApiFp(this.configuration).utilStatusControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityTerminationTypeApi - axios parameter creator
 * @export
 */
export const UtilityTerminationTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTerminationTypeDto} createTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerCreate: async (createTerminationTypeDto: CreateTerminationTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTerminationTypeDto' is not null or undefined
            assertParamExists('utilTerminationTypeControllerCreate', 'createTerminationTypeDto', createTerminationTypeDto)
            const localVarPath = `/api/v1/util-termination-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTerminationTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteTerminationTypeDto} deleteTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerDelete: async (deleteTerminationTypeDto: DeleteTerminationTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTerminationTypeDto' is not null or undefined
            assertParamExists('utilTerminationTypeControllerDelete', 'deleteTerminationTypeDto', deleteTerminationTypeDto)
            const localVarPath = `/api/v1/util-termination-type/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTerminationTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditTerminationTypeDto} editTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerEdit: async (editTerminationTypeDto: EditTerminationTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editTerminationTypeDto' is not null or undefined
            assertParamExists('utilTerminationTypeControllerEdit', 'editTerminationTypeDto', editTerminationTypeDto)
            const localVarPath = `/api/v1/util-termination-type/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editTerminationTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-termination-type/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityTerminationTypeApi - functional programming interface
 * @export
 */
export const UtilityTerminationTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityTerminationTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTerminationTypeDto} createTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilTerminationTypeControllerCreate(createTerminationTypeDto: CreateTerminationTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilTerminationTypeControllerCreate(createTerminationTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityTerminationTypeApi.utilTerminationTypeControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteTerminationTypeDto} deleteTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilTerminationTypeControllerDelete(deleteTerminationTypeDto: DeleteTerminationTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilTerminationTypeControllerDelete(deleteTerminationTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityTerminationTypeApi.utilTerminationTypeControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditTerminationTypeDto} editTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilTerminationTypeControllerEdit(editTerminationTypeDto: EditTerminationTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilTerminationTypeControllerEdit(editTerminationTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityTerminationTypeApi.utilTerminationTypeControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilTerminationTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilTerminationTypeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilTerminationTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityTerminationTypeApi.utilTerminationTypeControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityTerminationTypeApi - factory interface
 * @export
 */
export const UtilityTerminationTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityTerminationTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTerminationTypeDto} createTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerCreate(createTerminationTypeDto: CreateTerminationTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilTerminationTypeControllerCreate(createTerminationTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteTerminationTypeDto} deleteTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerDelete(deleteTerminationTypeDto: DeleteTerminationTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilTerminationTypeControllerDelete(deleteTerminationTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditTerminationTypeDto} editTerminationTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerEdit(editTerminationTypeDto: EditTerminationTypeDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilTerminationTypeControllerEdit(editTerminationTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilTerminationTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilTerminationTypeControllerGet200Response> {
            return localVarFp.utilTerminationTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityTerminationTypeApi - object-oriented interface
 * @export
 * @class UtilityTerminationTypeApi
 * @extends {BaseAPI}
 */
export class UtilityTerminationTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateTerminationTypeDto} createTerminationTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityTerminationTypeApi
     */
    public utilTerminationTypeControllerCreate(createTerminationTypeDto: CreateTerminationTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityTerminationTypeApiFp(this.configuration).utilTerminationTypeControllerCreate(createTerminationTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteTerminationTypeDto} deleteTerminationTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityTerminationTypeApi
     */
    public utilTerminationTypeControllerDelete(deleteTerminationTypeDto: DeleteTerminationTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityTerminationTypeApiFp(this.configuration).utilTerminationTypeControllerDelete(deleteTerminationTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditTerminationTypeDto} editTerminationTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityTerminationTypeApi
     */
    public utilTerminationTypeControllerEdit(editTerminationTypeDto: EditTerminationTypeDto, options?: RawAxiosRequestConfig) {
        return UtilityTerminationTypeApiFp(this.configuration).utilTerminationTypeControllerEdit(editTerminationTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityTerminationTypeApi
     */
    public utilTerminationTypeControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityTerminationTypeApiFp(this.configuration).utilTerminationTypeControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilityViolationApi - axios parameter creator
 * @export
 */
export const UtilityViolationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateViolationDto} createViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerCreate: async (createViolationDto: CreateViolationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createViolationDto' is not null or undefined
            assertParamExists('utilViolationControllerCreate', 'createViolationDto', createViolationDto)
            const localVarPath = `/api/v1/util-violation/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createViolationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteViolationDto} deleteViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerDelete: async (deleteViolationDto: DeleteViolationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteViolationDto' is not null or undefined
            assertParamExists('utilViolationControllerDelete', 'deleteViolationDto', deleteViolationDto)
            const localVarPath = `/api/v1/util-violation/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteViolationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditViolationDto} editViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerEdit: async (editViolationDto: EditViolationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editViolationDto' is not null or undefined
            assertParamExists('utilViolationControllerEdit', 'editViolationDto', editViolationDto)
            const localVarPath = `/api/v1/util-violation/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editViolationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerGet: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/util-violation/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                for (const [key, value] of Object.entries(order)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityViolationApi - functional programming interface
 * @export
 */
export const UtilityViolationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityViolationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateViolationDto} createViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilViolationControllerCreate(createViolationDto: CreateViolationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilViolationControllerCreate(createViolationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityViolationApi.utilViolationControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteViolationDto} deleteViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilViolationControllerDelete(deleteViolationDto: DeleteViolationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilViolationControllerDelete(deleteViolationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityViolationApi.utilViolationControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditViolationDto} editViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilViolationControllerEdit(editViolationDto: EditViolationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilViolationControllerEdit(editViolationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityViolationApi.utilViolationControllerEdit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilViolationControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilViolationControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilViolationControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilityViolationApi.utilViolationControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilityViolationApi - factory interface
 * @export
 */
export const UtilityViolationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityViolationApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateViolationDto} createViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerCreate(createViolationDto: CreateViolationDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilViolationControllerCreate(createViolationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteViolationDto} deleteViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerDelete(deleteViolationDto: DeleteViolationDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilViolationControllerDelete(deleteViolationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditViolationDto} editViolationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerEdit(editViolationDto: EditViolationDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilViolationControllerEdit(editViolationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilViolationControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<UtilViolationControllerGet200Response> {
            return localVarFp.utilViolationControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityViolationApi - object-oriented interface
 * @export
 * @class UtilityViolationApi
 * @extends {BaseAPI}
 */
export class UtilityViolationApi extends BaseAPI {
    /**
     * 
     * @param {CreateViolationDto} createViolationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityViolationApi
     */
    public utilViolationControllerCreate(createViolationDto: CreateViolationDto, options?: RawAxiosRequestConfig) {
        return UtilityViolationApiFp(this.configuration).utilViolationControllerCreate(createViolationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteViolationDto} deleteViolationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityViolationApi
     */
    public utilViolationControllerDelete(deleteViolationDto: DeleteViolationDto, options?: RawAxiosRequestConfig) {
        return UtilityViolationApiFp(this.configuration).utilViolationControllerDelete(deleteViolationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditViolationDto} editViolationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityViolationApi
     */
    public utilViolationControllerEdit(editViolationDto: EditViolationDto, options?: RawAxiosRequestConfig) {
        return UtilityViolationApiFp(this.configuration).utilViolationControllerEdit(editViolationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityViolationApi
     */
    public utilViolationControllerGet(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return UtilityViolationApiFp(this.configuration).utilViolationControllerGet(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



