import classNames from "classnames";
import "./dot-label.scss";

export interface DotLabelProps {
  label: string;
  classBg?: string;
  classLabel?: string;
}

export function DotLabel({
  label,
  classBg = "bg-blue-500",
  classLabel,
}: DotLabelProps) {
  return (
    <div id="DotLabel" className="dot-label flex items-center gap-2">
      <span
        className={classNames("block rounded-full w-3 h-3", classBg)}
      ></span>
      <label className={classNames("font-bold", classLabel)}>{label}</label>
    </div>
  );
}

export default DotLabel;
