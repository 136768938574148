import { useState } from "react";
import { CreatePenaltyScheduleModel } from "@core/model/query-model-penalty-schedule";
import {
  useQyCreatePenaltySchedule,
  useQyGetPenaltySchedule,
} from "@core/query/penalty-schedule.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownPenaltySchedule = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newPenaltySchedule, setNewPenaltySchedule] =
    useState<CreatePenaltyScheduleModel>({
      name: "",
      description: "",
    });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewPenaltySchedule({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newPenaltySchedule.name) {
      showWarning("Please fill in PenaltySchedule details");
      return;
    }
    setIsCreating(true);
    addPenaltySchedule(newPenaltySchedule);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New PenaltySchedule is added. Check and select the PenaltySchedule in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addPenaltySchedule } = useQyCreatePenaltySchedule(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: penaltyScheduleResponse } = useQyGetPenaltySchedule("");
  const mappedPenaltySchedule = (penaltyScheduleResponse?.data || []).map(
    (item) =>
      ({
        label: item.description,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newPenaltySchedule,
    mappedPenaltySchedule,
    isCreating,
    penaltyScheduleResponse,
    setSidebar,
    setFilter,
    setNewPenaltySchedule,
    handleFilterInput,
    handleAdd,
    addPenaltySchedule,
  };
};
