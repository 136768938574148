import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownShiftType } from "./form-dropdown-shift-type.hook";

const FormDropdownShiftTypeContext = createContext(
  {} as ReturnType<typeof useFormDropdownShiftType>
);
export function useFormDropdownShiftTypeContext() {
  return useContext(FormDropdownShiftTypeContext);
}

interface FormDropdownShiftTypeProviderProps {
  children: ReactNode;
}
export function FormDropdownShiftTypeProvider({
  children,
}: FormDropdownShiftTypeProviderProps) {
  return (
    <FormDropdownShiftTypeContext.Provider value={useFormDropdownShiftType()}>
      {children}
    </FormDropdownShiftTypeContext.Provider>
  );
}
