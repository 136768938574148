import { Outlet } from "react-router-dom";

import { FilterPayrollProvider } from "./filter-payroll/filter-payroll.context";
import "./payroll.scss";
import { FormDropdownBranchProvider } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import { FormDropdownPayrollProvider } from "./form-dropdown-payroll/form-dropdown-payroll.context";

export function Payroll() {
  return (
    <div id="Payroll" className="payroll">
      <FormDropdownPayrollProvider>
        <FormDropdownBranchProvider>
          <FilterPayrollProvider>
            <Outlet />
          </FilterPayrollProvider>
        </FormDropdownBranchProvider>
      </FormDropdownPayrollProvider>
    </div>
  );
}

export default Payroll;
