import { Outlet } from "react-router-dom";

import { FilterJobGradeProvider } from "./filter-job-grade/filter-job-grade.context";
import './job-grade.scss';

export function JobGrade() {
  return (
    <div id="JobGrade" className="job-grade">
       <FilterJobGradeProvider>
        <Outlet />
      </FilterJobGradeProvider>
    </div>
  );
}

export default JobGrade;
