import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeAttachmentFormRule,
  EmployeeAttachmentFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeAttachment } from "@core/form/form-default-employee-attachment.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeAttachment,
  useQyGetEmployeeAttachmentById,
  useQyDeleteEmployeeAttachment,
} from "@core/query/employee-attachment.query";
import { GetResponseEmployeeAttachmentModel } from "@core/model/query-model-employee-attachment";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { viewAsset } from "@core/query/google-cloud-storage.query";

export function useEditEmployeeAttachment() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeAttachment();
  const navigate = useNavigate();
  const { mapUpdateEmployeeAttachment, mapDeleteEmployeeAttachment } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeAttachmentId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeAttachment
  const handleGetApiSuccess = async (
    data: GetResponseEmployeeAttachmentModel
  ) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("isActive", Boolean(responseData?.is_active));
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("source", responseData?.source || "");
      setValue("attachmentType", responseData?.attachment_type || "");
      setValue("requestCode", responseData?.request_code || "");
      setValue("mimeType", responseData?.mime_type || "");

      if (responseData?.file) {
        const assetFile = await viewAsset(responseData?.file);
        const blobFile = URL.createObjectURL(assetFile);
        setValue("file", blobFile);
      }
    }
  };
  const {
    data: employeeAttachmentResponse,
    isLoading,
    isError: employeeAttachmentError,
  } = useQyGetEmployeeAttachmentById(
    employeeAttachmentId || "",
    handleGetApiSuccess
  );
  const employeeAttachmentData = employeeAttachmentResponse?.data?.[0];

  // API UPDATE EmployeeAttachment
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeAttachment updated successfully");
  };
  const { mutate: updateEmployeeAttachment, isLoading: isEditLoading } =
    useQyUpdateEmployeeAttachment(handleUpdateApiSuccess);

  // API DELETE EmployeeAttachment
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeAttachment deleted successfully");
  };
  const { mutate: deleteEmployeeAttachment } = useQyDeleteEmployeeAttachment(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeAttachmentFormSchema>({
    defaultValues: getDefault(employeeAttachmentData),
    resolver: zodResolver(EmployeeAttachmentFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeAttachmentFormSchema) => {
    if (!employeeAttachmentData) {
      throw new Error("No employeeAttachment data");
    }

    const formData = mapUpdateEmployeeAttachment(
      form,
      employeeAttachmentData.code
    );
    updateEmployeeAttachment(formData);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeAttachmentFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeAttachmentData) {
      throw new Error("No employeeAttachment data found");
    }

    const form = mapDeleteEmployeeAttachment(employeeAttachmentData.code);
    deleteEmployeeAttachment(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeAttachmentId,
    employeeAttachmentData,
    isLoading,
    employeeAttachmentError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
